import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { getRequestedProposalsStatus, getSharedContacts } from '../planConfiguration';
import { clearDentalConfigurationState, setDentalPlans } from './dentalConfiguration.slice';

import { ERROR } from 'src/constants/errorNames';
import { calculateDentalVisionRates } from 'src/constants/dentalVisionRates';

export const getDentalPlans = createAsyncThunk(
  'dental-configuration/get-dental-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getDentalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getDentalQuotePlans = createAsyncThunk(
  'dental-configuration/get-dental-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getDentalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const updateDentalQuotePlanOrder = createAsyncThunk(
  'dental-configuration/update-dental-quote-plan-order',
  async (data) => {
    try {
      const { accountId, proposalId, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.planConfiguration.updateDentalQuotePlanOrder(accountId, proposalId, payload);
    } catch (err) {
      console.warn(err);
    }
  },
);

export const deleteDentalPlan = createAsyncThunk(
  'dental-configuration/delete-dental-plan',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, planId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.planConfiguration.deleteDentalPlanConfiguration(accountId, proposalId, planId);

      toast('Dental plan was deleted successfully!', { type: 'success' });

      thunkApi.dispatch(getDentalPlans({ accountId, proposalId }));
      thunkApi.dispatch(getDentalQuotePlans({ accountId, proposalId }));
      thunkApi.dispatch(getSharedContacts({ accountId, proposalId }));
      thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
    } catch (e) {
      console.warn(e);
    }
  },
);

export const getDentalOffers = createAsyncThunk(
  'dental-configuration/get-dental-offers',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading } = data;
      setIsLoading(true);

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      const userType = thunkApi.getState().app.userType;

      const response = await api.planConfiguration.getMedicalOffers(
        accountId,
        proposalId,
        'dental',
        configId,
      );

      if (userType === 'broker') {
        const groupMembers = thunkApi.getState().groupMembers.groupMembers;

        const brokerOffer = {
          ...brokerConfig,
          issuer_name: 'broker',
          status: 'selected',
        };

        const updatedResponse = [brokerOffer, ...response];

        const calculatedResponse = updatedResponse?.map((item) => {
          if (item?.status !== 'pending') {
            const calculatedRates = calculateDentalVisionRates(
              groupMembers,
              item,
              {},
              item?.selected_plan_name,
              'dental',
            );

            return { ...item, calculation: calculatedRates };
          }
          return item;
        });

        return setOffers(calculatedResponse);
      }

      return setOffers(response);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

export const getDentalQuotes = createAsyncThunk(
  'dental-configuration/get-dental-quotes',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading, read_token } =
        data;
      setIsLoading(true);

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'dental',
        configId,
        read_token,
      );

      const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      const brokerOffer = {
        ...brokerConfig,
        issuer_name: 'broker',
        status: 'selected',
      };

      const updatedResponse = [brokerOffer, ...response];

      const calculatedResponse = updatedResponse?.map((item) => {
        const calculatedRates = calculateDentalVisionRates(
          groupMembers,
          item,
          {},
          item?.selected_plan_name,
          'dental',
        );

        return { ...item, calculation: calculatedRates };
      });

      return setOffers(calculatedResponse);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

// ISSUER

export const getIssuerDentalPlans = createAsyncThunk(
  'issuer/get-issuer-dental-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.planConfiguration.getDentalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createDentalOffer = createAsyncThunk(
  'issuer/create-dental-offer',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, config_id } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = {
        ...data,
      };

      delete payload.comp_amount;
      delete payload.created_timestamp;

      delete payload.accountId;
      delete payload.proposal_id;
      delete payload.is_current_rate;
      delete payload.proposalId;
      delete payload.order_id;

      const response = await api.issuer.createCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'dental',
        payload,
      );

      toast('Your dental offer was successfully saved!', { type: 'success' });
      thunkApi.dispatch(getIssuerDentalPlans({ accountId, proposalId }));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
    }
  },
);

export const updateDentalOffer = createAsyncThunk(
  'issuer/update-dental-offer',
  async (data, thunkApi) => {
    try {
      const { payload, offerId, read_token, setUpdatedOffer } = data;

      const { accountId, proposalId, config_id, isBroker } = payload;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      delete payload.id;
      delete payload.comp_amount;
      delete payload.issuer_name;
      delete payload.created_timestamp;

      delete payload.accountId;
      delete payload.proposalId;
      delete payload.isBroker;

      const response = await api.issuer.updateCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'dental',
        payload,
        offerId,
        read_token,
      );

      toast('Your dental offer was successfully updated!', { type: 'success' });
      thunkApi.dispatch(getIssuerDentalPlans({ accountId, proposalId, read_token }));

      if (isBroker) {
        if (setUpdatedOffer) {
          setUpdatedOffer([response]);
        }
      }

      const dentalResponse = await api.planConfiguration.getDentalPlans(
        accountId,
        proposalId,
        read_token,
      );

      thunkApi.dispatch(setDentalPlans(dentalResponse));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
    }
  },
);

export const deleteDentalOffer = createAsyncThunk('issuer/delete-dental-offer', async (data) => {
  try {
    const { accountId, proposalId, configId, offerId } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    await api.issuer.deleteCarrierOffer(accountId, proposalId, 'dental', configId, offerId);
  } catch (err) {
    console.error(err);
  }
});

// CLEAR STATE

export const clearDentalState = createAsyncThunk('dental/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearDentalConfigurationState());
});
