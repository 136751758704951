import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.dental;

export const getDentalPlanSelector = createSelector(getState, (state) => state.dentalPlans);

// ISSUER
export const getIssuerDentalPlansSelecetor = createSelector(
  getState,
  (state) => state.issuerDentalPlans,
);
export const isLoadingIssuerDentalPlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerDentalPlans,
);
