export const MEDICAL_FORM_NAMES = {
  contribution_ee: 'Contribution to Employees',
  contribution_dep: 'Contribution to Dependents',
  funding_type: 'Funding Type',
  plan_type: 'Plan Type',
  network_size: 'Network Size',
  level: 'Tier',
  primary_care_physician: 'Primary Care Physician Copay',
  specialist: 'Specialist Copay',
  individual_medical_deductible: 'Individual Deductible',
  family_medical_deductible: 'Family Deductible',
  inpatient_facility_is_percent: 'Inpatient Facility',
  inpatient_facility_dollar_amount: 'Inpatient cost per day',
  inpatient_facility_dollar_days: 'Inpatient number of days',
  inpatient_facility_percent_value: '% Per Day',
  plan_coinsurance: 'Coinsurance',
  emergency_room: 'Emergency Room',
  urgent_care: 'Urgent Care',
  individual_medical_moop: 'Max Out-of-Pocket',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const DENTAL_FORM_NAMES = {
  contribution_ee: 'Contribution to Employees',
  contribution_dep: 'Contribution to Dependents',
  employer_contribution: 'Employer Contribution',
  death_benefit: 'Death Benefit',
  funding_type: 'Funding Type',
  plan_type: 'Plan Type',
  waiting_period: 'Waiting Period',
  dppo_out_coverage: 'DPPO: Out of Network Coverage',
  dppo_annual_max: 'DPPO: Annual Max',
  dppo_deductible: 'DPPO: Deductible',
  dppo_preventative: 'DPPO: Preventative',
  dppo_basic: 'DPPO: Basic',
  dppo_major: 'DPPO: Major',
  orthodontics: 'Orthodontics',
  orthodontics_coverage: 'Orthodontics Coverage',
  implant_coverage: 'Implant Coverage',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const VISION_FORM_NAMES = {
  funding_type: 'Funding Type',
  frequency: 'Frequency',
  exam_copay: 'Exam Copay',
  materials_copay: 'Materials Copay',
  frame_allowance: 'Frame Allowance',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const LIFE_FORM_NAMES = {
  contribution_type: 'Employer Contribution',
  death_benefit: 'Death Benefit',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const ACCIDENT_FORM_NAMES = {
  plan_type: '24 hour / Off the Job',
  hospital_admission: 'Hospital Admission',
  icu_confinement: 'ICU Confinement',
  daily_hospital_confinement: 'Daily Hospital Confinement',
  confinement_days_covered: 'Confinement Days Covered',
  daily_icu_confinement: 'Daily ICU Confinement',
  icu_days_covered: 'ICU Days Covered',
  accidental_death: 'Accidental Death',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const HOSPITAL_FORM_NAMES = {
  initial_hospital_confinement: 'Initial Hospital Confinement',
  per_day_hospitalization: 'Per Day Hospitalization',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};

export const CRITICAL_FORM_NAMES = {
  lump_sum_benefits: 'Lump Sum Benefit',
  gi_amount: 'GI Amount',
  include_cancer: 'Include Cancer',
  wellness_benefit: 'Wellness Benefit',
  recurrence_benefit: 'Recurrance Benefit',
  spouse_coverage: 'Spouse Coverage',
  child_coverage: 'Child Coverage',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};
