import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.disability;

export const getDisabilityPlansSelector = createSelector(
  getState,
  (state) => state.disabilityPlans,
);

//ISSUER
export const getIssuerDisabilityPlansSelecetor = createSelector(
  getState,
  (state) => state.issuerDisabilityPlans,
);
export const isLoadingIssuerDisabilityPlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerDisabilityPlans,
);
