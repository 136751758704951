import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import moment from 'moment';

import {
  brokerUsersMetaSelector,
  brokerUsersSelector,
  createCustomToken,
  getAdminBrokers,
  isLoadingBrokerUsersSelector,
} from 'src/store/admin';

import { Status } from '../Status/Status';
import { Loader, LoaderWrapper } from '../Loader/Loader';

import classes from './adminBrokersTable.module.scss';

const headerRows = [
  { id: 'email', name: 'Broker Email', sortable: true },
  { id: 'last_active', name: 'Last Active', sortable: true },
  { id: 'display_name', name: 'Broker Name', sortable: true },
  { id: 'status', name: 'Status', sortable: true },
  { id: '1', name: '', sortable: false },
];

export const AdminBrokersTable = (props) => {
  const { isImpersonate = true } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminBrokers = useSelector(brokerUsersSelector);
  const adminBrokersMeta = useSelector(brokerUsersMetaSelector);
  const isLoadingBrokerUsers = useSelector(isLoadingBrokerUsersSelector);

  const [currentPage, setCurrentPage] = useState(
    adminBrokersMeta?.page ? adminBrokersMeta?.page : 1,
  );
  const [selectedPageSize, setSelectedPageSize] = useState(
    adminBrokersMeta?.per_page ? adminBrokersMeta?.per_page : 10,
  );
  const [searchValue, setSearchValue] = useState('');
  const [sortMethod, setSortMethod] = useState('-last_active');
  const [selectedCell, setSelectedCell] = useState('');
  const [cellDirection, setCellDirection] = useState('asc');

  const [, setCookie] = useCookies(['is_impersonate']);

  useEffect(() => {
    if (!searchValue) {
      dispatch(
        getAdminBrokers({ sort: sortMethod, per_page: selectedPageSize, page: currentPage }),
      );
    }

    if (searchValue) {
      const debounceSearch = setTimeout(() => {
        dispatch(
          getAdminBrokers({
            page: currentPage,
            per_page: selectedPageSize,
            sort: sortMethod,
            q: searchValue,
          }),
        );
      }, 500);

      return () => clearTimeout(debounceSearch);
    }
  }, [dispatch, sortMethod, currentPage, selectedPageSize, searchValue]);

  const onChangeCellSort = (property) => () => {
    setSelectedCell(property);

    if (property) {
      const isAsc = sortMethod === property && cellDirection === 'asc';
      const direction = isAsc ? 'desc' : 'asc';

      setCellDirection(direction);

      if (direction === 'asc') {
        setSortMethod(`${property}`);
      } else {
        setSortMethod(`-${property}`);
      }
    }
  };

  const onChangeSearch = useCallback((event) => {
    setCurrentPage(1);

    setSearchValue(event.target.value);
  }, []);

  const searchOnKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13 && searchValue) {
        dispatch(
          getAdminBrokers({
            q: searchValue,
            page: currentPage,
            per_page: selectedPageSize,
            sort: '-last_active',
          }),
        );
      }
    },
    [dispatch, searchValue, currentPage, selectedPageSize],
  );

  const onClickImpersonate = useCallback(
    (user_id) => async () => {
      const payload = {
        user_id,
        navigate,
      };

      await dispatch(createCustomToken(payload));
      setCookie('is_impersonate', 'true', { path: '/' });
    },
    [dispatch, navigate, setCookie],
  );

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);

  return (
    <div className={classes.AdminBrokersTable}>
      <Paper>
        {isImpersonate && (
          <div className={classes.AdminBrokersTableContainer}>
            <TextField
              inputProps={{ type: 'search' }}
              label="Search"
              variant="outlined"
              size="small"
              value={searchValue}
              onChange={onChangeSearch}
              onKeyDown={searchOnKeyDown}
            />
          </div>
        )}
        {isLoadingBrokerUsers ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  {headerRows?.map((item) => (
                    <TableCell key={item.id}>
                      {item.sortable ? (
                        <TableSortLabel
                          active={selectedCell === item.id}
                          direction={cellDirection}
                          onClick={onChangeCellSort(item.id)}
                        >
                          {item.name}
                        </TableSortLabel>
                      ) : (
                        item.name
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {adminBrokers.map((item) => (
                  <TableRow
                    key={item.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {moment(item.last_active).format('h:MM A MM/DD/YYYY')}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.display_name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Status status={item?.status} />
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {isImpersonate && (
                        <Button
                          variant="contained"
                          onClick={onClickImpersonate(item?.id)}
                          size="small"
                        >
                          Impersonate
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          component="div"
          count={adminBrokersMeta?.total ?? 0}
          rowsPerPage={selectedPageSize}
          page={currentPage - 1}
          onPageChange={onPageChange}
          onRowsPerPageChange={onChangePageSize}
        />
      </Paper>
    </div>
  );
};
