import React from 'react';
import PropTypes from 'prop-types';

import classes from './toggleButton.module.scss';

export const ToggleButton = (props) => {
  const { isToggle, onClickToggle, isDisabled, titleOff, titleOn } = props;

  return (
    <div
      className={`${classes.Toggle} ${isDisabled ? classes.ToggleDisabled : ''}`}
      // data-tip={tooltipText}
    >
      <div className={!isToggle ? classes.ToggleUniActive : classes.ToggleUni}>{titleOff}</div>
      <div
        className={`${classes.ToggleButton} ${isToggle ? classes.ToggleButtonChecked : ''}`}
        onClick={isDisabled ? () => {} : onClickToggle}
      >
        <div
          className={`${
            isToggle ? classes.ToggleButtonContainerCheck : classes.ToggleButtonContainerUncheck
          } ${isDisabled ? classes.ToggleDisabled : ''}`}
        >
          <div className={classes.ToggleButtonCheck}>
            <span></span>
          </div>
          <div className={classes.ToggleButtonUncheck}>
            <span></span>
          </div>
        </div>
        <div
          className={`${classes.ToggleButtonCircle} ${isDisabled ? classes.ToggleDisabled : ''}`}
        ></div>
        <input className={classes.ToggleButtonInput} type="checkbox" aria-label="Toggle Button" />
      </div>
      <div className={isToggle ? classes.ToggleNonActive : classes.ToggleNon}>{titleOn}</div>
    </div>
  );
};

ToggleButton.propTypes = {
  titleOff: PropTypes.string,
  titleOn: PropTypes.string,
  tooltipText: PropTypes.string,
  onClickToggle: PropTypes.func,
  isToggle: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
