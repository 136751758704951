import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, InputLabel, FormControl, Box } from '@mui/material';

import classes from './muiSelect.module.scss';

export const MUISelect = (props) => {
  const {
    label,
    value,
    options,
    onChange,
    minWidth = 120,
    maxWidth,
    size = 'small',
    disabled = false,
    required = false,
  } = props;

  return (
    <Box sx={{ minWidth, maxWidth }}>
      <FormControl fullWidth>
        <InputLabel required={required} size={size} id={`select-${label}`}>
          {label}
        </InputLabel>
        <Select
          size={size}
          onChange={onChange}
          value={value}
          labelId={`select-${label}`}
          label={label}
          style={{ textAlign: 'left' }}
          required={required}
          disabled={disabled}
        >
          {options?.map((option) => (
            <MenuItem key={option.title} value={option.value}>
              {option?.logo && (
                <img
                  src={option?.logo}
                  id={option?.logo}
                  onError={() => (document.getElementById(option.logo).style.overflow = 'hidden')}
                  alt=""
                  className={classes.Logo}
                />
              )}
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

MUISelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
