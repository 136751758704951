import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import _ from 'lodash';

import {
  createDentalOffer,
  deleteDentalOffer,
  updateDentalOffer,
} from 'src/store/dentalConfiguration';

import { Button, DropZone, Input, Modal, SelectOption, ExtraRows } from 'src/components';

import { rateValueRegex } from 'src/constants/regularExpression';
import {
  normalizeCompensationAmount,
  normalizeCompensationAmountEdit,
  normalizeOrthodonticsValue,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import { normalizeFundingType } from 'src/constants/proposalFormField';
import { TOOLTIP_NAMES } from 'src/constants/tooltipNames';

import {
  compensationAmountData,
  compensationMethodData,
  implantCoverageData,
  networkCoverageData,
  waitingPeriodData,
  fundingTypeData,
} from 'src/features/dental/dentalForm/dentalFormConstants';

import classes from './dentalConfigurationForm.module.scss';
import { toast } from 'react-toastify';
import { brokerAgencySelector } from 'src/store/agency';

export const DentalConfigurationForm = (props) => {
  const {
    brokerConfig,
    dentalOffers,
    userId,
    issuerId,
    id,
    proposalId,
    accountId,
    getDentalOffers,
    getDentalQuotes,
  } = props;
  const dispatch = useDispatch();

  const brokerAgency = useSelector(brokerAgencySelector);

  const [dentalConfigs, setDentalConfigs] = useState([]);
  const [originalConfig, setOriginalConfig] = useState({});
  const [selectedConfig, setSelectedConfig] = useState({});
  const [extraRows, setExtraRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedDeleteConfig, setSelectedDeleteConfig] = useState({});

  const [isVisibleWaitingPeriod, setIsVisibleWaitingPeriod] = useState(false);
  const [isVisibleFundingType, setIsVisibleFundingType] = useState(false);
  const [isVisibleImplantCoverage, setIsVisibleImplantCoverage] = useState(false);
  const [isVisibleCompensationMethod, setIsVisibleCompensationMethod] = useState(false);
  const [isVisibleCompensationAmount, setIsVisibleCompensationAmount] = useState(false);
  const [isVisibleOutOfNetwork, setIsVisibleOutOfNetwork] = useState(false);
  const [isVisibleSelect, setIsVisibleSelect] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const originalId = dentalOffers?.find((item) => item.issuer_name)?.user_id;

  useEffect(() => {
    setDentalConfigs([...dentalOffers]);
  }, [dentalOffers]);

  const tierOptions = [
    { key: '3tier', name: '3 Tier' },
    { key: '4tier', name: '4 Tier' },
  ];

  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig, selectedConfig));
  }, [originalConfig, selectedConfig]);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig?.custom_fields, extraRows));
  }, [extraRows, originalConfig?.custom_fields]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      const selectedDentalConfig = dentalConfigs?.find((item) => item.id === selectedConfig?.id);
      if (selectedDentalConfig?.custom_fields?.length) {
        for (const originalField of selectedDentalConfig?.custom_fields) {
          for (const carrierField of extraRows) {
            if (originalField.id === carrierField.id) {
              if (originalField.name !== carrierField.name) {
                setIsEdited(true);
              }

              if (originalField.value !== carrierField.value) {
                setIsEdited(true);
              }
            }
          }
        }
      }
    }
  }, [dentalConfigs, selectedConfig, extraRows]);

  const onClickAddOffer = useCallback(() => {
    const payload = {
      ...brokerConfig,
      config_id: brokerConfig?.id,
      id: uuid(),
      issuer_id: issuerId,
      rate_ee: null,
      rate_ee_1: null,
      rate_ee_ch: null,
      rate_ee_sp: null,
      rate_fam: null,
      comp_amount: normalizeCompensationAmountEdit(brokerConfig),
      // 2 cases - 1 for broker side, second one for carrier side
      user_id: userId !== originalId ? originalId : userId,
    };

    setSelectedConfig(payload);
    setDentalConfigs((state) => [...state, payload]);
  }, [brokerConfig, issuerId, originalId, userId]);

  const onClickEditConfig = useCallback(
    (item) => () => {
      const originalConfig = dentalConfigs?.find((offer) => offer.id === item.id);
      const originalPayload = {
        ...originalConfig,
        rate_ee: originalConfig.rate_ee ? originalConfig.rate_ee.toString() : null,
        rate_ee_1: originalConfig.rate_ee_1 ? originalConfig.rate_ee_1.toString() : null,
        rate_ee_ch: originalConfig.rate_ee_ch ? originalConfig.rate_ee_ch.toString() : null,
        rate_ee_sp: originalConfig.rate_ee_sp ? originalConfig.rate_ee_sp.toString() : null,
        rate_fam: originalConfig.rate_fam ? originalConfig.rate_fam.toString() : null,
        comp_amount: normalizeCompensationAmountEdit(originalConfig),
      };

      const clonedCustomFields = _.cloneDeep(item?.custom_fields);
      const selectedPayload = {
        ...item,
        rate_ee: item.rate_ee ? item.rate_ee.toString() : 0,
        rate_ee_1: item.rate_ee_1 ? item.rate_ee_1.toString() : 0,
        rate_ee_ch: item.rate_ee_ch ? item.rate_ee_ch.toString() : 0,
        rate_ee_sp: item.rate_ee_sp ? item.rate_ee_sp.toString() : 0,
        rate_fam: item.rate_fam ? item.rate_fam.toString() : 0,
        custom_fields: clonedCustomFields,
        comp_amount: normalizeCompensationAmountEdit(item),
      };

      setOriginalConfig(originalPayload);
      setSelectedConfig(selectedPayload);
      setExtraRows(clonedCustomFields ?? []);
    },
    [dentalConfigs],
  );

  const onChangePercentage = useCallback((event, target) => {
    if (target === 'comp_amount_pct') {
      setSelectedConfig((prev) => ({
        ...prev,
        comp_pppm_dollar: null,
        comp_pepm_dollar: null,
      }));
    }

    if (event.target.value.length > 3) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: Number(event.target.value.slice(0, 3)),
      }));
    }
    if (event.target.value < 0) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 0,
      }));
    }
    if (event.target.value > 100) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 100,
      }));
    }

    setSelectedConfig((prev) => ({
      ...prev,
      [target]: event.target.value,
    }));
  }, []);

  const onChangeInput = useCallback(
    (event, target) => {
      const payload = {
        ...selectedConfig,
        [target]: event.target.value,
      };

      setSelectedConfig(payload);
    },

    [selectedConfig],
  );

  const handleOpenCarrierSelect = useCallback(
    (setCarrierSelectValue) => () => {
      setCarrierSelectValue((prev) => !prev);
    },
    [],
  );

  const onSelectOption = useCallback(
    (value, target, setIsVisible) => () => {
      let payload = {};
      setIsVisible(false);

      if (value === 'yes' || value === 'no') {
        payload = {
          ...selectedConfig,
          [target]: value === 'yes' ? true : false,
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onChangeInputValue = useCallback(
    (event, target, maxRange) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };

        return setSelectedConfig(payload);
      }
      payload = {
        ...selectedConfig,
        [target]: event.target.value > maxRange ? maxRange : event.target.value,
      };

      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onFocusInput = (event, target) => {
    if (Number(event.target.value) === 0) {
      const payload = {
        ...selectedConfig,
        [target]: '',
      };
      return setSelectedConfig(payload);
    }
  };

  const onBlurInput = (event, target) => {
    if (!event.target.value) {
      const payload = {
        ...selectedConfig,
        [target]: 0,
      };
      return setSelectedConfig(payload);
    }

    if (
      !event.target.value.includes('.') &&
      (event.target.value[0] === '0' || event.target.value[0] === 0)
    ) {
      const payload = {
        ...selectedConfig,
        [target]: event.target.value.slice(1) ? event.target.value.slice(1) : 0,
      };
      return setSelectedConfig(payload);
    }
  };

  const onClickCancel = useCallback(() => {
    setOriginalConfig({});
    setSelectedConfig({});
    setDentalConfigs(dentalOffers);
    setExtraRows([]);
  }, [dentalOffers]);

  const onChangePepmPppmValue = useCallback((event, target) => {
    if (target === 'comp_pepm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pppm_dollar: null }));
    }

    if (target === 'comp_pppm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pepm_dollar: null }));
    }

    if (event.target.value.includes('.')) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 4) }));
    }

    if (event.target.value > 3) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 3) }));
    }

    setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value }));
  }, []);

  const compensationAmount = compensationAmountData(
    selectedConfig,
    onChangePercentage,
    onChangePepmPppmValue,
  );

  const onChangeRateValue = useCallback(
    (event, target) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };
        return setSelectedConfig(payload);
      }

      if (!event.target.value.match(rateValueRegex)) {
        payload = {
          ...selectedConfig,
          [target]: event.target.value.slice(0, event.target.value.length - 1),
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onSaveSelectedConfig = useCallback(async () => {
    const isOfferExist = !_.isEmpty(dentalOffers?.find((item) => item?.id === selectedConfig?.id));

    const data = {
      issuer_id: issuerId,
      config_id: id,
      proposalId: proposalId,
      accountId: accountId,
    };

    if (!isOfferExist) {
      const payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      delete payload.calculation;

      await dispatch(createDentalOffer(payload));

      if (getDentalQuotes) {
        await getDentalQuotes();
      }
    }

    if (isOfferExist) {
      const payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      delete payload.author;
      delete payload.calculation;

      await dispatch(updateDentalOffer({ payload, offerId: selectedConfig?.id }));

      if (getDentalQuotes) {
        await getDentalQuotes();
      }
    }
  }, [
    dispatch,
    getDentalQuotes,
    dentalOffers,
    selectedConfig,
    issuerId,
    id,
    proposalId,
    accountId,
    extraRows,
  ]);

  const onAddExtraRow = useCallback(() => {
    if (!_.isEmpty(brokerAgency) && brokerAgency.status !== 'active') {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    if (_.isEmpty(brokerAgency)) {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    const payload = {
      id: uuid(),
      value: '',
    };
    setExtraRows((state) => [...state, payload]);
  }, [extraRows.length, brokerAgency]);

  const isVisibleButtons = useCallback((status) => {
    if (status === 'approved' || status === 'selected') {
      return false;
    }
    return true;
  }, []);

  const onClickDelete = useCallback(
    (item) => async () => {
      const payload = {
        accountId,
        proposalId,
        configId: item.config_id,
        offerId: item.id,
      };

      setIsDeleteModal(true);
      setSelectedDeleteConfig(payload);
    },
    [accountId, proposalId],
  );

  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteModal(false);
    setSelectedDeleteConfig({});
  }, []);

  const onConfirmDelete = useCallback(async () => {
    await dispatch(deleteDentalOffer(selectedDeleteConfig));
    await getDentalOffers();
  }, [dispatch, getDentalOffers, selectedDeleteConfig]);

  const onRejectOffer = useCallback(async () => {
    const offer = dentalOffers[0];

    const payload = {
      proposalId,
      accountId,
      ...offer,
      status: 'declined',
    };

    if (!_.isEmpty(offer)) {
      delete payload?.rep;
      delete payload.issuer_name;
      const offerId = offer.id;

      delete payload.calculation;

      await dispatch(updateDentalOffer({ payload, offerId }));

      if (getDentalQuotes) {
        await getDentalQuotes();
      }
    }
  }, [dispatch, dentalOffers, accountId, proposalId, getDentalQuotes]);

  const isFourTier = !_.isEmpty(dentalConfigs?.find((item) => item.rate_tier_type === '4tier'));
  const isDeclined = !_.isEmpty(dentalConfigs?.find((item) => item.status === 'declined'));

  return (
    <>
      {isDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseDeleteModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Are you sure you want to delete this offer?
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={onCloseDeleteModal} />
              <Button type="danger" title="Yes" onClick={onConfirmDelete} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.DentalConfigurationContentWrapper}>
        {!isDeclined && _.isEmpty(selectedConfig) && (
          <div className={classes.DentalConfigurationContentBodyOffer}>
            <Button
              title="Add Offer"
              type="primary"
              onClick={onClickAddOffer}
              isDisabled={!_.isEmpty(selectedConfig)}
            />
          </div>
        )}
        <div className={classes.DentalConfigurationContentBody}>
          <div className={classes.DentalConfigurationContentTier}>
            <table className={classes.DentalConfigurationTierTable}>
              <thead className={classes.DentalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.DentalConfigurationTitleMain}>Plan Configuration Form</th>
                </tr>
                <tr>
                  <th>Contribution to Employees</th>
                  <th>Contribution to Dependents</th>
                  <th>Funding Type</th>
                  <th>Plan Type</th>
                  <th>Waiting Period</th>
                  {brokerConfig?.plan_type === 'PPO' && (
                    <>
                      <th>DPPO: Out of Network Coverage</th>
                      <th>DPPO: Annual Max</th>
                      <th>DPPO: Deductible</th>
                      <th>DPPO: Preventative</th>
                      <th>DPPO: Basic</th>
                      <th>DPPO: Major</th>
                    </>
                  )}
                  <th>Orthodontics</th>
                  {(brokerConfig?.orthodontics !== 'none' ||
                    (!_.isEmpty(selectedConfig) && selectedConfig?.orthodontics !== 'none')) && (
                    <th>Orthodontics Coverage</th>
                  )}
                  <th>Implant Coverage</th>
                  <th>Compensation Method</th>
                  <th>Compensation Amount</th>
                  <th>Current Rate</th>
                  {brokerConfig?.selected_plan_name && <th>Selected Plan Name</th>}
                </tr>
              </thead>
              <tbody>
                <tr className={classes.DentalConfigurationTierTableBroker}>
                  <th className={classes.DentalConfigurationTitle}>Broker Request</th>
                  <td>
                    {brokerConfig?.contribution_type === 'dollar' && '$'}
                    {brokerConfig?.contribution_ee}
                    {brokerConfig?.contribution_type === 'percent' && '%'}
                  </td>
                  <td>
                    {brokerConfig?.contribution_type === 'dollar' && '$'}
                    {brokerConfig?.contribution_dep}
                    {brokerConfig?.contribution_type === 'percent' && '%'}
                  </td>
                  <td>{normalizeFundingType(brokerConfig?.funding_type)}</td>
                  <td>{brokerConfig?.plan_type}</td>
                  <td>{brokerConfig?.waiting_period ? 'Yes' : 'No'}</td>
                  {brokerConfig?.plan_type === 'PPO' && (
                    <>
                      <td>{brokerConfig?.dppo_out_coverage}</td>
                      <td>${brokerConfig?.dppo_annual_max}</td>
                      <td>${brokerConfig?.dppo_deductible}</td>
                      <td>{brokerConfig?.dppo_preventative}%</td>
                      <td>{brokerConfig?.dppo_basic}%</td>
                      <td>{brokerConfig?.dppo_major}%</td>
                    </>
                  )}
                  <td>{brokerConfig?.orthodontics}</td>
                  {(brokerConfig?.orthodontics !== 'none' ||
                    (!_.isEmpty(selectedConfig) && selectedConfig?.orthodontics !== 'none')) && (
                    <td>
                      {brokerConfig?.orthodontics_coverage
                        ? `$${brokerConfig?.orthodontics_coverage}`
                        : `None`}
                    </td>
                  )}
                  <td>{brokerConfig?.implant_coverage ? 'Yes' : 'No'}</td>
                  <td>{brokerConfig?.comp_method}</td>
                  <td>{normalizeCompensationAmount(brokerConfig)}</td>
                  <td>{brokerConfig?.is_current_rate ? 'Yes' : 'No'}</td>
                  {brokerConfig?.selected_plan_name && <td>{brokerConfig?.selected_plan_name}</td>}
                </tr>
                {dentalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;
                  const waitingPeriod = item.waiting_period ? 'Yes' : 'No';
                  const implantCoverage = item.implant_coverage ? 'Yes' : 'No';

                  return (
                    <tr key={item?.id}>
                      <th className={classes.DentalConfigurationTitle}>Carrier Reply</th>
                      <td>
                        {!isEditMode ? (
                          <>
                            {item?.contribution_type === 'dollar' && '$'}
                            {item?.contribution_ee}
                            {item?.contribution_type === 'percent' && '%'}
                          </>
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.contribution_ee}
                            onChange={(event) =>
                              item?.contribution_type === 'percent'
                                ? onChangePercentage(event, 'contribution_ee')
                                : onChangeInput(event, 'contribution_ee')
                            }
                            onFocus={(event) => onFocusInput(event, 'contribution_ee')}
                            onBlur={(event) => onBlurInput(event, 'contribution_ee')}
                            type={item?.contribution_type === 'percent' ? 'percentage' : 'number'}
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          <>
                            {item?.contribution_type === 'dollar' && '$'}
                            {item?.contribution_dep}
                            {item?.contribution_type === 'percent' && '%'}
                          </>
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.contribution_dep}
                            onChange={(event) =>
                              item?.contribution_type === 'percent'
                                ? onChangePercentage(event, 'contribution_dep')
                                : onChangeInput(event, 'contribution_dep')
                            }
                            onFocus={(event) => onFocusInput(event, 'contribution_dep')}
                            onBlur={(event) => onBlurInput(event, 'contribution_dep')}
                            type={item?.contribution_type === 'percent' ? 'percentage' : 'number'}
                          />
                        )}
                      </td>

                      <td>
                        {!isEditMode ? (
                          normalizeFundingType(item?.funding_type)
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={fundingTypeData}
                            selectedOption={selectedConfig?.funding_type}
                            isVisibleSelect={isVisibleFundingType}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleFundingType)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'funding_type', setIsVisibleFundingType)
                            }
                            isAbsolute
                          />
                        )}
                      </td>

                      <td>{item?.plan_type}</td>
                      <td>
                        {!isEditMode ? (
                          waitingPeriod
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={waitingPeriodData}
                            selectedOption={selectedConfig.waiting_period ? 'yes' : 'no'}
                            isVisibleSelect={isVisibleWaitingPeriod}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleWaitingPeriod)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'waiting_period', setIsVisibleWaitingPeriod)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      {item?.plan_type === 'PPO' && (
                        <>
                          <td>
                            {!isEditMode ? (
                              item.dppo_out_coverage
                            ) : (
                              <SelectOption
                                height="30px"
                                width="142px"
                                options={networkCoverageData}
                                selectedOption={selectedConfig?.dppo_out_coverage}
                                isVisibleSelect={isVisibleOutOfNetwork}
                                handleOpenSelect={handleOpenCarrierSelect(setIsVisibleOutOfNetwork)}
                                onSelectOption={(event) =>
                                  onSelectOption(
                                    event,
                                    'dppo_out_coverage',
                                    setIsVisibleOutOfNetwork,
                                  )
                                }
                                isAbsolute
                              />
                            )}
                          </td>
                          <td>
                            {!isEditMode ? (
                              `$${item.dppo_annual_max}`
                            ) : (
                              <Input
                                placeholder="Placeholder"
                                value={selectedConfig?.dppo_annual_max}
                                onChange={(event) => onChangeInputValue(event, 'dppo_annual_max')}
                                onFocus={(event) => onFocusInput(event, 'dppo_annual_max')}
                                onBlur={(event) => onBlurInput(event, 'dppo_annual_max')}
                                type="number"
                              />
                            )}
                          </td>
                          <td>
                            {!isEditMode ? (
                              `$${item.dppo_deductible}`
                            ) : (
                              <Input
                                placeholder="Placeholder"
                                value={selectedConfig?.dppo_deductible}
                                onChange={(event) => onChangeInputValue(event, 'dppo_deductible')}
                                onFocus={(event) => onFocusInput(event, 'dppo_deductible')}
                                onBlur={(event) => onBlurInput(event, 'dppo_deductible')}
                                type="number"
                              />
                            )}
                          </td>
                          <td>
                            {!isEditMode ? (
                              `${item.dppo_preventative}%`
                            ) : (
                              <Input
                                placeholder="Placeholder"
                                value={selectedConfig?.dppo_preventative}
                                onChange={(event) => onChangePercentage(event, 'dppo_preventative')}
                                onFocus={(event) => onFocusInput(event, 'dppo_preventative')}
                                onBlur={(event) => onBlurInput(event, 'dppo_preventative')}
                                type="percentage"
                              />
                            )}
                          </td>
                          <td>
                            {!isEditMode ? (
                              `${item.dppo_basic}%`
                            ) : (
                              <Input
                                placeholder="Placeholder"
                                value={selectedConfig?.dppo_basic}
                                onChange={(event) => onChangePercentage(event, 'dppo_basic')}
                                onFocus={(event) => onFocusInput(event, 'dppo_basic')}
                                onBlur={(event) => onBlurInput(event, 'dppo_basic')}
                                type="percentage"
                              />
                            )}
                          </td>
                          <td>
                            {!isEditMode ? (
                              `${item.dppo_major}%`
                            ) : (
                              <Input
                                placeholder="Placeholder"
                                value={selectedConfig?.dppo_major}
                                onChange={(event) => onChangePercentage(event, 'dppo_major')}
                                onFocus={(event) => onFocusInput(event, 'dppo_major')}
                                onBlur={(event) => onBlurInput(event, 'dppo_major')}
                                type="percentage"
                              />
                            )}
                          </td>
                        </>
                      )}
                      <td>{normalizeOrthodonticsValue(item.orthodontics)}</td>
                      {item.orthodontics !== 'none' && !isEditMode && (
                        <td>{item.orthodontics_coverage}</td>
                      )}
                      {selectedConfig.orthodontics !== 'none' && isEditMode && (
                        <td>
                          <Input
                            placeholder="Placeholder"
                            type="number"
                            value={selectedConfig?.orthodontics_coverage}
                            onChange={(event) => onChangeInputValue(event, 'orthodontics_coverage')}
                            onFocus={(event) => onFocusInput(event, 'orthodontics_coverage')}
                            onBlur={(event) => onBlurInput(event, 'orthodontics_coverage')}
                          />
                        </td>
                      )}
                      <td>
                        {!isEditMode ? (
                          implantCoverage
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={implantCoverageData}
                            selectedOption={selectedConfig.implant_coverage ? 'yes' : 'no'}
                            isVisibleSelect={isVisibleImplantCoverage}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleImplantCoverage)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'implant_coverage', setIsVisibleImplantCoverage)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          item.comp_method
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationMethodData}
                            selectedOption={selectedConfig?.comp_method}
                            isVisibleSelect={isVisibleCompensationMethod}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationMethod,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_method', setIsVisibleCompensationMethod)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          normalizeCompensationAmount(item)
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationAmount}
                            selectedOption={selectedConfig?.comp_amount}
                            isVisibleSelect={isVisibleCompensationAmount}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationAmount,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_amount', setIsVisibleCompensationAmount)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>{item?.is_current_rate ? 'Yes' : 'No'}</td>
                      {brokerConfig?.selected_plan_name && (
                        <td>{brokerConfig?.selected_plan_name}</td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.DentalConfigurationContentTier}>
            <table className={classes.DentalConfigurationTierTable}>
              <thead className={classes.DentalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Custom Fields</th>
                </tr>
                <tr>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.title}</span>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr
                  className={classes.DentalConfigurationTierTableBroker}
                  style={{ backgroundColor: '#e5e5e5' }}
                >
                  <td className={classes.EmptyRow}></td>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.value}</span>
                    </td>
                  ))}
                </tr>
                {dentalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      {!isEditMode ? (
                        item.custom_fields?.map((item) => (
                          <td key={item.id}>
                            <span>{item.value}</span>
                          </td>
                        ))
                      ) : (
                        <div className={classes.ExtraRowButtonWrapper}>
                          <ExtraRows
                            extraRows={extraRows}
                            setExtraRows={setExtraRows}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            isDisabled={!isEditMode}
                          />

                          <div className={classes.ExtraRowButton}>
                            <Button
                              title="Add Extra Row"
                              type="secondary"
                              onClick={onAddExtraRow}
                              isDisabled={!isEditMode}
                            />

                            <Tooltip title={TOOLTIP_NAMES.MAX_ROWS}>
                              <i className="fas fa-info-circle" />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.DentalConfigurationContentTier}>
            <table className={classes.DentalConfigurationTierTable}>
              <thead className={classes.DentalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Rates</th>
                </tr>
                <tr>
                  <th className={classes.TierTypeRow}>Rate Tier Type</th>
                </tr>
                <tr>
                  <th>Employee</th>
                </tr>
                {(brokerConfig?.rate_tier_type === '3tier' ||
                  selectedConfig?.rate_tier_type === '3tier') && (
                  <>
                    <tr>
                      <th>Employee + 1</th>
                    </tr>
                  </>
                )}
                {(brokerConfig?.rate_tier_type === '4tier' ||
                  selectedConfig?.rate_tier_type === '4tier' ||
                  isFourTier) && (
                  <>
                    <tr>
                      <th>Employee + Spouse</th>
                    </tr>
                    <tr>
                      <th>Employee + Child(ren)</th>
                    </tr>
                  </>
                )}
                <tr>
                  <th>Family</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.DentalConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                  <td className={classes.TierTypeRow}>
                    {normalizeRateTierType(brokerConfig?.rate_tier_type)}
                  </td>
                  <td>{brokerConfig?.rate_ee && `$${brokerConfig?.rate_ee}`}</td>
                  {(brokerConfig?.rate_tier_type === '3tier' ||
                    selectedConfig?.rate_tier_type === '3tier') && (
                    <td>{brokerConfig?.rate_ee_1 && `$${brokerConfig?.rate_ee_1}`}</td>
                  )}
                  {(brokerConfig?.rate_tier_type === '4tier' ||
                    selectedConfig?.rate_tier_type === '4tier' ||
                    isFourTier) && (
                    <>
                      <td>{brokerConfig?.rate_ee_sp && `$${brokerConfig?.rate_ee_sp}`}</td>
                      <td>{brokerConfig?.rate_ee_ch && `$${brokerConfig?.rate_ee_ch}`}</td>
                    </>
                  )}
                  <td>{brokerConfig?.rate_fam && `$${brokerConfig?.rate_fam}`}</td>
                  <td className={classes.EmptyDropZoneRow}>
                    <DropZone
                      label="Current Benefits Summary"
                      maxFiles={100}
                      path={`/proposals/${proposalId}/uploads/broker/plan-configs/${id}/`}
                      isDisabled={true}
                    />
                  </td>
                </tr>
                {dentalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      <td className={classes.TierTypeRow}>
                        {!isEditMode ? (
                          normalizeRateTierType(item.rate_tier_type)
                        ) : (
                          <SelectOption
                            label="Rate Tier Type"
                            options={tierOptions}
                            selectedOption={selectedConfig?.rate_tier_type}
                            isVisibleSelect={isVisibleSelect}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleSelect)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'rate_tier_type', setIsVisibleSelect)
                            }
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          (item.rate_ee || item.rate_ee === 0) && `$${item.rate_ee}`
                        ) : (
                          <Input
                            value={selectedConfig?.rate_ee}
                            onChange={(event) => onChangeRateValue(event, 'rate_ee')}
                            onFocus={(event) => onFocusInput(event, 'rate_ee')}
                            onBlur={(event) => onBlurInput(event, 'rate_ee')}
                            type="number"
                          />
                        )}
                      </td>
                      {selectedConfig?.rate_tier_type === '3tier' ||
                      (!isEditMode && item.rate_tier_type === '3tier') ? (
                        <>
                          <td>
                            {!isEditMode ? (
                              (item.rate_ee_1 || item.rate_ee_1 === 0) && `$${item.rate_ee_1}`
                            ) : (
                              <Input
                                value={selectedConfig?.rate_ee_1}
                                onChange={(event) => onChangeRateValue(event, 'rate_ee_1')}
                                onFocus={(event) => onFocusInput(event, 'rate_ee_1')}
                                onBlur={(event) => onBlurInput(event, 'rate_ee_1')}
                                type="number"
                              />
                            )}
                          </td>
                          {(brokerConfig?.rate_tier_type === '4tier' ||
                            selectedConfig?.rate_tier_type === '4tier' ||
                            isFourTier) && <td></td>}
                          {(brokerConfig?.rate_tier_type === '4tier' ||
                            selectedConfig?.rate_tier_type === '4tier' ||
                            isFourTier) && <td></td>}
                        </>
                      ) : (
                        <>
                          {brokerConfig?.rate_tier_type === '3tier' && <td></td>}
                          <td>
                            {!isEditMode ? (
                              (item.rate_ee_sp || item.rate_ee_sp === 0) && `$${item.rate_ee_sp}`
                            ) : (
                              <Input
                                value={selectedConfig?.rate_ee_sp}
                                onChange={(event) => onChangeRateValue(event, 'rate_ee_sp')}
                                onFocus={(event) => onFocusInput(event, 'rate_ee_sp')}
                                onBlur={(event) => onBlurInput(event, 'rate_ee_sp')}
                                type="number"
                              />
                            )}
                          </td>
                          <td>
                            {!isEditMode ? (
                              (item.rate_ee_ch || item.rate_ee_ch === 0) && `$${item.rate_ee_ch}`
                            ) : (
                              <Input
                                value={selectedConfig?.rate_ee_ch}
                                onChange={(event) => onChangeRateValue(event, 'rate_ee_ch')}
                                onFocus={(event) => onFocusInput(event, 'rate_ee_ch')}
                                onBlur={(event) => onBlurInput(event, 'rate_ee_ch')}
                                type="number"
                              />
                            )}
                          </td>
                        </>
                      )}
                      <td>
                        {!isEditMode ? (
                          (item.rate_fam || item.rate_fam === 0) && `$${item.rate_fam}`
                        ) : (
                          <Input
                            value={selectedConfig?.rate_fam}
                            onChange={(event) => onChangeRateValue(event, 'rate_fam')}
                            onFocus={(event) => onFocusInput(event, 'rate_fam')}
                            onBlur={(event) => onBlurInput(event, 'rate_fam')}
                            type="number"
                          />
                        )}
                      </td>
                      <td className={classes.EmptyDropZoneRow}>
                        <DropZone
                          label="Proposed Benefits Summary"
                          maxFiles={100}
                          path={`/proposals/${proposalId}/uploads/carrier/plan-configs/${id}/offers/${item?.id}/`}
                          isDisabled={!isEditMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.DentalConfigurationContentTier}>
            <table className={classes.DentalConfigurationTierTable}>
              <thead className={classes.DentalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}></th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.DentalConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                </tr>
                {dentalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyButtonRow}>
                        {!isEditMode && (
                          <div className={classes.DentalConfigurationEditTableItem}>
                            {isVisibleButtons(item?.status) && (
                              <div className={classes.DentalConfigurationEditTableItemWrapper}>
                                <Button
                                  title="Edit"
                                  type="primary"
                                  onClick={onClickEditConfig(item)}
                                />
                                {item.status !== 'declined' && dentalOffers.length > 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Delete" onClick={onClickDelete(item)} />
                                  </div>
                                )}
                                {item.status !== 'declined' && dentalOffers.length === 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Decline" onClick={onRejectOffer} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {isEditMode && (
                          <div className={classes.DentalConfigurationContentFooter}>
                            {isEdited && (
                              <Button title="Save" type="primary" onClick={onSaveSelectedConfig} />
                            )}
                            <Button title="Cancel" type="secondary" onClick={onClickCancel} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
