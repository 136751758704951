import { onBlurInput, onFocusInput } from 'src/components';

export const LumpGiData = (onChange, setValue, value) => [
  { name: '$5000', key: '5000' },
  { name: '$10000', key: '10000' },
  {
    name: '$20000',
    key: '20000',
  },
  {
    name: '$30000',
    key: '30000',
  },
  {
    name: '$40000',
    key: '40000',
  },
  {
    name: '$50000',
    key: '50000',
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const SpouseChildData = (onChange, setValue, value) => [
  { name: '25%', key: '25' },
  { name: '50%', key: '50' },
  {
    name: 'N/A',
    key: 'N/A',
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'percentage',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const ConfirmData = [
  {
    name: 'Yes',
    key: 'Yes',
  },
  {
    name: 'No',
    key: 'No',
  },
];
