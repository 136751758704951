import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';

import { sumPremium, getBasePlanQuote, setSelectedQuotes } from 'src/store/proposals';

import { inNetworkRegex } from 'src/constants/regularExpression';
import { titleCase, currencyFormatter } from 'src/constants/currency';

import { Button } from '../Button/Button';

import ArrowImage from 'src/assets/broker/arrow.svg';

import classes from './selectedQuotesTable.module.scss';
import { groupMembersSelector } from 'src/store/groupMembers';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export const SelectedQuotesTable = (props) => {
  const { selectedQoutes, quotesMetaData, accountProposalData, isEdit = false } = props;
  const dispatch = useDispatch();

  const members = useSelector(groupMembersSelector);
  const basePlanQuote = useSelector(getBasePlanQuote);

  const [isEmployee, setIsEmployee] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [isTotal, setIsTotal] = useState(false);

  const accountMembers = accountProposalData?.groups?.map((group) => group?.members)[0];

  const basePlanQuoteId = selectedQoutes.find(
    (item) => item.id === quotesMetaData?.quote?.base_plan_id,
  );

  const selectedBasePlanQuote = !isEmpty(basePlanQuoteId) ? basePlanQuoteId : basePlanQuote;

  const updatedSelectedQuotes = sumPremium(
    selectedQoutes,
    members.length ? members : accountMembers,
    quotesMetaData?.quote,
    null,
    selectedBasePlanQuote,
  );

  const handleEmployee = useCallback(() => {
    setIsEmployee((prev) => !prev);
  }, []);

  const handleEmployer = useCallback(() => {
    setIsEmployer((prev) => !prev);
  }, []);

  const handleTotal = useCallback(() => {
    setIsTotal((prev) => !prev);
  }, []);

  // TODO: Refactoring -> move calculate functions to constants
  const getNormalizedMemberAge = useCallback(
    (dateOfBirth) => {
      const { quote } = quotesMetaData;
      let memberAge = moment(quote?.effective_date).diff(moment(dateOfBirth), 'years');
      if (memberAge > 65) memberAge = 65;

      return memberAge;
    },
    [quotesMetaData],
  );

  const calculateMemberRate = useCallback(
    (member, rate, withContribution = true) => {
      const { quote } = quotesMetaData;

      let employerCost = 0;
      let memberAge = getNormalizedMemberAge(member.date_of_birth);

      const memberRate = !isEmpty(selectedBasePlanQuote)
        ? selectedBasePlanQuote[`age_${memberAge}`]
        : rate[`age_${memberAge}`];

      const eeContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_ee, 10) / 100
          : quote?.contribution_ee;
      const depContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_dep, 10) / 100
          : quote?.contribution_dep;

      if (withContribution) {
        if (member.relationship === 'employee') {
          if (quote?.contribution_type === 'percent') {
            employerCost += memberRate * eeContributionPercent;
          }
          if (quote?.contribution_type === 'dollar') {
            if (eeContributionPercent < memberRate) {
              employerCost += eeContributionPercent;
            }

            if (eeContributionPercent > memberRate) {
              employerCost += memberRate;
            }
          }
        } else {
          if (quote?.contribution_type === 'percent') {
            employerCost += memberRate * depContributionPercent;
          }
          if (quote?.contribution_type === 'dollar') {
            if (depContributionPercent < memberRate) {
              employerCost += depContributionPercent;
            }

            if (depContributionPercent > memberRate) {
              employerCost += memberRate;
            }
          }
        }
      }

      employerCost =
        quote?.contribution_type === 'percent'
          ? Math.round((employerCost + Number.EPSILON) * 100) / 100
          : employerCost;

      if (quote?.quote_type === 'advanced') {
        return rate[`age_${memberAge}`] - employerCost;
      }

      if (quote?.quote_type !== 'advanced') {
        return memberRate - employerCost;
      }
    },
    [getNormalizedMemberAge, quotesMetaData, selectedBasePlanQuote],
  );

  const calculateEmployerCost = useCallback(
    (member, rate) => {
      const { quote } = quotesMetaData;

      let employerCost = 0;
      let memberAge = getNormalizedMemberAge(member.date_of_birth);

      const memberRate = !isEmpty(selectedBasePlanQuote)
        ? selectedBasePlanQuote[`age_${memberAge}`]
        : rate[`age_${memberAge}`];

      const eeContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_ee, 10) / 100
          : quote?.contribution_ee;
      const depContributionPercent =
        quote?.contribution_type === 'percent'
          ? Number.parseInt(quote?.contribution_dep, 10) / 100
          : quote?.contribution_dep;

      if (member.relationship === 'employee') {
        if (quote?.contribution_type === 'percent') {
          employerCost +=
            Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
        }
        if (quote?.contribution_type === 'dollar') {
          if (eeContributionPercent < memberRate) {
            employerCost += eeContributionPercent;
          }

          if (eeContributionPercent > memberRate) {
            employerCost += memberRate;
          }
        }
      } else {
        if (quote?.contribution_type === 'percent') {
          employerCost +=
            Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
        }
        if (quote?.contribution_type === 'dollar') {
          if (depContributionPercent < memberRate) {
            employerCost += depContributionPercent;
          }

          if (depContributionPercent > memberRate) {
            employerCost += memberRate;
          }
        }
      }

      if (quote?.quote_type === 'advanced') {
        const valueToDecrease = rate[`age_${memberAge}`] - employerCost;
        if (valueToDecrease < 0) {
          return employerCost + valueToDecrease;
        } else {
          return employerCost;
        }
      }

      if (quote?.quote_type !== 'advanced') {
        return employerCost;
      }
    },
    [getNormalizedMemberAge, quotesMetaData, selectedBasePlanQuote],
  );

  const calculateEmployerTotal = useCallback(
    (rate) => {
      const { quote } = quotesMetaData;

      const quoteMembers = [...(members.length ? members : accountMembers)];
      const planMembers = quoteMembers.filter(
        (member) => member.current_medical_plan_name === rate.current_plan_name,
      );

      let totalCost = 0;

      for (const member of planMembers) {
        let employerCost = 0; // need to reset this each iteration
        let memberAge = getNormalizedMemberAge(member.date_of_birth);

        const memberRate = !isEmpty(selectedBasePlanQuote)
          ? selectedBasePlanQuote[`age_${memberAge}`]
          : rate[`age_${memberAge}`];

        const eeContributionPercent =
          quote?.contribution_type === 'percent'
            ? Number.parseInt(quote?.contribution_ee, 10) / 100
            : quote?.contribution_ee;
        const depContributionPercent =
          quote?.contribution_type === 'percent'
            ? Number.parseInt(quote?.contribution_dep, 10) / 100
            : quote?.contribution_dep;

        if (member.relationship === 'employee') {
          if (quote?.contribution_type === 'percent') {
            employerCost +=
              Math.round((memberRate * eeContributionPercent + Number.EPSILON) * 100) / 100;
          }

          if (quote?.contribution_type === 'dollar') {
            if (eeContributionPercent < memberRate) {
              employerCost += eeContributionPercent;
            }

            if (eeContributionPercent > memberRate) {
              employerCost += memberRate;
            }
          }
        } else {
          if (quote?.contribution_type === 'percent') {
            employerCost +=
              Math.round((memberRate * depContributionPercent + Number.EPSILON) * 100) / 100;
          }
          if (quote?.contribution_type === 'dollar') {
            if (depContributionPercent < memberRate) {
              employerCost += depContributionPercent;
            }

            if (depContributionPercent > memberRate) {
              employerCost += memberRate;
            }
          }
        }

        if (quote?.quote_type === 'advanced') {
          if (rate[`age_${memberAge}`] > employerCost) {
            totalCost += employerCost;
          } else {
            totalCost += rate[`age_${memberAge}`];
          }
        }

        if (quote?.quote_type !== 'advanced') {
          totalCost += employerCost;
        }
      }

      return currencyFormatter(totalCost);
    },
    [accountMembers, getNormalizedMemberAge, members, quotesMetaData, selectedBasePlanQuote],
  );

  const calculateEmployeeTotal = useCallback(
    (rate) => {
      const { quote } = quotesMetaData;

      const quoteMembers = [...(members.length ? members : accountMembers)];
      const planMembers = quoteMembers.filter(
        (member) => member.current_medical_plan_name === rate.current_plan_name,
      );

      let sumPerPlan = 0;

      for (const member of planMembers) {
        let employerCost = 0;
        let memberAge = getNormalizedMemberAge(member.date_of_birth);

        const memberRate = !isEmpty(selectedBasePlanQuote)
          ? selectedBasePlanQuote[`age_${memberAge}`]
          : rate[`age_${memberAge}`];

        const eeContributionPercent =
          quote?.contribution_type === 'percent'
            ? Number.parseInt(quote?.contribution_ee, 10) / 100
            : quote?.contribution_ee;
        const depContributionPercent =
          quote?.contribution_type === 'percent'
            ? Number.parseInt(quote?.contribution_dep, 10) / 100
            : quote?.contribution_dep;

        if (true) {
          if (member.relationship === 'employee') {
            if (quote?.contribution_type === 'percent') {
              employerCost += memberRate * eeContributionPercent;
            }
            if (quote?.contribution_type === 'dollar') {
              if (eeContributionPercent < memberRate) {
                employerCost += eeContributionPercent;
              }

              if (eeContributionPercent > memberRate) {
                employerCost += memberRate;
              }
            }
          } else {
            if (quote?.contribution_type === 'percent') {
              employerCost += memberRate * depContributionPercent;
            }
            if (quote?.contribution_type === 'dollar') {
              if (depContributionPercent < memberRate) {
                employerCost += depContributionPercent;
              }

              if (depContributionPercent > memberRate) {
                employerCost += memberRate;
              }
            }
          }
        }

        employerCost =
          quote?.contribution_type === 'percent'
            ? Math.round((employerCost + Number.EPSILON) * 100) / 100
            : employerCost;

        sumPerPlan += rate[`age_${memberAge}`] - employerCost;
      }

      return currencyFormatter(sumPerPlan);
    },
    [accountMembers, getNormalizedMemberAge, members, quotesMetaData, selectedBasePlanQuote],
  );

  const calculatePlanTotal = useCallback(
    (rate) => {
      const { quote } = quotesMetaData;

      const quoteMembers = [...(members.length ? members : accountMembers)];
      const planMembers = quoteMembers.filter(
        (item) => item.current_medical_plan_name === rate.current_plan_name,
      );

      let employerCost = 0;
      let sumCost = 0;

      for (const member of planMembers) {
        let memberAge = getNormalizedMemberAge(member.date_of_birth);

        const memberRate = !isEmpty(selectedBasePlanQuote)
          ? selectedBasePlanQuote[`age_${memberAge}`]
          : rate[`age_${memberAge}`];

        const eeContributionPercent = Number.parseInt(quote?.contribution_ee, 10) / 100;
        const depContributionPercent = Number.parseInt(quote?.contribution_dep, 10) / 100;

        if (true) {
          if (member.relationship === 'employee') {
            employerCost += memberRate * eeContributionPercent;
          } else {
            employerCost += memberRate * depContributionPercent;
          }
        }

        employerCost = Math.round((employerCost + Number.EPSILON) * 100) / 100;
        sumCost += rate[`age_${memberAge}`];
      }
      return currencyFormatter(sumCost);
    },
    [accountMembers, getNormalizedMemberAge, members, quotesMetaData, selectedBasePlanQuote],
  );

  const onDeselectQuote = useCallback(
    (id) => () => {
      const updatedSelectedQuotes = _.reject(selectedQoutes, (el) => el.id === id);

      dispatch(setSelectedQuotes(updatedSelectedQuotes));
    },
    [dispatch, selectedQoutes],
  );

  const moveLeft = useCallback(
    (id) => () => {
      const quotes = _.cloneDeep(selectedQoutes);

      const index = quotes.findIndex((item) => item.id === id);
      if (index > 0) {
        const data = [...quotes];
        [data[index - 1].order_id, data[index].order_id] = [
          data[index].order_id,
          data[index - 1].order_id,
        ];
        dispatch(setSelectedQuotes(data.sort((a, b) => a.order_id - b.order_id)));
      }
    },
    [dispatch, selectedQoutes],
  );

  const moveRight = useCallback(
    (id) => () => {
      const quotes = _.cloneDeep(selectedQoutes);
      const index = quotes.findIndex((item) => item.id === id);
      if (index < quotes.length - 1) {
        const data = [...quotes];
        [data[index + 1].order_id, data[index].order_id] = [
          data[index].order_id,
          data[index + 1].order_id,
        ];
        dispatch(setSelectedQuotes(data.sort((a, b) => a.order_id - b.order_id)));
      }
    },
    [dispatch, selectedQoutes],
  );

  const employeeIconStyles = classNames({
    [classes.Icon]: !isEmployee,
    [classes.IconActive]: isEmployee,
  });

  const employerIconStyles = classNames({
    [classes.Icon]: !isEmployer,
    [classes.IconActive]: isEmployer,
  });

  const totalIconStyles = classNames({
    [classes.Icon]: !isTotal,
    [classes.IconActive]: isTotal,
  });

  const selectedPayer = quotesMetaData?.quote?.contribution_source;

  return (
    <div className={classes.SelectedRatesTable}>
      <table style={{ width: '100%' }}>
        <tbody>
          {updatedSelectedQuotes[0]?.current_plan_name && (
            <tr>
              <th>Current Plan Name</th>
              {updatedSelectedQuotes?.map((item, i) => (
                <td key={item.id + i}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  {item.current_plan_name}
                  {item.id === basePlanQuoteId?.id && (
                    <span className={classes.BasePlan}>Base Plan</span>
                  )}
                </td>
              ))}
            </tr>
          )}
          {isEdit && selectedQoutes.length !== 1 && (
            <tr>
              <th></th>
              {updatedSelectedQuotes?.map((item, i) => (
                <td key={item.id + i}>
                  {/* eslint-disable-next-line jsx-a11y/alt-text */}
                  <div className={classes.SortIcons}>
                    <div></div>
                    {i !== 0 && (
                      <div className={classes.SortIconsItem} onClick={moveLeft(item.id)}>
                        <ChevronLeft />
                      </div>
                    )}
                    {i !== selectedQoutes.length - 1 && (
                      <div className={classes.SortIconsItem} onClick={moveRight(item.id)}>
                        <ChevronRight />
                      </div>
                    )}
                  </div>
                </td>
              ))}
            </tr>
          )}
          <tr>
            <th></th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={item.logo_url} className={classes.SelectedRatesLogo} />
              </td>
            ))}
          </tr>
          <tr>
            <th>Carrier</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item.carrier_name}</td>
            ))}
          </tr>
          <tr>
            <th>Plan Name</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} className={classes.PlanName}>
                {item.display_name}
              </td>
            ))}
          </tr>
          <tr>
            <th>Plan Type</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item.plan_type}</td>
            ))}
          </tr>
          <tr>
            <th>Network Size</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.network_size?.toLocaleString()}</td>
            ))}
          </tr>
          <tr>
            <th>Tier</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{titleCase(item.level)}</td>
            ))}
          </tr>
          <tr>
            <th>Primary Care Physician Copay</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.primary_care_physician?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Specialist Copay</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.specialist}</td>
            ))}
          </tr>
          <tr>
            <th>Individual Deductible</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>
                {item?.individual_medical_deductible?.match(inNetworkRegex)[1]}
              </td>
            ))}
          </tr>
          <tr>
            <th>Family Deductible</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.family_medical_deductible?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Inpatient Facility</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.inpatient_facility?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Coinsurance</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.plan_coinsurance}</td>
            ))}
          </tr>
          <tr>
            <th>Individual Out-of-Pocket</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.individual_medical_moop?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Family Out-of-Pocket</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.family_medical_moop?.match(inNetworkRegex)[1]}</td>
            ))}
          </tr>
          <tr>
            <th>Emergency Room</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.emergency_room}</td>
            ))}
          </tr>
          <tr>
            <th>Urgent Care</th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i}>{item?.urgent_care}</td>
            ))}
          </tr>
          <tr>
            <th>Benefits Summary</th>
            {updatedSelectedQuotes?.map((item, i) =>
              item?.benefits_summary_url ? (
                <td key={item.id + i} className={classes.TableLink}>
                  <a href={item?.benefits_summary_url} target="_blank" rel="noreferrer">
                    Click Here
                  </a>
                </td>
              ) : (
                <td key={item.id + i}>None available</td>
              ),
            )}
          </tr>
          <tr>
            <th onClick={handleEmployee} id="employee">
              Employer
              <img src={ArrowImage} className={employeeIconStyles} alt="ArrowImage" />
            </th>

            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} style={{ fontWeight: isEmployee ? 'bold' : 400 }}>
                {selectedPayer === 'employee' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployeeTotal(item)
                      : currencyFormatter(item?.employee_cost)}
                  </>
                )}

                {selectedPayer === 'employer' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployerTotal(item)
                      : currencyFormatter(item?.employer_cost)}
                  </>
                )}
              </td>
            ))}
          </tr>
          {isEmployee && (
            <>
              {accountProposalData?.groups?.map((group) => {
                return group?.members?.map((member) => (
                  <tr
                    key={member.id}
                    id="employeeTable"
                    className={isEmployee ? classes.VisibleTable : classes.HiddenTable}
                  >
                    <td style={{ paddingLeft: '30px' }}>
                      {member?.first_name} {member?.last_name}
                    </td>
                    {updatedSelectedQuotes.map((item, i) => (
                      <td key={item.id + i}>
                        {selectedPayer === 'employee' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(calculateMemberRate(member, item))
                                  : '--'}
                              </>
                            ) : (
                              <>{currencyFormatter(calculateMemberRate(member, item))}</>
                            )}
                          </>
                        )}

                        {selectedPayer === 'employer' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(calculateEmployerCost(member, item, false))
                                  : '--'}
                              </>
                            ) : (
                              <>{currencyFormatter(calculateEmployerCost(member, item, false))}</>
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ));
              })}
            </>
          )}
          <tr>
            <th onClick={handleEmployer} id="employer">
              Employee
              <img src={ArrowImage} className={employerIconStyles} alt="ArrowImage" />
            </th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} style={{ fontWeight: isEmployer ? 'bold' : 400 }}>
                {selectedPayer === 'employee' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployerTotal(item)
                      : currencyFormatter(item?.employer_cost)}
                  </>
                )}
                {selectedPayer === 'employer' && (
                  <>
                    {quotesMetaData?.quote?.quote_type === 'advanced'
                      ? calculateEmployeeTotal(item)
                      : currencyFormatter(item?.employee_cost)}
                  </>
                )}
              </td>
            ))}
          </tr>
          {isEmployer && (
            <>
              {accountProposalData?.groups?.map((group) => {
                return group?.members?.map((member) => (
                  <tr
                    key={member.id}
                    id="employerTable"
                    className={isEmployer ? classes.VisibleTable : classes.HiddenTable}
                  >
                    <td style={{ paddingLeft: '30px' }}>
                      {member?.first_name} {member?.last_name}
                    </td>
                    {updatedSelectedQuotes.map((item, i) => (
                      <td key={item.id + i}>
                        {selectedPayer === 'employee' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(calculateEmployerCost(member, item, false))
                                  : '--'}
                              </>
                            ) : (
                              <>{currencyFormatter(calculateEmployerCost(member, item, false))}</>
                            )}
                          </>
                        )}

                        {selectedPayer === 'employer' && (
                          <>
                            {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                              <>
                                {member.current_medical_plan_name === item.current_plan_name
                                  ? currencyFormatter(calculateMemberRate(member, item))
                                  : '--'}
                              </>
                            ) : (
                              <>{currencyFormatter(calculateMemberRate(member, item))}</>
                            )}
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ));
              })}
            </>
          )}
          <tr>
            <th onClick={handleTotal} id="total">
              Total <img src={ArrowImage} className={totalIconStyles} alt="ArrowImage" />
            </th>
            {updatedSelectedQuotes?.map((item, i) => (
              <td key={item.id + i} style={{ fontWeight: isTotal ? 'bold' : 400 }}>
                {quotesMetaData?.quote?.quote_type === 'advanced'
                  ? calculatePlanTotal(item)
                  : currencyFormatter(item?.total_premium)}
              </td>
            ))}
          </tr>
          {isTotal && (
            <>
              {accountProposalData?.groups?.map((group) => {
                return group?.members?.map((member) => (
                  <tr
                    key={member.id}
                    className={isTotal ? classes.VisibleTable : classes.HiddenTable}
                  >
                    <td style={{ paddingLeft: '30px' }}>
                      {member?.first_name} {member?.last_name}
                    </td>
                    {updatedSelectedQuotes.map((item, i) => (
                      <td key={item.id + i}>
                        {quotesMetaData?.quote?.quote_type === 'advanced' ? (
                          <>
                            {member.current_medical_plan_name === item.current_plan_name
                              ? currencyFormatter(calculateMemberRate(member, item, false))
                              : '--'}
                          </>
                        ) : (
                          <>{currencyFormatter(calculateMemberRate(member, item, false))}</>
                        )}
                      </td>
                    ))}
                  </tr>
                ));
              })}
            </>
          )}

          {isEdit && (
            <tr>
              <th></th>
              {updatedSelectedQuotes?.map((item, i) => (
                <td key={item.id + i}>
                  <Button type="inverse" title="Deselect" onClick={onDeselectQuote(item.id)} />
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>

    // TODO Research about vertcal aligned tables etc.
  );
};

SelectedQuotesTable.propTypes = {
  selectedQoutes: PropTypes.array,
  quotesMetaData: PropTypes.object,
  accountProposalData: PropTypes.object,
};
