import { createSlice } from '@reduxjs/toolkit';

import {
  getDentalPlans,
  getDentalQuotePlans,
  getIssuerDentalPlans,
  updateDentalOffer,
} from './dentalConfiguration.actions';

const initialState = {
  dentalPlans: [],
  dentalQuotePlans: [],
  isLoadingDentalQuotePlans: false,
  isLoadingDentalPlans: false,

  // ISSUER
  issuerDentalPlans: [],
  isLoadingIssuerDentalPlans: false,
  isLoadingUpdateDentalOffer: false,
};

const dentalConfigurationSlice = createSlice({
  name: 'dentalConfiguration',
  initialState,
  reducers: {
    setDentalPlans: (state, action) => {
      state.dentalPlans = action.payload;
    },
    setDentalQuotePlans: (state, action) => {
      state.dentalQuotePlans = action.payload;
    },

    setIssuerDentalPlans: (state, action) => {
      state.issuerDentalPlans = action.payload;
    },

    clearDentalConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDentalQuotePlans.pending, (state) => {
      state.isLoadingDentalQuotePlans = true;
    });
    builder.addCase(getDentalQuotePlans.fulfilled, (state, action) => {
      state.dentalQuotePlans = action.payload;
      state.isLoadingDentalQuotePlans = false;
    });
    builder.addCase(getDentalQuotePlans.rejected, (state) => {
      state.isLoadingDentalQuotePlans = false;
    });

    builder.addCase(getDentalPlans.pending, (state) => {
      state.isLoadingDentalPlans = true;
    });
    builder.addCase(getDentalPlans.fulfilled, (state, action) => {
      state.dentalPlans = action.payload;
      state.isLoadingDentalPlans = false;
    });
    builder.addCase(getDentalPlans.rejected, (state) => {
      state.isLoadingDentalPlans = false;
    });

    // ISSUER

    builder.addCase(getIssuerDentalPlans.pending, (state) => {
      state.isLoadingIssuerDentalPlans = true;
    });
    builder.addCase(getIssuerDentalPlans.fulfilled, (state, action) => {
      state.issuerDentalPlans = action.payload;
      state.isLoadingIssuerDentalPlans = false;
    });
    builder.addCase(getIssuerDentalPlans.rejected, (state) => {
      state.isLoadingIssuerDentalPlans = false;
    });

    builder.addCase(updateDentalOffer.pending, (state) => {
      state.isLoadingUpdateDentalOffer = true;
    });
    builder.addCase(updateDentalOffer.fulfilled, (state) => {
      state.isLoadingUpdateDentalOffer = false;
    });
    builder.addCase(updateDentalOffer.rejected, (state) => {
      state.isLoadingUpdateDentalOffer = false;
    });
  },
});

export const {
  setDentalPlans,
  setDentalQuotePlans,
  setIssuerDentalPlans,
  clearDentalConfigurationState,
} = dentalConfigurationSlice.actions;

export default dentalConfigurationSlice.reducer;
