import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIssuerDataSelector } from 'src/store/issuer';
import {
  getIssuerHospitalPlans,
  getIssuerHospitalPlansSelector,
  isLoadingIssuerHospitalPlansSelector,
} from 'src/store/hospitalConfiguration';

import { EmptyField, Loader, LoaderWrapper } from 'src/components';
import { HospitalConfiguration } from '../../components';

import classes from './hospitalPlans.module.scss';

export const HospitalPlans = (props) => {
  const { activeTab } = props;

  const dispatch = useDispatch();

  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const issuerData = useSelector(getIssuerDataSelector);
  const hospitalPlans = useSelector(getIssuerHospitalPlansSelector);
  const isLoading = useSelector(isLoadingIssuerHospitalPlansSelector);

  const issuerId = issuerData?.issuer_id;

  useEffect(() => {
    dispatch(getIssuerHospitalPlans({ accountId, proposalId }));
  }, [dispatch, accountId, proposalId]);

  return isLoading ? (
    <div className={classes.Loader}>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    </div>
  ) : hospitalPlans?.length > 0 ? (
    hospitalPlans?.map((item) => (
      <div key={item.id}>
        <HospitalConfiguration
          id={item.id}
          activeTab={activeTab}
          accountId={accountId}
          proposalId={item.proposal_id}
          userId={issuerData?.id}
          issuerId={issuerId}
          brokerRequest={item}
        />
      </div>
    ))
  ) : (
    <EmptyField title="No dental RFP created yet." />
  );
};
