import { createSlice } from '@reduxjs/toolkit';

import {
  getIssuerMedicalPlans,
  getMedicalPlans,
  getMedicalQuotePlans,
  updateMedicalOffer,
} from './medicalConfiguration.actions';

const initialState = {
  medicalPlans: [],
  medicalQuotePlans: [],
  isLoadingMedicalQuotePlans: false,
  isLoadingMedicalPlans: false,

  // ISSUER

  issuerMedicalPlans: [],
  isLoadingIssuerMedicalPlans: false,

  isLoadingUpdateMedicalOffer: false,
};

const medicalConfigurationSlice = createSlice({
  name: 'medicalConfiguration',
  initialState,
  reducers: {
    setMedicalPlans: (state, action) => {
      state.medicalPlans = action.payload;
    },
    setMedicalQuotePlans: (state, action) => {
      state.medicalQuotePlans = action.payload;
    },

    setIssuerMedicalPlans: (state, action) => {
      state.issuerMedicalPlans = action.payload;
    },

    clearMedicalConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getMedicalQuotePlans.pending, (state) => {
      state.isLoadingMedicalQuotePlans = true;
    });
    builder.addCase(getMedicalQuotePlans.fulfilled, (state, action) => {
      state.medicalQuotePlans = action.payload;
      state.isLoadingMedicalQuotePlans = false;
    });
    builder.addCase(getMedicalQuotePlans.rejected, (state) => {
      state.isLoadingMedicalQuotePlans = false;
    });

    builder.addCase(getMedicalPlans.pending, (state) => {
      state.isLoadingMedicalPlans = true;
    });
    builder.addCase(getMedicalPlans.fulfilled, (state, action) => {
      state.medicalPlans = action.payload;
      state.isLoadingMedicalPlans = false;
    });
    builder.addCase(getMedicalPlans.rejected, (state) => {
      state.isLoadingMedicalPlans = false;
    });

    // ISSUER

    builder.addCase(getIssuerMedicalPlans.pending, (state) => {
      state.isLoadingIssuerMedicalPlans = true;
    });
    builder.addCase(getIssuerMedicalPlans.fulfilled, (state, action) => {
      state.issuerMedicalPlans = action.payload;
      state.isLoadingIssuerMedicalPlans = false;
    });
    builder.addCase(getIssuerMedicalPlans.rejected, (state) => {
      state.isLoadingIssuerMedicalPlans = false;
    });

    builder.addCase(updateMedicalOffer.pending, (state) => {
      state.isLoadingUpdateMedicalOffer = true;
    });
    builder.addCase(updateMedicalOffer.fulfilled, (state) => {
      state.isLoadingUpdateMedicalOffer = false;
    });
    builder.addCase(updateMedicalOffer.rejected, (state) => {
      state.isLoadingUpdateMedicalOffer = false;
    });
  },
});

export const {
  setMedicalPlans,
  setMedicalQuotePlans,
  setIssuerMedicalPlans,
  clearMedicalConfigurationState,
} = medicalConfigurationSlice.actions;

export default medicalConfigurationSlice.reducer;
