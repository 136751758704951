import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { getRequestedProposalsStatus, getSharedContacts } from '../planConfiguration';
import { clearLifeConfigurationState, setLifePlans } from './lifeConfiguration.slice';

import { ERROR } from 'src/constants/errorNames';
import { calculateLifeRates, calculateUniLifeRates } from 'src/constants/lifeRates';
import { calculateLifeConfigurationAgeRates } from 'src/constants/calculateConfigurationRates';

export const getLifePlans = createAsyncThunk(
  'life-configuration/get-life-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getLifePlans(accountId, proposalId, read_token);

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getLifeQuotePlans = createAsyncThunk(
  'life-configuration/get-life-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getLifeQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const updateLifePlanOrder = createAsyncThunk(
  'life-configuration/update-life-plan-order',
  async (data) => {
    try {
      const { accountId, proposalId, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.planConfiguration.updateLifePlanOrder(accountId, proposalId, payload);
    } catch (err) {
      console.warn(err);
    }
  },
);

export const updateLifeQuotePlanOrder = createAsyncThunk(
  'life-configuration/update-life-quote-plan-order',
  async (data) => {
    try {
      const { accountId, proposalId, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.planConfiguration.updateLifeQuotePlanOrder(accountId, proposalId, payload);
    } catch (err) {
      console.warn(err);
    }
  },
);

export const deleteLifePlan = createAsyncThunk(
  'life-configuration/delete-life-plan',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, planId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.planConfiguration.deleteLifePlanConfiguration(accountId, proposalId, planId);

      toast('Life plan was deleted successfully!', { type: 'success' });

      thunkApi.dispatch(getLifePlans({ accountId, proposalId }));
      thunkApi.dispatch(getLifeQuotePlans({ accountId, proposalId }));
      thunkApi.dispatch(getSharedContacts({ accountId, proposalId }));
      thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
    } catch (e) {
      console.warn(e);
    }
  },
);

export const getLifeOffers = createAsyncThunk(
  'life-configuration/get-life-offers',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading } = data;
      setIsLoading(true);

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      const userType = thunkApi.getState().app.userType;

      const response = await api.planConfiguration.getMedicalOffers(
        accountId,
        proposalId,
        'life',
        configId,
      );

      if (userType === 'broker') {
        const groupMembers = thunkApi.getState().groupMembers.groupMembers;

        const brokerOffer = {
          ...brokerConfig,
          issuer_name: 'broker',
          status: 'selected',
        };

        const updatedResponse = [brokerOffer, ...response];

        const calculatedResponse = updatedResponse?.map((item) => {
          if (item?.status !== 'pending') {
            let calculatedRates = {};

            if (item?.rate_tier_type === '4tier') {
              if (item?.is_uni_tobacco) {
                calculatedRates = calculateUniLifeRates(groupMembers, item);
              }

              if (!item?.is_uni_tobacco) {
                calculatedRates = calculateLifeRates(groupMembers, item);
              }
            }

            if (item?.rate_tier_type !== '4tier') {
              calculatedRates = calculateLifeConfigurationAgeRates(groupMembers, item);
            }

            return { ...item, calculation: calculatedRates };
          }
          return item;
        });

        return setOffers(calculatedResponse);
      }

      return setOffers(response);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

export const getLifeQuotes = createAsyncThunk(
  'life-configuration/get-life-quotes',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, setOffers, brokerConfig, setIsLoading, read_token } =
        data;
      setIsLoading(true);

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'life',
        configId,
        read_token,
      );

      const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      const brokerOffer = {
        ...brokerConfig,
        issuer_name: 'broker',
        status: 'selected',
      };

      const updatedResponse = [brokerOffer, ...response];

      const calculatedResponse = updatedResponse?.map((item) => {
        let calculatedRates = {};

        if (item?.rate_tier_type === '4tier') {
          if (item?.is_uni_tobacco) {
            calculatedRates = calculateUniLifeRates(groupMembers, item);
          }

          if (!item?.is_uni_tobacco) {
            calculatedRates = calculateLifeRates(groupMembers, item);
          }
        }

        if (item?.rate_tier_type !== '4tier') {
          calculatedRates = calculateLifeConfigurationAgeRates(groupMembers, item);
        }

        return { ...item, calculation: calculatedRates };
      });

      return setOffers(calculatedResponse);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

// ISSUER

export const getIssuerLifePlans = createAsyncThunk(
  'issuer/get-issuer-life-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.planConfiguration.getLifePlans(accountId, proposalId);

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createLifeOffer = createAsyncThunk(
  'issuer/create-life-offer',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, config_id } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = {
        ...data,
      };

      delete payload.created_timestamp;
      delete payload.comp_amount;
      delete payload.death_benefit_key;

      delete payload.accountId;
      delete payload.proposal_id;
      delete payload.is_current_rate;
      delete payload.proposalId;
      delete payload.order_id;

      const response = await api.issuer.createCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'life',
        payload,
      );

      toast('Your life offer was successfully saved!', { type: 'success' });

      thunkApi.dispatch(getIssuerLifePlans({ accountId, proposalId }));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const updateLifeOffer = createAsyncThunk(
  'issuer/update-life-offer',
  async (data, thunkApi) => {
    try {
      const { payload, offerId, read_token, setUpdatedOffer } = data;

      const { accountId, proposalId, config_id, isBroker } = payload;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      delete payload.comp_amount;
      delete payload.id;
      delete payload.created_timestamp;
      delete payload.issuer_name;
      delete payload.death_benefit_key;

      delete payload.accountId;
      delete payload.proposalId;
      delete payload.isBroker;

      const response = await api.issuer.updateCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'life',
        payload,
        offerId,
        read_token,
      );

      toast('Your life offer was successfully updated!', { type: 'success' });
      thunkApi.dispatch(getIssuerLifePlans({ accountId, proposalId }));

      if (isBroker) {
        if (setUpdatedOffer) {
          setUpdatedOffer([response]);
        }
      }

      const lifeResponse = await api.planConfiguration.getLifePlans(
        accountId,
        proposalId,
        read_token,
      );

      thunkApi.dispatch(setLifePlans(lifeResponse));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const deleteLifeOffer = createAsyncThunk('issuer/delete-life-offer', async (data) => {
  try {
    const { accountId, proposalId, configId, offerId } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    await api.issuer.deleteCarrierOffer(accountId, proposalId, 'life', configId, offerId);
  } catch (err) {
    console.error(err);
  }
});

// CLEAR STATE

export const clearLifeState = createAsyncThunk('life/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearLifeConfigurationState());
});
