import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import _ from 'lodash';

import {
  createMedicalOffer,
  deleteMedicalOffer,
  updateMedicalOffer,
} from 'src/store/medicalConfiguration';

import {
  normalizeMedicalTenYearAgeRates,
  tenYearAges,
} from 'src/features/medical/medicalAgeBandedRates/medicalTenYearAges';
import {
  fiveYearAges,
  normalizeMedicalFiveYearAgeRates,
} from 'src/features/medical/medicalAgeBandedRates/medicalFiveYearAges';
import {
  allAges,
  normalizeAllAgeRates,
} from 'src/features/medical/medicalAgeBandedRates/medicalAllAges';

import { Button, DropZone, Input, Modal, SelectOption, ExtraRows } from 'src/components';

import { compensationAmountData } from 'src/features/dental/dentalForm/dentalFormConstants';

import {
  fundingTypeData,
  planTypeData,
  networkSizeData,
  tierData,
  compensationMethodData,
  inpatientFacilityOptions,
} from 'src/features/medical/medicalForm/medicalFormConstants';
import { MedicalAgeBandedRates } from 'src/features/medical/medicalAgeBandedRates/MedicalAgeBandedRates';
import { rateValueRegex } from 'src/constants/regularExpression';
import {
  normalizeCompensationAmount,
  normalizeCompensationAmountEdit,
  normalizeCoverageAgeRates,
  normalizeRateTierType,
} from 'src/constants/requestProposal';

import classes from './medicalConfigurationForm.module.scss';
import { normalizeFundingType } from 'src/constants/proposalFormField';
import { TOOLTIP_NAMES } from 'src/constants/tooltipNames';
import { brokerAgencySelector } from 'src/store/agency';

export const MedicalConfigurationForm = (props) => {
  const {
    id,
    accountId,
    proposalId,
    userId,
    issuerId,
    brokerConfig,
    medicalOffers,
    getMedicalOffers,
    getMedicalQuotes,
  } = props;
  const dispatch = useDispatch();

  const brokerAgency = useSelector(brokerAgencySelector);

  const [medicalConfigs, setMedicalConfigs] = useState([]);
  const [originalConfig, setOriginalConfig] = useState({});
  const [selectedConfig, setSelectedConfig] = useState({});
  const [extraRows, setExtraRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedDeleteConfig, setSelectedDeleteConfig] = useState({});

  const [isVisibleFundingType, setIsVisibleFundingType] = useState(false);
  const [isVisiblePlanType, setIsVisiblePlanType] = useState(false);
  const [isVisibleNetworkSize, setIsVisibleNetworkSize] = useState(false);
  const [isVisibleTier, setIsVisibleTier] = useState(false);
  const [isVisibleInpatient, setIsVisibleInpatient] = useState(false);
  const [isVisibleCompensationMethod, setIsVisibleCompensationMethod] = useState(false);
  const [isVisibleCompensationAmount, setIsVisibleCompensationAmount] = useState(false);
  const [isVisibleSelect, setIsVisibleSelect] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const originalId = medicalOffers?.find((item) => item.issuer_name)?.user_id;

  useEffect(() => {
    setMedicalConfigs([...medicalOffers]);
  }, [medicalOffers]);

  const tierOptions = [
    { key: '3tier', name: '3 Tier' },
    { key: '4tier', name: '4 Tier' },
    { key: 'all_ages', name: 'All Ages' },
    { key: 'banded_5', name: '5 Year Banded' },
    { key: 'banded_10', name: '10 Year Banded' },
  ];

  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig, selectedConfig));
  }, [originalConfig, selectedConfig]);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig?.custom_fields, extraRows));
  }, [extraRows, originalConfig?.custom_fields]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      const selectedMedicalConfig = medicalConfigs?.find((item) => item.id === selectedConfig?.id);
      if (selectedMedicalConfig?.custom_fields?.length) {
        for (const originalField of selectedMedicalConfig?.custom_fields) {
          for (const carrierField of extraRows) {
            if (originalField.id === carrierField.id) {
              if (originalField.name !== carrierField.name) {
                setIsEdited(true);
              }

              if (originalField.value !== carrierField.value) {
                setIsEdited(true);
              }
            }
          }
        }
      }
    }
  }, [medicalConfigs, selectedConfig, extraRows]);

  const onClickAddOffer = useCallback(() => {
    const payload = {
      ...brokerConfig,
      config_id: brokerConfig?.id,
      id: uuid(),
      issuer_id: issuerId,
      emergency_room: brokerConfig.emergency_room.replaceAll('$', '').replaceAll('%', ''),
      urgent_care: brokerConfig.urgent_care.replaceAll('$', '').replaceAll('%', ''),
      rate_ee: null,
      rate_ee_1: null,
      rate_ee_ch: null,
      rate_ee_sp: null,
      rate_fam: null,
      // 2 cases - 1 for broker side, second one for carrier side
      user_id: userId !== originalId ? originalId : userId,
      comp_amount: normalizeCompensationAmountEdit(brokerConfig),
    };

    setSelectedConfig(payload);
    setMedicalConfigs((state) => [...state, payload]);
  }, [brokerConfig, issuerId, originalId, userId]);

  const onClickEditConfig = useCallback(
    (item) => () => {
      const originalConfig = medicalConfigs?.find((offer) => offer.id === item.id);

      const originalPayload = {
        ...originalConfig,
        rate_ee: originalConfig.rate_ee ? originalConfig.rate_ee.toString() : null,
        rate_ee_1: originalConfig.rate_ee_1 ? originalConfig.rate_ee_1.toString() : null,
        rate_ee_ch: originalConfig.rate_ee_ch ? originalConfig.rate_ee_ch.toString() : null,
        rate_ee_sp: originalConfig.rate_ee_sp ? originalConfig.rate_ee_sp.toString() : null,
        rate_fam: originalConfig.rate_fam ? originalConfig.rate_fam.toString() : null,
        emergency_room: originalConfig.emergency_room.replaceAll('$', '').replaceAll('%', ''),
        urgent_care: originalConfig.urgent_care.replaceAll('$', '').replaceAll('%', ''),
        comp_amount: normalizeCompensationAmountEdit(originalConfig),
      };

      const clonedCustomFields = _.cloneDeep(item.custom_fields);
      const selectedPayload = {
        ...item,
        rate_ee: item.rate_ee ? item.rate_ee.toString() : 0,
        rate_ee_1: item.rate_ee_1 ? item.rate_ee_1.toString() : 0,
        rate_ee_ch: item.rate_ee_ch ? item.rate_ee_ch.toString() : 0,
        rate_ee_sp: item.rate_ee_sp ? item.rate_ee_sp.toString() : 0,
        rate_fam: item.rate_fam ? item.rate_fam.toString() : 0,
        custom_fields: clonedCustomFields,
        emergency_room: item.emergency_room.replaceAll('$', '').replaceAll('%', ''),
        urgent_care: item.urgent_care.replaceAll('$', '').replaceAll('%', ''),
        comp_amount: normalizeCompensationAmountEdit(item),
      };

      setOriginalConfig(originalPayload);
      setSelectedConfig(selectedPayload);
      setExtraRows(clonedCustomFields ?? []);
    },
    [medicalConfigs],
  );

  const onChangePercentage = useCallback((event, target) => {
    if (target === 'comp_amount_pct') {
      setSelectedConfig((prev) => ({
        ...prev,
        comp_pppm_dollar: null,
        comp_pepm_dollar: null,
      }));
    }

    if (event.target.value.length > 3) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: Number(event.target.value.slice(0, 3)),
      }));
    }
    if (event.target.value < 0) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 0,
      }));
    }
    if (event.target.value > 100) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 100,
      }));
    }

    setSelectedConfig((prev) => ({
      ...prev,
      [target]: event.target.value,
    }));
  }, []);

  const onChangeInput = useCallback(
    (event, target) => {
      const payload = {
        ...selectedConfig,
        [target]: event.target.value,
      };

      setSelectedConfig(payload);
    },

    [selectedConfig],
  );

  const handleOpenCarrierSelect = useCallback(
    (setCarrierSelectValue) => () => {
      setCarrierSelectValue((prev) => !prev);
    },
    [],
  );

  const onSelectOption = useCallback(
    (value, target, setIsVisible) => () => {
      let payload = {};
      setIsVisible(false);

      if (value === 'yes' || value === 'no') {
        payload = {
          ...selectedConfig,
          [target]: value === 'yes' ? true : false,
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onChangeInputValue = useCallback(
    (event, target, maxRange) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value > maxRange ? maxRange : event.target.value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onClickCancel = useCallback(() => {
    setOriginalConfig({});
    setSelectedConfig({});
    setMedicalConfigs(medicalOffers);
    setExtraRows([]);
  }, [medicalOffers]);

  const onChangePepmPppmValue = useCallback((event, target) => {
    if (target === 'comp_pepm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pppm_dollar: null }));
    }

    if (target === 'comp_pppm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pepm_dollar: null }));
    }

    if (event.target.value.includes('.')) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 4) }));
    }

    if (event.target.value > 3) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 3) }));
    }

    setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value }));
  }, []);

  const compensationAmount = compensationAmountData(
    selectedConfig,
    onChangePercentage,
    onChangePepmPppmValue,
  );

  const onFocusInput = (event, target) => {
    let payload = {};

    if (Number(event.target.value) === 0) {
      payload = {
        ...selectedConfig,
        [target]: '',
      };
      return setSelectedConfig(payload);
    }
  };

  const onBlurInput = (event, target) => {
    if (!event.target.value) {
      const payload = {
        ...selectedConfig,
        [target]: 0,
      };
      return setSelectedConfig(payload);
    }

    if (
      !event.target.value.includes('.') &&
      (event.target.value[0] === '0' || event.target.value[0] === 0)
    ) {
      const payload = {
        ...selectedConfig,
        [target]: event.target.value.slice(1) ? event.target.value.slice(1) : 0,
      };
      return setSelectedConfig(payload);
    }
  };

  const onChangeRateValue = useCallback(
    (event, target) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };
        return setSelectedConfig(payload);
      }

      if (!event.target.value.match(rateValueRegex)) {
        payload = {
          ...selectedConfig,
          [target]: event.target.value.slice(0, event.target.value.length - 1),
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const [brokerAgeBandedRates, setBrokerAgebandedRates] = useState();

  useEffect(() => {
    let payload = {};

    for (const item of Object.keys(brokerConfig)) {
      if (item.includes('age')) {
        payload = {
          ...payload,
          [item]: brokerConfig[item],
        };
      }

      setBrokerAgebandedRates(payload);
    }
  }, [brokerConfig]);

  const [selectedAllAges, setSelectedAllAges] = useState();
  const [selectedAllAgesPayload, setSelectedAllAgesPayload] = useState();
  const [selectedFiveYearAges, setSelectedFiveYearAges] = useState();
  const [selectedFiveYearAgesPayload, setSelectedFiveYearAgesPayload] = useState();
  const [selectedTenYearAges, setSelectedTenYearAges] = useState();
  const [selectedTenYearAgesPayload, setSelectedTenYearAgesPayload] = useState();

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      if (selectedConfig?.rate_tier_type === 'all_ages') {
        const payload = allAges(selectedConfig);
        setSelectedAllAges(payload);
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        const payload = fiveYearAges(selectedConfig);
        setSelectedFiveYearAges(payload);
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        const payload = tenYearAges(selectedConfig);
        setSelectedTenYearAges(payload);
      }
    }
  }, [selectedConfig]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      if (!_.isEmpty(selectedAllAges)) {
        const payload = normalizeAllAgeRates(selectedAllAges);

        setSelectedAllAgesPayload(payload);
      }
      if (!_.isEmpty(selectedFiveYearAges)) {
        const payload = normalizeMedicalFiveYearAgeRates(selectedFiveYearAges);

        setSelectedFiveYearAgesPayload(payload);
      }
      if (!_.isEmpty(selectedTenYearAges)) {
        const payload = normalizeMedicalTenYearAgeRates(selectedTenYearAges);

        setSelectedTenYearAgesPayload(payload);
      }
    }
  }, [selectedAllAges, selectedConfig, selectedFiveYearAges, selectedTenYearAges]);

  const onSaveSelectedConfig = useCallback(async () => {
    const isOfferExist = !_.isEmpty(medicalOffers?.find((item) => item?.id === selectedConfig?.id));

    const data = {
      // 2 cases - 1 for broker side, second one for carrier side
      // user_id: userId !== originalId ? originalId : userId,
      issuer_id: issuerId,
      config_id: id,
      proposalId: proposalId,
      accountId: accountId,
      emergency_room: brokerConfig.emergency_room.includes('$')
        ? `$${selectedConfig.emergency_room}`
        : `${selectedConfig.emergency_room}%`,
      urgent_care: brokerConfig.urgent_care.includes('$')
        ? `$${selectedConfig.urgent_care}`
        : `${selectedConfig.urgent_care}%`,
    };

    if (!isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        payload = {
          ...payload,
          ...selectedAllAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        payload = {
          ...payload,
          ...selectedFiveYearAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        payload = {
          ...payload,
          ...selectedTenYearAgesPayload,
        };
      }

      if (Number(payload.individual_medical_moop) < 1000) {
        return toast(`Minimum amount for Max Out-of-Pocket is: $1000`, { type: 'warning' });
      }

      delete payload.calculation;

      await dispatch(createMedicalOffer(payload));

      if (getMedicalQuotes) {
        await getMedicalQuotes();
      }
    }

    if (isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        payload = {
          ...payload,
          ...selectedAllAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        payload = {
          ...payload,
          ...selectedFiveYearAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        payload = {
          ...payload,
          ...selectedTenYearAgesPayload,
        };
      }

      delete payload.calculation;
      delete payload.author;

      if (Number(payload.individual_medical_moop) < 1000) {
        return toast(`Minimum amount for Max Out-of-Pocket is: $1000`, { type: 'warning' });
      }

      await dispatch(updateMedicalOffer({ payload, offerId: selectedConfig?.id }));

      if (getMedicalQuotes) {
        await getMedicalQuotes();
      }
    }
  }, [
    medicalOffers,
    issuerId,
    id,
    proposalId,
    accountId,
    brokerConfig.emergency_room,
    brokerConfig.urgent_care,
    selectedConfig,
    extraRows,
    dispatch,
    getMedicalQuotes,
    selectedAllAgesPayload,
    selectedFiveYearAgesPayload,
    selectedTenYearAgesPayload,
  ]);

  const onAddExtraRow = useCallback(() => {
    if (!_.isEmpty(brokerAgency) && brokerAgency.status !== 'active') {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    if (_.isEmpty(brokerAgency)) {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    const payload = {
      id: uuid(),
      value: '',
    };
    setExtraRows((state) => [...state, payload]);
  }, [extraRows.length, brokerAgency]);

  const isVisibleButtons = useCallback((status) => {
    if (status === 'approved' || status === 'selected') {
      return false;
    }
    return true;
  }, []);

  const onClickDelete = useCallback(
    (item) => async () => {
      const payload = {
        accountId,
        proposalId,
        configId: item.config_id,
        offerId: item.id,
      };

      setIsDeleteModal(true);
      setSelectedDeleteConfig(payload);
    },
    [accountId, proposalId],
  );

  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteModal(false);
    setSelectedDeleteConfig({});
  }, []);

  const onConfirmDelete = useCallback(async () => {
    await dispatch(deleteMedicalOffer(selectedDeleteConfig));
    await getMedicalOffers();
  }, [dispatch, getMedicalOffers, selectedDeleteConfig]);

  const onRejectOffer = useCallback(async () => {
    const offer = medicalOffers[0];

    const payload = {
      proposalId,
      accountId,
      ...offer,
      status: 'declined',
    };

    if (!_.isEmpty(offer)) {
      delete payload?.rep;
      delete payload.issuer_name;
      delete payload.calculation;
      const offerId = offer.id;

      await dispatch(updateMedicalOffer({ payload, offerId }));

      if (getMedicalQuotes) {
        await getMedicalQuotes();
      }
    }
  }, [dispatch, medicalOffers, proposalId, accountId, getMedicalQuotes]);

  const isFourTier = !_.isEmpty(medicalConfigs?.find((item) => item.rate_tier_type === '4tier'));
  const isDeclined = !_.isEmpty(medicalConfigs?.find((item) => item.status === 'declined'));

  const brokerTierType =
    brokerConfig?.rate_tier_type === '3tier' || brokerConfig?.rate_tier_type === '4tier';
  const offerTierType =
    selectedConfig?.rate_tier_type === '3tier' || selectedConfig?.rate_tier_type === '4tier';

  const isSelectedPlanName = medicalOffers.find((item) => item.selected_plan_name);

  return (
    <>
      {isDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseDeleteModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Are you sure you want to delete this offer?
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={onCloseDeleteModal} />
              <Button type="danger" title="Yes" onClick={onConfirmDelete} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.MedicalConfigurationContentWrapper}>
        {!isDeclined && _.isEmpty(selectedConfig) && (
          <div className={classes.MedicalConfigurationContentBodyOffer}>
            <Button title="Add Offer" type="primary" onClick={onClickAddOffer} />
          </div>
        )}
        <div className={classes.MedicalConfigurationContentBody}>
          <div className={classes.MedicalConfigurationContentTier}>
            <table className={classes.MedicalConfigurationTierTable}>
              <thead className={classes.MedicalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.MedicalConfigurationTitleMain}>Plan Configuration Form</th>
                </tr>
                <tr>
                  <th>Contribution to Employees</th>
                  <th>Contribution to Dependents</th>
                  <th>Funding Type</th>
                  <th>Plan Type</th>
                  <th>Network Size</th>
                  <th>Tier</th>
                  <th>Primary Care Physician Copay</th>
                  <th>Specialist Copay</th>
                  <th>Individual Deductible</th>
                  <th>Family Deductible</th>
                  <th>Inpatient Facility</th>
                  {brokerConfig?.inpatient_facility_is_percent ? (
                    <>
                      {/* <th>% Per day</th>
                      <th>% Per day</th> */}
                      <th></th>
                      <th></th>
                    </>
                  ) : (
                    <>
                      <th></th>
                      <th></th>
                      {/* <th>Inpatient cost per day</th>
                      <th>Inpatient number of days</th> */}
                    </>
                  )}
                  <th>Coinsurance</th>
                  <th>Emergency Room</th>
                  <th>Urgent Care</th>
                  <th>Max Out-of-Pocket</th>
                  <th>Compensation Method</th>
                  <th>Compensation Amount</th>
                  <th>Current Rate</th>
                  {isSelectedPlanName && <th>Selected Plan Name</th>}
                </tr>
              </thead>
              <tbody>
                <tr className={classes.MedicalConfigurationTierTableBroker}>
                  <th className={classes.MedicalConfigurationTitle}>Broker Request</th>
                  <td>
                    {brokerConfig?.contribution_type === 'dollar' && '$'}
                    {brokerConfig?.contribution_ee}
                    {brokerConfig?.contribution_type === 'percent' && '%'}
                  </td>
                  <td>
                    {brokerConfig?.contribution_type === 'dollar' && '$'}
                    {brokerConfig?.contribution_dep}
                    {brokerConfig?.contribution_type === 'percent' && '%'}
                  </td>
                  <td>{normalizeFundingType(brokerConfig?.funding_type)}</td>
                  <td>{brokerConfig?.plan_type}</td>
                  <td>{brokerConfig?.network_size}</td>
                  <td>{brokerConfig?.level}</td>
                  <td>${brokerConfig?.primary_care_physician}</td>
                  <td>${brokerConfig?.specialist}</td>
                  <td>${brokerConfig?.individual_medical_deductible}</td>
                  <td>${brokerConfig?.family_medical_deductible}</td>
                  <td>{brokerConfig?.inpatient_facility_is_percent ? 'Percent' : '$ Per Day'}</td>
                  {brokerConfig?.inpatient_facility_is_percent ? (
                    <>
                      <td>
                        <Tooltip title="Inpatient cost per day">
                          {brokerConfig?.inpatient_facility_percent_value}%
                        </Tooltip>
                      </td>
                      <td></td>
                    </>
                  ) : (
                    <>
                      <td>
                        <Tooltip title="Inpatient cost per day">
                          ${brokerConfig?.inpatient_facility_dollar_amount}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip title="Inpatient number of days">
                          {brokerConfig?.inpatient_facility_dollar_days} Days
                        </Tooltip>
                      </td>
                    </>
                  )}
                  <td>{brokerConfig?.plan_coinsurance}%</td>
                  <td>{brokerConfig?.emergency_room}</td>
                  <td>{brokerConfig?.urgent_care}</td>
                  <td>${brokerConfig?.individual_medical_moop}</td>
                  <td>{brokerConfig?.comp_method}</td>
                  <td>{normalizeCompensationAmount(brokerConfig)}</td>
                  <td>{brokerConfig?.is_current_rate ? 'Yes' : 'No'}</td>
                  {isSelectedPlanName && <td>{brokerConfig?.selected_plan_name}</td>}
                </tr>
                {medicalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;
                  const emergencyRoomType = brokerConfig?.emergency_room.includes('$')
                    ? 'number'
                    : 'percentage';
                  const urgentCareType = brokerConfig?.urgent_care.includes('$')
                    ? 'number'
                    : 'percentage';

                  const inpatientFacility = !isEditMode
                    ? item?.inpatient_facility_is_percent
                    : selectedConfig?.inpatient_facility_is_percent;

                  return (
                    <tr key={item?.id}>
                      <th className={classes.MedicalConfigurationTitle}>Carrier Reply</th>
                      <td>
                        {!isEditMode ? (
                          <>
                            {item?.contribution_type === 'dollar' && '$'}
                            {item?.contribution_ee}
                            {item?.contribution_type === 'percent' && '%'}
                          </>
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.contribution_ee}
                            onChange={(event) =>
                              item?.contribution_type === 'percent'
                                ? onChangePercentage(event, 'contribution_ee')
                                : onChangeInput(event, 'contribution_ee')
                            }
                            onFocus={(event) => onFocusInput(event, 'contribution_ee')}
                            onBlur={(event) => onBlurInput(event, 'contribution_ee')}
                            type={item?.contribution_type === 'percent' ? 'percentage' : 'number'}
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          <>
                            {item?.contribution_type === 'dollar' && '$'}
                            {item?.contribution_dep}
                            {item?.contribution_type === 'percent' && '%'}
                          </>
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.contribution_dep}
                            onChange={(event) =>
                              item?.contribution_type === 'percent'
                                ? onChangePercentage(event, 'contribution_dep')
                                : onChangeInput(event, 'contribution_dep')
                            }
                            onFocus={(event) => onFocusInput(event, 'contribution_dep')}
                            onBlur={(event) => onBlurInput(event, 'contribution_dep')}
                            type={item?.contribution_type === 'percent' ? 'percentage' : 'number'}
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          normalizeFundingType(item.funding_type)
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={fundingTypeData}
                            selectedOption={selectedConfig?.funding_type}
                            isVisibleSelect={isVisibleFundingType}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleFundingType)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'funding_type', setIsVisibleFundingType)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          item.plan_type
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={planTypeData}
                            selectedOption={selectedConfig?.plan_type}
                            isVisibleSelect={isVisiblePlanType}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisiblePlanType)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'plan_type', setIsVisiblePlanType)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          item.network_size
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={networkSizeData}
                            selectedOption={selectedConfig?.network_size}
                            isVisibleSelect={isVisibleNetworkSize}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleNetworkSize)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'network_size', setIsVisibleNetworkSize)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          item.level
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={tierData}
                            selectedOption={selectedConfig?.level}
                            isVisibleSelect={isVisibleTier}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleTier)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'level', setIsVisibleTier)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `$${item.primary_care_physician}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.primary_care_physician}
                            onChange={(event) =>
                              onChangeInputValue(event, 'primary_care_physician')
                            }
                            onFocus={(event) => onFocusInput(event, 'primary_care_physician')}
                            onBlur={(event) => onBlurInput(event, 'primary_care_physician')}
                            type="number"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `$${item.specialist}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.specialist}
                            onChange={(event) => onChangeInputValue(event, 'specialist')}
                            onFocus={(event) => onFocusInput(event, 'specialist')}
                            onBlur={(event) => onBlurInput(event, 'specialist')}
                            type="number"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `$${item.individual_medical_deductible}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.individual_medical_deductible}
                            onChange={(event) =>
                              onChangeInputValue(event, 'individual_medical_deductible', 10000)
                            }
                            onFocus={(event) =>
                              onFocusInput(event, 'individual_medical_deductible')
                            }
                            onBlur={(event) => onBlurInput(event, 'individual_medical_deductible')}
                            type="number"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `$${item.family_medical_deductible}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.family_medical_deductible}
                            onChange={(event) =>
                              onChangeInputValue(event, 'family_medical_deductible', 20000)
                            }
                            onFocus={(event) => onFocusInput(event, 'family_medical_deductible')}
                            onBlur={(event) => onBlurInput(event, 'family_medical_deductible')}
                            type="number"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `${item?.inpatient_facility_is_percent ? 'Percent' : '$ Per Day'}`
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={inpatientFacilityOptions}
                            selectedOption={selectedConfig?.inpatient_facility_is_percent}
                            isVisibleSelect={isVisibleInpatient}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleInpatient)}
                            onSelectOption={(event) =>
                              onSelectOption(
                                event,
                                'inpatient_facility_is_percent',
                                setIsVisibleInpatient,
                              )
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      {inpatientFacility ? (
                        <>
                          <td>
                            <Tooltip title="Inpatient cost per day">
                              {!isEditMode ? (
                                `${item.inpatient_facility_percent_value}%`
                              ) : (
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.inpatient_facility_percent_value}
                                  onChange={(event) =>
                                    onChangePercentage(event, 'inpatient_facility_percent_value')
                                  }
                                  onFocus={(event) =>
                                    onFocusInput(event, 'inpatient_facility_percent_value')
                                  }
                                  onBlur={(event) =>
                                    onBlurInput(event, 'inpatient_facility_percent_value')
                                  }
                                  type="percentage"
                                />
                              )}
                            </Tooltip>
                          </td>
                          <td></td>
                        </>
                      ) : (
                        <>
                          <td>
                            <Tooltip title="Inpatient cost per day">
                              {!isEditMode ? (
                                `$${item.inpatient_facility_dollar_amount}`
                              ) : (
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.inpatient_facility_dollar_amount}
                                  onChange={(event) =>
                                    onChangeInputValue(
                                      event,
                                      'inpatient_facility_dollar_amount',
                                      750,
                                    )
                                  }
                                  onFocus={(event) =>
                                    onFocusInput(event, 'inpatient_facility_dollar_amount')
                                  }
                                  onBlur={(event) =>
                                    onBlurInput(event, 'inpatient_facility_dollar_amount')
                                  }
                                  type="number"
                                />
                              )}
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Inpatient number of days">
                              {!isEditMode ? (
                                `${item.inpatient_facility_dollar_days} Days`
                              ) : (
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.inpatient_facility_dollar_days}
                                  onChange={(event) =>
                                    onChangeInputValue(event, 'inpatient_facility_dollar_days')
                                  }
                                  onFocus={(event) =>
                                    onFocusInput(event, 'inpatient_facility_dollar_days')
                                  }
                                  onBlur={(event) =>
                                    onBlurInput(event, 'inpatient_facility_dollar_days')
                                  }
                                  type="number"
                                  title="Days"
                                />
                              )}
                            </Tooltip>
                          </td>
                        </>
                      )}
                      <td>
                        {!isEditMode ? (
                          `${item.plan_coinsurance}%`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.plan_coinsurance}
                            onChange={(event) => onChangePercentage(event, 'plan_coinsurance')}
                            onFocus={(event) => onFocusInput(event, 'plan_coinsurance')}
                            onBlur={(event) => onBlurInput(event, 'plan_coinsurance')}
                            type="percentage"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `${item.emergency_room}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.emergency_room}
                            onChange={(event) =>
                              emergencyRoomType === 'percentage'
                                ? onChangePercentage(event, 'emergency_room')
                                : onChangeInputValue(event, 'emergency_room')
                            }
                            onFocus={(event) => onFocusInput(event, 'emergency_room')}
                            onBlur={(event) => onBlurInput(event, 'emergency_room')}
                            type={emergencyRoomType}
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `${item.urgent_care}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.urgent_care}
                            onChange={(event) =>
                              urgentCareType === 'percentage'
                                ? onChangePercentage(event, 'urgent_care')
                                : onChangeInputValue(event, 'urgent_care')
                            }
                            onFocus={(event) => onFocusInput(event, 'urgent_care')}
                            onBlur={(event) => onBlurInput(event, 'urgent_care')}
                            type={urgentCareType}
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `$${item.individual_medical_moop}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.individual_medical_moop}
                            onChange={(event) =>
                              onChangeInputValue(event, 'individual_medical_moop')
                            }
                            onFocus={(event) => onFocusInput(event, 'individual_medical_moop')}
                            onBlur={(event) => onBlurInput(event, 'individual_medical_moop')}
                            type="number"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          item.comp_method
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationMethodData}
                            selectedOption={selectedConfig?.comp_method}
                            isVisibleSelect={isVisibleCompensationMethod}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationMethod,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_method', setIsVisibleCompensationMethod)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          normalizeCompensationAmount(item)
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationAmount}
                            selectedOption={selectedConfig?.comp_amount}
                            isVisibleSelect={isVisibleCompensationAmount}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationAmount,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_amount', setIsVisibleCompensationAmount)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>{item?.is_current_rate ? 'Yes' : 'No'}</td>
                      {isSelectedPlanName && <td>{item?.selected_plan_name}</td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.MedicalConfigurationContentTier}>
            <table className={classes.MedicalConfigurationTierTable}>
              <thead className={classes.MedicalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Custom Fields</th>
                </tr>
                <tr>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.title}</span>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className={classes.MedicalConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.value}</span>
                    </td>
                  ))}
                </tr>
                {medicalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      {!isEditMode ? (
                        item.custom_fields?.map((item) => (
                          <td key={item.id}>
                            <span>{item.value}</span>
                          </td>
                        ))
                      ) : (
                        <div className={classes.ExtraRowButtonWrapper}>
                          <ExtraRows
                            extraRows={extraRows}
                            setExtraRows={setExtraRows}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            isDisabled={!isEditMode}
                          />

                          <div className={classes.ExtraRowButton}>
                            <Button
                              title="Add Extra Row"
                              type="secondary"
                              onClick={onAddExtraRow}
                              isDisabled={!isEditMode}
                            />

                            <Tooltip title={TOOLTIP_NAMES.MAX_ROWS}>
                              <i className="fas fa-info-circle" />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.MedicalConfigurationContentTier}>
            <table className={classes.MedicalConfigurationTierTable}>
              <thead className={classes.MedicalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Rates</th>
                </tr>
                <tr>
                  <th className={classes.TierTypeRow}>Rate Tier Type</th>
                </tr>
                {(brokerTierType || offerTierType) && (
                  <>
                    <tr>
                      <th>Employee</th>
                    </tr>
                    {(brokerConfig?.rate_tier_type === '3tier' ||
                      selectedConfig?.rate_tier_type === '3tier') && (
                      <>
                        <tr>
                          <th>Employee + 1</th>
                        </tr>
                      </>
                    )}
                    {(brokerConfig?.rate_tier_type === '4tier' ||
                      selectedConfig?.rate_tier_type === '4tier' ||
                      isFourTier) && (
                      <>
                        <tr>
                          <th>Employee + Spouse</th>
                        </tr>
                        <tr>
                          <th>Employee + Child(ren)</th>
                        </tr>
                      </>
                    )}
                    <tr>
                      <th>Family</th>
                    </tr>
                  </>
                )}
              </thead>
              <tbody>
                <tr className={classes.MedicalConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                  <td className={classes.TierTypeRow}>
                    {normalizeRateTierType(brokerConfig?.rate_tier_type)}
                  </td>
                  <div className={classes.AgeBandedRates}>
                    <MedicalAgeBandedRates
                      type={brokerConfig?.rate_tier_type}
                      ageBandedRates={brokerAgeBandedRates}
                      isEditMode={false}
                      isIssuer={true}
                    />
                  </div>
                  {brokerTierType && (brokerTierType || offerTierType) && (
                    <>
                      <td>{brokerConfig?.rate_ee && `$${brokerConfig?.rate_ee}`}</td>
                      {(brokerConfig?.rate_tier_type === '3tier' ||
                        selectedConfig?.rate_tier_type === '3tier') && (
                        <td>{brokerConfig?.rate_ee_1 && `$${brokerConfig?.rate_ee_1}`}</td>
                      )}
                      {(brokerConfig?.rate_tier_type === '4tier' ||
                        selectedConfig?.rate_tier_type === '4tier' ||
                        isFourTier) && (
                        <>
                          <td>{brokerConfig?.rate_ee_sp && `$${brokerConfig?.rate_ee_sp}`}</td>
                          <td>{brokerConfig?.rate_ee_ch && `$${brokerConfig?.rate_ee_ch}`}</td>
                        </>
                      )}
                      <td>{brokerConfig?.rate_fam && `$${brokerConfig?.rate_fam}`}</td>
                    </>
                  )}
                  <td className={classes.EmptyDropZoneRow}>
                    <DropZone
                      label="Current Benefits Summary"
                      maxFiles={100}
                      path={`/proposals/${proposalId}/uploads/broker/plan-configs/${id}/`}
                      isDisabled={true}
                    />
                  </td>
                </tr>
                {medicalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;
                  const isConfigTierType =
                    item?.rate_tier_type === '3tier' || item?.rate_tier_type === '4tier';
                  const isSelectedConfigTierType =
                    selectedConfig?.rate_tier_type === '3tier' ||
                    selectedConfig?.rate_tier_type === '4tier';

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      <td className={classes.TierTypeRow}>
                        {!isEditMode ? (
                          normalizeRateTierType(item.rate_tier_type)
                        ) : (
                          <SelectOption
                            label="Rate Tier Type"
                            width="140px"
                            options={tierOptions}
                            selectedOption={selectedConfig?.rate_tier_type}
                            isVisibleSelect={isVisibleSelect}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleSelect)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'rate_tier_type', setIsVisibleSelect)
                            }
                          />
                        )}
                      </td>
                      <div className={classes.AgeBandedRates}>
                        <MedicalAgeBandedRates
                          type={!isEditMode ? item?.rate_tier_type : selectedConfig?.rate_tier_type}
                          ageBandedRates={
                            !isEditMode
                              ? normalizeCoverageAgeRates(item)
                              : normalizeCoverageAgeRates(selectedConfig)
                          }
                          selectedAllAges={!isEditMode ? allAges(item) : selectedAllAges}
                          selectedFiveYearAges={
                            !isEditMode ? fiveYearAges(item) : selectedFiveYearAges
                          }
                          selectedTenYearAges={
                            !isEditMode ? tenYearAges(item) : selectedTenYearAges
                          }
                          setSelectedAllAges={setSelectedAllAges}
                          setSelectedFiveYearAges={setSelectedFiveYearAges}
                          setSelectedTenYearAges={setSelectedTenYearAges}
                          isEditMode={isEditMode}
                          isIssuer={true}
                        />
                      </div>
                      {((isConfigTierType && !isEditMode) || isSelectedConfigTierType) && (
                        <>
                          <td>
                            {!isEditMode ? (
                              (item.rate_ee || item.rate_ee === 0) && `$${item.rate_ee}`
                            ) : (
                              <Input
                                value={selectedConfig?.rate_ee}
                                onChange={(event) => onChangeRateValue(event, 'rate_ee')}
                                onFocus={(event) => onFocusInput(event, 'rate_ee')}
                                onBlur={(event) => onBlurInput(event, 'rate_ee')}
                                type="number"
                              />
                            )}
                          </td>
                          <>
                            {selectedConfig?.rate_tier_type === '3tier' ||
                            (!isEditMode && item.rate_tier_type === '3tier') ? (
                              <>
                                <td>
                                  {!isEditMode ? (
                                    (item.rate_ee_1 || item.rate_ee_1 === 0) && `$${item.rate_ee_1}`
                                  ) : (
                                    <Input
                                      value={selectedConfig?.rate_ee_1}
                                      onChange={(event) => onChangeRateValue(event, 'rate_ee_1')}
                                      onFocus={(event) => onFocusInput(event, 'rate_ee_1')}
                                      onBlur={(event) => onBlurInput(event, 'rate_ee_1')}
                                      type="number"
                                    />
                                  )}
                                </td>
                                {(brokerConfig?.rate_tier_type === '4tier' ||
                                  selectedConfig?.rate_tier_type === '4tier' ||
                                  isFourTier) && <td></td>}
                                {(brokerConfig?.rate_tier_type === '4tier' ||
                                  selectedConfig?.rate_tier_type === '4tier' ||
                                  isFourTier) && <td></td>}
                              </>
                            ) : (
                              <>
                                {brokerConfig?.rate_tier_type === '3tier' && <td></td>}
                                <td>
                                  {!isEditMode ? (
                                    (item.rate_ee_sp || item.rate_ee_sp === 0) &&
                                    `$${item.rate_ee_sp}`
                                  ) : (
                                    <Input
                                      value={selectedConfig?.rate_ee_sp}
                                      onChange={(event) => onChangeRateValue(event, 'rate_ee_sp')}
                                      onFocus={(event) => onFocusInput(event, 'rate_ee_sp')}
                                      onBlur={(event) => onBlurInput(event, 'rate_ee_sp')}
                                      type="number"
                                    />
                                  )}
                                </td>
                                <td>
                                  {!isEditMode ? (
                                    (item.rate_ee_ch || item.rate_ee_ch === 0) &&
                                    `$${item.rate_ee_ch}`
                                  ) : (
                                    <Input
                                      value={selectedConfig?.rate_ee_ch}
                                      onChange={(event) => onChangeRateValue(event, 'rate_ee_ch')}
                                      onFocus={(event) => onFocusInput(event, 'rate_ee_ch')}
                                      onBlur={(event) => onBlurInput(event, 'rate_ee_ch')}
                                      type="number"
                                    />
                                  )}
                                </td>
                              </>
                            )}
                          </>
                          <td>
                            {!isEditMode ? (
                              (item.rate_fam || item.rate_fam === 0) && `$${item.rate_fam}`
                            ) : (
                              <Input
                                value={selectedConfig?.rate_fam}
                                onChange={(event) => onChangeRateValue(event, 'rate_fam')}
                                onFocus={(event) => onFocusInput(event, 'rate_fam')}
                                onBlur={(event) => onBlurInput(event, 'rate_fam')}
                                type="number"
                              />
                            )}
                          </td>
                        </>
                      )}
                      <td className={classes.EmptyDropZoneRow}>
                        <DropZone
                          label="Proposed Benefits Summary"
                          maxFiles={100}
                          path={`/proposals/${proposalId}/uploads/carrier/plan-configs/${id}/offers/${item?.id}/`}
                          isDisabled={!isEditMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.MedicalConfigurationContentTier}>
            <table className={classes.MedicalConfigurationTierTable}>
              <thead className={classes.MedicalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}></th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.MedicalConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                </tr>
                {medicalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyButtonRow}>
                        {!isEditMode && (
                          <div className={classes.MedicalConfigurationEditTableItem}>
                            {isVisibleButtons(item?.status) && (
                              <div className={classes.MedicalConfigurationEditTableItemWrapper}>
                                <Button
                                  title="Edit"
                                  type="primary"
                                  onClick={onClickEditConfig(item)}
                                />
                                {item.status !== 'declined' && medicalOffers.length > 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Delete" onClick={onClickDelete(item)} />
                                  </div>
                                )}
                                {item.status !== 'declined' && medicalOffers.length === 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Decline" onClick={onRejectOffer} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {isEditMode && (
                          <div className={classes.MedicalConfigurationContentFooter}>
                            {isEdited && (
                              <Button title="Save" type="primary" onClick={onSaveSelectedConfig} />
                            )}
                            <Button title="Cancel" type="secondary" onClick={onClickCancel} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
