import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import PropTypes from 'prop-types';

import {
  deleteQuote,
  getAccountProposalSelector,
  isExpandedQuotesSelector,
  isProposalFinalizeSelector,
  proposalManagement,
  setIsExpandedQuotes,
  updateQuote,
} from 'src/store/proposals';
import {
  coverageSelector,
  getCoveragePlans,
  getCoverageQuotePlans,
  isLoadingDeletePlanSelector,
} from 'src/store/coverageBuilder';
import { setIsShareModal } from 'src/store/planConfiguration';

import { COVERAGE_NAMES } from './cb_constants';
import { PlanConfig } from './PlanConfig/PlanConfig';
import { ProposalCard } from './ProposalCard/ProposalCard';

import { Button, EmptyField, Loader, LoaderWrapper, QuoteCard } from 'src/components';

import { ExpandMoreOutlined } from '@mui/icons-material';
import classes from './coverageBuilder.module.scss';
import { CreateMedcialQuote } from '../medical/medicalQuotes/createMedicalQuote/CreateMedicalQuote';
import { getMedicalQuotePlansSelector } from 'src/store/medicalConfiguration';
import { getAccountData } from 'src/store/broker';

export const CoverageBuilder = (props) => {
  const { build } = props;

  const dispatch = useDispatch();
  const params = useParams();
  const { pathname, search } = useLocation();

  const accountData = useSelector(getAccountData);
  const coverageData = useSelector(coverageSelector);
  const accountProposalData = useSelector(getAccountProposalSelector);
  const isProposalFinalize = useSelector(isProposalFinalizeSelector);
  const isExpandedQuotes = useSelector(isExpandedQuotesSelector);
  const isLoadingDeletePlan = useSelector(isLoadingDeletePlanSelector);
  const medicalQuotePlans = useSelector(getMedicalQuotePlansSelector);

  const { coverage, title, form_options } = build;

  // const { proposal } = card_options;

  const proposalsList = coverageData[`${coverage}_proposals`];
  const quotesList = coverageData[`${coverage}_quotes`];
  const isMedicalQuoting =
    accountData?.address_state === 'CA' || accountData?.address_state === 'IL';

  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;
  const read_token = params?.token;
  const validToken = `${read_token}${search}`;

  const isReadOnlyMode = pathname.includes('/read-only');

  const [isExpandedQuote, setIsExpandedQuote] = useState(true);
  const [isLoadingProposals, setIsLoadingProposals] = useState(false);
  const [isLoadingQuotes, setIsLoadingQuotes] = useState(false);
  const [isPlanConfig, setIsPlanConfig] = useState(false);

  const [planConfigMode, setPlanConfigMode] = useState('');

  useEffect(() => {
    dispatch(setIsExpandedQuotes(true));
    // return () => {
    //   dispatch(setVisionPlans([]));
    //   dispatch(setVisionQuotePlans([]));
    // };
  }, [dispatch]);

  useEffect(() => {
    setIsExpandedQuote(isExpandedQuotes);
  }, [isExpandedQuotes]);

  useEffect(() => {
    let isMounted = true;

    if (accountId && proposalId) {
      const fetchProposalsList = async () => {
        if (!isMounted) return;

        setIsLoadingProposals(true);
        try {
          await dispatch(getCoveragePlans({ accountId, proposalId, coverage, read_token }));
        } catch (error) {
          if (isMounted) setIsLoadingProposals(false);
        } finally {
          if (isMounted) setIsLoadingProposals(false);
        }
      };

      if (!isProposalFinalize) {
        fetchProposalsList();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, accountId, proposalId, isProposalFinalize, coverage, read_token]);

  useEffect(() => {
    let isMounted = true;

    if (accountId && proposalId) {
      const fetchQuotesList = async () => {
        if (!isMounted) return;

        setIsLoadingQuotes(true);
        try {
          await dispatch(getCoverageQuotePlans({ accountId, proposalId, coverage, read_token }));
        } catch (error) {
          if (isMounted) setIsLoadingQuotes(false);
        } finally {
          if (isMounted) setIsLoadingQuotes(false);
        }
      };

      fetchQuotesList();
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, accountId, proposalId, isProposalFinalize, coverage, read_token]);

  const onExapandQuotes = useCallback(() => {
    setIsExpandedQuote((prev) => !prev);
  }, []);

  const onClickShare = useCallback(() => {
    dispatch(setIsShareModal(true));
  }, [dispatch]);

  const onOpenAddPlanConfig = useCallback(
    (mode) => () => {
      setIsPlanConfig(true);
      setPlanConfigMode(mode);
    },
    [],
  );

  const onClickConfigDetails = useCallback(
    (value) => () => {
      setPlanConfigMode('EDIT');
      setIsPlanConfig(true);
      form_options.setFormData(value);
    },
    [form_options],
  );

  const onClosePlanConfig = useCallback(() => {
    setPlanConfigMode('');
    setIsPlanConfig(false);
    form_options.setFormData(form_options.initial_state);
  }, [form_options]);

  const onSubmitQuote = useCallback(
    (coverageType, proposalId, quoteId) => async () => {
      if (isReadOnlyMode) {
        const payload = {
          coverage_type: coverageType,
          proposal_id: proposalId,
          quote_id: quoteId,
          status: 'approved',
        };

        dispatch(
          proposalManagement({ accountId, proposalId, validToken, token: read_token, payload }),
        );
      }

      if (!isReadOnlyMode) {
        const payload = {
          // client_picked: true,
          status: 'approved',
        };

        const proposalData = {
          ...accountProposalData,
          status: 'approved',
        };

        delete proposalData.groups;
        delete proposalData.quotes;

        await dispatch(
          updateQuote({
            accountId,
            proposalId,
            quoteId: quoteId,
            payload,
            proposalData,
            read_token: read_token,
          }),
        );
      }
    },
    [dispatch, isReadOnlyMode, accountId, validToken, read_token, accountProposalData],
  );

  const onDeselectQuote = useCallback(
    (quoteId) => () => {
      const payload = {
        // client_picked: true,
        status: 'active',
      };

      const proposalData = {
        ...accountProposalData,
        status: 'submitted',
      };

      delete proposalData.groups;
      delete proposalData.quotes;

      dispatch(
        updateQuote({
          accountId,
          proposalId,
          quoteId: quoteId,
          payload: payload,
          proposalData,
          read_token: read_token,
          valid_token: validToken,
        }),
      );
    },
    [dispatch, accountId, proposalId, accountProposalData, read_token, validToken],
  );

  const onDeleteQuote = useCallback(
    (groupId, quoteId) => () => {
      dispatch(deleteQuote({ accountId, groupId, quoteId, proposalId }));
    },
    [dispatch, accountId, proposalId],
  );

  return (
    <>
      {isPlanConfig && (
        <PlanConfig build={build} mode={planConfigMode} onClose={onClosePlanConfig} />
      )}

      {/* <VisionForm mode={'CREATE'} /> */}

      {!isPlanConfig && (
        <div className={classes.CoverageBuilder}>
          {!isProposalFinalize && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls="panel1-content"
                id="panel1-header"
                className={classes.CoverageBuilderTitle}
              >
                {title} RFPs
              </AccordionSummary>
              <AccordionDetails>
                {isLoadingProposals || isLoadingDeletePlan ? (
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                ) : proposalsList?.length ? (
                  proposalsList?.map((proposal) => (
                    <ProposalCard
                      key={proposal.id}
                      build={build}
                      config={proposal}
                      onClickConfigDetails={onClickConfigDetails}
                    />
                  ))
                ) : (
                  <EmptyField title={`No ${COVERAGE_NAMES[coverage]} RFP created yet.`} />
                )}
                {!isReadOnlyMode && (
                  <div style={{ display: 'flex' }}>
                    <div className={classes.CoverageBuilderHeaderButtons}>
                      {proposalsList?.length > 0 && (
                        <Button title="Send RFP" type="primary" onClick={onClickShare} />
                      )}
                      <Button
                        title="Add Plan Config"
                        type="secondary"
                        onClick={onOpenAddPlanConfig('CREATE')}
                      />
                    </div>
                    {coverage === 'medical' && <CreateMedcialQuote />}
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion expanded={isExpandedQuote} onChange={onExapandQuotes}>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel2-content"
              id="panel2-header"
              className={classes.CoverageBuilderTitle}
            >
              {title} Quotes
            </AccordionSummary>
            <AccordionDetails>
              {isLoadingQuotes || isLoadingDeletePlan ? (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              ) : quotesList?.length ? (
                quotesList?.map((quote) => (
                  <ProposalCard
                    key={quote.id}
                    build={build}
                    config={quote}
                    type="quote"
                    onClickConfigDetails={onClickConfigDetails}
                  />
                ))
              ) : (
                <EmptyField title={`No ${COVERAGE_NAMES[coverage]} quotes selected yet.`} />
              )}

              {coverage === 'medical' && (
                <div className={classes.MedicalQuotes}>
                  {medicalQuotePlans?.length > 0 && (
                    <div className={classes.MedicalQuotesDivider} />
                  )}

                  <div id="QuoteCards" className={classes.MedicalQuotesCard}>
                    {!isMedicalQuoting && !isReadOnlyMode ? (
                      <EmptyField title="Medical quoting is not currently available in this state, we are working on expanding this functionality in this region" />
                    ) : (
                      <QuoteCard
                        accountProposalData={accountProposalData}
                        onSubmitQuote={onSubmitQuote}
                        onDeselectQuote={onDeselectQuote}
                        onDeleteQuote={onDeleteQuote}
                      />
                    )}
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
};

CoverageBuilder.propTypes = {
  build: PropTypes.object,
};
