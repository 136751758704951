import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIssuerDataSelector } from 'src/store/issuer';
import {
  getIssuerDisabilityPlans,
  getIssuerDisabilityPlansSelecetor,
  isLoadingIssuerDisabilityPlansSelector,
} from 'src/store/disabilityConfiguration';

import { Loader, LoaderWrapper, EmptyField } from 'src/components';
import { DisabilityConfiguration } from '../../components';

import classes from './disabilityPlans.module.scss';

export const DisabilityPlans = (props) => {
  const { activeTab } = props;

  const dispatch = useDispatch();

  const issuerData = useSelector(getIssuerDataSelector);
  const disabilityPlans = useSelector(getIssuerDisabilityPlansSelecetor);
  const isLoading = useSelector(isLoadingIssuerDisabilityPlansSelector);

  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const issuerId = issuerData?.issuer_id;

  useEffect(() => {
    dispatch(getIssuerDisabilityPlans({ accountId, proposalId }));
  }, [dispatch, accountId, proposalId]);

  return isLoading ? (
    <div className={classes.Loader}>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    </div>
  ) : disabilityPlans?.length > 0 ? (
    disabilityPlans?.map((item) => (
      <div key={item.id}>
        <DisabilityConfiguration
          id={item.id}
          activeTab={activeTab}
          accountId={accountId}
          issuerId={issuerId}
          proposalId={item.proposal_id}
          userId={issuerData?.id}
          brokerRequest={item}
        />
      </div>
    ))
  ) : (
    <EmptyField title="No disability RFP created yet." />
  );
};
