import { createSlice } from '@reduxjs/toolkit';

import {
  getAccidentQuotePlans,
  getAccidentPlans,
  updateAccidentOffer,
  getIssuerAccidentPlans,
} from './accidentConfiguration.actions';

const initialState = {
  accidentPlans: [],
  accidentQuotePlans: [],

  isLoadingAccidentQuotePlans: false,
  isLoadingAccidentPlans: false,
  isLoadingUpdateAccidentOffer: false,

  issuerAccidentPlans: [],
  isLoadingIssuerAccidentPlans: false,
};

const accidentConfigurationSlice = createSlice({
  name: 'accidentConfiguration',
  initialState,
  reducers: {
    setAccidentPlan: (state, action) => {
      state.accidentPlans = action.payload;
    },
    setAccidentQuotePlans: (state, action) => {
      state.accidentQuotePlans = action.payload;
    },

    clearAccidentConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAccidentQuotePlans.pending, (state) => {
      state.isLoadingAccidentQuotePlans = true;
    });
    builder.addCase(getAccidentQuotePlans.fulfilled, (state, action) => {
      state.accidentQuotePlans = action.payload;
      state.isLoadingAccidentQuotePlans = false;
    });
    builder.addCase(getAccidentQuotePlans.rejected, (state) => {
      state.isLoadingAccidentQuotePlans = false;
    });

    builder.addCase(getAccidentPlans.pending, (state) => {
      state.isLoadingAccidentPlans = true;
    });
    builder.addCase(getAccidentPlans.fulfilled, (state, action) => {
      state.accidentPlans = action.payload;
      state.isLoadingAccidentPlans = false;
    });
    builder.addCase(getAccidentPlans.rejected, (state) => {
      state.isLoadingAccidentPlans = false;
    });

    builder.addCase(updateAccidentOffer.pending, (state) => {
      state.isLoadingUpdateAccidentOffer = true;
    });
    builder.addCase(updateAccidentOffer.fulfilled, (state) => {
      state.isLoadingUpdateAccidentOffer = false;
    });
    builder.addCase(updateAccidentOffer.rejected, (state) => {
      state.isLoadingUpdateAccidentOffer = false;
    });

    // ISSUER
    builder.addCase(getIssuerAccidentPlans.pending, (state) => {
      state.isLoadingIssuerAccidentPlans = true;
    });
    builder.addCase(getIssuerAccidentPlans.fulfilled, (state, action) => {
      state.issuerAccidentPlans = action.payload;
      state.isLoadingIssuerAccidentPlans = false;
    });
    builder.addCase(getIssuerAccidentPlans.rejected, (state) => {
      state.isLoadingIssuerAccidentPlans = false;
    });
  },
});

export const { setAccidentPlan, setAccidentQuotePlans, clearAccidentConfigurationState } =
  accidentConfigurationSlice.actions;

export default accidentConfigurationSlice.reducer;
