import { ALL_AGES_NAMES } from 'src/features/medical/medicalAgeBandedRates/medicalAllAges';
import { FIVE_YEAR_NAMES } from 'src/features/medical/medicalAgeBandedRates/medicalFiveYearAges';
import { TEN_YEAR_NAMES } from 'src/features/medical/medicalAgeBandedRates/medicalTenYearAges';

const spanStyles = {
  height: '38px',
  width: '100%',
  display: 'flex',
  borderBottom: '1px solid #dbdbdb',
};

const divStyles = {
  width: '100%',
  padding: '7px 10px',
};

export const renderTierRates = (selectedPlan) => {
  const { rate_ee, rate_ee_1, rate_ee_ch, rate_ee_sp, rate_fam, rate_tier_type } = selectedPlan;

  return (
    <>
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_ee || 0} (EE)`}</div>
      </span>
      {rate_tier_type === '3tier' && (
        <span style={spanStyles}>
          <div style={divStyles}>{`$${rate_ee_1 || 0} (EE + 1)`}</div>
        </span>
      )}
      {rate_tier_type === '4tier' && (
        <>
          <span style={spanStyles}>
            <div style={divStyles}>{`$${rate_ee_ch || 0} (EE + CH)`}</div>
          </span>
          <span style={spanStyles}>
            <div style={divStyles}>{`$${rate_ee_sp || 0} (EE + SP)`}</div>
          </span>
        </>
      )}
      <span style={spanStyles}>
        <div style={divStyles}>{`$${rate_fam || 0} (FAM)`}</div>
      </span>
    </>
  );
};

export const renderCustomField = (value, key) => {
  return (
    <span style={spanStyles} key={key}>
      <div style={divStyles}>{value}</div>
    </span>
  );
};

export const renderAllAges = (allAgesRates) => {
  return Object.keys(allAgesRates).map((item, index) => (
    <span style={spanStyles} key={index}>
      <div style={divStyles}>{`$${allAgesRates[item] || '0'} (${ALL_AGES_NAMES[item]})`}</div>
    </span>
  ));
};

export const renderFiveYearAges = (fiveYearAgesRates) => {
  return Object.keys(fiveYearAgesRates).map((item, index) => (
    <span style={spanStyles} key={index}>
      <div style={divStyles}>{`$${fiveYearAgesRates[item] || '0'} (${FIVE_YEAR_NAMES[item]})`}</div>
    </span>
  ));
};

export const renderTenYearAges = (tenYearAgesRates) => {
  return Object.keys(tenYearAgesRates).map((item, index) => (
    <span style={spanStyles} key={index}>
      <div style={divStyles}>{`$${tenYearAgesRates[item] || '0'} (${TEN_YEAR_NAMES[item]})`}</div>
    </span>
  ));
};
