import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.life;

export const getLifePlansSelector = createSelector(getState, (state) => state.lifePlans);

// ISSUER
export const getIssuerLifePlansSelecetor = createSelector(
  getState,
  (state) => state.issuerLifePlans,
);
export const isLoadingIssuerLifePlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerLifePlans,
);
