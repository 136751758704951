import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  createDisabilityOffer,
  deleteDisabilitOffer,
  updateDisabilityOffer,
} from 'src/store/disabilityConfiguration';
import { brokerAgencySelector } from 'src/store/agency';

import { companyContributionData } from 'src/features/disability/termDisabilityForm/termDisabilityFormConstants';
import { compensationAmountData } from 'src/features/dental/dentalForm/dentalFormConstants';
import { compensationMethodData } from 'src/features/medical/medicalForm/medicalFormConstants';
import { CoverageAgeBandedRates } from 'src/features/proposal/coverageAgeBandedRates/CoverageAgeBandedRates';
import {
  coverageAllAges,
  normalizeAllAgeRates,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  coverageFiveYearAges,
  normalizeCoverageFiveYearAgeRates,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  coverageTenYearAges,
  normalizeCoverageTenYearAgeRates,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';

import { Button, DropZone, Input, Modal, SelectOption, ExtraRows } from 'src/components';

import {
  normalizeCompensationAmount,
  normalizeCompensationAmountEdit,
  normalizeCoverageAgeRates,
  normalizeDisabilityRateBasis,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import { threeDigitsRegex } from 'src/constants/regularExpression';
import { TOOLTIP_NAMES } from 'src/constants/tooltipNames';

import classes from './disabilityConfigurationForm.module.scss';

export const DisabilityConfigurationForm = (props) => {
  const {
    brokerConfig,
    disabilityOffers,
    userId,
    issuerId,
    id,
    proposalId,
    accountId,
    getDisabilityOffers,
    getDisabilityQuotes,
  } = props;
  const dispatch = useDispatch();

  const brokerAgency = useSelector(brokerAgencySelector);

  const [disabilityConfigs, setDisabilityConfigs] = useState([]);
  const [originalConfig, setOriginalConfig] = useState({});
  const [selectedConfig, setSelectedConfig] = useState({});
  const [extraRows, setExtraRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedDeleteConfig, setSelectedDeleteConfig] = useState({});

  const [isVisibleContribution, setIsVisibleContribution] = useState(false);
  const [isVisibleSelect, setIsVisibleSelect] = useState(false);
  const [isVisibleRateSelect, setIsVisibleRateSelect] = useState(false);

  const [isVisibleCompensationMethod, setIsVisibleCompensationMethod] = useState(false);
  const [isVisibleCompensationAmount, setIsVisibleCompensationAmount] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [selectedAllAges, setSelectedAllAges] = useState();
  const [selectedAllAgesPayload, setSelectedAllAgesPayload] = useState();
  const [selectedFiveYearAges, setSelectedFiveYearAges] = useState();
  const [selectedFiveYearAgesPayload, setSelectedFiveYearAgesPayload] = useState();
  const [selectedTenYearAges, setSelectedTenYearAges] = useState();
  const [selectedTenYearAgesPayload, setSelectedTenYearAgesPayload] = useState();
  const [brokerAgeBandedRates, setBrokerAgebandedRates] = useState();
  const [isEditedRates, setIsEditedRates] = useState(false);

  const originalId = disabilityOffers?.find((item) => item.issuer_name)?.user_id;

  const rateBasisOptions = [
    { key: 'earnings', name: 'Earnings Amount' },
    { key: 'benefit', name: 'Benefit Amount' },
  ];

  const tierOptions = [
    { key: 'employee', name: 'Employee' },
    { key: 'all_ages', name: 'All Ages' },
    { key: 'banded_5', name: '5 Year Banded' },
    { key: 'banded_10', name: '10 Year Banded' },
  ];

  useEffect(() => {
    setDisabilityConfigs([...disabilityOffers]);
  }, [disabilityOffers]);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig, selectedConfig));
  }, [originalConfig, selectedConfig]);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig?.custom_fields, extraRows));
  }, [extraRows, originalConfig?.custom_fields]);

  useEffect(() => {
    let payload = {};

    for (const item of Object.keys(brokerConfig)) {
      if (item.includes('age')) {
        payload = {
          ...payload,
          [item]: brokerConfig[item],
        };
      }

      setBrokerAgebandedRates(payload);
    }
  }, [brokerConfig]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      let ageRatesPayload = {};

      for (const item of Object.keys(brokerConfig)) {
        if (item.includes('age')) {
          ageRatesPayload = {
            ...ageRatesPayload,
            [item]: brokerConfig[item],
          };
        }
      }

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        setIsEditedRates(!_.isEqual(ageRatesPayload, selectedAllAgesPayload));
      }

      if (selectedConfig?.rate_tier_type === 'banded_5') {
        setIsEditedRates(!_.isEqual(ageRatesPayload, selectedFiveYearAgesPayload));
      }

      if (selectedConfig?.rate_tier_type === 'banded_10') {
        setIsEditedRates(!_.isEqual(ageRatesPayload, selectedTenYearAgesPayload));
      }
    }
  }, [
    brokerConfig,
    selectedAllAges,
    selectedAllAgesPayload,
    selectedConfig,
    selectedFiveYearAges,
    selectedFiveYearAgesPayload,
    selectedTenYearAges,
    selectedTenYearAgesPayload,
  ]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      if (selectedConfig?.rate_tier_type === 'all_ages') {
        const payload = coverageAllAges(selectedConfig);
        setSelectedAllAges(payload);
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        const payload = coverageFiveYearAges(selectedConfig);
        setSelectedFiveYearAges(payload);
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        const payload = coverageTenYearAges(selectedConfig);
        setSelectedTenYearAges(payload);
      }
    }
  }, [selectedConfig]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      if (!_.isEmpty(selectedAllAges)) {
        const payload = normalizeAllAgeRates(selectedAllAges);

        setSelectedAllAgesPayload(payload);
      }
      if (!_.isEmpty(selectedFiveYearAges)) {
        const payload = normalizeCoverageFiveYearAgeRates(selectedFiveYearAges);

        setSelectedFiveYearAgesPayload(payload);
      }
      if (!_.isEmpty(selectedTenYearAges)) {
        const payload = normalizeCoverageTenYearAgeRates(selectedTenYearAges);

        setSelectedTenYearAgesPayload(payload);
      }
    }
  }, [selectedAllAges, selectedConfig, selectedFiveYearAges, selectedTenYearAges]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      const selectedDisabilityConfig = disabilityConfigs?.find(
        (item) => item.id === selectedConfig?.id,
      );
      if (selectedDisabilityConfig?.custom_fields?.length) {
        for (const originalField of selectedDisabilityConfig?.custom_fields) {
          for (const carrierField of extraRows) {
            if (originalField.id === carrierField.id) {
              if (originalField.name !== carrierField.name) {
                setIsEdited(true);
              }

              if (originalField.value !== carrierField.value) {
                setIsEdited(true);
              }
            }
          }
        }
      }
    }
  }, [disabilityConfigs, selectedConfig, extraRows]);

  const onClickAddOffer = useCallback(() => {
    const payload = {
      ...brokerConfig,
      config_id: brokerConfig?.id,
      id: uuid(),
      issuer_id: issuerId,
      rate_ee: null,
      // 2 cases - 1 for broker side, second one for carrier side
      user_id: userId !== originalId ? originalId : userId,
      comp_amount: normalizeCompensationAmountEdit(brokerConfig),
    };

    setSelectedConfig(payload);
    setDisabilityConfigs((state) => [...state, payload]);
  }, [brokerConfig, issuerId, originalId, userId]);

  const onClickEditConfig = useCallback(
    (item) => () => {
      const originalConfig = disabilityConfigs?.find((offer) => offer.id === item.id);

      const clonedCustomFields = _.cloneDeep(item?.custom_fields);
      const selectedPayload = {
        ...item,
        rate_ee: item.rate_ee ? item.rate_ee : 0,
        custom_fields: clonedCustomFields,
        comp_amount: normalizeCompensationAmountEdit(item),
      };

      setOriginalConfig({
        ...originalConfig,
        comp_amount: normalizeCompensationAmountEdit(originalConfig),
      });
      setSelectedConfig(selectedPayload);
      setExtraRows(clonedCustomFields ?? []);
    },
    [disabilityConfigs],
  );

  const onChangePercentage = useCallback((event, target) => {
    if (target === 'comp_amount_pct') {
      setSelectedConfig((prev) => ({
        ...prev,
        comp_pppm_dollar: null,
        comp_pepm_dollar: null,
      }));
    }

    if (event.target.value.length > 3) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: Number(event.target.value.slice(0, 3)),
      }));
    }
    if (event.target.value < 0) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 0,
      }));
    }
    if (event.target.value > 100) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 100,
      }));
    }

    setSelectedConfig((prev) => ({
      ...prev,
      [target]: event.target.value,
    }));
  }, []);

  const onChangePepmPppmValue = useCallback((event, target) => {
    if (target === 'comp_pepm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pppm_dollar: null }));
    }

    if (target === 'comp_pppm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pepm_dollar: null }));
    }

    if (event.target.value.includes('.')) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 4) }));
    }

    if (event.target.value > 3) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 3) }));
    }

    setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value }));
  }, []);

  const handleOpenCarrierSelect = useCallback(
    (setCarrierSelectValue) => () => {
      setCarrierSelectValue((prev) => !prev);
    },
    [],
  );

  const onSelectOption = useCallback(
    (value, target, setIsVisible) => () => {
      let payload = {};
      setIsVisible(false);

      payload = {
        ...selectedConfig,
        [target]: value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onChangeInputValue = useCallback(
    (event, target, maxRange) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };

        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value > maxRange ? maxRange : event.target.value,
      };

      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onFocusInput = (event, target) => {
    if (Number(event.target.value) === 0) {
      const payload = {
        ...selectedConfig,
        [target]: '',
      };
      return setSelectedConfig(payload);
    }
  };

  const onBlurInput = (event, target) => {
    if (!event.target.value) {
      const payload = {
        ...selectedConfig,
        [target]: 0,
      };
      return setSelectedConfig(payload);
    }

    if (
      !event.target.value.includes('.') &&
      (event.target.value[0] === '0' || event.target.value[0] === 0)
    ) {
      const payload = {
        ...selectedConfig,
        [target]: event.target.value.slice(1) ? event.target.value.slice(1) : 0,
      };
      return setSelectedConfig(payload);
    }
  };

  const onClickCancel = useCallback(() => {
    setOriginalConfig({});
    setSelectedConfig({});
    setDisabilityConfigs(disabilityOffers);
    setIsEdited(false);
    setIsEditedRates(false);
  }, [disabilityOffers]);

  const onChangeRateValue = useCallback(
    (event, target) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };
        return setSelectedConfig(payload);
      }

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...selectedConfig,
          [target]: event.target.value.slice(0, event.target.value.length - 1),
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onSaveSelectedConfig = useCallback(async () => {
    const isOfferExist = !_.isEmpty(
      disabilityOffers?.find((item) => item?.id === selectedConfig?.id),
    );

    const data = {
      issuer_id: issuerId,
      config_id: id,
      proposalId: proposalId,
      accountId: accountId,
    };

    if (!isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        payload = {
          ...payload,
          ...selectedAllAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        payload = {
          ...payload,
          ...selectedFiveYearAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        payload = {
          ...payload,
          ...selectedTenYearAgesPayload,
        };
      }

      delete payload.created_timestamp;
      delete payload.comp_amount;
      delete payload.calculation;

      await dispatch(createDisabilityOffer(payload));

      if (getDisabilityQuotes) {
        await getDisabilityQuotes();
      }
    }

    if (isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        payload = {
          ...payload,
          ...selectedAllAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        payload = {
          ...payload,
          ...selectedFiveYearAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        payload = {
          ...payload,
          ...selectedTenYearAgesPayload,
        };
      }

      delete payload.comp_amount;
      delete payload.id;
      delete payload.created_timestamp;
      delete payload.issuer_name;
      delete payload.author;
      delete payload.calculation;

      await dispatch(updateDisabilityOffer({ payload, offerId: selectedConfig?.id }));

      if (getDisabilityQuotes) {
        await getDisabilityQuotes();
      }
    }
  }, [
    dispatch,
    disabilityOffers,
    issuerId,
    id,
    proposalId,
    accountId,
    selectedConfig,
    extraRows,
    getDisabilityQuotes,
    selectedAllAgesPayload,
    selectedFiveYearAgesPayload,
    selectedTenYearAgesPayload,
  ]);

  const onAddExtraRow = useCallback(() => {
    if (!_.isEmpty(brokerAgency) && brokerAgency.status !== 'active') {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    if (_.isEmpty(brokerAgency)) {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    const payload = {
      id: uuid(),
      title: '',
      value: '',
    };
    setExtraRows((state) => [...state, payload]);
  }, [extraRows.length, brokerAgency]);

  const isVisibleButtons = useCallback((status) => {
    if (status === 'approved' || status === 'selected') {
      return false;
    }
    return true;
  }, []);

  const onClickDelete = useCallback(
    (item) => async () => {
      const payload = {
        accountId,
        proposalId,
        configId: item.config_id,
        offerId: item.id,
      };

      setIsDeleteModal(true);
      setSelectedDeleteConfig(payload);
    },
    [accountId, proposalId],
  );

  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteModal(false);
    setSelectedDeleteConfig({});
  }, []);

  const onConfirmDelete = useCallback(async () => {
    await dispatch(deleteDisabilitOffer(selectedDeleteConfig));
    await getDisabilityOffers();
  }, [dispatch, getDisabilityOffers, selectedDeleteConfig]);

  const onRejectOffer = useCallback(async () => {
    const offer = disabilityOffers[0];

    const payload = {
      proposalId,
      accountId,
      ...offer,
      status: 'declined',
    };

    if (!_.isEmpty(offer)) {
      delete payload?.rep;
      delete payload.issuer_name;
      delete payload.calculation;
      const offerId = offer.id;

      await dispatch(updateDisabilityOffer({ payload, offerId }));

      if (getDisabilityQuotes) {
        await getDisabilityQuotes();
      }
    }
  }, [dispatch, accountId, proposalId, disabilityOffers, getDisabilityQuotes]);

  const isDeclined = !_.isEmpty(disabilityConfigs?.find((item) => item.status === 'declined'));
  const compensationAmount = compensationAmountData(
    selectedConfig,
    onChangePercentage,
    onChangePepmPppmValue,
  );

  return (
    <>
      {isDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseDeleteModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Are you sure you want to delete this offer?
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={onCloseDeleteModal} />
              <Button type="danger" title="Yes" onClick={onConfirmDelete} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.DisabilityConfigurationContentWrapper}>
        {!isDeclined && _.isEmpty(selectedConfig) && (
          <div className={classes.DisabilityConfigurationContentBodyOffer}>
            <Button
              title="Add Offer"
              type="primary"
              onClick={onClickAddOffer}
              isDisabled={!_.isEmpty(selectedConfig)}
            />
          </div>
        )}
        <div className={classes.DisabilityConfigurationContentBody}>
          <div className={classes.DisabilityConfigurationContentTier}>
            <table className={classes.DisabilityConfigurationTierTable}>
              <thead className={classes.DisabilityConfigurationTierTableHead}>
                <tr>
                  <th className={classes.DisabilityConfigurationTitleMain}>
                    Plan Configuration Form
                  </th>
                </tr>
                <tr>
                  <th>Contribution</th>
                  <th>% of Income Requested</th>
                  {brokerConfig?.plan_type === 'ltd' ? (
                    <>
                      <th>LTD Monthly Max</th>
                      <th>LTD Elimination Period</th>
                      <th>LTD Benefit Period</th>
                    </>
                  ) : (
                    <>
                      <th>STD Weekly Max</th>
                      <th>STD Elimination Period</th>
                      <th>STD Benefit Period</th>
                    </>
                  )}
                  <th>Compensation Method</th>
                  <th>Compensation Amount</th>
                  <th>Current Rate</th>
                  <th>Per unit</th>
                  <th>Rate Basis</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.DisabilityConfigurationTierTableBroker}>
                  <th className={classes.DisabilityConfigurationTitle}>Broker Request</th>
                  <td>{brokerConfig?.contribution_type}</td>
                  <td>{brokerConfig?.income_pct}%</td>
                  {brokerConfig?.plan_type === 'ltd' ? (
                    <>
                      <td>${brokerConfig?.ltd_monthly_max}</td>
                      <td>{brokerConfig?.ltd_elimination_period} Days</td>
                      <td>{brokerConfig?.ltd_benefit_period} Days</td>
                    </>
                  ) : (
                    <>
                      <td>${brokerConfig?.std_weekly_max}</td>
                      <td>{brokerConfig?.std_elimination_period}</td>
                      <td>{brokerConfig?.std_benefit_period} Days</td>
                    </>
                  )}
                  <td>{brokerConfig?.comp_method}</td>
                  <td>{normalizeCompensationAmount(brokerConfig)}</td>
                  <td>{brokerConfig?.is_current_rate ? 'Yes' : 'No'}</td>
                  <td>${brokerConfig?.rate_per_unit}</td>
                  <td>{normalizeDisabilityRateBasis(brokerConfig?.rate_basis)}</td>
                </tr>
                {disabilityConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <th className={classes.DisabilityConfigurationTitle}>Carrier Reply</th>
                      <td>
                        {!isEditMode ? (
                          item.contribution_type
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={companyContributionData}
                            selectedOption={selectedConfig?.contribution_type}
                            isVisibleSelect={isVisibleContribution}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleContribution)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'contribution_type', setIsVisibleContribution)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `${item.income_pct}%`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.income_pct}
                            onChange={(event) => onChangePercentage(event, 'income_pct')}
                            onFocus={(event) => onFocusInput(event, 'income_pct')}
                            onBlur={(event) => onBlurInput(event, 'income_pct')}
                            type="percentage"
                          />
                        )}
                      </td>
                      {!isEditMode ? (
                        <>
                          {item?.plan_type === 'ltd' ? (
                            <>
                              <td>${item?.ltd_monthly_max}</td>
                              <td>{item?.ltd_elimination_period} Days</td>
                              <td>{item?.ltd_benefit_period} Days</td>
                            </>
                          ) : (
                            <>
                              <td>${item?.std_weekly_max}</td>
                              <td>{item?.std_elimination_period}</td>
                              <td>{item?.std_benefit_period} Days</td>
                            </>
                          )}
                          <td>{item?.comp_method}</td>
                          <td>{normalizeCompensationAmount(item)}</td>
                          <td>{item?.is_current_rate ? 'Yes' : 'No'}</td>
                          <td>${item?.rate_per_unit}</td>
                          <td>{normalizeDisabilityRateBasis(item?.rate_basis)}</td>
                        </>
                      ) : (
                        <>
                          {item?.plan_type === 'ltd' ? (
                            <>
                              <td>
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.ltd_monthly_max}
                                  onChange={(event) => onChangeInputValue(event, 'ltd_monthly_max')}
                                  onFocus={(event) => onFocusInput(event, 'ltd_monthly_max')}
                                  onBlur={(event) => onBlurInput(event, 'ltd_monthly_max')}
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.ltd_elimination_period}
                                  onChange={(event) =>
                                    onChangeInputValue(event, 'ltd_elimination_period')
                                  }
                                  onFocus={(event) => onFocusInput(event, 'ltd_elimination_period')}
                                  onBlur={(event) => onBlurInput(event, 'ltd_elimination_period')}
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.ltd_benefit_period}
                                  onChange={(event) =>
                                    onChangeInputValue(event, 'ltd_benefit_period')
                                  }
                                  onFocus={(event) => onFocusInput(event, 'ltd_benefit_period')}
                                  onBlur={(event) => onBlurInput(event, 'ltd_benefit_period')}
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.std_weekly_max}
                                  onChange={(event) => onChangeInputValue(event, 'std_weekly_max')}
                                  onFocus={(event) => onFocusInput(event, 'std_weekly_max')}
                                  onBlur={(event) => onBlurInput(event, 'std_weekly_max')}
                                  type="number"
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.std_elimination_period}
                                  onChange={(event) =>
                                    onChangeInputValue(event, 'std_elimination_period')
                                  }
                                />
                              </td>
                              <td>
                                <Input
                                  placeholder="Placeholder"
                                  value={selectedConfig?.std_benefit_period}
                                  onChange={(event) =>
                                    onChangeInputValue(event, 'std_benefit_period')
                                  }
                                  onFocus={(event) => onFocusInput(event, 'std_benefit_period')}
                                  onBlur={(event) => onBlurInput(event, 'std_benefit_period')}
                                />
                              </td>
                            </>
                          )}
                          <td>
                            <SelectOption
                              height="30px"
                              width="142px"
                              options={compensationMethodData}
                              selectedOption={selectedConfig?.comp_method}
                              isVisibleSelect={isVisibleCompensationMethod}
                              handleOpenSelect={handleOpenCarrierSelect(
                                setIsVisibleCompensationMethod,
                              )}
                              onSelectOption={(event) =>
                                onSelectOption(event, 'comp_method', setIsVisibleCompensationMethod)
                              }
                              isAbsolute
                            />
                          </td>
                          <td>
                            <SelectOption
                              height="30px"
                              width="142px"
                              options={compensationAmount}
                              selectedOption={selectedConfig?.comp_amount}
                              isVisibleSelect={isVisibleCompensationAmount}
                              handleOpenSelect={handleOpenCarrierSelect(
                                setIsVisibleCompensationAmount,
                              )}
                              onSelectOption={(event) =>
                                onSelectOption(event, 'comp_amount', setIsVisibleCompensationAmount)
                              }
                              isAbsolute
                            />
                          </td>
                          <td>{item?.is_current_rate ? 'Yes' : 'No'}</td>
                          <td>
                            <Input
                              type="number"
                              value={selectedConfig?.rate_per_unit}
                              onChange={(event) => onChangeInputValue(event, 'rate_per_unit')}
                              onFocus={(event) => onFocusInput(event, 'rate_per_unit')}
                              onBlur={(event) => onBlurInput(event, 'rate_per_unit')}
                              isDisabled={!isEditMode}
                            />
                          </td>
                          <td>
                            <SelectOption
                              height="30px"
                              width="142px"
                              options={rateBasisOptions}
                              selectedOption={selectedConfig?.rate_basis}
                              isVisibleSelect={isVisibleSelect}
                              handleOpenSelect={handleOpenCarrierSelect(setIsVisibleSelect)}
                              onSelectOption={(event) =>
                                onSelectOption(event, 'rate_basis', setIsVisibleSelect)
                              }
                              isDisabled={!isEditMode}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.DisabilityConfigurationContentTier}>
            <table className={classes.DisabilityConfigurationTierTable}>
              <thead className={classes.DisabilityConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Custom Fields</th>
                </tr>
                <tr>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.title}</span>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr
                  className={classes.DisabilityConfigurationTierTableBroker}
                  style={{ backgroundColor: '#e5e5e5' }}
                >
                  <td className={classes.EmptyRow}></td>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.value}</span>
                    </td>
                  ))}
                </tr>
                {disabilityConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      {!isEditMode ? (
                        item.custom_fields?.map((item) => (
                          <td key={item.id}>
                            <span>{item.value}</span>
                          </td>
                        ))
                      ) : (
                        <div className={classes.ExtraRowButtonWrapper}>
                          <ExtraRows
                            extraRows={extraRows}
                            setExtraRows={setExtraRows}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            isDisabled={!isEditMode}
                          />

                          <div className={classes.ExtraRowButton}>
                            <Button
                              title="Add Extra Row"
                              type="secondary"
                              onClick={onAddExtraRow}
                              isDisabled={!isEditMode}
                            />

                            <Tooltip title={TOOLTIP_NAMES.MAX_ROWS}>
                              <i className="fas fa-info-circle" />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.DisabilityConfigurationContentTier}>
            <table className={classes.DisabilityConfigurationTierTable}>
              <thead className={classes.DisabilityConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Rates</th>
                </tr>
                <tr>
                  <th>Employee</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.DisabilityConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}>
                    {normalizeRateTierType(brokerConfig?.rate_tier_type)}
                  </td>
                  {brokerConfig?.rate_tier_type !== 'employee' && (
                    <div className={classes.AgeBandedRates}>
                      <CoverageAgeBandedRates
                        tierType={brokerConfig?.rate_tier_type}
                        ageBandedRates={brokerAgeBandedRates}
                        isEditMode={false}
                        isIssuer={true}
                      />
                    </div>
                  )}
                  {brokerConfig?.rate_tier_type === 'employee' && (
                    <td>{brokerConfig?.rate_ee && `$${brokerConfig?.rate_ee}`}</td>
                  )}
                  <td className={classes.EmptyDropZoneRow}>
                    <DropZone
                      label="Current Benefits Summary"
                      maxFiles={100}
                      path={`/proposals/${proposalId}/uploads/broker/plan-configs/${id}/`}
                      isDisabled={true}
                    />
                  </td>
                </tr>
                {disabilityConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  const selectedTier = isEditMode
                    ? selectedConfig?.rate_tier_type === 'employee'
                    : item?.rate_tier_type === 'employee';

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}>
                        {!isEditMode ? (
                          normalizeRateTierType(item.rate_tier_type)
                        ) : (
                          <SelectOption
                            label="Rate Tier Type"
                            width="140px"
                            options={tierOptions}
                            selectedOption={selectedConfig?.rate_tier_type}
                            isVisibleSelect={isVisibleRateSelect}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleRateSelect)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'rate_tier_type', setIsVisibleRateSelect)
                            }
                          />
                        )}
                      </td>
                      <div className={classes.AgeBandedRates}>
                        <CoverageAgeBandedRates
                          tierType={
                            !isEditMode ? item?.rate_tier_type : selectedConfig?.rate_tier_type
                          }
                          ageBandedRates={
                            !isEditMode
                              ? normalizeCoverageAgeRates(item)
                              : normalizeCoverageAgeRates(selectedConfig)
                          }
                          selectedAllAges={!isEditMode ? coverageAllAges(item) : selectedAllAges}
                          selectedFiveYearAges={
                            !isEditMode ? coverageFiveYearAges(item) : selectedFiveYearAges
                          }
                          selectedTenYearAges={
                            !isEditMode ? coverageTenYearAges(item) : selectedTenYearAges
                          }
                          setSelectedAllAges={setSelectedAllAges}
                          setSelectedFiveYearAges={setSelectedFiveYearAges}
                          setSelectedTenYearAges={setSelectedTenYearAges}
                          isEditMode={isEditMode}
                          isIssuer={true}
                        />
                      </div>
                      {selectedTier && (
                        <td>
                          {!isEditMode ? (
                            (item.rate_ee || item.rate_ee === 0) && `$${item.rate_ee}`
                          ) : (
                            <Input
                              value={selectedConfig?.rate_ee}
                              onChange={(event) => onChangeRateValue(event, 'rate_ee')}
                              onFocus={(event) => onFocusInput(event, 'rate_ee')}
                              onBlur={(event) => onBlurInput(event, 'rate_ee')}
                              type="number"
                            />
                          )}
                        </td>
                      )}
                      <td className={classes.EmptyDropZoneRow}>
                        <DropZone
                          label="Proposed Benefits Summary"
                          maxFiles={100}
                          path={`/proposals/${proposalId}/uploads/carrier/plan-configs/${id}/offers/${item?.id}/`}
                          isDisabled={!isEditMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.DisabilityConfigurationContentTier}>
            <table className={classes.DisabilityConfigurationTierTable}>
              <thead className={classes.DisabilityConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}></th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.DisabilityConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                </tr>
                {disabilityConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyButtonRow}>
                        {!isEditMode && (
                          <div className={classes.DisabilityConfigurationEditTableItem}>
                            {isVisibleButtons(item?.status) && (
                              <div className={classes.DisabilityConfigurationEditTableItemWrapper}>
                                <Button
                                  title="Edit"
                                  type="primary"
                                  onClick={onClickEditConfig(item)}
                                />
                                {item.status !== 'declined' && disabilityOffers.length > 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Delete" onClick={onClickDelete(item)} />
                                  </div>
                                )}
                                {item.status !== 'declined' && disabilityOffers?.length === 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Decline" onClick={onRejectOffer} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {isEditMode && (
                          <div className={classes.DisabilityConfigurationContentFooter}>
                            {(isEdited || isEditedRates) && (
                              <Button title="Save" type="primary" onClick={onSaveSelectedConfig} />
                            )}
                            <Button title="Cancel" type="secondary" onClick={onClickCancel} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
