import { createSlice } from '@reduxjs/toolkit';

import {
  getIssuerLifePlans,
  getLifePlans,
  getLifeQuotePlans,
  updateLifeOffer,
} from './lifeConfiguration.actions';

const initialState = {
  lifePlans: [],
  lifeQuotePlans: [],
  isLoadingLifeQuotePlans: false,
  isLoadingLifePlans: false,

  // ISSUER
  issuerLifePlans: [],
  isLoadingIssuerLifePlans: false,
  isLoadingUpdateLifeOffer: false,
};

const lifeConfigurationSlice = createSlice({
  name: 'lifeConfiguration',
  initialState,
  reducers: {
    setLifePlans: (state, action) => {
      state.lifePlans = action.payload;
    },
    setLifeQuotePlans: (state, action) => {
      state.lifeQuotePlans = action.payload;
    },

    setIssuerLifePlans: (state, action) => {
      state.issuerLifePlans = action.payload;
    },

    clearLifeConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getLifeQuotePlans.pending, (state) => {
      state.isLoadingLifeQuotePlans = true;
    });
    builder.addCase(getLifeQuotePlans.fulfilled, (state, action) => {
      state.lifeQuotePlans = action.payload;
      state.isLoadingLifeQuotePlans = false;
    });
    builder.addCase(getLifeQuotePlans.rejected, (state) => {
      state.isLoadingLifeQuotePlans = false;
    });

    builder.addCase(getLifePlans.pending, (state) => {
      state.isLoadingLifePlans = true;
    });
    builder.addCase(getLifePlans.fulfilled, (state, action) => {
      state.lifePlans = action.payload;
      state.isLoadingLifePlans = false;
    });
    builder.addCase(getLifePlans.rejected, (state) => {
      state.isLoadingLifePlans = false;
    });

    // ISSUER
    builder.addCase(getIssuerLifePlans.pending, (state) => {
      state.isLoadingIssuerLifePlans = true;
    });
    builder.addCase(getIssuerLifePlans.fulfilled, (state, action) => {
      state.issuerLifePlans = action.payload;
      state.isLoadingIssuerLifePlans = false;
    });
    builder.addCase(getIssuerLifePlans.rejected, (state) => {
      state.isLoadingIssuerLifePlans = false;
    });

    builder.addCase(updateLifeOffer.pending, (state) => {
      state.isLoadingUpdateLifeOffer = true;
    });
    builder.addCase(updateLifeOffer.fulfilled, (state, action) => {
      state.issuerLifePlans = action.payload;
      state.isLoadingUpdateLifeOffer = false;
    });
    builder.addCase(updateLifeOffer.rejected, (state) => {
      state.isLoadingUpdateLifeOffer = false;
    });
  },
});

export const { setLifePlans, setLifeQuotePlans, setIssuerLifePlans, clearLifeConfigurationState } =
  lifeConfigurationSlice.actions;

export default lifeConfigurationSlice.reducer;
