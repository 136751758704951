export const TERM_DISABILITY_FORM_NAMES = {
  plan_type: 'Plan Type',
  contribution_type: 'Company Contribution',
  income_pct: '% of Income Requested',
  std_elimination_period: 'STD Elimination Period \n (Accident/Sickness)',
  std_benefit_period: 'STD Benefit Period',
  ltd_elimination_period: 'LTD Elimination Period',
  ltd_benefit_period: 'LTD Benefit Period',
  std_weekly_max: 'STD Weekly Max',
  ltd_monthly_max: 'LTD Monthly Max',
  comp_method: 'Compensation Method',
  compensation_amount: 'Compensation Amount',
};
