import { createSlice } from '@reduxjs/toolkit';

import {
  getHospitalQuotePlans,
  getHospitalPlans,
  getIssuerHospitalPlans,
  updateHospitalOffer,
} from './hospitalConfiguration.actions';

const initialState = {
  hospitalPlans: [],
  hospitalQuotePlans: [],

  isLoadingHospitalQuotePlans: false,
  isLoadingHospitalPlans: false,
  isLoadingUpdateHospitalOffer: false,

  issuerHospitalPlans: [],
  isLoadingIssuerHospitalPlans: false,
};

const hospitalConfigurationSlice = createSlice({
  name: 'hospitalConfiguration',
  initialState,
  reducers: {
    setHospitalPlan: (state, action) => {
      state.hospitalPlans = action.payload;
    },

    setHospitalQuotePlans: (state, action) => {
      state.hospitalQuotePlans = action.payload;
    },

    clearHospitalConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getHospitalQuotePlans.pending, (state) => {
      state.isLoadingHospitalQuotePlans = true;
    });
    builder.addCase(getHospitalQuotePlans.fulfilled, (state, action) => {
      state.hospitalQuotePlans = action.payload;
      state.isLoadingHospitalQuotePlans = false;
    });
    builder.addCase(getHospitalQuotePlans.rejected, (state) => {
      state.isLoadingHospitalQuotePlans = false;
    });

    builder.addCase(getHospitalPlans.pending, (state) => {
      state.isLoadingHospitalPlans = true;
    });
    builder.addCase(getHospitalPlans.fulfilled, (state, action) => {
      state.hospitalPlans = action.payload;
      state.isLoadingHospitalPlans = false;
    });
    builder.addCase(getHospitalPlans.rejected, (state) => {
      state.isLoadingHospitalPlans = false;
    });

    builder.addCase(updateHospitalOffer.pending, (state) => {
      state.isLoadingUpdateHospitalOffer = true;
    });
    builder.addCase(updateHospitalOffer.fulfilled, (state) => {
      state.isLoadingUpdateHospitalOffer = false;
    });
    builder.addCase(updateHospitalOffer.rejected, (state) => {
      state.isLoadingUpdateHospitalOffer = false;
    });

    // ISSUER
    builder.addCase(getIssuerHospitalPlans.pending, (state) => {
      state.isLoadingIssuerHospitalPlans = true;
    });
    builder.addCase(getIssuerHospitalPlans.fulfilled, (state, action) => {
      state.issuerHospitalPlans = action.payload;
      state.isLoadingIssuerHospitalPlans = false;
    });
    builder.addCase(getIssuerHospitalPlans.rejected, (state) => {
      state.isLoadingIssuerHospitalPlans = false;
    });
  },
});

export const { setHospitalPlan, setHospitalQuotePlans, clearHospitalConfigurationState } =
  hospitalConfigurationSlice.actions;

export default hospitalConfigurationSlice.reducer;
