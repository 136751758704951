import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { getAccidentOffers } from 'src/store/accidentCnfiguration';

import { normalizeAccidentType } from 'src/constants/requestProposal';

import { AccidentConfigurationForm } from '../AccidentConfigurationForm/AccidentConfigurationForm';

import { IssuerCoverageCard } from '../IssuerCoverageCard/IssuerCoverageCard';

export const AccidentConfiguration = (props) => {
  const { id, accountId, proposalId, userId, issuerId, brokerRequest } = props;

  const dispatch = useDispatch();

  const [selectedProposalIds, setSelectedProposalIds] = useState([]);

  const [accidentOffers, setAccidentOffers] = useState([]);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);

  const offerStatus = accidentOffers?.find((item) => item.config_id === id)?.status;

  const getOffers = useCallback(() => {
    dispatch(
      getAccidentOffers({
        accountId,
        proposalId,
        configId: id,
        setOffers: setAccidentOffers,
        setIsLoading: setIsLoadingOffers,
      }),
    );
  }, [dispatch, id, accountId, proposalId]);

  useEffect(() => {
    if (id) {
      getOffers();
    }
    return () => {
      setAccidentOffers([]);
    };
  }, [id, getOffers]);

  const handleOpenProposal = useCallback(
    (id) => () => {
      if (selectedProposalIds.includes(id)) {
        const updatedSelectedQuotes = _.reject(selectedProposalIds, (itemId) => itemId === id);

        setSelectedProposalIds(updatedSelectedQuotes);
        return;
      }

      setSelectedProposalIds([...selectedProposalIds, id]);
    },
    [selectedProposalIds],
  );

  const cardData = [
    { title: 'Type', sub_title: normalizeAccidentType(brokerRequest) },
    {
      title: 'Hospital Admission',
      sub_title: brokerRequest?.hospital_admission ? `$${brokerRequest?.hospital_admission}` : '--',
    },
    {
      title: 'ICU Confinement',
      sub_title: brokerRequest?.icu_confinement ? `$${brokerRequest?.icu_confinement}` : '--',
    },
    {
      title: 'Current',
      sub_title: brokerRequest?.is_current_rate ? 'Yes' : 'No',
    },
  ];

  return (
    <IssuerCoverageCard
      id={id}
      selectedProposalIds={selectedProposalIds}
      handleOpenProposal={handleOpenProposal}
      cardData={cardData}
      isLoading={isLoadingOffers}
      status={offerStatus}
    >
      <AccidentConfigurationForm
        id={id}
        accountId={accountId}
        proposalId={proposalId}
        userId={userId}
        issuerId={issuerId}
        brokerConfig={brokerRequest}
        accidentOffers={accidentOffers}
        getAccidentOffers={getOffers}
      />
    </IssuerCoverageCard>
  );
};
