export const fundingTypeData = [
  { name: 'Fully Insured', key: 'fully_insured' },
  { name: 'Level Funded', key: 'level_funded' },
];

export const frequencyCopayData = (onChange, value) => [
  { name: '12/12/12', key: '12/12/12' },
  { name: '12/12/24', key: '12/12/24' },
  {
    name: '12/24/24',
    key: '12/24/24',
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'text',
    onChange: onChange,
    value: value,
  },
];

export const examCopayData = (onChange, value) => [
  { name: '$5', key: 5 },
  { name: '$10', key: 10 },
  {
    name: '$15',
    key: 15,
  },
  {
    name: '$20',
    key: 20,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, 'exam_copay_other'),
    value: value,
  },
];

export const materialsCopayData = (onChange, value) => [
  { name: '$10', key: 10 },
  {
    name: '$15',
    key: 15,
  },
  {
    name: '$20',
    key: 20,
  },
  {
    name: '$25',
    key: 25,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, 'materials_copay_other'),
    value: value,
  },
];

export const frameAllowanceData = (onChange, value) => [
  { name: '$100', key: 100 },
  {
    name: '$120',
    key: 120,
  },
  {
    name: '$130',
    key: 130,
  },
  {
    name: '$150',
    key: 150,
  },

  {
    name: '$200',
    key: 200,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, 'frame_allowance_other'),
    value: value,
  },
];
