import { onBlurInput, onFocusInput } from 'src/components';

export const OffTheJobData = [
  { name: '24 hour', key: '24_hour' },
  {
    name: 'Off the Job',
    key: 'off_job',
  },
];

export const HospitalAdmissionData = (onChange, setValue, value) => [
  { name: '$500', key: 500 },
  {
    name: '$1000',
    key: 1000,
  },
  {
    name: '$1500',
    key: 1500,
  },
  {
    name: '$2000',
    key: 2000,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const IcuConfinementData = (onChange, setValue, value) => [
  { name: '$1000', key: 1000 },
  {
    name: '$2000',
    key: 2000,
  },
  {
    name: '$3000',
    key: 3000,
  },
  {
    name: '$4000',
    key: 4000,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const DailyHospitalConfinementData = (onChange, setValue, value) => [
  { name: '$100', key: 100 },
  {
    name: '$150',
    key: 150,
  },
  {
    name: '$200',
    key: 200,
  },
  {
    name: '$250',
    key: 250,
  },
  {
    name: '$300',
    key: 300,
  },
  {
    name: '$400',
    key: 400,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const ConfinementDaysCoveredData = (onChange, setValue, value) => [
  { name: '365', key: 365 },
  {
    name: 'Other: ',
    key: 'other',
    type: 'default',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const DailyICUConfinementData = (onChange, setValue, value) => [
  {
    name: '$200',
    key: 200,
  },
  {
    name: '$300',
    key: 300,
  },
  {
    name: '$400',
    key: 400,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const ICUDaysCoveredData = (onChange, setValue, value) => [
  {
    name: '30',
    key: 30,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'default',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];

export const AccidentalDeathData = (onChange, setValue, value) => [
  { name: '$10000', key: 10000 },
  {
    name: '$20000',
    key: 20000,
  },
  {
    name: '$30000',
    key: 30000,
  },
  {
    name: '$40000',
    key: 40000,
  },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, setValue),
    onFocus: (event) => onFocusInput(event, setValue),
    onBlur: (event) => onBlurInput(event, setValue),
    value: value,
  },
];
