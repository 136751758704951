import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { getRequestedProposalsStatus, getSharedContacts } from '../planConfiguration';
import { clearMedicalConfigurationState, setMedicalPlans } from './medicalConfiguration.slice';

import { ERROR } from 'src/constants/errorNames';
import { calculateMedicalAgeRates, calculateMedicalRates } from 'src/constants/medicalRates';

export const getMedicalPlans = createAsyncThunk(
  'medical-configuration/get-medical-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getMedicalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getMedicalQuotePlans = createAsyncThunk(
  'medical-configuration/get-medical-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getMedicalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const deleteMedicalPlan = createAsyncThunk(
  'medical-configuration/delete-medical-plan',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, planId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.planConfiguration.deleteMedicalPlanConfiguration(accountId, proposalId, planId);

      toast('Medcial plan was deleted successfully!', { type: 'success' });

      thunkApi.dispatch(getMedicalPlans({ accountId, proposalId }));
      thunkApi.dispatch(getMedicalQuotePlans({ accountId, proposalId }));
      thunkApi.dispatch(getSharedContacts({ accountId, proposalId }));
      thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
    } catch (e) {
      console.warn(e);
    }
  },
);

export const getMedicalOffers = createAsyncThunk(
  'medical-configuration/get-medical-offers',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading } = data;
      setIsLoading(true);
      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const userType = thunkApi.getState().app.userType;

      const response = await api.planConfiguration.getMedicalOffers(
        accountId,
        proposalId,
        'medical',
        configId,
      );

      if (userType === 'broker') {
        const groupMembers = thunkApi.getState().groupMembers.groupMembers;

        const brokerOffer = {
          ...brokerConfig,
          issuer_name: 'broker',
          status: 'selected',
        };

        const updatedResponse = [brokerOffer, ...response];

        const calculatedResponse = updatedResponse?.map((item) => {
          if (item?.status !== 'pending') {
            let calculatedRates = {};

            if (item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateMedicalRates(groupMembers, item, {});
            }

            if (!item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateMedicalAgeRates(groupMembers, item);
            }

            return { ...item, calculation: calculatedRates };
          }
          return item;
        });

        return setOffers(calculatedResponse);
      }

      return setOffers(response);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

export const getMedicalQuotes = createAsyncThunk(
  'medical-configuration/get-medical-quotes',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading, read_token } =
        data;
      setIsLoading(true);

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'medical',
        configId,
        read_token,
      );

      const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      const brokerOffer = {
        ...brokerConfig,
        issuer_name: 'broker',
        status: 'selected',
      };

      const updatedResponse = [brokerOffer, ...response];

      const calculatedResponse = updatedResponse?.map((item) => {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateMedicalRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateMedicalAgeRates(groupMembers, item);
        }

        return { ...item, calculation: calculatedRates };
      });

      return setOffers(calculatedResponse);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

// ISSUER

export const getIssuerMedicalPlans = createAsyncThunk(
  'medical-configuration/get-issuer-medical-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.planConfiguration.getMedicalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createMedicalOffer = createAsyncThunk(
  'issuer/create-medical-offer',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, config_id } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = {
        ...data,
      };

      delete payload.comp_amount;
      delete payload.created_timestamp;

      delete payload.accountId;
      delete payload.proposal_id;
      delete payload.is_current_rate;
      delete payload.proposalId;
      delete payload.order_id;

      const response = await api.issuer.createCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'medical',
        payload,
      );

      toast('Your medical offer was successfully saved!', { type: 'success' });
      thunkApi.dispatch(getIssuerMedicalPlans({ accountId, proposalId }));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
    }
  },
);

export const updateMedicalOffer = createAsyncThunk(
  'issuer/update-medical-offer',
  async (data, thunkApi) => {
    try {
      const { payload, offerId, read_token, setUpdatedOffer } = data;

      const { accountId, proposalId, config_id, isBroker } = payload;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      delete payload.id;
      delete payload.comp_amount;
      delete payload.issuer_name;
      delete payload.created_timestamp;

      delete payload.accountId;
      delete payload.proposalId;
      delete payload.isBroker;

      const response = await api.issuer.updateCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'medical',
        payload,
        offerId,
        read_token,
      );

      toast('Your medical offer was successfully updated!', { type: 'success' });
      thunkApi.dispatch(getIssuerMedicalPlans({ accountId, proposalId, read_token }));

      if (isBroker) {
        if (setUpdatedOffer) {
          setUpdatedOffer([response]);
        }
      }

      const medicalResponse = await api.planConfiguration.getMedicalPlans(
        accountId,
        proposalId,
        read_token,
      );

      thunkApi.dispatch(setMedicalPlans(medicalResponse));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
    }
  },
);

export const deleteMedicalOffer = createAsyncThunk('issuer/delete-medical-offer', async (data) => {
  try {
    const { accountId, proposalId, configId, offerId } = data;

    const token = await auth.currentUser.getIdToken();

    api.apiClient.setToken(token);

    await api.issuer.deleteCarrierOffer(accountId, proposalId, 'medical', configId, offerId);
  } catch (err) {
    console.error(err);
  }
});

// CLEAR STATE

export const clearMedicalState = createAsyncThunk('medical/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearMedicalConfigurationState());
});
