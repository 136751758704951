import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIssuerDataSelector } from 'src/store/issuer';
import {
  getIssuerLifePlans,
  getIssuerLifePlansSelecetor,
  isLoadingIssuerLifePlansSelector,
} from 'src/store/lifeConfiguration';

import { Loader, LoaderWrapper, EmptyField } from 'src/components';
import { LifeConfiguration } from '../../components';

import classes from './lifePlans.module.scss';

export const LifePlans = (props) => {
  const { activeTab } = props;

  const dispatch = useDispatch();

  const issuerData = useSelector(getIssuerDataSelector);
  const lifePlans = useSelector(getIssuerLifePlansSelecetor);
  const isLoading = useSelector(isLoadingIssuerLifePlansSelector);

  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const issuerId = issuerData?.issuer_id;

  useEffect(() => {
    dispatch(getIssuerLifePlans({ accountId, proposalId }));
  }, [dispatch, accountId, proposalId]);

  return isLoading ? (
    <div className={classes.Loader}>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    </div>
  ) : lifePlans?.length > 0 ? (
    lifePlans?.map((item) => (
      <div key={item.id}>
        <LifeConfiguration
          id={item.id}
          activeTab={activeTab}
          accountId={accountId}
          issuerId={issuerId}
          proposalId={item.proposal_id}
          userId={issuerData?.id}
          brokerRequest={item}
        />
      </div>
    ))
  ) : (
    <EmptyField title="No life RFP created yet." />
  );
};
