import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import { BuilderSelect, BuilderCASelect, BuilderInput } from 'src/features/coverageBuilder';
import {
  compMethodOptions,
  contributionTypeOptions,
  deathBenefitOptions,
  rateBasisOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { LIFE_AGES } from 'src/features/coverageBuilder/cb_constants';

export const useLifeBuild = () => {
  const initialState = {
    id: uuid(),
    ...LIFE_AGES,
    is_current_rate: false,
    is_uni_tobacco: true,
    rate_basis: 'earnings',
    contribution_type: null,
    death_benefit: null,
    rate_per_unit: 1000,
    comp_method: null,
    comp_amount_pct: '10',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    custom_fields: [],
    rate_tier_type: '4tier',
    rate_ee_uni_tobacco: null,
    rate_sp_uni_tobacco: null,
    rate_ch_uni_tobacco: null,
    rate_fam_uni_tobacco: null,
    rate_ee_tobacco: null,
    rate_sp_tobacco: null,
    rate_ch_tobacco: null,
    rate_fam_tobacco: null,
    rate_ee_non_tobacco: null,
    rate_sp_non_tobacco: null,
    rate_ch_non_tobacco: null,
    rate_fam_non_tobacco: null,
  };

  const [formData, setFormData] = useState(initialState);

  const onChange = useCallback((event, key) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const formattedValue = (value) => {
    return currencyFormatter(value).replace('.00', '');
  };

  const build = {
    coverage: 'life',
    title: 'Life',
    card_options: {
      proposal: [
        {
          title: 'Contribution',
          key: 'contribution',
          format: () => 'Employer',
        },
        {
          title: 'Death Benefit',
          key: 'death_benefit',
          format: (value) => formattedValue(value),
        },
      ],
    },
    form_options: {
      initial_state: initialState,
      form_data: formData,
      setFormData,
    },
    form_content: () => [
      <BuilderSelect
        key="1"
        label="Employer Contribution"
        value={formData.contribution_type}
        options={contributionTypeOptions}
        onChange={(event) => onChange(event, 'contribution_type')}
      />,
      <BuilderSelect
        key="2"
        label="Death Benefit"
        value={formData.death_benefit}
        options={deathBenefitOptions}
        onChange={(event) => onChange(event, 'death_benefit')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderInput
        key="3"
        label="Rate Per Unit"
        value={formData.rate_per_unit}
        onChange={(event) => onChange(event, 'rate_per_unit')}
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="4"
        label="Rate Basis"
        value={formData.rate_basis}
        options={rateBasisOptions}
        onChange={(event) => onChange(event, 'rate_basis')}
      />,
      <BuilderSelect
        key="5"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="6"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'contribution_type' },
      {
        key: 'death_benefit',
        format: (value) => (_.isNumber(value) ? formattedValue(value) : value),
      },
      { key: 'rate_per_unit', format: (value) => formattedValue(value) },
      { key: 'rate_basis' },
      { key: 'comp_method' },
    ],
  };

  return { build };
};
