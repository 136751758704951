import React from 'react';
import { InputAdornment, TextField } from '@mui/material';

import { AGE_LABELS } from '../../cb_constants';

import classes from '../planConfig.module.scss';

export const PlanConfigAgeRates = (props) => {
  const { build } = props;

  const { coverage, form_options } = build;
  const { form_data, setFormData } = form_options;

  const rate_tier_type = form_data.rate_tier_type;
  const ageRates = Object.keys(form_data).filter((item) => item.includes('age_'));
  const uniAgeRates = Object.keys(form_data).filter(
    (item) =>
      item.includes('_uni_tobacco') && !item.includes('rate_') && !item.includes('is_uni_tobacco'),
  );
  const tobaccoAgeRates = Object.keys(form_data).filter(
    (item) =>
      item.includes('_tobacco') &&
      !item.includes('uni_tobacco') &&
      !item.includes('_non_tobacco') &&
      !item.includes('rate_'),
  );
  const nonTobaccoAgeRates = Object.keys(form_data).filter(
    (item) => item.includes('_non_tobacco') && !item.includes('rate_'),
  );

  const onChangeInput = (ageKey, value) => {
    const updatedValues = { [ageKey]: value };
    const index = parseInt(ageKey.split('_')[1]);

    const isTobacco = ageKey.includes('_tobacco') && !ageKey.includes('_non_tobacco');
    const isNonTobacco = ageKey.includes('_non_tobacco');

    if (coverage !== 'life') {
      if (rate_tier_type === 'all_ages') {
        if (index <= 14) {
          for (let i = 0; i <= 14; i++) {
            updatedValues[`age_${i}`] = value;
          }
        }
      }
      if (rate_tier_type === 'banded_5') {
        if (index <= 17) {
          for (let i = 0; i <= 17; i++) {
            updatedValues[`age_${i}`] = value;
          }
        } else if (index === 18) {
          for (let i = 18; i <= 24; i++) {
            updatedValues[`age_${i}`] = value;
          }
        } else {
          const startIndex = Math.floor((index - 25) / 5) * 5 + 25;

          for (let i = 0; i < 5; i++) {
            if (startIndex + i <= (coverage !== 'medical' ? 80 : 65)) {
              updatedValues[`age_${startIndex + i}`] = value;
            }
          }
        }
      }
      if (rate_tier_type === 'banded_10') {
        if (index <= 17) {
          for (let i = 0; i <= 17; i++) {
            updatedValues[`age_${i}`] = value;
          }
        } else if (index === 18) {
          for (let i = 18; i <= 29; i++) {
            updatedValues[`age_${i}`] = value;
          }
        } else {
          const startIndex = Math.floor((index - 30) / 10) * 10 + 30;
          for (let i = 0; i < 10; i++) {
            if (startIndex + i <= 80) {
              updatedValues[`age_${startIndex + i}`] = value;
            }
          }
        }
      }
    }

    if (coverage === 'life') {
      if (form_data.is_uni_tobacco) {
        if (rate_tier_type === 'all_ages') {
          if (index <= 14) {
            for (let i = 0; i <= 14; i++) {
              updatedValues[`age_${i}_uni_tobacco`] = value;
            }
          }
        }
        if (rate_tier_type === 'banded_5') {
          if (index <= 17) {
            for (let i = 0; i <= 17; i++) {
              updatedValues[`age_${i}_uni_tobacco`] = value;
            }
          } else if (index === 18) {
            for (let i = 18; i <= 24; i++) {
              updatedValues[`age_${i}_uni_tobacco`] = value;
            }
          } else {
            const startIndex = Math.floor((index - 25) / 5) * 5 + 25;

            for (let i = 0; i < 5; i++) {
              if (startIndex + i <= 80) {
                updatedValues[`age_${startIndex + i}_uni_tobacco`] = value;
              }
            }
          }
        }

        if (rate_tier_type === 'banded_10') {
          if (index <= 17) {
            for (let i = 0; i <= 17; i++) {
              updatedValues[`age_${i}_uni_tobacco`] = value;
            }
          } else if (index === 18) {
            for (let i = 18; i <= 29; i++) {
              updatedValues[`age_${i}_uni_tobacco`] = value;
            }
          } else {
            const startIndex = Math.floor((index - 30) / 10) * 10 + 30;
            for (let i = 0; i < 10; i++) {
              if (startIndex + i <= 80) {
                updatedValues[`age_${startIndex + i}_uni_tobacco`] = value;
              }
            }
          }
        }
      }

      if (!form_data.is_uni_tobacco) {
        if (rate_tier_type === 'all_ages') {
          if (isTobacco) {
            if (index <= 14) {
              for (let i = 0; i <= 14; i++) {
                updatedValues[`age_${i}_tobacco`] = value;
              }
            }
          }
          if (isNonTobacco) {
            if (index <= 14) {
              for (let i = 0; i <= 14; i++) {
                updatedValues[`age_${i}_non_tobacco`] = value;
              }
            }
          }
        }
        if (rate_tier_type === 'banded_5') {
          if (isTobacco) {
            if (index <= 17) {
              for (let i = 0; i <= 17; i++) {
                updatedValues[`age_${i}_tobacco`] = value;
              }
            } else if (index === 18) {
              for (let i = 18; i <= 24; i++) {
                updatedValues[`age_${i}_tobacco`] = value;
              }
            } else {
              const startIndex = Math.floor((index - 25) / 5) * 5 + 25;

              for (let i = 0; i < 5; i++) {
                if (startIndex + i <= 80) {
                  updatedValues[`age_${startIndex + i}_tobacco`] = value;
                }
              }
            }
          }

          if (isNonTobacco) {
            if (index <= 17) {
              for (let i = 0; i <= 17; i++) {
                updatedValues[`age_${i}_non_tobacco`] = value;
              }
            } else if (index === 18) {
              for (let i = 18; i <= 24; i++) {
                updatedValues[`age_${i}_non_tobacco`] = value;
              }
            } else {
              const startIndex = Math.floor((index - 25) / 5) * 5 + 25;

              for (let i = 0; i < 5; i++) {
                if (startIndex + i <= 80) {
                  updatedValues[`age_${startIndex + i}_non_tobacco`] = value;
                }
              }
            }
          }
        }

        if (rate_tier_type === 'banded_10') {
          if (isTobacco) {
            if (index <= 17) {
              for (let i = 0; i <= 17; i++) {
                updatedValues[`age_${i}_tobacco`] = value;
              }
            } else if (index === 18) {
              for (let i = 18; i <= 29; i++) {
                updatedValues[`age_${i}_tobacco`] = value;
              }
            } else {
              const startIndex = Math.floor((index - 30) / 10) * 10 + 30;
              for (let i = 0; i < 10; i++) {
                if (startIndex + i <= 80) {
                  updatedValues[`age_${startIndex + i}_tobacco`] = value;
                }
              }
            }
          }

          if (isNonTobacco) {
            if (index <= 17) {
              for (let i = 0; i <= 17; i++) {
                updatedValues[`age_${i}_non_tobacco`] = value;
              }
            } else if (index === 18) {
              for (let i = 18; i <= 29; i++) {
                updatedValues[`age_${i}_non_tobacco`] = value;
              }
            } else {
              const startIndex = Math.floor((index - 30) / 10) * 10 + 30;
              for (let i = 0; i < 10; i++) {
                if (startIndex + i <= 80) {
                  updatedValues[`age_${startIndex + i}_non_tobacco`] = value;
                }
              }
            }
          }
        }
      }
    }

    setFormData((prev) => ({
      ...prev,
      ...updatedValues,
    }));
  };

  return (
    <>
      {!rate_tier_type.includes('tier') && coverage !== 'life' && (
        <>
          {rate_tier_type === 'all_ages' && (
            <div className={classes.PlanConfigForm}>
              <TextField
                label={AGE_LABELS['age_0_14']}
                onChange={(e) => onChangeInput('age_0', e.target.value)}
                value={form_data['age_0']}
                type="number"
                size="small"
                required
                InputProps={{
                  inputProps: { min: 0, max: 999999 },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              {ageRates.slice(15).map((item) => (
                <TextField
                  key={item}
                  label={AGE_LABELS[item]}
                  onChange={(e) => onChangeInput(item, e.target.value)}
                  value={form_data[item]}
                  type="number"
                  size="small"
                  required
                  InputProps={{
                    inputProps: { min: 0, max: 999999 },
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              ))}
            </div>
          )}
          {rate_tier_type === 'banded_5' && (
            <div className={classes.PlanConfigForm}>
              <TextField
                label={AGE_LABELS['age_0_17']}
                onChange={(e) => onChangeInput('age_0', e.target.value)}
                value={form_data['age_0']}
                type="number"
                size="small"
                required
                InputProps={{
                  inputProps: { min: 0, max: 999999 },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                label={AGE_LABELS['age_18_24']}
                onChange={(e) => onChangeInput('age_18', e.target.value)}
                value={form_data['age_18']}
                type="number"
                size="small"
                required
                InputProps={{
                  inputProps: { min: 0, max: 999999 },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              {ageRates
                .slice(25)
                .filter((_, index) => index % 5 === 0)
                .map((item, i) => {
                  const startIndex = 25 + i * 5;
                  const endIndex = Math.min(startIndex + 4, coverage !== 'medical' ? 80 : 65);

                  return (
                    <TextField
                      key={item}
                      label={AGE_LABELS[`age_${startIndex}_${endIndex}`]}
                      onChange={(e) => onChangeInput(item, e.target.value)}
                      value={form_data[item]}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  );
                })}
            </div>
          )}
          {rate_tier_type === 'banded_10' && (
            <div className={classes.PlanConfigForm}>
              <TextField
                label={AGE_LABELS['age_0_17']}
                onChange={(e) => onChangeInput('age_0', e.target.value)}
                value={form_data['age_0']}
                type="number"
                size="small"
                required
                InputProps={{
                  inputProps: { min: 0, max: 999999 },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              <TextField
                label={AGE_LABELS['age_18_29']}
                onChange={(e) => onChangeInput('age_18', e.target.value)}
                value={form_data['age_18']}
                type="number"
                size="small"
                required
                InputProps={{
                  inputProps: { min: 0, max: 999999 },
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
              {ageRates
                .slice(30)
                .filter((_, index) => index % 10 === 0)
                .map((item, i) => {
                  const startIndex = 30 + i * 10;

                  const endIndex = Math.min(startIndex + 9, coverage !== 'medical' ? 80 : 65);

                  return (
                    <TextField
                      key={item}
                      label={AGE_LABELS[`age_${startIndex}_${endIndex}`]}
                      onChange={(e) => onChangeInput(item, e.target.value)}
                      value={form_data[item]}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  );
                })}
            </div>
          )}
        </>
      )}
      {!rate_tier_type.includes('tier') && coverage === 'life' && (
        <>
          {form_data.is_uni_tobacco && (
            <>
              {rate_tier_type === 'all_ages' && (
                <div className={classes.PlanConfigForm}>
                  <TextField
                    label={AGE_LABELS['age_0_14']}
                    onChange={(e) => onChangeInput('age_0_uni_tobacco', e.target.value)}
                    value={form_data['age_0_uni_tobacco']}
                    type="number"
                    size="small"
                    required
                    InputProps={{
                      inputProps: { min: 0, max: 999999 },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  {uniAgeRates.slice(15).map((item) => (
                    <TextField
                      key={item}
                      label={AGE_LABELS[item.replace('_uni_tobacco', '')]}
                      onChange={(e) => onChangeInput(item, e.target.value)}
                      value={form_data[item]}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  ))}
                </div>
              )}
              {rate_tier_type === 'banded_5' && (
                <div className={classes.PlanConfigForm}>
                  <TextField
                    label={AGE_LABELS['age_0_17']}
                    onChange={(e) => onChangeInput('age_0_uni_tobacco', e.target.value)}
                    value={form_data['age_0_uni_tobacco']}
                    type="number"
                    size="small"
                    required
                    InputProps={{
                      inputProps: { min: 0, max: 999999 },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <TextField
                    label={AGE_LABELS['age_18_24']}
                    onChange={(e) => onChangeInput('age_18_uni_tobacco', e.target.value)}
                    value={form_data['age_18_uni_tobacco']}
                    type="number"
                    size="small"
                    required
                    InputProps={{
                      inputProps: { min: 0, max: 999999 },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  {uniAgeRates
                    .slice(25)
                    .filter((_, index) => index % 5 === 0)
                    .map((item, i) => {
                      const startIndex = 25 + i * 5;
                      const endIndex = Math.min(startIndex + 4, 80);

                      return (
                        <TextField
                          key={item}
                          label={AGE_LABELS[`age_${startIndex}_${endIndex}`]}
                          onChange={(e) => onChangeInput(item, e.target.value)}
                          value={form_data[item]}
                          type="number"
                          size="small"
                          required
                          InputProps={{
                            inputProps: { min: 0, max: 999999 },
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      );
                    })}
                </div>
              )}
              {rate_tier_type === 'banded_10' && (
                <div className={classes.PlanConfigForm}>
                  <TextField
                    label={AGE_LABELS['age_0_17']}
                    onChange={(e) => onChangeInput('age_0_uni_tobacco', e.target.value)}
                    value={form_data['age_0_uni_tobacco']}
                    type="number"
                    size="small"
                    required
                    InputProps={{
                      inputProps: { min: 0, max: 999999 },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  <TextField
                    label={AGE_LABELS['age_18_29']}
                    onChange={(e) => onChangeInput('age_18_uni_tobacco', e.target.value)}
                    value={form_data['age_18_uni_tobacco']}
                    type="number"
                    size="small"
                    required
                    InputProps={{
                      inputProps: { min: 0, max: 999999 },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                  {uniAgeRates
                    .slice(30)
                    .filter((_, index) => index % 10 === 0)
                    .map((item, i) => {
                      const startIndex = 30 + i * 10;

                      const endIndex = Math.min(startIndex + 9, 80);

                      return (
                        <TextField
                          key={item}
                          label={AGE_LABELS[`age_${startIndex}_${endIndex}`]}
                          onChange={(e) => onChangeInput(item, e.target.value)}
                          value={form_data[item]}
                          type="number"
                          size="small"
                          required
                          InputProps={{
                            inputProps: { min: 0, max: 999999 },
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      );
                    })}
                </div>
              )}
            </>
          )}
          {!form_data.is_uni_tobacco && (
            <>
              {rate_tier_type === 'all_ages' && (
                <>
                  <div className={classes.PlanConfigForm}>
                    <TextField
                      label={AGE_LABELS['age_0_14'] + ' Tobacco'}
                      onChange={(e) => onChangeInput('age_0_tobacco', e.target.value)}
                      value={form_data['age_0_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    {tobaccoAgeRates.slice(15).map((item) => (
                      <TextField
                        key={item}
                        label={AGE_LABELS[item.replace('_tobacco', '')] + ' Tobacco'}
                        onChange={(e) => onChangeInput(item, e.target.value)}
                        value={form_data[item]}
                        type="number"
                        size="small"
                        required
                        InputProps={{
                          inputProps: { min: 0, max: 999999 },
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    ))}
                  </div>
                  <div className={classes.PlanConfigForm} style={{ marginLeft: '15px' }}>
                    <TextField
                      label={AGE_LABELS['age_0_14'] + ' Non-Tobacco'}
                      onChange={(e) => onChangeInput('age_0_non_tobacco', e.target.value)}
                      value={form_data['age_0_non_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    {nonTobaccoAgeRates.slice(15).map((item) => (
                      <TextField
                        key={item}
                        label={AGE_LABELS[item.replace('_non_tobacco', '')] + ' Non-Tobacco'}
                        onChange={(e) => onChangeInput(item, e.target.value)}
                        value={form_data[item]}
                        type="number"
                        size="small"
                        required
                        InputProps={{
                          inputProps: { min: 0, max: 999999 },
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    ))}
                  </div>
                </>
              )}

              {rate_tier_type === 'banded_5' && (
                <>
                  <div className={classes.PlanConfigForm}>
                    <TextField
                      label={AGE_LABELS['age_0_17'] + ' Tobacco'}
                      onChange={(e) => onChangeInput('age_0_tobacco', e.target.value)}
                      value={form_data['age_0_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    <TextField
                      label={AGE_LABELS['age_18_24'] + ' Tobacco'}
                      onChange={(e) => onChangeInput('age_18_tobacco', e.target.value)}
                      value={form_data['age_18_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    {tobaccoAgeRates
                      .slice(25)
                      .filter((_, index) => index % 5 === 0)
                      .map((item, i) => {
                        const startIndex = 25 + i * 5;
                        const endIndex = Math.min(startIndex + 4, 80);

                        return (
                          <TextField
                            key={item}
                            label={AGE_LABELS[`age_${startIndex}_${endIndex}`]}
                            onChange={(e) => onChangeInput(item, e.target.value)}
                            value={form_data[item]}
                            type="number"
                            size="small"
                            required
                            InputProps={{
                              inputProps: { min: 0, max: 999999 },
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div className={classes.PlanConfigForm} style={{ marginLeft: '15px' }}>
                    <TextField
                      label={AGE_LABELS['age_0_17'] + ' Non-tobacco'}
                      onChange={(e) => onChangeInput('age_0_non_tobacco', e.target.value)}
                      value={form_data['age_0_non_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    <TextField
                      label={AGE_LABELS['age_18_24'] + ' Non-tobacco'}
                      onChange={(e) => onChangeInput('age_18_non_tobacco', e.target.value)}
                      value={form_data['age_18_non_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    {nonTobaccoAgeRates
                      .slice(25)
                      .filter((_, index) => index % 5 === 0)
                      .map((item, i) => {
                        const startIndex = 25 + i * 5;
                        const endIndex = Math.min(startIndex + 4, 80);

                        return (
                          <TextField
                            key={item}
                            label={AGE_LABELS[`age_${startIndex}_${endIndex}`] + ' Non-tobacco'}
                            onChange={(e) => onChangeInput(item, e.target.value)}
                            value={form_data[item]}
                            type="number"
                            size="small"
                            required
                            InputProps={{
                              inputProps: { min: 0, max: 999999 },
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        );
                      })}
                  </div>
                </>
              )}

              {rate_tier_type === 'banded_10' && (
                <>
                  <div className={classes.PlanConfigForm}>
                    <TextField
                      label={AGE_LABELS['age_0_17'] + ' Tobacco'}
                      onChange={(e) => onChangeInput('age_0_tobacco', e.target.value)}
                      value={form_data['age_0_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    <TextField
                      label={AGE_LABELS['age_18_29'] + ' Tobacco'}
                      onChange={(e) => onChangeInput('age_18_tobacco', e.target.value)}
                      value={form_data['age_18_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    {tobaccoAgeRates
                      .slice(30)
                      .filter((_, index) => index % 10 === 0)
                      .map((item, i) => {
                        const startIndex = 30 + i * 10;

                        const endIndex = Math.min(startIndex + 9, 80);

                        return (
                          <TextField
                            key={item}
                            label={AGE_LABELS[`age_${startIndex}_${endIndex}`] + ' Tobacco'}
                            onChange={(e) => onChangeInput(item, e.target.value)}
                            value={form_data[item]}
                            type="number"
                            size="small"
                            required
                            InputProps={{
                              inputProps: { min: 0, max: 999999 },
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        );
                      })}
                  </div>
                  <div className={classes.PlanConfigForm} style={{ marginLeft: '15px' }}>
                    <TextField
                      label={AGE_LABELS['age_0_17'] + ' Non-tobacco'}
                      onChange={(e) => onChangeInput('age_0_non_tobacco', e.target.value)}
                      value={form_data['age_0_non_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    <TextField
                      label={AGE_LABELS['age_18_29'] + ' Non-tobacco'}
                      onChange={(e) => onChangeInput('age_18_non_tobacco', e.target.value)}
                      value={form_data['age_18_non_tobacco']}
                      type="number"
                      size="small"
                      required
                      InputProps={{
                        inputProps: { min: 0, max: 999999 },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    {nonTobaccoAgeRates
                      .slice(30)
                      .filter((_, index) => index % 10 === 0)
                      .map((item, i) => {
                        const startIndex = 30 + i * 10;

                        const endIndex = Math.min(startIndex + 9, 80);

                        return (
                          <TextField
                            key={item}
                            label={AGE_LABELS[`age_${startIndex}_${endIndex}`] + ' Non-tobacco'}
                            onChange={(e) => onChangeInput(item, e.target.value)}
                            value={form_data[item]}
                            type="number"
                            size="small"
                            required
                            InputProps={{
                              inputProps: { min: 0, max: 999999 },
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                        );
                      })}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
