import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearCriticalConfigurationState, setCriticalPlan } from './criticalConfiguration.slice';

import { ERROR } from 'src/constants/errorNames';
import {
  calculateConfigurationAgeRates,
  calculateConfigurationRates,
} from 'src/constants/calculateConfigurationRates';

export const getCriticalPlans = createAsyncThunk(
  'critical-configuration/get-critical-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.criticalConfiguration.getCriticalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getCriticalQuotePlans = createAsyncThunk(
  'critical-configuration/get-critical-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.criticalConfiguration.getCriticalQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getCriticalOffers = createAsyncThunk(
  'critical-configuration/get-critical-offers',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading } = data;
      setIsLoading(true);

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      const userType = thunkApi.getState().app.userType;

      const response = await api.planConfiguration.getMedicalOffers(
        accountId,
        proposalId,
        'critical_illness',
        configId,
      );

      if (userType === 'broker') {
        const groupMembers = thunkApi.getState().groupMembers.groupMembers;

        const brokerOffer = {
          ...brokerConfig,
          issuer_name: 'broker',
          status: 'selected',
        };

        const updatedResponse = [brokerOffer, ...response];

        const calculatedResponse = updatedResponse?.map((item) => {
          if (item?.status !== 'pending') {
            let calculatedRates = {};

            if (item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateConfigurationRates(groupMembers, item, {});
            }

            if (!item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
            }

            return { ...item, calculation: calculatedRates };
          }
          return item;
        });

        return setOffers(calculatedResponse);
      }

      return setOffers(response);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

export const getCriticalQuotes = createAsyncThunk(
  'critical-configuration/get-critical-quotes',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading, read_token } =
        data;
      setIsLoading(true);

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'critical_illness',
        configId,
        read_token,
      );

      const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      const brokerOffer = {
        ...brokerConfig,
        issuer_name: 'broker',
        status: 'selected',
      };

      const updatedResponse = [brokerOffer, ...response];

      const calculatedResponse = updatedResponse?.map((item) => {
        let calculatedRates = {};

        if (item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationRates(groupMembers, item, {});
        }

        if (!item?.rate_tier_type?.includes('tier')) {
          calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
        }

        return { ...item, calculation: calculatedRates };
      });

      return setOffers(calculatedResponse);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

// ISSUER

export const getIssuerCriticalPlans = createAsyncThunk(
  'critical-configuration/get-issuer-critical-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.criticalConfiguration.getCriticalPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createCriticalOffer = createAsyncThunk(
  'critical-configuration/create-critical-offer',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, config_id } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = {
        ...data,
      };

      delete payload.comp_amount;
      delete payload.created_timestamp;

      delete payload.accountId;
      delete payload.proposal_id;
      delete payload.proposalId;
      delete payload.order_id;

      const response = await api.issuer.createCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'critical_illness',
        payload,
      );

      toast('Your critical offer was successfully saved!', { type: 'success' });
      thunkApi.dispatch(getIssuerCriticalPlans({ accountId, proposalId }));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
    }
  },
);

export const updateCriticalOffer = createAsyncThunk(
  'critical-configuration/update-critical-offer',
  async (data, thunkApi) => {
    try {
      const { payload, offerId, read_token, setUpdatedOffer } = data;

      const { accountId, proposalId, config_id, isBroker } = payload;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      delete payload.id;
      delete payload.comp_amount;
      delete payload.issuer_name;
      delete payload.created_timestamp;

      delete payload.accountId;
      delete payload.proposalId;
      delete payload.isBroker;

      const response = await api.issuer.updateCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'critical_illness',
        payload,
        offerId,
        read_token,
      );

      toast('Your critical offer was successfully updated!', { type: 'success' });
      thunkApi.dispatch(getIssuerCriticalPlans({ accountId, proposalId, read_token }));

      if (isBroker) {
        if (setUpdatedOffer) {
          setUpdatedOffer([response]);
        }
      }

      const criticalResponse = await api.criticalConfiguration.getCriticalPlans(
        accountId,
        proposalId,
        read_token,
      );

      thunkApi.dispatch(setCriticalPlan(criticalResponse));

      return response;
    } catch (err) {
      console.error('err', err);
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const deleteCriticalOffer = createAsyncThunk(
  'critical-configuration/delete-critical-offer',
  async (data) => {
    try {
      const { accountId, proposalId, configId, offerId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.issuer.deleteCarrierOffer(
        accountId,
        proposalId,
        'critical_illness',
        configId,
        offerId,
      );
    } catch (err) {
      console.error(err);
    }
  },
);

// CLEAR STATE

export const clearCriticalState = createAsyncThunk('critical/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearCriticalConfigurationState());
});
