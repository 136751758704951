import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import _ from 'lodash';

import { createLifeOffer, deleteLifeOffer, updateLifeOffer } from 'src/store/lifeConfiguration';

import {
  Button,
  DropZone,
  Input,
  Modal,
  ToggleButton,
  SelectOption,
  ExtraRows,
} from 'src/components';

import { companyContributionData } from 'src/features/disability/termDisabilityForm/termDisabilityFormConstants';
import {
  normalizeCompensationAmount,
  normalizeCompensationAmountEdit,
  normalizeCoverageAgeRates,
  normalizeDisabilityRateBasis,
  normalizeRateTierType,
} from 'src/constants/requestProposal';
import { threeDigitsRegex } from 'src/constants/regularExpression';
import { TOOLTIP_NAMES } from 'src/constants/tooltipNames';

import { LifeAgeBandedRates } from 'src/features/life/lifeAgeBandedRates/LifeAgeBandedRates';
import {
  lifeNonTobaccoAllAges,
  lifeTobaccoAllAges,
  lifeUniAllAges,
  normalizeAllAgeNonTobaccoRates,
  normalizeAllAgeTobaccoRates,
  normalizeAllAgeUniRates,
} from 'src/features/life/lifeAgeBandedRates/lifeAllAges';
import {
  lifeNonTobaccoFiveYearAges,
  lifeTobaccoFiveYearAges,
  lifeUniFiveYearAges,
  normalizeLifeNonTobaccoFiveYearAgeRates,
  normalizeLifeTobaccoFiveYearAgeRates,
  normalizeLifeUniFiveYearAgeRates,
} from 'src/features/life/lifeAgeBandedRates/lifeFiveYearAges';
import {
  lifeNonTobaccoTenYearAges,
  lifeTobaccoTenYearAges,
  lifeUniTenYearAges,
  normalizeLifeNonTobaccoTenYearAgeRates,
  normalizeLifeTobaccoTenYearAgeRates,
  normalizeLifeUniTenYearAgeRates,
} from 'src/features/life/lifeAgeBandedRates/lifeTenYearAges';

import classes from './lifeConfigurationForm.module.scss';
import { toast } from 'react-toastify';
import { brokerAgencySelector } from 'src/store/agency';
import { compensationAmountData } from 'src/features/dental/dentalForm/dentalFormConstants';
import { compensationMethodData } from 'src/features/medical/medicalForm/medicalFormConstants';

export const LifeConfigurationForm = (props) => {
  const {
    brokerConfig,
    lifeOffers,
    userId,
    issuerId,
    id,
    proposalId,
    accountId,
    getLifeOffers,
    getLifeQuotes,
  } = props;
  const dispatch = useDispatch();

  const brokerAgency = useSelector(brokerAgencySelector);

  const [lifeConfigs, setLifeConfigs] = useState([]);
  const [originalConfig, setOriginalConfig] = useState({});
  const [selectedConfig, setSelectedConfig] = useState({});
  const [extraRows, setExtraRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedDeleteConfig, setSelectedDeleteConfig] = useState({});

  const [isVisibleContribution, setIsVisibleContribution] = useState(false);
  const [isVisibleDeathBenefit, setIsVisibleDeathBenefit] = useState(false);
  const [isVisibleSelect, setIsVisibleSelect] = useState(false);
  const [isVisibleRateSelect, setIsVisibleRateSelect] = useState(false);
  const [isVisibleCompensationMethod, setIsVisibleCompensationMethod] = useState(false);
  const [isVisibleCompensationAmount, setIsVisibleCompensationAmount] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const [allAgeBandedUniPayload, setAllAgeBandedUniPayload] = useState();
  const [allAgeBandedTobaccoPayload, setAllAgeBandedTobaccoPayload] = useState();
  const [allAgeBandedNonTobaccoPayload, setAllAgeBandedNonTobaccoPayload] = useState();

  const [fiveYearBandedUniPayload, setFiveYearBandeUniPayload] = useState();
  const [fiveYearBandedTobaccoPayload, setFiveYearBandedTobaccoPayload] = useState();
  const [fiveYearBandedNonTobaccoPayload, setFiveYearBandedNonTobaccoPayload] = useState();

  const [tenYearBandedUniPayload, setTenYearBandeUniPayload] = useState();
  const [tenYearBandedTobaccoPayload, setTenYearBandedTobaccoPayload] = useState();
  const [tenYearBandedNonTobaccoPayload, setTenYearBandedNonTobaccoPayload] = useState();

  const originalId = lifeOffers?.find((item) => item.issuer_name)?.user_id;

  const rateBasisOptions = [
    { key: 'earnings', name: 'Earnings Amount' },
    { key: 'benefit', name: 'Benefit Amount' },
  ];

  const tierOptions = [
    { key: '4tier', name: '4 Tier' },
    { key: 'all_ages', name: 'All Ages' },
    { key: 'banded_5', name: '5 Year Banded' },
    { key: 'banded_10', name: '10 Year Banded' },
  ];

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      if (
        allAgeBandedUniPayload ||
        allAgeBandedTobaccoPayload ||
        allAgeBandedNonTobaccoPayload ||
        fiveYearBandedUniPayload ||
        fiveYearBandedTobaccoPayload ||
        fiveYearBandedNonTobaccoPayload ||
        tenYearBandedUniPayload ||
        tenYearBandedTobaccoPayload ||
        tenYearBandedNonTobaccoPayload
      ) {
        setIsEdited(true);
      }
    }
  }, [
    allAgeBandedNonTobaccoPayload,
    allAgeBandedTobaccoPayload,
    allAgeBandedUniPayload,
    fiveYearBandedNonTobaccoPayload,
    fiveYearBandedTobaccoPayload,
    fiveYearBandedUniPayload,
    selectedConfig,
    tenYearBandedNonTobaccoPayload,
    tenYearBandedTobaccoPayload,
    tenYearBandedUniPayload,
  ]);

  useEffect(() => {
    setLifeConfigs([...lifeOffers]);
  }, [lifeOffers]);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig, selectedConfig));
  }, [originalConfig, selectedConfig]);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig?.custom_fields, extraRows));
  }, [extraRows, originalConfig?.custom_fields]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      const selectedLifeConfig = lifeConfigs?.find((item) => item.id === selectedConfig?.id);
      if (selectedLifeConfig?.custom_fields?.length) {
        for (const originalField of selectedLifeConfig?.custom_fields) {
          for (const carrierField of extraRows) {
            if (originalField.id === carrierField.id) {
              if (originalField.name !== carrierField.name) {
                setIsEdited(true);
              }

              if (originalField.value !== carrierField.value) {
                setIsEdited(true);
              }
            }
          }
        }
      }
    }
  }, [lifeConfigs, selectedConfig, extraRows]);

  const onClickAddOffer = useCallback(() => {
    let death_benefit_key;
    if (brokerConfig?.death_benefit === '1xsalary') {
      death_benefit_key = '1xsalary';
    }
    if (brokerConfig?.death_benefit === '2xsalary') {
      death_benefit_key = '2xsalary';
    }
    if (brokerConfig?.death_benefit !== '1xsalary' && brokerConfig?.death_benefit !== '2xsalary') {
      death_benefit_key = 'other';
    }

    const payload = {
      ...brokerConfig,
      config_id: brokerConfig?.id,
      id: uuid(),
      issuer_id: issuerId,
      death_benefit_key,
      rate_basis: 'benefit',
      rate_ch_non_tobacco: null,
      rate_ch_tobacco: null,
      rate_ch_uni_tobacco: null,
      rate_ee_non_tobacco: null,
      rate_ee_tobacco: null,
      rate_ee_uni_tobacco: null,
      rate_fam_non_tobacco: null,
      rate_fam_tobacco: null,
      rate_fam_uni_tobacco: null,
      rate_sp_non_tobacco: null,
      rate_sp_tobacco: null,
      rate_sp_uni_tobacco: null,
      // 2 cases - 1 for broker side, second one for carrier side
      user_id: userId !== originalId ? originalId : userId,
      comp_amount: normalizeCompensationAmountEdit(brokerConfig),
    };

    setSelectedConfig(payload);
    setLifeConfigs((state) => [...state, payload]);
  }, [brokerConfig, issuerId, originalId, userId]);

  const onClickEditConfig = useCallback(
    (item) => () => {
      const originalConfig = lifeConfigs?.find((offer) => offer.id === item.id);

      let death_benefit_key;
      if (item?.death_benefit === '1xsalary') {
        death_benefit_key = '1xsalary';
      }
      if (item?.death_benefit === '2xsalary') {
        death_benefit_key = '2xsalary';
      }
      if (item?.death_benefit !== '1xsalary' && item?.death_benefit !== '2xsalary') {
        death_benefit_key = 'other';
      }

      const originalPayload = {
        ...originalConfig,
        death_benefit_key,
        comp_amount: normalizeCompensationAmountEdit(originalConfig),
      };

      const clonedCustomFields = _.cloneDeep(item?.custom_fields);
      const selectedPayload = {
        ...item,
        custom_fields: clonedCustomFields,
        death_benefit_key: death_benefit_key,
        rate_ch_non_tobacco: item.rate_ch_non_tobacco ? item.rate_ch_non_tobacco : 0,
        rate_ch_tobacco: item.rate_ch_tobacco ? item.rate_ch_tobacco : 0,
        rate_ch_uni_tobacco: item.rate_ch_uni_tobacco ? item.rate_ch_uni_tobacco : 0,
        rate_ee_non_tobacco: item.rate_ee_non_tobacco ? item.rate_ee_non_tobacco : 0,
        rate_ee_tobacco: item.rate_ee_tobacco ? item.rate_ee_tobacco : 0,
        rate_ee_uni_tobacco: item.rate_ee_uni_tobacco ? item.rate_ee_uni_tobacco : 0,
        rate_fam_non_tobacco: item.rate_fam_non_tobacco ? item.rate_fam_non_tobacco : 0,
        rate_fam_tobacco: item.rate_fam_tobacco ? item.rate_fam_tobacco : 0,
        rate_fam_uni_tobacco: item.rate_fam_uni_tobacco ? item.rate_fam_uni_tobacco : 0,
        rate_per_unit: item.rate_per_unit ? item.rate_per_unit : 0,
        rate_sp_non_tobacco: item.rate_sp_non_tobacco ? item.rate_sp_non_tobacco : 0,
        rate_sp_tobacco: item.rate_sp_tobacco ? item.rate_sp_tobacco : 0,
        rate_sp_uni_tobacco: item.rate_sp_uni_tobacco ? item.rate_sp_uni_tobacco : 0,
        comp_amount: normalizeCompensationAmountEdit(item),
      };

      setOriginalConfig(originalPayload);
      setSelectedConfig(selectedPayload);
      setExtraRows(clonedCustomFields ?? []);
    },
    [lifeConfigs],
  );

  const handleOpenCarrierSelect = useCallback(
    (setCarrierSelectValue) => () => {
      setCarrierSelectValue((prev) => !prev);
    },
    [],
  );

  const onSelectOption = useCallback(
    (value, target, setIsVisible) => () => {
      let payload = {};
      setIsVisible(false);

      if (target === 'death_benefit') {
        let death_benefit_key;
        if (value === '1xsalary') {
          death_benefit_key = '1xsalary';
        }
        if (value === '2xsalary') {
          death_benefit_key = '2xsalary';
        }
        if (value !== '1xsalary' && value !== '2xsalary') {
          death_benefit_key = 'other';
        }

        payload = {
          ...selectedConfig,
          [target]: value,
          death_benefit_key,
        };

        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onChangePercentage = useCallback((event, target) => {
    if (target === 'comp_amount_pct') {
      setSelectedConfig((prev) => ({
        ...prev,
        comp_pppm_dollar: null,
        comp_pepm_dollar: null,
      }));
    }

    if (event.target.value.length > 3) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: Number(event.target.value.slice(0, 3)),
      }));
    }
    if (event.target.value < 0) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 0,
      }));
    }
    if (event.target.value > 100) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 100,
      }));
    }

    setSelectedConfig((prev) => ({
      ...prev,
      [target]: event.target.value,
    }));
  }, []);

  const onChangePepmPppmValue = useCallback((event, target) => {
    if (target === 'comp_pepm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pppm_dollar: null }));
    }

    if (target === 'comp_pppm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pepm_dollar: null }));
    }

    if (event.target.value.includes('.')) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 4) }));
    }

    if (event.target.value > 3) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 3) }));
    }

    setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value }));
  }, []);

  const onChangeInputValue = useCallback(
    (event, target, maxRange) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };

        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value > maxRange ? maxRange : event.target.value,
      };

      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onFocusInput = (event, target) => {
    if (Number(event.target.value) === 0) {
      const payload = {
        ...selectedConfig,
        [target]: '',
      };
      return setSelectedConfig(payload);
    }
  };

  const onBlurInput = (event, target) => {
    if (!event.target.value) {
      const payload = {
        ...selectedConfig,
        [target]: 0,
      };
      return setSelectedConfig(payload);
    }

    if (
      !event.target.value.includes('.') &&
      (event.target.value[0] === '0' || event.target.value[0] === 0)
    ) {
      const payload = {
        ...selectedConfig,
        [target]: event.target.value.slice(1) ? event.target.value.slice(1) : 0,
      };
      return setSelectedConfig(payload);
    }
  };

  const onClickCancel = useCallback(() => {
    setOriginalConfig({});
    setSelectedConfig({});
    setLifeConfigs(lifeOffers);
  }, [lifeOffers]);

  const onChangeRateValue = useCallback(
    (event, target) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };
        return setSelectedConfig(payload);
      }

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...selectedConfig,
          [target]: event.target.value.slice(0, event.target.value.length - 1),
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const [brokerAgeBandedRates, setBrokerAgebandedRates] = useState();

  useEffect(() => {
    let payload = {};

    for (const item of Object.keys(brokerConfig)) {
      if (item.includes('age')) {
        payload = {
          ...payload,
          [item]: brokerConfig[item],
        };
      }

      setBrokerAgebandedRates(payload);
    }
  }, [brokerConfig]);

  const deathBenefitData = [
    { name: '1x Salary', key: '1xsalary' },
    { name: '2x Salary', key: '2xsalary' },
    {
      name: 'Other: ',
      key: 'other',
      type: 'numeric',
      onChange: (event) => onChangeInputValue(event, 'death_benefit'),
      value: selectedConfig?.death_benefit,
    },
  ];

  const onSaveSelectedConfig = useCallback(async () => {
    const isOfferExist = !_.isEmpty(lifeOffers?.find((item) => item?.id === selectedConfig?.id));

    const data = {
      issuer_id: issuerId,
      config_id: id,
      proposalId: proposalId,
      accountId: accountId,
    };

    if (!isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (payload.rate_tier_type === 'all_ages' && payload.is_uni_tobacco) {
        if (allAgeBandedUniPayload) {
          const allAgesUniPayload = normalizeAllAgeUniRates(allAgeBandedUniPayload);

          payload = {
            ...payload,
            ...allAgesUniPayload,
          };
        }
      }
      if (payload.rate_tier_type === 'banded_5' && payload.is_uni_tobacco) {
        if (fiveYearBandedUniPayload) {
          const fiveYearUniPayload = normalizeLifeUniFiveYearAgeRates(fiveYearBandedUniPayload);

          payload = {
            ...payload,
            ...fiveYearUniPayload,
          };
        }
      }
      if (payload.rate_tier_type === 'banded_10' && payload.is_uni_tobacco) {
        if (tenYearBandedUniPayload) {
          const tenYearUniPayload = normalizeLifeUniTenYearAgeRates(tenYearBandedUniPayload);

          payload = {
            ...payload,
            ...tenYearUniPayload,
          };
        }
      }

      if (payload.rate_tier_type === 'all_ages' && !payload.is_uni_tobacco) {
        if (allAgeBandedTobaccoPayload) {
          const allAgeTobaccoPayload = normalizeAllAgeTobaccoRates(allAgeBandedTobaccoPayload);

          payload = {
            ...payload,
            ...allAgeTobaccoPayload,
          };
        }

        if (allAgeBandedNonTobaccoPayload) {
          const allAgeNonTobaccoPayload = normalizeAllAgeNonTobaccoRates(
            allAgeBandedNonTobaccoPayload,
          );

          payload = {
            ...payload,
            ...allAgeNonTobaccoPayload,
          };
        }
      }

      if (payload.rate_tier_type === 'banded_5' && !payload.is_uni_tobacco) {
        if (fiveYearBandedTobaccoPayload) {
          const fiveYearTobaccoPayload = normalizeLifeTobaccoFiveYearAgeRates(
            fiveYearBandedTobaccoPayload,
          );

          payload = {
            ...payload,
            ...fiveYearTobaccoPayload,
          };
        }
        if (fiveYearBandedNonTobaccoPayload) {
          const fiveYearNonTobaccoPayload = normalizeLifeNonTobaccoFiveYearAgeRates(
            fiveYearBandedNonTobaccoPayload,
          );

          payload = {
            ...payload,
            ...fiveYearNonTobaccoPayload,
          };
        }
      }
      if (payload.rate_tier_type === 'banded_10' && !payload.is_uni_tobacco) {
        if (tenYearBandedTobaccoPayload) {
          const tenYearTobaccoPayload = normalizeLifeTobaccoTenYearAgeRates(
            tenYearBandedTobaccoPayload,
          );
          payload = {
            ...payload,
            ...tenYearTobaccoPayload,
          };
        }

        if (tenYearBandedNonTobaccoPayload) {
          const tenYearNonTobaccoPayload = normalizeLifeNonTobaccoTenYearAgeRates(
            tenYearBandedNonTobaccoPayload,
          );
          payload = {
            ...payload,
            ...tenYearNonTobaccoPayload,
          };
        }
      }

      delete payload.calculation;

      await dispatch(createLifeOffer(payload));

      if (getLifeQuotes) {
        await getLifeQuotes();
      }
    }

    if (isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (payload.rate_tier_type === 'all_ages' && payload.is_uni_tobacco) {
        if (allAgeBandedUniPayload) {
          const allAgesUniPayload = normalizeAllAgeUniRates(allAgeBandedUniPayload);

          payload = {
            ...payload,
            ...allAgesUniPayload,
          };
        }
      }
      if (payload.rate_tier_type === 'banded_5' && payload.is_uni_tobacco) {
        if (fiveYearBandedUniPayload) {
          const fiveYearUniPayload = normalizeLifeUniFiveYearAgeRates(fiveYearBandedUniPayload);

          payload = {
            ...payload,
            ...fiveYearUniPayload,
          };
        }
      }
      if (payload.rate_tier_type === 'banded_10' && payload.is_uni_tobacco) {
        if (tenYearBandedUniPayload) {
          const tenYearUniPayload = normalizeLifeUniTenYearAgeRates(tenYearBandedUniPayload);

          payload = {
            ...payload,
            ...tenYearUniPayload,
          };
        }
      }

      if (payload.rate_tier_type === 'all_ages' && !payload.is_uni_tobacco) {
        if (allAgeBandedTobaccoPayload) {
          const allAgeTobaccoPayload = normalizeAllAgeTobaccoRates(allAgeBandedTobaccoPayload);

          payload = {
            ...payload,
            ...allAgeTobaccoPayload,
          };
        }

        if (allAgeBandedNonTobaccoPayload) {
          const allAgeNonTobaccoPayload = normalizeAllAgeNonTobaccoRates(
            allAgeBandedNonTobaccoPayload,
          );

          payload = {
            ...payload,
            ...allAgeNonTobaccoPayload,
          };
        }
      }

      if (payload.rate_tier_type === 'banded_5' && !payload.is_uni_tobacco) {
        if (fiveYearBandedTobaccoPayload) {
          const fiveYearTobaccoPayload = normalizeLifeTobaccoFiveYearAgeRates(
            fiveYearBandedTobaccoPayload,
          );

          payload = {
            ...payload,
            ...fiveYearTobaccoPayload,
          };
        }
        if (fiveYearBandedNonTobaccoPayload) {
          const fiveYearNonTobaccoPayload = normalizeLifeNonTobaccoFiveYearAgeRates(
            fiveYearBandedNonTobaccoPayload,
          );

          payload = {
            ...payload,
            ...fiveYearNonTobaccoPayload,
          };
        }
      }
      if (payload.rate_tier_type === 'banded_10' && !payload.is_uni_tobacco) {
        if (tenYearBandedTobaccoPayload) {
          const tenYearTobaccoPayload = normalizeLifeTobaccoTenYearAgeRates(
            tenYearBandedTobaccoPayload,
          );
          payload = {
            ...payload,
            ...tenYearTobaccoPayload,
          };
        }

        if (tenYearBandedNonTobaccoPayload) {
          const tenYearNonTobaccoPayload = normalizeLifeNonTobaccoTenYearAgeRates(
            tenYearBandedNonTobaccoPayload,
          );
          payload = {
            ...payload,
            ...tenYearNonTobaccoPayload,
          };
        }
      }

      delete payload.author;
      delete payload.calculation;

      await dispatch(updateLifeOffer({ payload, offerId: selectedConfig?.id }));

      if (getLifeQuotes) {
        await getLifeQuotes();
      }
    }
  }, [
    lifeOffers,
    issuerId,
    id,
    proposalId,
    accountId,
    selectedConfig,
    extraRows,
    dispatch,
    getLifeQuotes,
    allAgeBandedUniPayload,
    fiveYearBandedUniPayload,
    tenYearBandedUniPayload,
    allAgeBandedTobaccoPayload,
    allAgeBandedNonTobaccoPayload,
    fiveYearBandedTobaccoPayload,
    fiveYearBandedNonTobaccoPayload,
    tenYearBandedTobaccoPayload,
    tenYearBandedNonTobaccoPayload,
  ]);

  const onAddExtraRow = useCallback(() => {
    if (!_.isEmpty(brokerAgency) && brokerAgency.status !== 'active') {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    if (_.isEmpty(brokerAgency)) {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    const payload = {
      id: uuid(),
      title: '',
      value: '',
    };
    setExtraRows((state) => [...state, payload]);
  }, [extraRows.length, brokerAgency]);

  const isVisibleButtons = useCallback((status) => {
    if (status === 'approved' || status === 'selected') {
      return false;
    }
    return true;
  }, []);

  const onClickDelete = useCallback(
    (item) => async () => {
      const payload = {
        accountId,
        proposalId,
        configId: item.config_id,
        offerId: item.id,
      };

      setIsDeleteModal(true);
      setSelectedDeleteConfig(payload);
    },
    [accountId, proposalId],
  );

  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteModal(false);
    setSelectedDeleteConfig({});
  }, []);

  const onConfirmDelete = useCallback(async () => {
    await dispatch(deleteLifeOffer(selectedDeleteConfig));
    await getLifeOffers();
  }, [dispatch, getLifeOffers, selectedDeleteConfig]);

  const onRejectOffer = useCallback(async () => {
    const offer = lifeOffers[0];

    const payload = {
      proposalId,
      accountId,
      ...offer,
      status: 'declined',
    };

    if (!_.isEmpty(offer)) {
      delete payload?.rep;
      delete payload.issuer_name;
      delete payload.calculation;
      const offerId = offer.id;

      await dispatch(updateLifeOffer({ payload, offerId }));

      if (getLifeQuotes) {
        await getLifeQuotes();
      }
    }
  }, [dispatch, accountId, proposalId, lifeOffers, getLifeQuotes]);

  const onToggleTobacco = useCallback(() => {
    const payload = {
      ...selectedConfig,
      is_uni_tobacco: !selectedConfig.is_uni_tobacco,
    };
    setSelectedConfig(payload);
  }, [selectedConfig]);

  const isDeclined = !_.isEmpty(lifeConfigs?.find((item) => item.status === 'declined'));
  const brokerTierType = brokerConfig?.rate_tier_type === '4tier';
  const offerTierType = selectedConfig?.rate_tier_type === '4tier';

  const compensationAmount = compensationAmountData(
    selectedConfig,
    onChangePercentage,
    onChangePepmPppmValue,
  );

  return (
    <>
      {isDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseDeleteModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Are you sure you want to delete this offer?
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={onCloseDeleteModal} />
              <Button type="danger" title="Yes" onClick={onConfirmDelete} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.LifeConfigurationContentWrapper}>
        {!isDeclined && _.isEmpty(selectedConfig) && (
          <div className={classes.LifeConfigurationContentBodyOffer}>
            <Button
              title="Add Offer"
              type="primary"
              onClick={onClickAddOffer}
              isDisabled={!_.isEmpty(selectedConfig)}
            />
          </div>
        )}
        <div className={classes.LifeConfigurationContentBody}>
          <div className={classes.LifeConfigurationContentTier}>
            <table className={classes.LifeConfigurationTierTable}>
              <thead className={classes.LifeConfigurationTierTableHead}>
                <tr>
                  <th className={classes.LifeConfigurationTitleMain}>Plan Configuration Form</th>
                </tr>
                <tr>
                  <th>Employer Contribution</th>
                  <th>Death Benefit</th>
                  <th>Compensation Method</th>
                  <th>Compensation Amount</th>
                  <th>Current Rate</th>
                  <th>Per unit</th>
                  <th>Rate Basis</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.LifeConfigurationTierTableBroker}>
                  <th className={classes.LifeConfigurationTitle}>Broker Request</th>
                  <td>{brokerConfig?.contribution_type}</td>
                  <td>${brokerConfig?.death_benefit}</td>
                  <td>{brokerConfig?.comp_method}</td>
                  <td>{normalizeCompensationAmount(brokerConfig)}</td>
                  <td>{brokerConfig?.is_current_rate ? 'Yes' : 'No'}</td>
                  <td>${brokerConfig?.rate_per_unit}</td>
                  <td>{normalizeDisabilityRateBasis(brokerConfig?.rate_basis)}</td>
                </tr>
                {lifeConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <th className={classes.LifeConfigurationTitle}>Carrier Reply</th>
                      <td>
                        {!isEditMode ? (
                          item.contribution_type
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={companyContributionData}
                            selectedOption={selectedConfig?.contribution_type}
                            isVisibleSelect={isVisibleContribution}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleContribution)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'contribution_type', setIsVisibleContribution)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `$${item.death_benefit}`
                        ) : (
                          <>
                            <SelectOption
                              height="30px"
                              width="142px"
                              options={deathBenefitData}
                              selectedOption={selectedConfig?.death_benefit_key}
                              isVisibleSelect={isVisibleDeathBenefit}
                              handleOpenSelect={handleOpenCarrierSelect(setIsVisibleDeathBenefit)}
                              onSelectOption={(event) =>
                                onSelectOption(event, 'death_benefit', setIsVisibleDeathBenefit)
                              }
                              isAbsolute
                            />
                          </>
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          item.comp_method
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationMethodData}
                            selectedOption={selectedConfig?.comp_method}
                            isVisibleSelect={isVisibleCompensationMethod}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationMethod,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_method', setIsVisibleCompensationMethod)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          normalizeCompensationAmount(item)
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationAmount}
                            selectedOption={selectedConfig?.comp_amount}
                            isVisibleSelect={isVisibleCompensationAmount}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationAmount,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_amount', setIsVisibleCompensationAmount)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>{item?.is_current_rate ? 'Yes' : 'No'}</td>
                      {!isEditMode ? (
                        <>
                          <td>${item?.rate_per_unit}</td>
                          <td>{normalizeDisabilityRateBasis(item?.rate_basis)}</td>
                        </>
                      ) : (
                        <>
                          <td>
                            <Input
                              type="number"
                              value={selectedConfig?.rate_per_unit}
                              onChange={(event) => onChangeInputValue(event, 'rate_per_unit')}
                              onFocus={(event) => onFocusInput(event, 'rate_per_unit')}
                              onBlur={(event) => onBlurInput(event, 'rate_per_unit')}
                              isDisabled={!isEditMode}
                            />
                          </td>
                          <td>
                            <SelectOption
                              height="30px"
                              width="142px"
                              options={rateBasisOptions}
                              selectedOption={selectedConfig?.rate_basis}
                              isVisibleSelect={isVisibleSelect}
                              handleOpenSelect={handleOpenCarrierSelect(setIsVisibleSelect)}
                              onSelectOption={(event) =>
                                onSelectOption(event, 'rate_basis', setIsVisibleSelect)
                              }
                              isDisabled={!isEditMode}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.LifeConfigurationContentTier}>
            <table className={classes.LifeConfigurationTierTable}>
              <thead className={classes.LifeConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Custom Fields</th>
                </tr>
                <tr>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.title}</span>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className={classes.LifeConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.value}</span>
                    </td>
                  ))}
                </tr>
                {lifeConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      {!isEditMode ? (
                        item.custom_fields?.map((item) => (
                          <td key={item.id}>
                            <span>{item.value}</span>
                          </td>
                        ))
                      ) : (
                        <div className={classes.ExtraRowButtonWrapper}>
                          <ExtraRows
                            extraRows={extraRows}
                            setExtraRows={setExtraRows}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            isDisabled={!isEditMode}
                          />

                          <div className={classes.ExtraRowButton}>
                            <Button
                              title="Add Extra Row"
                              type="secondary"
                              onClick={onAddExtraRow}
                              isDisabled={!isEditMode}
                            />

                            <Tooltip title={TOOLTIP_NAMES.MAX_ROWS}>
                              <i className="fas fa-info-circle" />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.LifeConfigurationContentTier}>
            <table className={classes.LifeConfigurationTierTable}>
              <thead className={classes.LifeConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Rates</th>
                </tr>
                <tr>
                  <th className={classes.TierTypeRow}>Rate Tier Type</th>
                </tr>
                {(brokerTierType || offerTierType) && (
                  <tr>
                    <th></th>
                    <th>Employee</th>
                    <th>Spouse</th>
                    <th>Children</th>
                    <th>Family</th>
                  </tr>
                )}
              </thead>
              <tbody>
                <tr className={classes.LifeConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                  <th className={classes.TierTypeRow}>
                    {normalizeRateTierType(brokerConfig?.rate_tier_type)}
                  </th>
                  <th>{brokerConfig?.is_uni_tobacco ? 'Uni-Tobacco' : 'Tobacco / Non-Tobacco'}</th>

                  <div className={classes.AgeBandedRates}>
                    <LifeAgeBandedRates
                      tierType={brokerConfig?.rate_tier_type}
                      ageBandedRates={brokerAgeBandedRates}
                      isEditMode={false}
                      isIssuer={true}
                      isUniTobacco={brokerConfig?.is_uni_tobacco}
                    />
                  </div>

                  {brokerTierType && (brokerTierType || offerTierType) && (
                    <>
                      {brokerConfig?.is_uni_tobacco && (
                        <>
                          <td>
                            {brokerConfig?.rate_ee_uni_tobacco &&
                              `$${brokerConfig?.rate_ee_uni_tobacco}`}
                          </td>
                          <td>
                            {brokerConfig?.rate_sp_uni_tobacco &&
                              `$${brokerConfig?.rate_sp_uni_tobacco}`}
                          </td>
                          <td>
                            {brokerConfig?.rate_ch_uni_tobacco &&
                              `$${brokerConfig?.rate_ch_uni_tobacco}`}
                          </td>
                          <td>
                            {brokerConfig?.rate_fam_uni_tobacco &&
                              `$${brokerConfig?.rate_fam_uni_tobacco}`}
                          </td>
                        </>
                      )}
                      {!brokerConfig?.is_uni_tobacco && (
                        <>
                          <td>
                            {brokerConfig?.rate_ee_tobacco && `$${brokerConfig?.rate_ee_tobacco}`} /
                            {brokerConfig?.rate_ee_non_tobacco &&
                              `$${brokerConfig?.rate_ee_non_tobacco}`}
                          </td>
                          <td>
                            {brokerConfig?.rate_sp_tobacco && `$${brokerConfig?.rate_sp_tobacco}`} /
                            {brokerConfig?.rate_sp_non_tobacco &&
                              `$${brokerConfig?.rate_sp_non_tobacco}`}
                          </td>
                          <td>
                            {brokerConfig?.rate_ch_tobacco && `$${brokerConfig?.rate_ch_tobacco}`} /
                            {brokerConfig?.rate_ch_non_tobacco &&
                              `$${brokerConfig?.rate_ch_non_tobacco}`}
                          </td>
                          <td>
                            {brokerConfig?.rate_fam_tobacco && `$${brokerConfig?.rate_fam_tobacco}`}{' '}
                            /
                            {brokerConfig?.rate_fam_non_tobacco &&
                              `$${brokerConfig?.rate_fam_non_tobacco}`}
                          </td>
                        </>
                      )}
                    </>
                  )}
                  <td className={classes.EmptyDropZoneRow}>
                    <DropZone
                      label="Current Benefits Summary"
                      maxFiles={100}
                      path={`/proposals/${proposalId}/uploads/broker/plan-configs/${id}/`}
                      isDisabled={true}
                    />
                  </td>
                </tr>
                {lifeConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;
                  const isUniTobacco = isEditMode
                    ? selectedConfig?.is_uni_tobacco
                    : item?.is_uni_tobacco;

                  const isConfigTierType = item?.rate_tier_type === '4tier';
                  const isSelectedConfigTierType = selectedConfig?.rate_tier_type === '4tier';

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}>
                        <div className={classes.LifeConfigurationToggle}>
                          <ToggleButton
                            titleOff="Uni-tobacco"
                            titleOn="Tobacco/Non-tobacco"
                            isToggle={!isUniTobacco}
                            onClickToggle={onToggleTobacco}
                            isDisabled={!isEditMode}
                          />
                        </div>
                      </td>
                      <td className={classes.TierTypeRow}>
                        {!isEditMode ? (
                          normalizeRateTierType(item.rate_tier_type)
                        ) : (
                          <SelectOption
                            label="Rate Tier Type"
                            width="140px"
                            options={tierOptions}
                            selectedOption={selectedConfig?.rate_tier_type}
                            isVisibleSelect={isVisibleRateSelect}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleRateSelect)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'rate_tier_type', setIsVisibleRateSelect)
                            }
                          />
                        )}
                      </td>
                      <th>{isUniTobacco ? 'Uni-tobacco' : 'Tobacco / Non-Tobacco'}</th>
                      <div className={classes.AgeBandedRates}>
                        <LifeAgeBandedRates
                          isUniTobacco={isUniTobacco}
                          tierType={
                            !isEditMode ? item?.rate_tier_type : selectedConfig?.rate_tier_type
                          }
                          ageBandedRates={
                            !isEditMode
                              ? normalizeCoverageAgeRates(item)
                              : normalizeCoverageAgeRates(selectedConfig)
                          }
                          allAgeBandedUni={
                            !isEditMode ? lifeUniAllAges(item) : allAgeBandedUniPayload
                          }
                          setAllAgeBandeUniPayload={setAllAgeBandedUniPayload}
                          allAgeBandedTobacco={
                            !isEditMode ? lifeTobaccoAllAges(item) : allAgeBandedTobaccoPayload
                          }
                          setAllAgeBandedTobaccoPayload={setAllAgeBandedTobaccoPayload}
                          allAgeBandedNonTobacco={
                            !isEditMode
                              ? lifeNonTobaccoAllAges(item)
                              : allAgeBandedNonTobaccoPayload
                          }
                          setAllAgeBandedNonTobaccoPayload={setAllAgeBandedNonTobaccoPayload}
                          fiveYearBandedUni={
                            !isEditMode ? lifeUniFiveYearAges(item) : fiveYearBandedUniPayload
                          }
                          setFiveYearBandeUniPayload={setFiveYearBandeUniPayload}
                          fiveYearBandedTobacco={
                            !isEditMode
                              ? lifeTobaccoFiveYearAges(item)
                              : fiveYearBandedTobaccoPayload
                          }
                          setFiveYearBandedTobaccoPayload={setFiveYearBandedTobaccoPayload}
                          fiveYearBandedNonTobacco={
                            !isEditMode
                              ? lifeNonTobaccoFiveYearAges(item)
                              : fiveYearBandedNonTobaccoPayload
                          }
                          setFiveYearBandedNonTobaccoPayload={setFiveYearBandedNonTobaccoPayload}
                          tenYearBandeUni={
                            !isEditMode ? lifeUniTenYearAges(item) : tenYearBandedUniPayload
                          }
                          setTenYearBandeUniPayload={setTenYearBandeUniPayload}
                          tenYearBandedTobacco={
                            !isEditMode ? lifeTobaccoTenYearAges(item) : tenYearBandedTobaccoPayload
                          }
                          setTenYearBandedTobaccoPayload={setTenYearBandedTobaccoPayload}
                          tenYearBandedNonTobacco={
                            !isEditMode
                              ? lifeNonTobaccoTenYearAges(item)
                              : tenYearBandedNonTobaccoPayload
                          }
                          setTenYearBandedNonTobaccoPayload={setTenYearBandedNonTobaccoPayload}
                          isEditMode={isEditMode}
                          isIssuer={true}
                        />
                      </div>

                      {((isConfigTierType && !isEditMode) || isSelectedConfigTierType) && (
                        <>
                          {isUniTobacco && (
                            <>
                              <td>
                                {!isEditMode ? (
                                  (item?.rate_ee_uni_tobacco || item?.rate_ee_uni_tobacco === 0) &&
                                  `$${item?.rate_ee_uni_tobacco}`
                                ) : (
                                  <Input
                                    value={selectedConfig?.rate_ee_uni_tobacco}
                                    onChange={(event) =>
                                      onChangeRateValue(event, 'rate_ee_uni_tobacco')
                                    }
                                    onFocus={(event) => onFocusInput(event, 'rate_ee_uni_tobacco')}
                                    onBlur={(event) => onBlurInput(event, 'rate_ee_uni_tobacco')}
                                    type="number"
                                  />
                                )}
                              </td>
                              <td>
                                {!isEditMode ? (
                                  (item?.rate_sp_uni_tobacco || item?.rate_sp_uni_tobacco === 0) &&
                                  `$${item?.rate_sp_uni_tobacco}`
                                ) : (
                                  <Input
                                    value={selectedConfig?.rate_sp_uni_tobacco}
                                    onChange={(event) =>
                                      onChangeRateValue(event, 'rate_sp_uni_tobacco')
                                    }
                                    onFocus={(event) => onFocusInput(event, 'rate_sp_uni_tobacco')}
                                    onBlur={(event) => onBlurInput(event, 'rate_sp_uni_tobacco')}
                                    type="number"
                                  />
                                )}
                              </td>
                              <td>
                                {!isEditMode ? (
                                  (item?.rate_ch_uni_tobacco || item?.rate_ch_uni_tobacco === 0) &&
                                  `$${item?.rate_ch_uni_tobacco}`
                                ) : (
                                  <Input
                                    value={selectedConfig?.rate_ch_uni_tobacco}
                                    onChange={(event) =>
                                      onChangeRateValue(event, 'rate_ch_uni_tobacco')
                                    }
                                    onFocus={(event) => onFocusInput(event, 'rate_ch_uni_tobacco')}
                                    onBlur={(event) => onBlurInput(event, 'rate_ch_uni_tobacco')}
                                    type="number"
                                  />
                                )}
                              </td>
                              <td>
                                {!isEditMode ? (
                                  (item?.rate_fam_uni_tobacco ||
                                    item?.rate_fam_uni_tobacco === 0) &&
                                  `$${item?.rate_fam_uni_tobacco}`
                                ) : (
                                  <Input
                                    value={selectedConfig?.rate_fam_uni_tobacco}
                                    onChange={(event) =>
                                      onChangeRateValue(event, 'rate_fam_uni_tobacco')
                                    }
                                    onFocus={(event) => onFocusInput(event, 'rate_fam_uni_tobacco')}
                                    onBlur={(event) => onBlurInput(event, 'rate_fam_uni_tobacco')}
                                    type="number"
                                  />
                                )}
                              </td>
                            </>
                          )}

                          {!isUniTobacco && (
                            <>
                              <td className={classes.TobaccoInput}>
                                {!isEditMode ? (
                                  <>
                                    {(item?.rate_ee_tobacco || item?.rate_ee_tobacco === 0) &&
                                      `$${item?.rate_ee_tobacco}`}
                                    /
                                    {(item?.rate_ee_non_tobacco ||
                                      item?.rate_ee_non_tobacco === 0) &&
                                      `$${item?.rate_ee_non_tobacco}`}
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      value={selectedConfig?.rate_ee_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_ee_tobacco')
                                      }
                                      onFocus={(event) => onFocusInput(event, 'rate_ee_tobacco')}
                                      onBlur={(event) => onBlurInput(event, 'rate_ee_tobacco')}
                                      type="number"
                                    />
                                    <Input
                                      value={selectedConfig?.rate_ee_non_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_ee_non_tobacco')
                                      }
                                      onFocus={(event) =>
                                        onFocusInput(event, 'rate_ee_non_tobacco')
                                      }
                                      onBlur={(event) => onBlurInput(event, 'rate_ee_non_tobacco')}
                                      type="number"
                                    />
                                  </>
                                )}
                              </td>
                              <td className={classes.TobaccoInput}>
                                {!isEditMode ? (
                                  <>
                                    {(item?.rate_sp_tobacco || item?.rate_sp_tobacco === 0) &&
                                      `$${item?.rate_sp_tobacco}`}
                                    /
                                    {(item?.rate_sp_non_tobacco ||
                                      item?.rate_sp_non_tobacco === 0) &&
                                      `$${item?.rate_sp_non_tobacco}`}
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      value={selectedConfig?.rate_sp_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_sp_tobacco')
                                      }
                                      onFocus={(event) => onFocusInput(event, 'rate_sp_tobacco')}
                                      onBlur={(event) => onBlurInput(event, 'rate_sp_tobacco')}
                                      type="number"
                                    />
                                    <Input
                                      value={selectedConfig?.rate_sp_non_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_sp_non_tobacco')
                                      }
                                      onFocus={(event) =>
                                        onFocusInput(event, 'rate_sp_non_tobacco')
                                      }
                                      onBlur={(event) => onBlurInput(event, 'rate_sp_non_tobacco')}
                                      type="number"
                                    />
                                  </>
                                )}
                              </td>
                              <td className={classes.TobaccoInput}>
                                {!isEditMode ? (
                                  <>
                                    {(item?.rate_ch_tobacco || item?.rate_ch_tobacco === 0) &&
                                      `$${item?.rate_ch_tobacco}`}
                                    /
                                    {(item?.rate_ch_non_tobacco ||
                                      item?.rate_ch_non_tobacco === 0) &&
                                      `$${item?.rate_ch_non_tobacco}`}
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      value={selectedConfig?.rate_ch_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_ch_tobacco')
                                      }
                                      onFocus={(event) => onFocusInput(event, 'rate_ch_tobacco')}
                                      onBlur={(event) => onBlurInput(event, 'rate_ch_tobacco')}
                                      type="number"
                                    />
                                    <Input
                                      value={selectedConfig?.rate_ch_non_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_ch_non_tobacco')
                                      }
                                      onFocus={(event) =>
                                        onFocusInput(event, 'rate_ch_non_tobacco')
                                      }
                                      onBlur={(event) => onBlurInput(event, 'rate_ch_non_tobacco')}
                                      type="number"
                                    />
                                  </>
                                )}
                              </td>
                              <td className={classes.TobaccoInput}>
                                {!isEditMode ? (
                                  <>
                                    {(item?.rate_fam_tobacco || item?.rate_fam_tobacco === 0) &&
                                      `$${item?.rate_fam_tobacco}`}
                                    /
                                    {(item?.rate_fam_non_tobacco ||
                                      item?.rate_fam_non_tobacco === 0) &&
                                      `$${item?.rate_fam_non_tobacco}`}
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      value={selectedConfig?.rate_fam_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_fam_tobacco')
                                      }
                                      onFocus={(event) => onFocusInput(event, 'rate_fam_tobacco')}
                                      onBlur={(event) => onBlurInput(event, 'rate_fam_tobacco')}
                                      type="number"
                                    />
                                    <Input
                                      value={selectedConfig?.rate_fam_non_tobacco}
                                      onChange={(event) =>
                                        onChangeRateValue(event, 'rate_fam_non_tobacco')
                                      }
                                      onFocus={(event) =>
                                        onFocusInput(event, 'rate_fam_non_tobacco')
                                      }
                                      onBlur={(event) => onBlurInput(event, 'rate_fam_non_tobacco')}
                                      type="number"
                                    />
                                  </>
                                )}
                              </td>
                            </>
                          )}
                        </>
                      )}

                      <td className={classes.EmptyDropZoneRow}>
                        <DropZone
                          label="Proposed Benefits Summary"
                          maxFiles={100}
                          path={`/proposals/${proposalId}/uploads/carrier/plan-configs/${id}/offers/${item?.id}/`}
                          isDisabled={!isEditMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.LifeConfigurationContentTier}>
            <table className={classes.LifeConfigurationTierTable}>
              <thead className={classes.LifeConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}></th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.LifeConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                </tr>
                {lifeConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyButtonRow}>
                        {!isEditMode && (
                          <div className={classes.LifeConfigurationEditTableItem}>
                            {isVisibleButtons(item?.status) && (
                              <div className={classes.LifeConfigurationEditTableItemWrapper}>
                                <Button
                                  title="Edit"
                                  type="primary"
                                  onClick={onClickEditConfig(item)}
                                />
                                {item.status !== 'declined' && lifeOffers.length > 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Delete" onClick={onClickDelete(item)} />
                                  </div>
                                )}
                                {item.status !== 'declined' && lifeOffers?.length === 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Decline" onClick={onRejectOffer} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {isEditMode && (
                          <div className={classes.LifeConfigurationContentFooter}>
                            {isEdited && (
                              <Button title="Save" type="primary" onClick={onSaveSelectedConfig} />
                            )}
                            <Button title="Cancel" type="secondary" onClick={onClickCancel} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
