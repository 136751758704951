import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { getAccountData, getBrokerData, getBrokerRequestedProposals } from 'src/store/broker';
import { getGroupMembers } from 'src/store/groupMembers';
import {
  getIssuerAccountProposalData,
  // getIssuerRfpQuotes,
  issuerAccountMembersSelector,
} from 'src/store/issuer';

import {
  onDownloadBrokerRequestedProposalsXlsx,
  // onDownloadCarrierProposalXlsx,
  onDownloadMembersXlsx,
} from 'src/utils/exportXlsx';
import { onDownloadMembersCsv } from 'src/utils/exportCsv';

import { Button, Loader, Modal } from 'src/components';

import DownloadCSVIcon from 'src/assets/proposal/downloadCSVIcon.svg';
import DownloadXLSXIcon from 'src/assets/proposal/downloadXLSXIcon.svg';

import classes from './issuerExportModal.module.scss';

export const IssuerExportModal = (props) => {
  const dispatch = useDispatch();
  const params = useParams();

  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const brokerData = useSelector(getBrokerData);
  const accountData = useSelector(getAccountData);
  const accountMembers = useSelector(issuerAccountMembersSelector);
  const accountProposalData = useSelector(getIssuerAccountProposalData);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [csvMembersData, setCsvMembersData] = useState([]);

  const handleOpenModal = useCallback(() => {
    setIsVisibleModal((prev) => !prev);
  }, []);

  // const onExportProposal = useCallback(async () => {
  //   const payload = {
  //     accountId,
  //     proposalId,
  //   };

  //   setIsLoading(true);

  //   await dispatch(getGroupMembers({ accountId, groupId: accountProposalData.group_ids[0] }));

  //   const response = await dispatch(getIssuerRfpQuotes(payload));

  //   const rfpQuotes = response?.payload;

  //   onDownloadCarrierProposalXlsx(
  //     accountData,
  //     accountProposalData,
  //     brokerData,
  //     accountMembers,
  //     rfpQuotes,
  //   );

  //   setIsLoading(false);
  // }, [
  //   dispatch,
  //   accountId,
  //   proposalId,
  //   accountMembers,
  //   accountData,
  //   accountProposalData,
  //   brokerData,
  // ]);

  const onExportRequestedProposals = useCallback(async () => {
    setIsLoading(true);

    const payload = {
      accountId,
      proposalId,
    };

    await dispatch(getGroupMembers({ accountId, groupId: accountProposalData.group_ids[0] }));

    const response = await dispatch(getBrokerRequestedProposals(payload));

    const brokerProposals = response?.payload;

    const account = {
      ...accountData,
      effective_date: accountProposalData?.effective_date,
    };

    onDownloadBrokerRequestedProposalsXlsx(
      account,
      accountProposalData,
      brokerData,
      accountMembers,
      brokerProposals,
    );

    setIsVisibleModal(false);
    setIsLoading(false);
  }, [
    dispatch,
    accountId,
    proposalId,
    accountProposalData,
    accountData,
    brokerData,
    accountMembers,
  ]);

  const renderDropdownItem = useCallback((func) => {
    return (
      <div className={classes.OfferTableDownloadItem} onClick={func}>
        <img src={DownloadXLSXIcon} alt={DownloadXLSXIcon} />
      </div>
    );
  }, []);

  return (
    <>
      {isLoading && (
        <div className={classes.LoaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={classes.ExportButton}>
        <Button title="Export" type="secondary" onClick={handleOpenModal} />
        {isVisibleModal && (
          <Modal type="xlarge" onClose={handleOpenModal} title="Export" closeButtonType="inside">
            <div className={classes.OfferTable}>
              <TableContainer component={Paper}>
                <Table>
                  {/* <TableHead>
                  <TableRow>
                    <th>Title</th>
                    <th>Description</th>
                    <th className={classes.OfferTableDownload}></th>
                    <th className={classes.OfferTableDownload}></th>
                  </TableRow>
                </TableHead> */}
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.OfferTableTitle}>Requested RFPs</TableCell>
                      <TableCell>
                        This export is designed to be shared with carrier sales teams and
                        underwriters to help them quote. It provides a summary of all plans
                        configurations being requested and separate sheets for the account details
                        and census information.
                      </TableCell>
                      <TableCell>
                        <span className={classes.OfferTableDownload}>
                          {renderDropdownItem(onExportRequestedProposals)}

                          <span />
                        </span>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.OfferTableTitle}>Census</TableCell>
                      <TableCell>
                        This export provides the census as it is showing in the proposal. This can
                        be used for third party systems.
                      </TableCell>
                      <TableCell>
                        <span className={classes.OfferTableDownload}>
                          {renderDropdownItem(
                            onDownloadMembersXlsx(
                              accountMembers,
                              `${accountData?.company_name}-${accountProposalData?.name}-Members`,
                            ),
                          )}

                          <CSVLink
                            data={csvMembersData}
                            className={classes.IssuerProposalHeaderExportDropDownLink}
                            onClick={onDownloadMembersCsv(accountMembers, setCsvMembersData)}
                            filename={`${accountData?.company_name}-${accountProposalData?.name}-Members`}
                          >
                            <div className={classes.OfferTableDownloadItem}>
                              <img src={DownloadCSVIcon} alt={DownloadCSVIcon} />
                            </div>
                          </CSVLink>
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};
