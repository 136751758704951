import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  coverageSelector,
  deleteCoverageTemplate,
  getCoverageTemplates,
} from 'src/store/coverageBuilder';
import { getAccountProposalSelector } from 'src/store/proposals';

import classes from '../planConfig.module.scss';
import { Button, EmptyField, Loader, LoaderWrapper, Modal } from 'src/components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';

export const PlanConfigTemplate = (props) => {
  const { build, setConfigType } = props;

  const { coverage, card_options, form_options } = build;

  const dispatch = useDispatch();
  const params = useParams();

  const coverageData = useSelector(coverageSelector);
  const accountProposalData = useSelector(getAccountProposalSelector);
  const accountId = params?.id ? params?.id : accountProposalData?.account_id;
  const proposalId = params?.proposalId ? params?.proposalId : accountProposalData?.id;

  const templates = coverageData[`${coverage}_templates`];
  const templatesMeta = coverageData['templates_meta'];

  const [currentPage, setCurrentPage] = useState(templatesMeta?.page ? templatesMeta?.page : 1);
  const [selectedPageSize, setSelectedPageSize] = useState(
    templatesMeta?.per_page ? templatesMeta?.per_page : 10,
  );

  const [isModal, setIsModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const onPageChange = useCallback((event, page) => {
    setCurrentPage(page + 1);
  }, []);

  const onChangePageSize = useCallback((event) => {
    setCurrentPage(1);
    setSelectedPageSize(parseFloat(event.target.value));
  }, []);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (accountId && proposalId) {
      const fetchProposalsList = async () => {
        if (!isMounted) return;

        setIsLoadingTemplates(true);
        try {
          await dispatch(
            getCoverageTemplates({
              accountId,
              proposalId,
              coverage,
              page: currentPage,
              per_page: selectedPageSize,
            }),
          );
        } catch (error) {
          if (isMounted) setIsLoadingTemplates(false);
        } finally {
          if (isMounted) setIsLoadingTemplates(false);
        }
      };

      fetchProposalsList();
    }

    return () => {
      isMounted = false;
    };
  }, [dispatch, accountId, proposalId, coverage, currentPage, selectedPageSize]);

  const normalizeFeildValue = useCallback((type, value) => {
    switch (type) {
      case 'currency':
        return `$${value}`;

      default:
        return value;
    }
  }, []);

  const onSelectTemplate = useCallback(
    (item) => () => {
      const payload = { ...item };

      delete payload.id;
      delete payload.broker_id;
      delete payload.template_name;
      delete payload.updated_timestamp;

      setConfigType('config');
      form_options.setFormData(payload);
    },
    [setConfigType, form_options],
  );

  const onClickDelete = useCallback(
    (item) => () => {
      setIsModal(true);
      setSelectedTemplate(item);
    },
    [],
  );

  const onSubmitDelete = useCallback(async () => {
    await dispatch(
      deleteCoverageTemplate({ accountId, proposalId, coverage, templateId: selectedTemplate.id }),
    );

    setIsModal(false);
    setSelectedTemplate({});
  }, [dispatch, accountId, proposalId, coverage, selectedTemplate]);

  const onCloseDeleteModal = useCallback(() => {
    setSelectedTemplate({});
    setIsModal(false);
  }, []);

  return (
    <>
      {isModal && (
        <Modal closeButtonType="inside" onClose={onCloseDeleteModal}>
          <div className={classes.Modal}>
            <div className={classes.ModalContent}>
              Are you sure you want to delete this template({selectedTemplate?.template_name})?
            </div>
            <div className={classes.ModalFooterCentered}>
              <Button title="Delete" type="danger" onClick={onSubmitDelete} />
              <Button title="Cancel" type="secondary" onClick={onCloseDeleteModal} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.PlanConfigTemplate}>
        {isLoadingTemplates ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : templates?.length ? (
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Template Name</TableCell>
                    {card_options.proposal?.map((item) => (
                      <TableCell key={item.key}>{item.title}</TableCell>
                    ))}
                    <TableCell>Created</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ cursor: 'pointer' }}
                      hover
                    >
                      <TableCell component="th" scope="row" onClick={onSelectTemplate(item)}>
                        {item?.template_name}
                      </TableCell>
                      {card_options.proposal?.map((option) => (
                        <TableCell component="th" scope="row" onClick={onSelectTemplate(item)}>
                          {normalizeFeildValue(option.type, item[option.key])}
                        </TableCell>
                      ))}
                      <TableCell component="th" scope="row">
                        {moment(item?.created_timestamp).format('LLLL')}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={onClickDelete(item)}>
                        <i className="fa fa-trash-o" style={{ color: 'red' }} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={templatesMeta?.total ?? 0}
              rowsPerPage={selectedPageSize}
              page={currentPage - 1}
              onPageChange={onPageChange}
              onRowsPerPageChange={onChangePageSize}
            />
          </Paper>
        ) : (
          <EmptyField title="No templates available" />
        )}
      </div>
    </>
  );
};
