import { createSlice } from '@reduxjs/toolkit';

import {
  getIssuerVisionPlans,
  getVisionPlans,
  getVisionQuotePlans,
  updateVisionOffer,
} from './visionConfiguration.actions';

const initialState = {
  visionPlans: [],
  visionQuotePlans: [],
  isLoadingVisionQuotePlans: false,
  isLoadingVisionPlans: false,

  // ISSUER
  issuerVisionPlans: [],
  isLoadingIssuerVisionPlans: false,
  isLoadingUpdateVisionOffer: false,
};

const visionConfigurationSlice = createSlice({
  name: 'visionConfiguration',
  initialState,
  reducers: {
    setVisionPlans: (state, action) => {
      state.visionPlans = action.payload;
    },
    setVisionQuotePlans: (state, action) => {
      state.visionQuotePlans = action.payload;
    },

    setIssuerVisionPlans: (state, action) => {
      state.issuerVisionPlans = action.payload;
    },

    clearVisionConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getVisionQuotePlans.pending, (state) => {
      state.isLoadingVisionQuotePlans = true;
    });
    builder.addCase(getVisionQuotePlans.fulfilled, (state, action) => {
      state.visionQuotePlans = action.payload;
      state.isLoadingVisionQuotePlans = false;
    });
    builder.addCase(getVisionQuotePlans.rejected, (state) => {
      state.isLoadingVisionQuotePlans = false;
    });

    builder.addCase(getVisionPlans.pending, (state) => {
      state.isLoadingVisionPlans = true;
    });
    builder.addCase(getVisionPlans.fulfilled, (state, action) => {
      state.visionPlans = action.payload;
      state.isLoadingVisionPlans = false;
    });
    builder.addCase(getVisionPlans.rejected, (state) => {
      state.isLoadingVisionPlans = false;
    });

    // ISSUER

    builder.addCase(getIssuerVisionPlans.pending, (state) => {
      state.isLoadingIssuerVisionPlans = true;
    });
    builder.addCase(getIssuerVisionPlans.fulfilled, (state, action) => {
      state.issuerVisionPlans = action.payload;
      state.isLoadingIssuerVisionPlans = false;
    });
    builder.addCase(getIssuerVisionPlans.rejected, (state) => {
      state.isLoadingIssuerVisionPlans = false;
    });

    builder.addCase(updateVisionOffer.pending, (state) => {
      state.isLoadingUpdateVisionOffer = true;
    });
    builder.addCase(updateVisionOffer.fulfilled, (state, action) => {
      state.isLoadingUpdateVisionOffer = false;
    });
    builder.addCase(updateVisionOffer.rejected, (state) => {
      state.isLoadingUpdateVisionOffer = false;
    });
  },
});

export const {
  setVisionPlans,
  setVisionQuotePlans,
  setIssuerVisionPlans,
  clearVisionConfigurationState,
} = visionConfigurationSlice.actions;

export default visionConfigurationSlice.reducer;
