import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { getVisionOffers } from 'src/store/visionConfiguration';

import { VisionConfigurationForm } from '../VisionConfigurationForm/VisionConfigurationForm';

import { IssuerCoverageCard } from '../IssuerCoverageCard/IssuerCoverageCard';
import { VISION_FORM_NAMES } from 'src/constants/formNames';

export const VisionConfiguration = (props) => {
  const { id, accountId, proposalId, issuerId, userId, brokerRequest } = props;

  const dispatch = useDispatch();

  const [selectedProposalIds, setSelectedProposalIds] = useState([]);

  const [visionOffers, setVisionOffers] = useState([]);
  const [isLoadingOffers, setIsLoadingOffers] = useState([]);

  const offerStatus = visionOffers?.find((item) => item.config_id === id)?.status;

  const getOffers = useCallback(() => {
    dispatch(
      getVisionOffers({
        accountId,
        proposalId,
        configId: id,
        setOffers: setVisionOffers,
        setIsLoading: setIsLoadingOffers,
      }),
    );
  }, [dispatch, id, accountId, proposalId]);

  useEffect(() => {
    if (id) {
      getOffers();
    }
    return () => {
      setVisionOffers([]);
    };
  }, [id, getOffers]);

  const handleOpenProposal = useCallback(
    (id) => () => {
      if (selectedProposalIds.includes(id)) {
        const updatedSelectedQuotes = _.reject(selectedProposalIds, (itemId) => itemId === id);

        setSelectedProposalIds(updatedSelectedQuotes);
        return;
      }

      setSelectedProposalIds([...selectedProposalIds, id]);
    },
    [selectedProposalIds],
  );

  const cardData = [
    {
      title: VISION_FORM_NAMES.frequency,
      sub_title: brokerRequest?.frequency,
    },
    {
      title: VISION_FORM_NAMES.exam_copay,
      sub_title: `$${brokerRequest?.exam_copay}`,
    },
    {
      title: VISION_FORM_NAMES.materials_copay,
      sub_title: `$${brokerRequest?.materials_copay}`,
    },
    {
      title: VISION_FORM_NAMES.frame_allowance,
      sub_title: `$${brokerRequest?.frame_allowance}`,
    },
    {
      title: 'Current',
      sub_title: brokerRequest?.is_current_rate ? 'Yes' : 'No',
    },
  ];

  return (
    <IssuerCoverageCard
      id={id}
      selectedProposalIds={selectedProposalIds}
      handleOpenProposal={handleOpenProposal}
      cardData={cardData}
      isLoading={isLoadingOffers}
      status={offerStatus}
    >
      <VisionConfigurationForm
        id={id}
        accountId={accountId}
        proposalId={proposalId}
        userId={userId}
        issuerId={issuerId}
        brokerConfig={brokerRequest}
        visionOffers={visionOffers}
        getVisionOffers={getOffers}
      />
    </IssuerCoverageCard>
  );
};
