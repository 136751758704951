import { createSlice } from '@reduxjs/toolkit';

import {
  getCriticalQuotePlans,
  getCriticalPlans,
  updateCriticalOffer,
  getIssuerCriticalPlans,
} from './criticalConfiguration.actions';

const initialState = {
  criticalPlans: [],
  criticalQuotePlans: [],

  isLoadingCriticalQuotePlans: false,
  isLoadingCriticalPlans: false,

  issuerCriticalPlans: [],
  isLoadingUpdateCriticalOffer: false,
  isLoadingIssuerCriticalPlans: false,
};

const criticalConfigurationSlice = createSlice({
  name: 'criticalConfiguration',
  initialState,
  reducers: {
    setCriticalPlan: (state, action) => {
      state.criticalPlans = action.payload;
    },
    setCriticalQuotePlans: (state, action) => {
      state.criticalQuotePlans = action.payload;
    },

    clearCriticalConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCriticalQuotePlans.pending, (state) => {
      state.isLoadingCriticalQuotePlans = true;
    });
    builder.addCase(getCriticalQuotePlans.fulfilled, (state, action) => {
      state.criticalQuotePlans = action.payload;
      state.isLoadingCriticalQuotePlans = false;
    });
    builder.addCase(getCriticalQuotePlans.rejected, (state) => {
      state.isLoadingCriticalQuotePlans = false;
    });

    builder.addCase(getCriticalPlans.pending, (state) => {
      state.isLoadingCriticalPlans = true;
    });
    builder.addCase(getCriticalPlans.fulfilled, (state, action) => {
      state.criticalPlans = action.payload;
      state.isLoadingCriticalPlans = false;
    });
    builder.addCase(getCriticalPlans.rejected, (state) => {
      state.isLoadingCriticalPlans = false;
    });

    builder.addCase(updateCriticalOffer.pending, (state) => {
      state.isLoadingUpdateCriticalOffer = true;
    });
    builder.addCase(updateCriticalOffer.fulfilled, (state, action) => {
      state.isLoadingUpdateCriticalOffer = false;
    });
    builder.addCase(updateCriticalOffer.rejected, (state) => {
      state.isLoadingUpdateCriticalOffer = false;
    });

    // ISSUER
    builder.addCase(getIssuerCriticalPlans.pending, (state) => {
      state.isLoadingIssuerCriticalPlans = true;
    });
    builder.addCase(getIssuerCriticalPlans.fulfilled, (state, action) => {
      state.issuerCriticalPlans = action.payload;
      state.isLoadingIssuerCriticalPlans = false;
    });
    builder.addCase(getIssuerCriticalPlans.rejected, (state) => {
      state.isLoadingIssuerCriticalPlans = false;
    });
  },
});

export const { setCriticalPlan, setCriticalQuotePlans, clearCriticalConfigurationState } =
  criticalConfigurationSlice.actions;

export default criticalConfigurationSlice.reducer;
