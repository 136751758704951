import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { groupMembersSelector } from 'src/store/groupMembers';

import { BuilderSelect, BuilderCASelect, BuilderInput } from 'src/features/coverageBuilder';
import {
  fundingTypeOptions,
  planTypeOptions,
  networkSizeOptions,
  tierOptions,
  primaryCareOptions,
  specialistCopayOptions,
  coinsuranceOptions,
  compMethodOptions,
  inpatientFacilityCostOptions,
  inpatientFacilityDaysOptions,
  percentInpatientFacilityOptions,
  inpatientFacilityOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { medicalRenewalRates } from 'src/constants/renewalRates';
import { normalizePlanType } from 'src/constants/coveragePlans';
import { normalizeFundingType } from 'src/constants/proposalFormField';
import { AGES_65 } from 'src/features/coverageBuilder/cb_constants';

export const useMedicalBuild = () => {
  const params = useParams();

  const proposal_id = params?.proposalId;

  const members = useSelector(groupMembersSelector);
  const plan_names = medicalRenewalRates(members);

  const initialState = {
    id: uuid(),
    ...AGES_65,
    is_current_rate: false,
    contribution_type: 'percent',
    contribution_ee: 50,
    contribution_dep: 0,
    funding_type: null,
    plan_type: null,
    network_size: null,
    level: null,
    primary_care_physician: null,
    specialist: null,
    individual_medical_moop: '1000',
    individual_medical_deductible: null,
    family_medical_deductible: null,
    inpatient_facility_is_percent: null, // true - percent | false - dollar
    inpatient_facility_dollar_days: null,
    inpatient_facility_dollar_amount: null,
    inpatient_facility_percent_value: null,
    plan_coinsurance: null,
    emergency_room: null, // value can be dollar or percent
    urgent_care: null, // value can be dollar or percent
    comp_method: null,
    comp_amount_pct: '5',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    selected_plan_name: null,
    custom_fields: [],
    rate_tier_type: '3tier',
    rate_ee: null,
    rate_ee_sp: null,
    rate_ee_ch: null,
    rate_ee_1: null,
    rate_fam: null,
    proposal_id,
  };

  const [formData, setFormData] = useState(initialState);

  const onChange = useCallback((event, key) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const formattedValue = (value) => {
    return currencyFormatter(value).replace('.00', '');
  };

  const build = {
    coverage: 'medical',
    title: 'Medical',
    coverage_plan_name: 'current_medical_plan_name',
    card_options: {
      proposal: [
        {
          title: 'Plan Type',
          key: 'plan_type',
          format: (value) => normalizePlanType(value),
        },
        {
          title: 'Contribution',
          key: 'contribution',
          format: () => 'Employer',
        },
        {
          title: 'Current Benefit',
          key: 'individual_medical_deductible',
          format: (value) => formattedValue(value),
        },
        {
          title: 'Individual Deductible',
          key: 'is_current_rate',
          format: (value) => (value ? 'Yes' : 'No'),
        },
      ],
    },
    form_options: {
      contribution: true,
      contribution_title: 'Map Employees',
      initial_state: initialState,
      form_data: formData,
      setFormData,
      plan_names,
    },
    form_content: () => [
      <BuilderSelect
        key="1"
        label="Funding Type"
        value={formData.funding_type}
        options={fundingTypeOptions}
        onChange={(event) => onChange(event, 'funding_type')}
      />,
      <BuilderSelect
        key="2"
        label="Plan Type"
        value={formData.plan_type}
        options={planTypeOptions}
        onChange={(event) => onChange(event, 'plan_type')}
      />,
      <BuilderSelect
        key="3"
        label="Network Size"
        value={formData.network_size}
        options={networkSizeOptions}
        onChange={(event) => onChange(event, 'network_size')}
      />,
      <BuilderSelect
        key="4"
        label="Tier"
        value={formData.level}
        options={tierOptions}
        onChange={(event) => onChange(event, 'level')}
      />,
      <BuilderSelect
        key="5"
        label="Primary Care Physician Copay"
        value={formData.primary_care_physician}
        options={primaryCareOptions}
        onChange={(event) => onChange(event, 'primary_care_physician')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="6"
        label="Specialist Copay"
        value={formData.specialist}
        options={specialistCopayOptions}
        onChange={(event) => onChange(event, 'specialist')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderInput
        key="7"
        label="Individual Deductible"
        value={formData.individual_medical_deductible}
        onChange={(event) => onChange(event, 'individual_medical_deductible')}
        symbol="$"
        inputType="number"
      />,
      <BuilderInput
        key="8"
        label="Family Deductible"
        value={formData.family_medical_deductible}
        onChange={(event) => onChange(event, 'family_medical_deductible')}
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="16"
        label="Specialist Copay"
        value={formData.inpatient_facility_is_percent}
        options={inpatientFacilityOptions}
        onChange={(event) => onChange(event, 'inpatient_facility_is_percent')}
      />,
      ...(formData.inpatient_facility_is_percent !== null
        ? formData.inpatient_facility_is_percent
          ? [
              <BuilderSelect
                key="17"
                label="% Per Day"
                value={formData.inpatient_facility_percent_value}
                options={percentInpatientFacilityOptions}
                onChange={(event) => onChange(event, 'inpatient_facility_percent_value')}
                type="other"
                symbol="%"
                symbolPosition="end"
                inputType="number"
              />,
            ]
          : [
              <BuilderSelect
                key="18"
                label="Inpatient cost per day"
                value={formData.inpatient_facility_dollar_amount}
                options={inpatientFacilityCostOptions}
                onChange={(event) => onChange(event, 'inpatient_facility_dollar_amount')}
              />,
              <BuilderSelect
                key="19"
                label="Inpatient number of days"
                value={formData.inpatient_facility_dollar_days}
                options={inpatientFacilityDaysOptions}
                onChange={(event) => onChange(event, 'inpatient_facility_dollar_days')}
                type="other"
                inputType="number"
              />,
            ]
        : []),
      <BuilderSelect
        key="10"
        label="Coinsurance"
        value={formData.plan_coinsurance}
        options={coinsuranceOptions}
        onChange={(event) => onChange(event, 'plan_coinsurance')}
        type="other"
        symbol="%"
        symbolPosition="end"
        inputType="number"
      />,
      <BuilderInput
        key="11"
        label="Emergency Room"
        value={formData.emergency_room}
        onChange={(event) => onChange(event, 'emergency_room')}
        symbol="$"
        inputType="number"
      />,
      <BuilderInput
        key="12"
        label="Urgent Care"
        value={formData.urgent_care}
        onChange={(event) => onChange(event, 'urgent_care')}
        symbol="$"
        inputType="number"
      />,
      <BuilderInput
        key="13"
        label="Max Out-of-Pocket"
        value={formData.individual_medical_moop}
        onChange={(event) => onChange(event, 'individual_medical_moop')}
        symbol="$"
        inputType="number"
        minValue={1000}
      />,
      <BuilderSelect
        key="14"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="15"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'funding_type', format: (value) => normalizeFundingType(value) },
      { key: 'plan_type', format: (value) => normalizePlanType(value) },
      { key: 'network_size' },
      { key: 'level' },
      { key: 'primary_care_physician', format: (value) => formattedValue(value) },
      { key: 'specialist', format: (value) => formattedValue(value) },
      { key: 'individual_medical_deductible', format: (value) => formattedValue(value) },
      { key: 'family_medical_deductible', format: (value) => formattedValue(value) },
      {
        key: 'inpatient_facility_is_percent',
        format: (value) => (value ? 'Percent' : '$ Per Day'),
        // render: (value) =>
        //   value
        //     ? [
        //         {
        //           key: 'inpatient_facility_percent_value',
        //           format: (value) => value,
        //         },
        //       ]
        //     : [
        //         {
        //           key: 'inpatient_facility_dollar_amount',
        //           format: (value) => formattedValue(value),
        //         },
        //         {
        //           key: 'inpatient_facility_dollar_days',
        //           format: (value) => `${value} Days`,
        //         },
        //       ],
      },
      { key: 'plan_coinsurance', format: (value) => `${value}%` },
      { key: 'emergency_room', format: (value) => value },
      { key: 'urgent_care', format: (value) => value },
      { key: 'individual_medical_moop', format: (value) => formattedValue(value) },
      { key: 'comp_method' },
    ],
  };

  return { build };
};
