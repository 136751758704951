import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNavigation = () => {
  const navigate = useNavigate();

  const onNavigate = useCallback(
    (value) => () => {
      navigate(value);
    },
    [navigate],
  );

  return { onNavigate, navigate };
};
