import React from 'react';
import PropTypes from 'prop-types';

import { Loader, Status } from 'src/components';

import classes from './issuerCoverageCard.module.scss';

export const IssuerCoverageCard = (props) => {
  const {
    id,
    selectedProposalIds,
    isLoading,
    handleOpenProposal,
    cardData = [],
    status,
    children,
  } = props;

  return (
    <>
      <div className={classes.CoverageCard}>
        <div className={classes.CoverageCardHeader} onClick={handleOpenProposal(id)}>
          <div className={classes.HeaderIcon}>
            <div className={classes.HeaderIconWrapper}>
              <i
                className={`fas fa-plus ${
                  selectedProposalIds.includes(id)
                    ? classes.CoverageCardHeaderIconActive
                    : classes.CoverageCardHeaderIcon
                }`}
              />
            </div>
          </div>
          <div className={classes.CoverageCardHeaderWrapper}>
            {cardData?.map((item, index) => (
              <div className={classes.CoverageCardHeaderItem} key={index}>
                <div className={classes.CoverageCardHeaderItemHeading}>{item.title}</div>
                <div className={classes.CoverageCardHeaderItemTitle}>{item.sub_title}</div>
              </div>
            ))}
            <div className={classes.CoverageCardButtons}>
              {isLoading ? <Loader size="small" /> : <Status status={status} />}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          selectedProposalIds.includes(id) ? classes.VisibleContent : classes.HiddenContent
        }
      >
        <div className={classes.CoverageCardContent}>{isLoading ? <Loader /> : children}</div>
      </div>
    </>
  );
};

IssuerCoverageCard.propTypes = {
  id: PropTypes.string,
  selectedProposalIds: PropTypes.array,
  isLoading: PropTypes.bool,
  handleOpenProposal: PropTypes.func,
  cardData: PropTypes.array,
  status: PropTypes.string,
};
