import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';

import classes from './builderSelect.module.scss';

export const BuilderSelect = (props) => {
  const {
    label,
    value,
    options,
    onChange,
    size = 'small',
    disabled = false,
    type = 'default',
    symbol = '',
    symbolPosition = 'start',
    inputType = 'text',
    maxValue,
  } = props;

  const [isOther, setIsOther] = useState(false);

  const onClickToggle = useCallback(() => {
    setIsOther((prev) => !prev);
  }, []);

  return (
    <div>
      {!isOther ? (
        <Box>
          <FormControl fullWidth>
            <InputLabel required size={size} id={`select-${label}`}>
              {label}
            </InputLabel>
            <Select
              size={size}
              onChange={onChange}
              value={value}
              labelId={`select-${label}`}
              label={label}
              style={{ textAlign: 'left' }}
              required
              disabled={disabled}
            >
              {options?.map((option) => (
                <MenuItem key={option.title} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <TextField
          style={{ width: '100%' }}
          label={label}
          onChange={onChange}
          value={value}
          type={inputType}
          size="small"
          required
          InputProps={{
            inputProps: inputType === 'number' ? { min: 0, max: maxValue ?? 999999 } : {},
            startAdornment:
              symbol && symbolPosition === 'start' ? (
                <InputAdornment position={symbolPosition}>{symbol}</InputAdornment>
              ) : (
                <></>
              ),
            endAdornment:
              symbol && symbolPosition === 'end' ? (
                <InputAdornment position={symbolPosition}>{symbol}</InputAdornment>
              ) : (
                <></>
              ),
          }}
        />
      )}
      {type !== 'default' && (
        <div className={classes.BuilderSelectToggle} onClick={onClickToggle}>
          {isOther ? 'Default' : 'Other'}
        </div>
      )}
    </div>
  );
};

BuilderSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  symbol: PropTypes.string,
  symbolPosition: PropTypes.string,
  inputType: PropTypes.string,
  maxValue: PropTypes.number,
};
