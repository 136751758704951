import React, { useCallback, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import PropTypes from 'prop-types';

import classes from './planConfig.module.scss';
import { PlanConfigForm } from './PlanConfigForm/PlanConfigForm';
import { PlanConfigTemplate } from './PlanConfigTemplate/PlanConfigTemplate';

export const PlanConfig = (props) => {
  const { build, mode, onClose } = props;

  const { title } = build;

  const [configType, setConfigType] = useState('config');

  const onChangeConfigToggle = useCallback((event) => {
    setConfigType(event.target.value);
  }, []);

  return (
    <div className={classes.PlanConfig}>
      <div className={classes.PlanConfigTitle}>
        {mode === 'CREATE' ? 'New' : 'Edit'} {title} Plan Configuration
      </div>
      {mode !== 'EDIT' && (
        <div className={classes.PlanConfigContribution}>
          <ToggleButtonGroup
            color="primary"
            value={configType}
            exclusive
            onChange={onChangeConfigToggle}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton value="config">New Configuration</ToggleButton>
            <ToggleButton value="template">New from Template</ToggleButton>
          </ToggleButtonGroup>
        </div>
      )}
      {configType === 'config' && <PlanConfigForm build={build} mode={mode} onClose={onClose} />}
      {configType === 'template' && (
        <PlanConfigTemplate build={build} setConfigType={setConfigType} />
      )}
    </div>
  );
};

PlanConfig.propTypes = {
  build: PropTypes.object,
  mode: PropTypes.string,
  onClose: PropTypes.func,
};
