import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.accident;

export const getAccidentPlansSelector = createSelector(getState, (state) => state.accidentPlans);

// ISSUER

export const getIssuerAccidentPlansSelector = createSelector(
  getState,
  (state) => state.issuerAccidentPlans,
);
export const isLoadingIssuerAccidentPlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerAccidentPlans,
);
