import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { Input } from 'src/components';

import { allAges, ALL_AGES_NAMES, normalizeAllAgeRates } from './medicalAllAges';
import {
  fiveYearAges,
  FIVE_YEAR_NAMES,
  normalizeMedicalFiveYearAgeRates,
} from './medicalFiveYearAges';
import { normalizeMedicalTenYearAgeRates, tenYearAges, TEN_YEAR_NAMES } from './medicalTenYearAges';

import { rateValueRegex } from 'src/constants/regularExpression';

import classes from './medicalAgeBandedRates.module.scss';

export const MedicalAgeBandedRates = (props) => {
  const {
    type,
    ageBandedRates,
    selectedAllAges,
    setSelectedAllAges,
    selectedFiveYearAges,
    setSelectedFiveYearAges,
    selectedTenYearAges,
    setSelectedTenYearAges,
    onSetAllAgeBandedRates,
    onSetFiveYearAgeBandedRates,
    onSetTenYearAgeBandedRates,
    isEditMode = true,
    isIssuer = false,
  } = props;

  const [allAgeBandedRates, setAllAgeBandedRates] = useState(
    isIssuer ? selectedAllAges || allAges(ageBandedRates) : allAges(ageBandedRates),
  );
  const [fiveYearAgeRates, setFiveYearAgeRates] = useState(
    isIssuer ? selectedFiveYearAges || fiveYearAges(ageBandedRates) : fiveYearAges(ageBandedRates),
  );
  const [tenYearAgeRates, setTenYearAgeRates] = useState(
    isIssuer ? selectedTenYearAges || tenYearAges(ageBandedRates) : tenYearAges(ageBandedRates),
  );

  const [allAgeBandedRatesPayload, setAllAgeBandedRatesPayload] = useState();
  const [fiveYearAgeRatesPayload, setFiveYearAgeRatesPayload] = useState();
  const [tenYearAgeRatesPayload, setTenYearAgeRatesPayload] = useState();

  const [selectedTarget, setSelectedTarget] = useState('');

  useEffect(() => {
    setAllAgeBandedRates(
      isIssuer ? selectedAllAges || allAges(ageBandedRates) : allAges(ageBandedRates),
    );
    setFiveYearAgeRates(
      isIssuer
        ? selectedFiveYearAges || fiveYearAges(ageBandedRates)
        : fiveYearAges(ageBandedRates),
    );
    setTenYearAgeRates(
      isIssuer ? selectedTenYearAges || tenYearAges(ageBandedRates) : tenYearAges(ageBandedRates),
    );
  }, [type, ageBandedRates, selectedTenYearAges, isIssuer, selectedAllAges, selectedFiveYearAges]);

  useEffect(() => {
    if (type === 'all_ages') {
      const payload = normalizeAllAgeRates(allAgeBandedRates);

      setAllAgeBandedRatesPayload(payload);
    }
  }, [type, allAgeBandedRates]);

  useEffect(() => {
    if (type === 'banded_5') {
      const payload = normalizeMedicalFiveYearAgeRates(fiveYearAgeRates);

      setFiveYearAgeRatesPayload(payload);
    }
  }, [type, fiveYearAgeRates]);

  useEffect(() => {
    if (type === 'banded_10') {
      const payload = normalizeMedicalTenYearAgeRates(tenYearAgeRates);

      setTenYearAgeRatesPayload(payload);
    }
  }, [type, tenYearAgeRates]);

  useEffect(() => {
    if (onSetAllAgeBandedRates) {
      onSetAllAgeBandedRates(allAgeBandedRatesPayload);
    }
  }, [onSetAllAgeBandedRates, allAgeBandedRatesPayload]);

  useEffect(() => {
    if (onSetFiveYearAgeBandedRates) {
      onSetFiveYearAgeBandedRates(fiveYearAgeRatesPayload);
    }
  }, [onSetFiveYearAgeBandedRates, fiveYearAgeRatesPayload]);

  useEffect(() => {
    if (onSetTenYearAgeBandedRates) {
      onSetTenYearAgeBandedRates(tenYearAgeRatesPayload);
    }
  }, [onSetTenYearAgeBandedRates, tenYearAgeRatesPayload]);

  const onFocusInput = useCallback((event, target, value, setValue) => {
    setSelectedTarget(target);

    if (Number(event.target.value) === 0) {
      const payload = {
        ...value,
        [target]: '',
      };

      return setValue(payload);
    }
  }, []);

  const onBlurInput = useCallback((event, target, value, setValue) => {
    setSelectedTarget('');

    if (!event.target.value) {
      const payload = {
        ...value,
        [target]: 0,
      };

      return setValue(payload);
    }
  }, []);

  const onChangeAllBandedAgeInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(rateValueRegex)) {
        payload = {
          ...allAgeBandedRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setSelectedAllAges(payload);
        }
        return setAllAgeBandedRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...allAgeBandedRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setSelectedAllAges(payload);
        }
        return setAllAgeBandedRates(payload);
      }

      payload = {
        ...allAgeBandedRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setSelectedAllAges(payload);
      }
      setAllAgeBandedRates(payload);
    },
    [allAgeBandedRates, isIssuer, selectedTarget, setSelectedAllAges],
  );

  const onChangeFiveYearAgeInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(rateValueRegex)) {
        payload = {
          ...fiveYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setSelectedFiveYearAges(payload);
        }
        return setFiveYearAgeRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...fiveYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setSelectedFiveYearAges(payload);
        }
        return setFiveYearAgeRates(payload);
      }

      payload = {
        ...fiveYearAgeRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setSelectedFiveYearAges(payload);
      }
      setFiveYearAgeRates(payload);
    },
    [fiveYearAgeRates, isIssuer, selectedTarget, setSelectedFiveYearAges],
  );

  const onChangeTenYearAgeInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(rateValueRegex)) {
        payload = {
          ...tenYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setSelectedTenYearAges(payload);
        }
        return setTenYearAgeRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...tenYearAgeRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setSelectedTenYearAges(payload);
        }
        return setTenYearAgeRates(payload);
      }

      payload = {
        ...tenYearAgeRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setSelectedTenYearAges(payload);
      }
      setTenYearAgeRates(payload);
    },
    [tenYearAgeRates, selectedTarget, isIssuer, setSelectedTenYearAges],
  );

  const renderAllAgeBandedRates = useCallback(
    () =>
      Object?.keys(allAgeBandedRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerMedicalAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={ALL_AGES_NAMES[item] === 'Age 65' ? 'Age 65+' : ALL_AGES_NAMES[item]}
                  // type="default-number"
                  value={allAgeBandedRates[item]}
                  // event, target, value, setValue
                  onFocus={(event) =>
                    onFocusInput(event, item, allAgeBandedRates, setAllAgeBandedRates)
                  }
                  onBlur={(event) =>
                    onBlurInput(event, item, allAgeBandedRates, setAllAgeBandedRates)
                  }
                  onChange={onChangeAllBandedAgeInput}
                />
                {isIssuer &&
                  `(${ALL_AGES_NAMES[item] === 'Age 65' ? 'Age 65+' : ALL_AGES_NAMES[item]})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${allAgeBandedRates[item] || '0'} (${
                ALL_AGES_NAMES[item] === 'Age 65' ? 'Age 65+' : ALL_AGES_NAMES[item]
              })`}
          </>
        </td>
      )),
    [allAgeBandedRates, isEditMode, isIssuer, onBlurInput, onChangeAllBandedAgeInput, onFocusInput],
  );

  const renderFiveAgeBandedRates = useCallback(
    () =>
      Object?.keys(fiveYearAgeRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerMedicalAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={FIVE_YEAR_NAMES[item] === 'Age 65' ? 'Age 65+' : FIVE_YEAR_NAMES[item]}
                  // type="default-number"
                  value={fiveYearAgeRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, fiveYearAgeRates, setFiveYearAgeRates)
                  }
                  onBlur={(event) =>
                    onBlurInput(event, item, fiveYearAgeRates, setFiveYearAgeRates)
                  }
                  onChange={onChangeFiveYearAgeInput}
                />
                {isIssuer &&
                  `(${FIVE_YEAR_NAMES[item] === 'Age 65' ? 'Age 65+' : FIVE_YEAR_NAMES[item]})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${fiveYearAgeRates[item] || '0'} (${
                FIVE_YEAR_NAMES[item] === 'Age 65' ? 'Age 65+' : FIVE_YEAR_NAMES[item]
              })`}
          </>
        </td>
      )),
    [fiveYearAgeRates, isIssuer, isEditMode, onFocusInput, onBlurInput, onChangeFiveYearAgeInput],
  );

  const renderTenAgeBandedRates = useCallback(
    () =>
      Object?.keys(tenYearAgeRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerMedicalAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={TEN_YEAR_NAMES[item] === 'Age 65' ? 'Age 65+' : TEN_YEAR_NAMES[item]}
                  // type="default-number"
                  value={tenYearAgeRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, tenYearAgeRates, setTenYearAgeRates)
                  }
                  onBlur={(event) => onBlurInput(event, item, tenYearAgeRates, setTenYearAgeRates)}
                  onChange={onChangeTenYearAgeInput}
                />
                {isIssuer &&
                  `(${TEN_YEAR_NAMES[item] === 'Age 65' ? 'Age 65+' : TEN_YEAR_NAMES[item]})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${tenYearAgeRates[item] || '0'} (${
                TEN_YEAR_NAMES[item] === 'Age 65' ? 'Age 65+' : TEN_YEAR_NAMES[item]
              })`}
          </>
        </td>
      )),
    [isIssuer, isEditMode, tenYearAgeRates, onFocusInput, onBlurInput, onChangeTenYearAgeInput],
  );

  const ageBandedRatesStyles = classnames({
    [classes.MedicalAgeBandedRates]: type !== 'all_ages',
    [classes.AllMedicalAgeBandedRates]: type === 'all_ages',
  });

  return (
    <div className={!isIssuer ? ageBandedRatesStyles : ''}>
      {type === 'all_ages' && renderAllAgeBandedRates()}
      {type === 'banded_5' && renderFiveAgeBandedRates()}
      {type === 'banded_10' && renderTenAgeBandedRates()}
    </div>
  );
};
