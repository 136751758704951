import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { getHospitalOffers } from 'src/store/hospitalConfiguration';

import { HospitalConfigurationForm } from '../HospitalConfigurationForm/HospitalConfigurationForm';
import { IssuerCoverageCard } from '../IssuerCoverageCard/IssuerCoverageCard';
import { HOSPITAL_FORM_NAMES } from 'src/constants/formNames';

export const HospitalConfiguration = (props) => {
  const { id, accountId, proposalId, userId, issuerId, brokerRequest } = props;

  const dispatch = useDispatch();

  const [selectedProposalIds, setSelectedProposalIds] = useState([]);

  const [hospitalOffers, setHospitalOffers] = useState([]);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);

  const offerStatus = hospitalOffers?.find((item) => item.config_id === id)?.status;

  const getOffers = useCallback(() => {
    dispatch(
      getHospitalOffers({
        accountId,
        proposalId,
        configId: id,
        setOffers: setHospitalOffers,
        setIsLoading: setIsLoadingOffers,
      }),
    );
  }, [dispatch, id, accountId, proposalId]);

  useEffect(() => {
    if (id) {
      getOffers();
    }
    return () => {
      setHospitalOffers([]);
    };
  }, [id, getOffers]);

  const handleOpenProposal = useCallback(
    (id) => () => {
      if (selectedProposalIds.includes(id)) {
        const updatedSelectedQuotes = _.reject(selectedProposalIds, (itemId) => itemId === id);

        setSelectedProposalIds(updatedSelectedQuotes);
        return;
      }

      setSelectedProposalIds([...selectedProposalIds, id]);
    },
    [selectedProposalIds],
  );

  const cardData = [
    {
      title: HOSPITAL_FORM_NAMES.initial_hospital_confinement,
      sub_title: brokerRequest?.initial_hospital_confinement
        ? `$${brokerRequest?.initial_hospital_confinement}`
        : '--',
    },
    {
      title: HOSPITAL_FORM_NAMES.per_day_hospitalization,
      sub_title: brokerRequest?.per_day_hospitalization
        ? `$${brokerRequest?.per_day_hospitalization}`
        : '--',
    },
    {
      title: 'Current',
      sub_title: brokerRequest?.is_current_rate ? 'Yes' : 'No',
    },
  ];

  return (
    <IssuerCoverageCard
      id={id}
      selectedProposalIds={selectedProposalIds}
      handleOpenProposal={handleOpenProposal}
      cardData={cardData}
      isLoading={isLoadingOffers}
      status={offerStatus}
    >
      <HospitalConfigurationForm
        id={id}
        accountId={accountId}
        proposalId={proposalId}
        userId={userId}
        issuerId={issuerId}
        brokerConfig={brokerRequest}
        hospitalOffers={hospitalOffers}
        getHospitalOffers={getOffers}
      />
    </IssuerCoverageCard>
  );
};
