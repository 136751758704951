import { createSlice } from '@reduxjs/toolkit';

import {
  getDisabilityPlans,
  getDisabilityQuotePlans,
  getIssuerDisabilityPlans,
  updateDisabilityOffer,
} from './disabilityConfiguration.actions';

const initialState = {
  disabilityPlans: [],
  disabilityQuotePlans: [],
  isLoadingDisabilityQuotePlans: false,
  isLoadingDisabilityPlans: false,

  // ISSUER
  issuerDisabilityPlans: [],
  isLoadingIssuerDisabilityPlans: false,
  isLoadingUpdateDisabilityOffer: false,
};

const disabilityConfigurationSlice = createSlice({
  name: 'disabilityConfiguration',
  initialState,
  reducers: {
    setDisabilityPlans: (state, action) => {
      state.disabilityPlans = action.payload;
    },
    setDisabilityQuotePlans: (state, action) => {
      state.disabilityQuotePlans = action.payload;
    },

    setIssuerDisabilityPlans: (state, action) => {
      state.issuerDisabilityPlans = action.payload;
    },

    clearDisabilityConfigurationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDisabilityQuotePlans.pending, (state) => {
      state.isLoadingDisabilityQuotePlans = true;
    });
    builder.addCase(getDisabilityQuotePlans.fulfilled, (state, action) => {
      state.disabilityQuotePlans = action.payload;
      state.isLoadingDisabilityQuotePlans = false;
    });
    builder.addCase(getDisabilityQuotePlans.rejected, (state) => {
      state.isLoadingDisabilityQuotePlans = false;
    });

    // DISABILITY PLAN CONFIGURATION

    builder.addCase(getDisabilityPlans.pending, (state) => {
      state.isLoadingDisabilityPlans = true;
    });
    builder.addCase(getDisabilityPlans.fulfilled, (state, action) => {
      state.disabilityPlans = action.payload;
      state.isLoadingDisabilityPlans = false;
    });
    builder.addCase(getDisabilityPlans.rejected, (state) => {
      state.isLoadingDisabilityPlans = false;
    });

    // ISSUER
    builder.addCase(getIssuerDisabilityPlans.pending, (state) => {
      state.isLoadingIssuerDisabilityPlans = true;
    });
    builder.addCase(getIssuerDisabilityPlans.fulfilled, (state, action) => {
      state.issuerDisabilityPlans = action.payload;
      state.isLoadingIssuerDisabilityPlans = false;
    });
    builder.addCase(getIssuerDisabilityPlans.rejected, (state) => {
      state.isLoadingIssuerDisabilityPlans = false;
    });

    builder.addCase(updateDisabilityOffer.pending, (state) => {
      state.isLoadingUpdateDisabilityOffer = true;
    });
    builder.addCase(updateDisabilityOffer.fulfilled, (state) => {
      state.isLoadingUpdateDisabilityOffer = false;
    });
    builder.addCase(updateDisabilityOffer.rejected, (state) => {
      state.isLoadingUpdateDisabilityOffer = false;
    });
  },
});

export const {
  setDisabilityPlans,
  setDisabilityQuotePlans,
  setIssuerDisabilityPlans,
  clearDisabilityConfigurationState,
} = disabilityConfigurationSlice.actions;

export default disabilityConfigurationSlice.reducer;
