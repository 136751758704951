import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.vision;

export const getVisionPlansSelector = createSelector(getState, (state) => state.visionPlans);

//ISSUER

export const getIssuerVisionPlansSelecetor = createSelector(
  getState,
  (state) => state.issuerVisionPlans,
);
export const isLoadingIssuerVisionPlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerVisionPlans,
);
export const getIsLoadingUpdateVisionOffer = createSelector(
  getState,
  (state) => state.isLoadingUpdateVisionOffer,
);
