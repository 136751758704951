import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { BuilderSelect, BuilderCASelect } from 'src/features/coverageBuilder';
import {
  compMethodOptions,
  lumpGiOptions,
  booleanOptions,
  spouseChildOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { AGES_80 } from 'src/features/coverageBuilder/cb_constants';

export const useCriticalBuild = () => {
  const initialState = {
    id: uuid(),
    ...AGES_80,
    is_current_rate: false,
    lump_sum_benefits: '',
    gi_amount: '',
    include_cancer: '',
    wellness_benefit: '',
    recurrence_benefit: '',
    spouse_coverage: '',
    child_coverage: '',
    comp_method: '',
    comp_amount_pct: '10',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    custom_fields: [],
    rate_tier_type: '3tier',
    rate_ee: null,
    rate_ee_sp: null,
    rate_ee_ch: null,
    rate_ee_1: null,
    rate_fam: null,
  };

  const [formData, setFormData] = useState(initialState);

  const onChange = useCallback((event, key) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const formattedValue = (value) => {
    return currencyFormatter(value).replace('.00', '');
  };

  const build = {
    coverage: 'critical_illness',
    title: 'Critical Illness',
    card_options: {
      proposal: [
        {
          title: 'Lump Sum Benefit',
          key: 'lump_sum_benefits',
          format: (value) => formattedValue(value),
        },
        {
          title: 'GI Amount',
          key: 'gi_amount',
          format: (value) => formattedValue(value),
        },
      ],
    },
    form_options: {
      initial_state: initialState,
      form_data: formData,
      setFormData,
    },
    form_content: () => [
      <BuilderSelect
        key="1"
        label="Lump Sum Benefit"
        value={formData.lump_sum_benefits}
        options={lumpGiOptions}
        onChange={(event) => onChange(event, 'lump_sum_benefits')}
      />,
      <BuilderSelect
        key="2"
        label="GI Amount"
        value={formData.gi_amount}
        options={lumpGiOptions}
        onChange={(event) => onChange(event, 'gi_amount')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="3"
        label="Include Cancer"
        value={formData.include_cancer}
        options={booleanOptions}
        onChange={(event) => onChange(event, 'include_cancer')}
      />,
      <BuilderSelect
        key="4"
        label="Wellness Benefit"
        value={formData.wellness_benefit}
        options={booleanOptions}
        onChange={(event) => onChange(event, 'wellness_benefit')}
      />,
      <BuilderSelect
        key="5"
        label="Recurrance Benefit"
        value={formData.recurrence_benefit}
        options={booleanOptions}
        onChange={(event) => onChange(event, 'recurrence_benefit')}
      />,
      <BuilderSelect
        key="6"
        label="Spouse Coverage"
        value={formData.spouse_coverage}
        options={spouseChildOptions}
        onChange={(event) => onChange(event, 'spouse_coverage')}
        type="other"
        symbol="%"
        inputType="number"
      />,
      <BuilderSelect
        key="7"
        label="Child Coverage"
        value={formData.child_coverage}
        options={spouseChildOptions}
        onChange={(event) => onChange(event, 'child_coverage')}
        type="other"
        symbol="%"
        inputType="number"
      />,
      <BuilderSelect
        key="8"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="9"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'lump_sum_benefits', format: (value) => currencyFormatter(value) },
      { key: 'gi_amount', format: (value) => currencyFormatter(value) },
      { key: 'include_cancer', format: (value) => (value ? 'Yes' : 'No') },
      { key: 'wellness_benefit', format: (value) => (value ? 'Yes' : 'No') },
      { key: 'recurrence_benefit', format: (value) => (value ? 'Yes' : 'No') },
      { key: 'spouse_coverage', format: (value) => `${value}%` },
      { key: 'child_coverage', format: (value) => `${value}%` },
    ],
  };

  return { build };
};
