import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import {
  getMedicalPlanType,
  setSelectedMedicalPlans,
  getSelectedMedicalPlans,
  setQuotesRates,
} from 'src/store/proposals';

import { inNetworkRegex } from 'src/constants/regularExpression';
import { currencyFormatter, titleCase } from 'src/constants/currency';

import { DetailsItem } from '../DetailsItem/DetailsItem';
import { Modal } from '../Modal/Modal';

import PurpleCircle from 'src/assets/app/purpleCircle.svg';
import CheckedPurpleCircle from 'src/assets/app/checkedPurpleCircle.svg';
import classes from './quotesTable.module.scss';

export const QuotesTable = (props) => {
  const { tableData, selectedQoutes, chooseQuote, quoteType } = props;

  const dispatch = useDispatch();

  const planType = useSelector(getMedicalPlanType);
  const selectedMedicalPlans = useSelector(getSelectedMedicalPlans);

  const [selectedQuote, setSelectedQuote] = useState();
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  useEffect(() => {
    if (!isVisibleModal) {
      document.body.style.overflow = 'hidden';
    }
  }, [isVisibleModal]);

  const onCloseModal = useCallback(() => {
    setSelectedQuote();
    setIsVisibleModal(false);
  }, []);

  const onClickSelectedQuote = useCallback(
    (item) => () => {
      setSelectedQuote(item);
      setIsVisibleModal(true);
    },
    [],
  );

  const onSelectQuote = useCallback(
    (item) => async () => {
      if (quoteType === 'advanced') {
        await dispatch(setSelectedMedicalPlans({ ...selectedMedicalPlans, [planType]: item }));
        dispatch(setQuotesRates([]));
      } else {
        dispatch(chooseQuote([...selectedQoutes, item]));
      }
    },
    [dispatch, chooseQuote, selectedQoutes, quoteType, planType, selectedMedicalPlans],
  );

  const onDeSelectQuote = useCallback(
    (item) => () => {
      dispatch(chooseQuote(_.reject(selectedQoutes, (el) => el.id === item.id)));
    },
    [dispatch, chooseQuote, selectedQoutes],
  );

  useEffect(() => {
    if (quoteType === 'advanced') {
      dispatch(
        chooseQuote(
          Object.keys(selectedMedicalPlans).map((planName) => {
            return { ...selectedMedicalPlans[planName], current_plan_name: planName };
          }),
        ),
      );
    }
  }, [dispatch, quoteType, selectedMedicalPlans, chooseQuote]);

  return (
    <>
      {isVisibleModal && (
        <Modal closeButtonType="inside" onClose={onCloseModal}>
          {/* ! TODO SPLIT MODAL CONTENT TO ANOTHER COMPONENT ! */}
          <div className={classes.ModalContent}>
            <div className={classes.ModalHeader}>
              <div className={classes.ModalHeaderImage}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={selectedQuote.logo_url} />
              </div>
              <div className={classes.ModalHeaderTitle}>{selectedQuote.display_name}</div>
            </div>
            <table className={classes.ModalContentMainTable}>
              <tbody>
                <DetailsItem title="Carrier" value={selectedQuote.carrier_name} />
                <DetailsItem title="Plan Name" value={selectedQuote.display_name} />
                <DetailsItem title="Plan Type" value={selectedQuote.plan_type} />
                <DetailsItem
                  title="Network Size"
                  value={selectedQuote.network_size.toLocaleString()}
                />
                <DetailsItem title="Tier" value={selectedQuote.level} />
                <DetailsItem
                  title="Primary Care Physician Copay"
                  value={selectedQuote.primary_care_physician}
                />
                <DetailsItem title="Specialist Copay" value={selectedQuote.specialist} />
                <DetailsItem
                  title="Individual Deductible"
                  value={selectedQuote.individual_medical_deductible}
                />
                <DetailsItem
                  title="Family Deductible"
                  value={selectedQuote.family_medical_deductible}
                />
                <DetailsItem title="Inpatient Facility" value={selectedQuote.inpatient_facility} />
                <DetailsItem title="Coinsurance" value={selectedQuote.plan_coinsurance} />
                <DetailsItem
                  title="Max Out-of-Pocket"
                  value={selectedQuote.individual_medical_moop}
                />
                <DetailsItem title="Emergency Room" value={selectedQuote.emergency_room} />
                <DetailsItem title="Urgent Care" value={selectedQuote.urgent_care} />
              </tbody>
            </table>

            {selectedQuote.benefits_summary_url && (
              <div className={classes.ModalContentLink}>
                <a href={selectedQuote.benefits_summary_url} target="_blank" rel="noreferrer">
                  See Full Benefits Summary
                </a>
              </div>
            )}
            <div className={classes.ModalFooter}>
              <div className={classes.ModalFooterTitle}>Cost Breakdown</div>
              <div className={classes.ModalFooterContent}>
                <table className={classes.ModalFooterContentTable}>
                  <tbody>
                    <DetailsItem
                      title="Employee"
                      value={currencyFormatter(selectedQuote.employee_cost)}
                    />
                    <DetailsItem
                      title="Employer"
                      value={currencyFormatter(selectedQuote.employer_cost)}
                    />
                    <DetailsItem
                      title="Total"
                      value={currencyFormatter(selectedQuote.total_premium)}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className={classes.QuotesTable}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Carrier</th>
              <th>Plan Name</th>
              <th>INF</th>
              <th>Plan Type</th>
              <th>Network Size</th>
              <th>Tier</th>
              <th>PCP Copay</th>
              <th>Deductible</th>
              <th>Inpatient Facility</th>
              <th>Max OOP</th>
              <th>Employer Cost</th>
              <th>Total Premium</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((item) => (
              <tr key={item.id}>
                <td className={classes.CirkleIcon}>
                  {quoteType === 'advanced' ? (
                    <img src={PurpleCircle} alt="PurpleCircle" onClick={onSelectQuote(item)} />
                  ) : selectedQoutes?.find((value) => value.id === item.id)?.id === item.id ? (
                    <img
                      src={CheckedPurpleCircle}
                      alt="CheckedPurpleCircle"
                      onClick={onDeSelectQuote(item)}
                    />
                  ) : (
                    // <i
                    //   className={`fas fa-check-circle fa-lg ${classes.fa_check_circle__active}`}
                    //   onClick={onDeSelectQuote(item)}
                    // />
                    <img src={PurpleCircle} alt="PurpleCircle" onClick={onSelectQuote(item)} />
                  )}
                </td>
                <td onClick={onClickSelectedQuote(item)}>{item.carrier_name}</td>
                <td onClick={onClickSelectedQuote(item)}>{item.display_name}</td>
                <td onClick={onClickSelectedQuote(item)}>
                  {item.infertility_treatment_rider ? 'Yes' : 'No'}
                </td>
                <td onClick={onClickSelectedQuote(item)}>{item.plan_type}</td>
                <td onClick={onClickSelectedQuote(item)}>{item.network_size.toLocaleString()}</td>
                <td onClick={onClickSelectedQuote(item)}>{titleCase(item.level)}</td>
                <td onClick={onClickSelectedQuote(item)}>
                  {item.primary_care_physician.match(inNetworkRegex)[1]}
                </td>
                <td onClick={onClickSelectedQuote(item)}>
                  {item.individual_medical_deductible.match(inNetworkRegex)[1]}
                </td>
                <td onClick={onClickSelectedQuote(item)}>
                  {item.inpatient_facility.match(inNetworkRegex)[1]}
                </td>
                <td onClick={onClickSelectedQuote(item)}>
                  {item.individual_medical_moop.match(inNetworkRegex)[1]}
                </td>
                <td onClick={onClickSelectedQuote(item)}>
                  <b>{currencyFormatter(item.employer_cost)}</b>
                </td>
                <td onClick={onClickSelectedQuote(item)}>
                  <b>{currencyFormatter(item.total_premium)}</b>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
