import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import {
  clearDisabilityConfigurationState,
  setDisabilityPlans,
} from './disabilityConfiguration.slice';

import { ERROR } from 'src/constants/errorNames';
import {
  calculateDisabilityAgeRates,
  calculateDisabilityRates,
} from 'src/constants/disabilityRates';

export const getDisabilityPlans = createAsyncThunk(
  'disability-configuration/get-disability-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getDisabilityPlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getDisabilityQuotePlans = createAsyncThunk(
  'disability-configuration/get-disability-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getDisabilityQuotePlans(
        accountId,
        proposalId,
        read_token,
      );

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getDisabilityOffers = createAsyncThunk(
  'disability-configuration/get-disability-offers',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading } = data;
      setIsLoading(true);

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      const userType = thunkApi.getState().app.userType;

      const response = await api.planConfiguration.getMedicalOffers(
        accountId,
        proposalId,
        'disability',
        configId,
      );

      if (userType === 'broker') {
        const groupMembers = thunkApi.getState().groupMembers.groupMembers;

        const brokerOffer = {
          ...brokerConfig,
          issuer_name: 'broker',
          status: 'selected',
        };

        const updatedResponse = [brokerOffer, ...response];

        const calculatedResponse = updatedResponse?.map((item) => {
          if (item?.status !== 'pending') {
            let calculatedRates = {};

            if (item?.rate_tier_type === 'employee') {
              calculatedRates = calculateDisabilityRates(groupMembers, item);
            }

            if (item?.rate_tier_type !== 'employee') {
              calculatedRates = calculateDisabilityAgeRates(groupMembers, item);
            }

            return { ...item, calculation: calculatedRates };
          }
          return item;
        });

        return setOffers(calculatedResponse);
      }

      return setOffers(response);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

export const getDisabilityQuotes = createAsyncThunk(
  'disability-configuration/get-disability-quotes',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, configId, brokerConfig, setOffers, setIsLoading, read_token } =
        data;
      setIsLoading(true);

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.planConfiguration.getMedicalQuotes(
        accountId,
        proposalId,
        'disability',
        configId,
        read_token,
      );

      const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      const brokerOffer = {
        ...brokerConfig,
        issuer_name: 'broker',
        status: 'selected',
      };

      const updatedResponse = [brokerOffer, ...response];

      const calculatedResponse = updatedResponse?.map((item) => {
        let calculatedRates = {};

        if (item?.rate_tier_type === 'employee') {
          calculatedRates = calculateDisabilityRates(groupMembers, item);
        }

        if (item?.rate_tier_type !== 'employee') {
          calculatedRates = calculateDisabilityAgeRates(groupMembers, item);
        }

        return { ...item, calculation: calculatedRates };
      });

      return setOffers(calculatedResponse);
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

// ISSUER

export const getIssuerDisabilityPlans = createAsyncThunk(
  'issuer/get-issuer-disability-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const response = await api.planConfiguration.getDisabilityPlans(accountId, proposalId);

      return response;
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createDisabilityOffer = createAsyncThunk(
  'issuer/create-disability-offer',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, config_id } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      const payload = {
        ...data,
      };

      delete payload.accountId;
      delete payload.proposal_id;
      delete payload.is_current_rate;
      delete payload.proposalId;
      delete payload.order_id;

      const response = await api.issuer.createCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'disability',
        payload,
      );

      toast('Your disability offer was successfully saved!', { type: 'success' });

      thunkApi.dispatch(getIssuerDisabilityPlans({ accountId, proposalId }));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const updateDisabilityOffer = createAsyncThunk(
  'issuer/update-disability-offer',
  async (data, thunkApi) => {
    try {
      const { payload, offerId, read_token, setUpdatedOffer } = data;

      const { accountId, proposalId, config_id, isBroker } = payload;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      // if (read_token) {
      //   api.apiClient.setToken(read_token);
      // }

      delete payload.issuer_name;
      delete payload.accountId;
      delete payload.proposalId;
      delete payload.isBroker;
      delete payload.calculation;

      const response = await api.issuer.updateCarrierOffer(
        accountId,
        proposalId,
        config_id,
        'disability',
        payload,
        offerId,
        read_token,
      );

      toast('Your disability offer was successfully updated!', { type: 'success' });
      thunkApi.dispatch(getIssuerDisabilityPlans({ accountId, proposalId }));

      if (isBroker) {
        if (setUpdatedOffer) {
          setUpdatedOffer([response]);
        }
      }

      const disabilityResponse = await api.planConfiguration.getDisabilityPlans(
        accountId,
        proposalId,
        read_token,
      );

      thunkApi.dispatch(setDisabilityPlans(disabilityResponse));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const deleteDisabilitOffer = createAsyncThunk(
  'issuer/delete-disability-offer',
  async (data) => {
    try {
      const { accountId, proposalId, configId, offerId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.issuer.deleteCarrierOffer(accountId, proposalId, 'disability', configId, offerId);
    } catch (err) {
      console.error(err);
    }
  },
);

// CLEAR STATE

export const clearDisabilityState = createAsyncThunk('disability/clear-state', (data, thunkApi) => {
  thunkApi.dispatch(clearDisabilityConfigurationState());
});
