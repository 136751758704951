import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Tooltip } from '@mui/material';
import _ from 'lodash';

import {
  createHospitalOffer,
  deleteHospitalOffer,
  updateHospitalOffer,
} from 'src/store/hospitalConfiguration';

import { Button, DropZone, Input, Modal, SelectOption, ExtraRows } from 'src/components';

import { rateValueRegex } from 'src/constants/regularExpression';
import { HOSPITAL_FORM_NAMES } from 'src/constants/formNames';
import { TOOLTIP_NAMES } from 'src/constants/tooltipNames';
import {
  normalizeCompensationAmount,
  normalizeCompensationAmountEdit,
  normalizeCoverageAgeRates,
  normalizeRateTierType,
} from 'src/constants/requestProposal';

import {
  coverageAllAges,
  normalizeAllAgeRates,
} from 'src/features/proposal/coverageAgeBandedRates/coverageAllAges';
import {
  coverageFiveYearAges,
  normalizeCoverageFiveYearAgeRates,
} from 'src/features/proposal/coverageAgeBandedRates/coverageFiveYearAges';
import {
  coverageTenYearAges,
  normalizeCoverageTenYearAgeRates,
} from 'src/features/proposal/coverageAgeBandedRates/coverageTenYearAges';
import { CoverageAgeBandedRates } from 'src/features/proposal/coverageAgeBandedRates/CoverageAgeBandedRates';

import classes from './hospitalConfigurationForm.module.scss';
import { toast } from 'react-toastify';
import { brokerAgencySelector } from 'src/store/agency';
import { compensationAmountData } from 'src/features/dental/dentalForm/dentalFormConstants';
import { compensationMethodData } from 'src/features/medical/medicalForm/medicalFormConstants';

export const HospitalConfigurationForm = (props) => {
  const {
    brokerConfig,
    hospitalOffers,
    userId,
    issuerId,
    id,
    proposalId,
    accountId,
    getHospitalOffers,
    getHospitalQuotes,
  } = props;
  const dispatch = useDispatch();

  const brokerAgency = useSelector(brokerAgencySelector);

  const [hospitalConfigs, setHospitalConfigs] = useState([]);
  const [originalConfig, setOriginalConfig] = useState({});
  const [selectedConfig, setSelectedConfig] = useState({});
  const [extraRows, setExtraRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedDeleteConfig, setSelectedDeleteConfig] = useState({});

  const [isVisibleSelect, setIsVisibleSelect] = useState(false);
  const [isVisibleCompensationMethod, setIsVisibleCompensationMethod] = useState(false);
  const [isVisibleCompensationAmount, setIsVisibleCompensationAmount] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const originalId = hospitalOffers?.find((item) => item.issuer_name)?.user_id;

  useEffect(() => {
    setHospitalConfigs([...hospitalOffers]);
  }, [hospitalOffers]);

  const tierOptions = [
    { key: '3tier', name: '3 Tier' },
    { key: '4tier', name: '4 Tier' },
    { key: 'all_ages', name: 'All Ages' },
    { key: 'banded_5', name: '5 Year Banded' },
    { key: 'banded_10', name: '10 Year Banded' },
  ];

  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig, selectedConfig));
  }, [originalConfig, selectedConfig]);

  useEffect(() => {
    setIsEdited(!_.isEqual(originalConfig?.custom_fields, extraRows));
  }, [extraRows, originalConfig?.custom_fields]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      const selectedHospitalConfig = hospitalConfigs?.find(
        (item) => item.id === selectedConfig?.id,
      );
      if (selectedHospitalConfig?.custom_fields?.length) {
        for (const originalField of selectedHospitalConfig?.custom_fields) {
          for (const carrierField of extraRows) {
            if (originalField.id === carrierField.id) {
              if (originalField.name !== carrierField.name) {
                setIsEdited(true);
              }

              if (originalField.value !== carrierField.value) {
                setIsEdited(true);
              }
            }
          }
        }
      }
    }
  }, [hospitalConfigs, selectedConfig, extraRows]);

  const onClickAddOffer = useCallback(() => {
    const payload = {
      ...brokerConfig,
      config_id: brokerConfig?.id,
      id: uuid(),
      issuer_id: issuerId,
      rate_ee: null,
      rate_ee_1: null,
      rate_ee_ch: null,
      rate_ee_sp: null,
      rate_fam: null,
      comp_amount: normalizeCompensationAmountEdit(brokerConfig),
      // 2 cases - 1 for broker side, second one for carrier side
      user_id: userId !== originalId ? originalId : userId,
    };

    setSelectedConfig(payload);
    setHospitalConfigs((state) => [...state, payload]);
  }, [brokerConfig, issuerId, originalId, userId]);

  const onClickEditConfig = useCallback(
    (item) => () => {
      const originalConfig = hospitalConfigs?.find((offer) => offer.id === item.id);

      const originalPayload = {
        ...originalConfig,
        rate_ee: originalConfig.rate_ee ? originalConfig.rate_ee.toString() : null,
        rate_ee_1: originalConfig.rate_ee_1 ? originalConfig.rate_ee_1.toString() : null,
        rate_ee_ch: originalConfig.rate_ee_ch ? originalConfig.rate_ee_ch.toString() : null,
        rate_ee_sp: originalConfig.rate_ee_sp ? originalConfig.rate_ee_sp.toString() : null,
        rate_fam: originalConfig.rate_fam ? originalConfig.rate_fam.toString() : null,
        comp_amount: normalizeCompensationAmountEdit(originalConfig),
      };

      const clonedCustomFields = _.cloneDeep(item?.custom_fields);
      const selectedPayload = {
        ...item,
        custom_fields: clonedCustomFields,
        rate_ee: item.rate_ee ? item.rate_ee.toString() : 0,
        rate_ee_1: item.rate_ee_1 ? item.rate_ee_1.toString() : 0,
        rate_ee_ch: item.rate_ee_ch ? item.rate_ee_ch.toString() : 0,
        rate_ee_sp: item.rate_ee_sp ? item.rate_ee_sp.toString() : 0,
        rate_fam: item.rate_fam ? item.rate_fam.toString() : 0,
        comp_amount: normalizeCompensationAmountEdit(item),
      };

      setOriginalConfig(originalPayload);
      setSelectedConfig(selectedPayload);
      setExtraRows(clonedCustomFields ?? []);
    },
    [hospitalConfigs],
  );

  const handleOpenCarrierSelect = useCallback(
    (setCarrierSelectValue) => () => {
      setCarrierSelectValue((prev) => !prev);
    },
    [],
  );

  const onChangePercentage = useCallback((event, target) => {
    if (target === 'comp_amount_pct') {
      setSelectedConfig((prev) => ({
        ...prev,
        comp_pppm_dollar: null,
        comp_pepm_dollar: null,
      }));
    }

    if (event.target.value.length > 3) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: Number(event.target.value.slice(0, 3)),
      }));
    }
    if (event.target.value < 0) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 0,
      }));
    }
    if (event.target.value > 100) {
      return setSelectedConfig((prev) => ({
        ...prev,
        [target]: 100,
      }));
    }

    setSelectedConfig((prev) => ({
      ...prev,
      [target]: event.target.value,
    }));
  }, []);

  const onChangePepmPppmValue = useCallback((event, target) => {
    if (target === 'comp_pepm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pppm_dollar: null }));
    }

    if (target === 'comp_pppm_dollar') {
      setSelectedConfig((prev) => ({ ...prev, comp_amount_pct: null, comp_pepm_dollar: null }));
    }

    if (event.target.value.includes('.')) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 4) }));
    }

    if (event.target.value > 3) {
      return setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value.slice(0, 3) }));
    }

    setSelectedConfig((prev) => ({ ...prev, [target]: event.target.value }));
  }, []);

  const onSelectOption = useCallback(
    (value, target, setIsVisible) => () => {
      let payload = {};
      setIsVisible(false);

      if (value === 'yes' || value === 'no') {
        payload = {
          ...selectedConfig,
          [target]: value === 'yes' ? true : false,
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onChangeInputValue = useCallback(
    (event, target, maxRange) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };

        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value > maxRange ? maxRange : event.target.value,
      };

      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const onFocusInput = (event, target) => {
    if (Number(event.target.value) === 0) {
      const payload = {
        ...selectedConfig,
        [target]: '',
      };
      return setSelectedConfig(payload);
    }
  };

  const onBlurInput = (event, target) => {
    if (!event.target.value) {
      const payload = {
        ...selectedConfig,
        [target]: 0,
      };
      return setSelectedConfig(payload);
    }

    if (
      !event.target.value.includes('.') &&
      (event.target.value[0] === '0' || event.target.value[0] === 0)
    ) {
      const payload = {
        ...selectedConfig,
        [target]: event.target.value.slice(1) ? event.target.value.slice(1) : 0,
      };
      return setSelectedConfig(payload);
    }
  };

  const onClickCancel = useCallback(() => {
    setOriginalConfig({});
    setSelectedConfig({});
    setHospitalConfigs(hospitalOffers);
    setExtraRows([]);
  }, [hospitalOffers]);

  const onChangeRateValue = useCallback(
    (event, target) => {
      let payload = {};

      if (event.target.value < 0) {
        payload = {
          ...selectedConfig,
          [target]: 0,
        };
        return setSelectedConfig(payload);
      }

      if (!event.target.value.match(rateValueRegex)) {
        payload = {
          ...selectedConfig,
          [target]: event.target.value.slice(0, event.target.value.length - 1),
        };
        return setSelectedConfig(payload);
      }

      payload = {
        ...selectedConfig,
        [target]: event.target.value,
      };
      setSelectedConfig(payload);
    },
    [selectedConfig],
  );

  const [brokerAgeBandedRates, setBrokerAgebandedRates] = useState();

  useEffect(() => {
    let payload = {};

    for (const item of Object.keys(brokerConfig)) {
      if (item.includes('age')) {
        payload = {
          ...payload,
          [item]: brokerConfig[item],
        };
      }

      setBrokerAgebandedRates(payload);
    }
  }, [brokerConfig]);

  const [selectedAllAges, setSelectedAllAges] = useState();
  const [selectedAllAgesPayload, setSelectedAllAgesPayload] = useState();
  const [selectedFiveYearAges, setSelectedFiveYearAges] = useState();
  const [selectedFiveYearAgesPayload, setSelectedFiveYearAgesPayload] = useState();
  const [selectedTenYearAges, setSelectedTenYearAges] = useState();
  const [selectedTenYearAgesPayload, setSelectedTenYearAgesPayload] = useState();
  const [isEditedRates, setIsEditedRates] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      let ageRatesPayload = {};

      for (const item of Object.keys(brokerConfig)) {
        if (item.includes('age')) {
          ageRatesPayload = {
            ...ageRatesPayload,
            [item]: brokerConfig[item],
          };
        }
      }

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        setIsEditedRates(!_.isEqual(ageRatesPayload, selectedAllAgesPayload));
      }

      if (selectedConfig?.rate_tier_type === 'banded_5') {
        setIsEditedRates(!_.isEqual(ageRatesPayload, selectedFiveYearAgesPayload));
      }

      if (selectedConfig?.rate_tier_type === 'banded_10') {
        setIsEditedRates(!_.isEqual(ageRatesPayload, selectedTenYearAgesPayload));
      }
    }
  }, [
    brokerConfig,
    selectedAllAges,
    selectedAllAgesPayload,
    selectedConfig,
    selectedFiveYearAges,
    selectedFiveYearAgesPayload,
    selectedTenYearAges,
    selectedTenYearAgesPayload,
  ]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      if (selectedConfig?.rate_tier_type === 'all_ages') {
        const payload = coverageAllAges(selectedConfig);
        setSelectedAllAges(payload);
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        const payload = coverageFiveYearAges(selectedConfig);
        setSelectedFiveYearAges(payload);
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        const payload = coverageTenYearAges(selectedConfig);
        setSelectedTenYearAges(payload);
      }
    }
  }, [selectedConfig]);

  useEffect(() => {
    if (!_.isEmpty(selectedConfig)) {
      if (!_.isEmpty(selectedAllAges)) {
        const payload = normalizeAllAgeRates(selectedAllAges);

        setSelectedAllAgesPayload(payload);
      }
      if (!_.isEmpty(selectedFiveYearAges)) {
        const payload = normalizeCoverageFiveYearAgeRates(selectedFiveYearAges);

        setSelectedFiveYearAgesPayload(payload);
      }
      if (!_.isEmpty(selectedTenYearAges)) {
        const payload = normalizeCoverageTenYearAgeRates(selectedTenYearAges);

        setSelectedTenYearAgesPayload(payload);
      }
    }
  }, [selectedAllAges, selectedConfig, selectedFiveYearAges, selectedTenYearAges]);

  const onSaveSelectedConfig = useCallback(async () => {
    const isOfferExist = !_.isEmpty(
      hospitalOffers?.find((item) => item?.id === selectedConfig?.id),
    );

    const data = {
      issuer_id: issuerId,
      config_id: id,
      proposalId: proposalId,
      accountId: accountId,
    };

    if (!isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        payload = {
          ...payload,
          ...selectedAllAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        payload = {
          ...payload,
          ...selectedFiveYearAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        payload = {
          ...payload,
          ...selectedTenYearAgesPayload,
        };
      }

      delete payload.calculation;

      await dispatch(createHospitalOffer(payload));

      if (getHospitalQuotes) {
        await getHospitalQuotes();
      }
    }

    if (isOfferExist) {
      let payload = {
        ...selectedConfig,
        custom_fields: extraRows,
        ...data,
        status: 'completed',
      };

      if (selectedConfig?.rate_tier_type === 'all_ages') {
        payload = {
          ...payload,
          ...selectedAllAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_5') {
        payload = {
          ...payload,
          ...selectedFiveYearAgesPayload,
        };
      }
      if (selectedConfig?.rate_tier_type === 'banded_10') {
        payload = {
          ...payload,
          ...selectedTenYearAgesPayload,
        };
      }

      delete payload.author;
      delete payload.calculation;

      await dispatch(updateHospitalOffer({ payload, offerId: selectedConfig?.id }));

      if (getHospitalQuotes) {
        await getHospitalQuotes();
      }
    }
  }, [
    hospitalOffers,
    issuerId,
    id,
    proposalId,
    accountId,
    selectedConfig,
    extraRows,
    dispatch,
    getHospitalQuotes,
    selectedAllAgesPayload,
    selectedFiveYearAgesPayload,
    selectedTenYearAgesPayload,
  ]);

  const onAddExtraRow = useCallback(() => {
    if (!_.isEmpty(brokerAgency) && brokerAgency.status !== 'active') {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    if (_.isEmpty(brokerAgency)) {
      if (extraRows.length === 3) {
        toast('You can add only 3 rows', { type: 'warning' });
        return;
      }
    }

    const payload = {
      id: uuid(),
      title: '',
      value: '',
    };
    setExtraRows((state) => [...state, payload]);
  }, [extraRows.length, brokerAgency]);

  const isVisibleButtons = useCallback((status) => {
    if (status === 'approved' || status === 'selected') {
      return false;
    }
    return true;
  }, []);

  const onClickDelete = useCallback(
    (item) => async () => {
      const payload = {
        accountId,
        proposalId,
        configId: item.config_id,
        offerId: item.id,
      };

      setIsDeleteModal(true);
      setSelectedDeleteConfig(payload);
    },
    [accountId, proposalId],
  );

  const onCloseDeleteModal = useCallback(() => {
    setIsDeleteModal(false);
    setSelectedDeleteConfig({});
  }, []);

  const onConfirmDelete = useCallback(async () => {
    await dispatch(deleteHospitalOffer(selectedDeleteConfig));
    await getHospitalOffers();
  }, [dispatch, getHospitalOffers, selectedDeleteConfig]);

  const onRejectOffer = useCallback(async () => {
    const offer = hospitalOffers[0];

    const payload = {
      proposalId,
      accountId,
      ...offer,
      status: 'declined',
    };

    if (!_.isEmpty(offer)) {
      delete payload?.rep;
      delete payload.issuer_name;
      delete payload.calculation;
      const offerId = offer.id;

      await dispatch(updateHospitalOffer({ payload, offerId }));

      if (getHospitalQuotes) {
        await getHospitalQuotes();
      }
    }
  }, [dispatch, hospitalOffers, accountId, proposalId, getHospitalQuotes]);

  const isFourTier = !_.isEmpty(hospitalConfigs?.find((item) => item.rate_tier_type === '4tier'));
  const isDeclined = !_.isEmpty(hospitalConfigs?.find((item) => item.status === 'declined'));
  const brokerTierType =
    brokerConfig?.rate_tier_type === '3tier' || brokerConfig?.rate_tier_type === '4tier';
  const offerTierType =
    selectedConfig?.rate_tier_type === '3tier' || selectedConfig?.rate_tier_type === '4tier';

  const compensationAmount = compensationAmountData(
    selectedConfig,
    onChangePercentage,
    onChangePepmPppmValue,
  );

  return (
    <>
      {isDeleteModal && (
        <Modal type="small" closeButtonType="inside" onClose={onCloseDeleteModal}>
          <div className={classes.ModalContent}>
            <div className={classes.ModalContentTitle}>
              Are you sure you want to delete this offer?
            </div>
            <div className={classes.ModalContentFooter}>
              <Button type="secondary" title="Cancel" onClick={onCloseDeleteModal} />
              <Button type="danger" title="Yes" onClick={onConfirmDelete} />
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.HospitalConfigurationContentWrapper}>
        {!isDeclined && _.isEmpty(selectedConfig) && (
          <div className={classes.HospitalConfigurationContentBodyOffer}>
            <Button
              title="Add Offer"
              type="primary"
              onClick={onClickAddOffer}
              isDisabled={!_.isEmpty(selectedConfig)}
            />
          </div>
        )}
        <div className={classes.HospitalConfigurationContentBody}>
          <div className={classes.HospitalConfigurationContentTier}>
            <table className={classes.HospitalConfigurationTierTable}>
              <thead className={classes.HospitalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.HospitalConfigurationTitleMain}>
                    Plan Configuration Form
                  </th>
                </tr>
                <tr>
                  <th>{HOSPITAL_FORM_NAMES.initial_hospital_confinement}</th>
                  <th>{HOSPITAL_FORM_NAMES.per_day_hospitalization}</th>
                  <th>Compensation Method</th>
                  <th>Compensation Amount</th>
                  <th>Current Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.HospitalConfigurationTierTableBroker}>
                  <th className={classes.HospitalConfigurationTitle}>Broker Request</th>
                  <td>${brokerConfig?.initial_hospital_confinement}</td>
                  <td>${brokerConfig?.per_day_hospitalization}</td>
                  <td>{brokerConfig?.comp_method}</td>
                  <td>{normalizeCompensationAmount(brokerConfig)}</td>
                  <td>{brokerConfig?.is_current_rate ? 'Yes' : 'No'}</td>
                </tr>
                {hospitalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <th className={classes.HospitalConfigurationTitle}>Carrier Reply</th>
                      <td>
                        {!isEditMode ? (
                          `$${item.initial_hospital_confinement}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.initial_hospital_confinement}
                            onChange={(event) =>
                              onChangeInputValue(event, 'initial_hospital_confinement')
                            }
                            onFocus={(event) => onFocusInput(event, 'initial_hospital_confinement')}
                            onBlur={(event) => onBlurInput(event, 'initial_hospital_confinement')}
                            type="number"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          `$${item.per_day_hospitalization}`
                        ) : (
                          <Input
                            placeholder="Placeholder"
                            value={selectedConfig?.per_day_hospitalization}
                            onChange={(event) =>
                              onChangeInputValue(event, 'per_day_hospitalization')
                            }
                            onFocus={(event) => onFocusInput(event, 'per_day_hospitalization')}
                            onBlur={(event) => onBlurInput(event, 'per_day_hospitalization')}
                            type="number"
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          item.comp_method
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationMethodData}
                            selectedOption={selectedConfig?.comp_method}
                            isVisibleSelect={isVisibleCompensationMethod}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationMethod,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_method', setIsVisibleCompensationMethod)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>
                        {!isEditMode ? (
                          normalizeCompensationAmount(item)
                        ) : (
                          <SelectOption
                            height="30px"
                            width="142px"
                            options={compensationAmount}
                            selectedOption={selectedConfig?.comp_amount}
                            isVisibleSelect={isVisibleCompensationAmount}
                            handleOpenSelect={handleOpenCarrierSelect(
                              setIsVisibleCompensationAmount,
                            )}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'comp_amount', setIsVisibleCompensationAmount)
                            }
                            isAbsolute
                          />
                        )}
                      </td>
                      <td>{item?.is_current_rate ? 'Yes' : 'No'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.HospitalConfigurationContentTier}>
            <table className={classes.HospitalConfigurationTierTable}>
              <thead className={classes.HospitalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Custom Fields</th>
                </tr>
                <tr>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.title}</span>
                    </td>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr
                  className={classes.HospitalConfigurationTierTableBroker}
                  style={{ backgroundColor: '#e5e5e5' }}
                >
                  <td className={classes.EmptyRow}></td>
                  {brokerConfig.custom_fields?.map((item) => (
                    <td key={item.id}>
                      <span>{item.value}</span>
                    </td>
                  ))}
                </tr>
                {hospitalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      {!isEditMode ? (
                        item.custom_fields?.map((item) => (
                          <td key={item.id}>
                            <span>{item.value}</span>
                          </td>
                        ))
                      ) : (
                        <div className={classes.ExtraRowButtonWrapper}>
                          <ExtraRows
                            extraRows={extraRows}
                            setExtraRows={setExtraRows}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            isDisabled={!isEditMode}
                          />

                          <div className={classes.ExtraRowButton}>
                            <Button
                              title="Add Extra Row"
                              type="secondary"
                              onClick={onAddExtraRow}
                              isDisabled={!isEditMode}
                            />

                            <Tooltip title={TOOLTIP_NAMES.MAX_ROWS}>
                              <i className="fas fa-info-circle" />
                            </Tooltip>
                          </div>
                        </div>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.HospitalConfigurationContentTier}>
            <table className={classes.HospitalConfigurationTierTable}>
              <thead className={classes.HospitalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}>Rates</th>
                </tr>
                <tr>
                  <th className={classes.TierTypeRow}>Rate Tier Type</th>
                </tr>
                {(brokerTierType || offerTierType) && (
                  <>
                    <tr>
                      <th>Employee</th>
                    </tr>
                    {(brokerConfig?.rate_tier_type === '3tier' ||
                      selectedConfig?.rate_tier_type === '3tier') && (
                      <>
                        <tr>
                          <th>Employee + 1</th>
                        </tr>
                      </>
                    )}
                    {(brokerConfig?.rate_tier_type === '4tier' ||
                      selectedConfig?.rate_tier_type === '4tier' ||
                      isFourTier) && (
                      <>
                        <tr>
                          <th>Employee + Spouse</th>
                        </tr>
                        <tr>
                          <th>Employee + Child(ren)</th>
                        </tr>
                      </>
                    )}
                    <tr>
                      <th>Family</th>
                    </tr>
                  </>
                )}
              </thead>
              <tbody>
                <tr className={classes.HospitalConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                  <td className={classes.TierTypeRow}>
                    {normalizeRateTierType(brokerConfig?.rate_tier_type)}
                  </td>
                  <div className={classes.AgeBandedRates}>
                    <CoverageAgeBandedRates
                      tierType={brokerConfig?.rate_tier_type}
                      ageBandedRates={brokerAgeBandedRates}
                      isEditMode={false}
                      isIssuer={true}
                    />
                  </div>
                  {brokerTierType && (brokerTierType || offerTierType) && (
                    <>
                      <td>{brokerConfig?.rate_ee && `$${brokerConfig?.rate_ee}`}</td>
                      {(brokerConfig?.rate_tier_type === '3tier' ||
                        selectedConfig?.rate_tier_type === '3tier') && (
                        <td>{brokerConfig?.rate_ee_1 && `$${brokerConfig?.rate_ee_1}`}</td>
                      )}
                      {(brokerConfig?.rate_tier_type === '4tier' ||
                        selectedConfig?.rate_tier_type === '4tier' ||
                        isFourTier) && (
                        <>
                          <td>{brokerConfig?.rate_ee_sp && `$${brokerConfig?.rate_ee_sp}`}</td>
                          <td>{brokerConfig?.rate_ee_ch && `$${brokerConfig?.rate_ee_ch}`}</td>
                        </>
                      )}
                      <td>{brokerConfig?.rate_fam && `$${brokerConfig?.rate_fam}`}</td>
                    </>
                  )}
                  <td className={classes.EmptyDropZoneRow}>
                    <DropZone
                      label="Current Benefits Summary"
                      maxFiles={100}
                      path={`/proposals/${proposalId}/uploads/broker/plan-configs/${id}/`}
                      isDisabled={true}
                    />
                  </td>
                </tr>
                {hospitalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  const isConfigTierType =
                    item?.rate_tier_type === '3tier' || item?.rate_tier_type === '4tier';
                  const isSelectedConfigTierType =
                    selectedConfig?.rate_tier_type === '3tier' ||
                    selectedConfig?.rate_tier_type === '4tier';

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyRow}></td>
                      <td className={classes.TierTypeRow}>
                        {!isEditMode ? (
                          normalizeRateTierType(item.rate_tier_type)
                        ) : (
                          <SelectOption
                            label="Rate Tier Type"
                            width="140px"
                            options={tierOptions}
                            selectedOption={selectedConfig?.rate_tier_type}
                            isVisibleSelect={isVisibleSelect}
                            handleOpenSelect={handleOpenCarrierSelect(setIsVisibleSelect)}
                            onSelectOption={(event) =>
                              onSelectOption(event, 'rate_tier_type', setIsVisibleSelect)
                            }
                          />
                        )}
                      </td>
                      <div className={classes.AgeBandedRates}>
                        <CoverageAgeBandedRates
                          tierType={
                            !isEditMode ? item?.rate_tier_type : selectedConfig?.rate_tier_type
                          }
                          ageBandedRates={
                            !isEditMode
                              ? normalizeCoverageAgeRates(item)
                              : normalizeCoverageAgeRates(selectedConfig)
                          }
                          selectedAllAges={!isEditMode ? coverageAllAges(item) : selectedAllAges}
                          selectedFiveYearAges={
                            !isEditMode ? coverageFiveYearAges(item) : selectedFiveYearAges
                          }
                          selectedTenYearAges={
                            !isEditMode ? coverageTenYearAges(item) : selectedTenYearAges
                          }
                          setSelectedAllAges={setSelectedAllAges}
                          setSelectedFiveYearAges={setSelectedFiveYearAges}
                          setSelectedTenYearAges={setSelectedTenYearAges}
                          isEditMode={isEditMode}
                          isIssuer={true}
                        />
                      </div>
                      {((isConfigTierType && !isEditMode) || isSelectedConfigTierType) && (
                        <>
                          <td>
                            {!isEditMode ? (
                              (item.rate_ee || item.rate_ee === 0) && `$${item.rate_ee}`
                            ) : (
                              <Input
                                value={selectedConfig?.rate_ee}
                                onChange={(event) => onChangeRateValue(event, 'rate_ee')}
                                onFocus={(event) => onFocusInput(event, 'rate_ee')}
                                onBlur={(event) => onBlurInput(event, 'rate_ee')}
                                type="number"
                              />
                            )}
                          </td>
                          {selectedConfig?.rate_tier_type === '3tier' ||
                          (!isEditMode && item.rate_tier_type === '3tier') ? (
                            <>
                              <td>
                                {!isEditMode ? (
                                  (item.rate_ee_1 || item.rate_ee_1 === 0) && `$${item.rate_ee_1}`
                                ) : (
                                  <Input
                                    value={selectedConfig?.rate_ee_1}
                                    onChange={(event) => onChangeRateValue(event, 'rate_ee_1')}
                                    onFocus={(event) => onFocusInput(event, 'rate_ee_1')}
                                    onBlur={(event) => onBlurInput(event, 'rate_ee_1')}
                                    type="number"
                                  />
                                )}
                              </td>
                              {(brokerConfig?.rate_tier_type === '4tier' ||
                                selectedConfig?.rate_tier_type === '4tier' ||
                                isFourTier) && <td></td>}
                              {(brokerConfig?.rate_tier_type === '4tier' ||
                                selectedConfig?.rate_tier_type === '4tier' ||
                                isFourTier) && <td></td>}
                            </>
                          ) : (
                            <>
                              {brokerConfig?.rate_tier_type === '3tier' && <td></td>}
                              <td>
                                {!isEditMode ? (
                                  (item.rate_ee_sp || item.rate_ee_sp === 0) &&
                                  `$${item.rate_ee_sp}`
                                ) : (
                                  <Input
                                    value={selectedConfig?.rate_ee_sp}
                                    onChange={(event) => onChangeRateValue(event, 'rate_ee_sp')}
                                    onFocus={(event) => onFocusInput(event, 'rate_ee_sp')}
                                    onBlur={(event) => onBlurInput(event, 'rate_ee_sp')}
                                    type="number"
                                  />
                                )}
                              </td>
                              <td>
                                {!isEditMode ? (
                                  (item.rate_ee_ch || item.rate_ee_ch === 0) &&
                                  `$${item.rate_ee_ch}`
                                ) : (
                                  <Input
                                    value={selectedConfig?.rate_ee_ch}
                                    onChange={(event) => onChangeRateValue(event, 'rate_ee_ch')}
                                    onFocus={(event) => onFocusInput(event, 'rate_ee_ch')}
                                    onBlur={(event) => onBlurInput(event, 'rate_ee_ch')}
                                    type="number"
                                  />
                                )}
                              </td>
                            </>
                          )}
                          <td>
                            {!isEditMode ? (
                              (item.rate_fam || item.rate_fam === 0) && `$${item.rate_fam}`
                            ) : (
                              <Input
                                value={selectedConfig?.rate_fam}
                                onChange={(event) => onChangeRateValue(event, 'rate_fam')}
                                onFocus={(event) => onFocusInput(event, 'rate_fam')}
                                onBlur={(event) => onBlurInput(event, 'rate_fam')}
                                type="number"
                              />
                            )}
                          </td>
                        </>
                      )}
                      <td className={classes.EmptyDropZoneRow}>
                        <DropZone
                          label="Proposed Benefits Summary"
                          maxFiles={100}
                          path={`/proposals/${proposalId}/uploads/carrier/plan-configs/${id}/offers/${item?.id}/`}
                          isDisabled={!isEditMode}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className={classes.HospitalConfigurationContentTier}>
            <table className={classes.HospitalConfigurationTierTable}>
              <thead className={classes.HospitalConfigurationTierTableHead}>
                <tr>
                  <th className={classes.EmptyRow}></th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.HospitalConfigurationTierTableBroker}>
                  <td className={classes.EmptyRow}></td>
                </tr>
                {hospitalConfigs?.map((item) => {
                  const isEditMode = item.id === selectedConfig?.id;

                  return (
                    <tr key={item?.id}>
                      <td className={classes.EmptyButtonRow}>
                        {!isEditMode && (
                          <div className={classes.HospitalConfigurationEditTableItem}>
                            {isVisibleButtons(item?.status) && (
                              <div className={classes.HospitalConfigurationEditTableItemWrapper}>
                                <Button
                                  title="Edit"
                                  type="primary"
                                  onClick={onClickEditConfig(item)}
                                />
                                {item.status !== 'declined' && hospitalOffers.length > 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Delete" onClick={onClickDelete(item)} />
                                  </div>
                                )}
                                {item.status !== 'declined' && hospitalOffers.length === 1 && (
                                  <div className={classes.DeclineButton}>
                                    <Button title="Decline" onClick={onRejectOffer} />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {isEditMode && (
                          <div className={classes.HospitalConfigurationContentFooter}>
                            {(isEdited || isEditedRates) && (
                              <Button title="Save" type="primary" onClick={onSaveSelectedConfig} />
                            )}
                            <Button title="Cancel" type="secondary" onClick={onClickCancel} />
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
