import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { Loader, LoaderWrapper } from 'src/components';

import ArrowIcon from 'src/assets/carrier/greyArrow.svg';

import classes from './selectOption.module.scss';

export const SelectOption = (props) => {
  const {
    label,
    options,
    width,
    height,
    selectedOption,
    isVisibleSelect,
    handleOpenSelect,
    onSelectOption,
    inputValue,
    onChangeInput,
    type = 'select',
    isAbsolute = false,
    isDisabled,
    isRequired = false,
    isLogo = false,
    dropDownType = 'down',
    isLoading,
  } = props;

  const selectRef = useRef();
  const [isPossibleToOpen, setIsPossibleToOpen] = useState(false);

  const wrapperInline = {
    width: width,
    height: height,
    cursor: !isDisabled ? 'pointer' : 'not-allowed',
    borderBottom: dropDownType === 'up' && '1px solid #dbdbdb',
    borderTop: isVisibleSelect && '1px solid #dbdbdb',
    borderRight: isVisibleSelect && '1px solid #dbdbdb',
    borderLeft: isVisibleSelect && '1px solid #dbdbdb',
    borderRadius: isVisibleSelect && dropDownType === 'up' && '0 0 5px 5px',
  };
  const dropDownInline = {
    width: width,
    maxHeight: options?.length > 4 && '300px',
    overflowY: options?.length > 4 && 'scroll',
    bottom: dropDownType === 'up' ? height : 'unset',
    borderRadius: isVisibleSelect && dropDownType === 'up' && '5px 5px 0 0',
    display: isVisibleSelect && dropDownType === 'up' && 'flex',
    flexDirection: isVisibleSelect && dropDownType === 'up' && 'column-reverse',
  };

  const mouseIn = useCallback(() => {
    setIsPossibleToOpen(true);
  }, []);

  const mouseOut = useCallback(() => {
    setIsPossibleToOpen(false);
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (selectRef && !selectRef.current?.contains(event.target) && isVisibleSelect) {
        handleOpenSelect();
      }
    },
    [selectRef, handleOpenSelect, isVisibleSelect],
  );

  useEffect(() => {
    if (!isVisibleSelect) {
      setIsPossibleToOpen(false);
    }
  }, [isVisibleSelect]);

  useEffect(() => {
    if (!isPossibleToOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isPossibleToOpen]);

  const mainOption = options?.find((item) => item.key === selectedOption);
  console.log('mainOption', mainOption);
  console.log('selectedOption', selectedOption);

  const labelStyles = classNames(classes.SelectLabel, {
    [classes.SelectLabelDisabled]: !selectedOption,
    [classes.SelectLabelActive]: selectedOption,
  });

  return (
    <div
      className={classes.SelectWrapper}
      style={{ position: isAbsolute ? 'absolute' : 'relative' }}
    >
      <div
        className={
          isVisibleSelect && !isDisabled && type === 'select'
            ? classes.SelectActive
            : classes.Select
        }
        style={wrapperInline}
        onClick={!isPossibleToOpen ? handleOpenSelect : () => {}}
        ref={selectRef}
      >
        <div className={labelStyles}>
          {label}
          {isRequired && <div className={classes.SelectLabelAsterisk}>*</div>}
        </div>
        {type === 'input' && (
          <div className={classes.SelectInputWrapper}>
            <div className={classes.SelectInputDollar}>$</div>
            <input
              type="number"
              className={classes.SelectInput}
              value={inputValue}
              onChange={onChangeInput}
              disabled={isDisabled}
            />
          </div>
        )}
        {type === 'select' && (
          <div className={classes.SelectOption}>
            {mainOption?.key?.toString()?.includes('other') ? (
              <div
                className={classes.SelectInputWrapper}
                onMouseEnter={mouseIn}
                onMouseLeave={mouseOut}
              >
                {mainOption?.type === 'numeric' && (
                  <div className={classes.SelectInputDollar}>$</div>
                )}
                <input
                  type="number"
                  className={classes.SelectInput}
                  value={mainOption?.value}
                  onChange={mainOption?.onChange}
                  autoFocus
                  disabled={isDisabled}
                />
                {mainOption?.type === 'percentage' && (
                  <div className={classes.SelectInputPercent}>%</div>
                )}
              </div>
            ) : mainOption?.key?.toString() ? (
              <div className={classes.SelectOptionName}>
                {mainOption?.logo && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img src={mainOption?.logo} />
                )}
                <div className={classes.SelectOptionNameBase}>{mainOption?.name}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        {type === 'select' && (
          <div className={classes.SelectIconWrapper}>
            <img
              className={
                isVisibleSelect && !isDisabled ? classes.SelectIconActive : classes.SelectIcon
              }
              src={ArrowIcon}
              alt="ArrowIcon"
            />
          </div>
        )}
      </div>
      {isVisibleSelect && !isDisabled && type === 'select' && (
        <div
          className={classes.SelectContent}
          style={dropDownInline}
          onMouseEnter={mouseIn}
          onMouseLeave={mouseOut}
        >
          {isLoading ? (
            <div className={classes.LoaderWrapper}>
              <LoaderWrapper>
                <Loader size="small" />
              </LoaderWrapper>
            </div>
          ) : (
            options.map((item) => (
              <div
                key={item.key}
                className={
                  item.key === selectedOption
                    ? classes.SelectContentItemSelected
                    : classes.SelectContentItem
                }
                onClick={onSelectOption(item.key)}
              >
                {item?.logo && (
                  // eslint-disable-next-line jsx-a11y/alt-text
                  <img
                    src={item?.logo}
                    id={item.logo}
                    onError={() => (document.getElementById(item.logo).style.overflow = 'hidden')}
                  />
                )}
                <div style={{ marginLeft: !item?.logo && isLogo && '85px' }}>{item?.name}</div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};
