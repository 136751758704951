export const fundingTypeData = [
  { name: 'Fully Insured', key: 'fully_insured' },
  { name: 'Level Funded', key: 'level_funded' },
];

export const planTypeData = [
  { name: 'PPO', key: 'PPO' },
  { name: 'POS', key: 'POS' },
  { name: 'EPO', key: 'EPO' },
  { name: 'HMO', key: 'HMO' },
];

export const networkSizeData = [
  { name: 'Full', key: 'Full' },
  { name: 'Mid', key: 'Mid' },
  { name: 'Low', key: 'Low' },
];

export const tierData = [
  { name: 'Platinum', key: 'Platinum' },
  { name: 'Gold', key: 'Gold' },
  { name: 'Silver', key: 'Silver' },
  { name: 'Bronze', key: 'Bronze' },
];

export const inpatientFacilityOptions = [
  { name: 'Percent', key: true },
  { name: '$ Per Day', key: false },
];

export const primaryCareData = (onChange, value) => [
  { name: '$0', key: '0' },
  { name: '$5', key: '5' },
  { name: '$10', key: '10' },
  { name: '$15', key: '15' },
  { name: '$20', key: '20' },
  { name: '$25', key: '25' },
  { name: '$30', key: '30' },
  { name: '$35', key: '35' },
  { name: '$40', key: '40' },
  { name: '$45', key: '45' },
  { name: '$50', key: '50' },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, 'primary_care_physician_other'),
    // onFocus: (event) => onFocusInput(event, 'primary_care_physician_other'),
    // onBlur: (event) => onBlurInput(event, 'primary_care_physician_other'),
    value: value ?? '',
  },
];

export const specialistCopayData = (onChange, value) => [
  { name: '$25', key: '25' },
  { name: '$30', key: '30' },
  { name: '$35', key: '35' },
  { name: '$40', key: '40' },
  { name: '$45', key: '45' },
  { name: '$50', key: '50' },
  { name: '$75', key: '75' },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, 'specialist_other'),
    // onFocus: (event) => onFocusInput(event, setValue),
    // onBlur: (event) => onBlurInput(event, setValue),
    value: value ?? '',
  },
];

export const coinsuranceData = (onChange, value) => [
  { name: '100%', key: '100' },
  { name: '90%', key: '90' },
  { name: '80%', key: '80' },
  { name: '70%', key: '70' },
  { name: '60%', key: '60' },
  { name: '50%', key: '50' },
  { name: '0%', key: '0' },
  {
    name: 'Other: ',
    key: 'other',
    type: 'percentage',
    onChange: (event) => onChange(event, 'plan_coinsurance_other'),
    // onFocus: (event) => onFocusInput(event, setValue),
    // onBlur: (event) => onBlurInput(event, setValue),
    value: value ?? '',
  },
];

export const inpatientFacilityData = [
  { name: '$ per day', key: 'dollar_per_day' },
  { name: '% per day', key: 'percent_per_day' },
];

export const percentInpatientFacilityData = (onChange, value) => [
  { name: '0%', key: '0' },
  { name: '10%', key: '10' },
  { name: '20%', key: '20' },
  { name: '30%', key: '30' },
  { name: '40%', key: '40' },
  { name: '45%', key: '45' },
  { name: '50%', key: '50' },
  { name: '100%', key: '100' },
  {
    name: 'Other: ',
    key: 'other',
    type: 'percentage',
    onChange: (event) => onChange(event, 'inpatient_facility_percent_value_other'),
    // onFocus: (event) => onFocusInput(event, setValue),
    // onBlur: (event) => onBlurInput(event, setValue),
    value: value ?? '',
  },
];

export const inpatientFacilityDaysData = (onChange, value) => [
  { name: '1', key: '1' },
  { name: '2', key: '2' },
  { name: '3', key: '3' },
  { name: '4', key: '4' },
  { name: '5', key: '5' },
  { name: '6', key: '6' },
  {
    name: 'Other: ',
    key: 'other',
    type: 'text',
    onChange: (event) => onChange(event, 'inpatient_facility_dollar_days_other'),
    // onFocus: (event) => onFocusInput(event, setValue),
    // onBlur: (event) => onBlurInput(event, setValue),
    value: value ?? '',
  },
];

export const inpatientFacilityCostData = [
  { name: '$0', key: '0' },
  { name: '$100', key: '100' },
  { name: '$150', key: '150' },
  { name: '$200', key: '200' },
  { name: '$250', key: '250' },
  { name: '$300', key: '300' },
  { name: '$400', key: '400' },
  { name: '$500', key: '500' },
  { name: '$600', key: '600' },
  { name: '$700', key: '700' },
  { name: '$750', key: '750' },
];

export const emergencyRoomData = (onChange, onChangePct, dollarValue, percentValue) => [
  {
    name: '',
    key: 'dollar',
    type: 'numeric',
    onChange: (event) => onChange(event, 'emergency_room_dollar'),
    // onFocus: (event) => onFocusInput(event, setDollarValue),
    // onBlur: (event) => onBlurInput(event, setDollarValue),
    value: dollarValue ?? '',
  },
  {
    name: '',
    key: 'percent',
    type: 'percentage',
    onChange: (event) => onChangePct(event, 'emergency_room_percent'),
    // onFocus: (event) => onFocusInput(event, setPercentValue),
    // onBlur: (event) => onBlurInput(event, setPercentValue),
    value: percentValue ?? '',
  },
];

export const urgentCareData = (onChange, onChangePct, dollarValue, percentValue) => [
  {
    name: '',
    key: 'dollar',
    type: 'numeric',
    onChange: (event) => onChange(event, 'urgent_care_dollar'),
    // onFocus: (event) => onFocusInput(event, setDollarValue),
    // onBlur: (event) => onBlurInput(event, setDollarValue),
    value: dollarValue ?? '',
  },
  {
    name: '',
    key: 'percent',
    type: 'percentage',
    onChange: (event) => onChangePct(event, 'urgent_care_percent'),
    // onFocus: (event) => onFocusInput(event, setPercentValue),
    // onBlur: (event) => onBlurInput(event, setPercentValue),
    value: percentValue ?? '',
  },
];

export const compensationMethodData = [
  { name: 'Levelized', key: 'levelized' },
  { name: 'Heaped', key: 'heaped' },
];
