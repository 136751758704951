import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auth } from 'src/app/database';
import api from 'src/app/apiSingleton';

import { clearCoveragesBuilderState } from './coverageBuilder.slice';

import { getRequestedProposalsStatus, getSharedContacts } from '../planConfiguration';
import { calculateDentalVisionRates } from 'src/constants/dentalVisionRates';
import { ERROR } from 'src/constants/errorNames';
import {
  calculateConfigurationAgeRates,
  calculateConfigurationRates,
  calculateLifeConfigurationAgeRates,
} from 'src/constants/calculateConfigurationRates';
import { calculateMedicalAgeRates, calculateMedicalRates } from 'src/constants/medicalRates';
import { calculateLifeRates, calculateUniLifeRates } from 'src/constants/lifeRates';
import {
  calculateDisabilityAgeRates,
  calculateDisabilityRates,
} from 'src/constants/disabilityRates';
import { COVERAGE_NAMES } from 'src/features/coverageBuilder/cb_constants';

export const createCoveragePlan = createAsyncThunk(
  'coverage-builder/create-coverage-plan',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.coverageBuilder.createCoveragePlan(accountId, proposalId, coverage, payload);
      await thunkApi.dispatch(getCoveragePlans({ accountId, proposalId, coverage }));
      await thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
      toast('Proposal was created successfully', { type: 'success' });
    } catch (e) {
      console.warn(e);
    }
  },
);

export const getCoveragePlans = createAsyncThunk(
  'coverage-builder/get-coverage-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.coverageBuilder.getCoveragePlans(
        accountId,
        proposalId,
        coverage,
        read_token,
      );

      return { coverage, response };
    } catch (err) {
      console.warn(err);
    }
  },
);

export const createMultipleCoveragePlans = createAsyncThunk(
  'coverage-builder/create-multiple-coverage-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, plans } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      for (const plan of plans) {
        delete plan.template_name;
        delete plan.broker_id;
        delete plan.created_timestamp;
        delete plan.updated_timestamp;

        if (plan.is_configured) {
          await api.coverageBuilder.createCoveragePlan(accountId, proposalId, coverage, plan);
        }
      }

      await thunkApi.dispatch(getCoveragePlans({ accountId, proposalId, coverage }));
      await thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
      toast('Proposal(-s) was created successfully', { type: 'success' });
    } catch (e) {
      console.warn(e);
    }
  },
);

export const updateCoveragePlan = createAsyncThunk(
  'coverage-builder/update-coverage-plan',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.coverageBuilder.updateCoveragePlan(accountId, proposalId, coverage, payload);
      await thunkApi.dispatch(getCoveragePlans({ accountId, proposalId, coverage }));
      toast('Proposal was updated successfully', { type: 'success' });
    } catch (e) {
      console.warn(e);
    }
  },
);

export const deleteCoveragePlan = createAsyncThunk(
  'coverage-builder/delete-coverage-plan',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, planId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.coverageBuilder.deleteCoveragePlan(accountId, proposalId, coverage, planId);

      toast('Proposal was deleted successfully', { type: 'success' });

      thunkApi.dispatch(getCoveragePlans({ accountId, proposalId, coverage }));
      thunkApi.dispatch(getCoverageQuotePlans({ accountId, proposalId, coverage }));
      thunkApi.dispatch(getSharedContacts({ accountId, proposalId }));
      thunkApi.dispatch(getRequestedProposalsStatus({ accountId, proposalId }));
    } catch (e) {
      console.warn(e);
    }
  },
);

export const updateCoverageOffer = createAsyncThunk(
  'coverage-builder/update-coverage-offer',
  async (data, thunkApi) => {
    try {
      const { payload, coverage, offerId, read_token } = data;

      const { accountId, proposalId, config_id } = payload;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      delete payload.id;
      delete payload.comp_amount;
      delete payload.issuer_name;
      delete payload.created_timestamp;
      delete payload.accountId;
      delete payload.proposalId;
      delete payload.isBroker;
      delete payload.calculation;

      const response = await api.coverageBuilder.updateCoverageOffer(
        accountId,
        proposalId,
        config_id,
        coverage,
        payload,
        offerId,
        read_token,
      );

      toast(`Your ${COVERAGE_NAMES[coverage]} offer was successfully updated!`, {
        type: 'success',
      });

      thunkApi.dispatch(getCoveragePlans({ accountId, proposalId, coverage, read_token }));
      thunkApi.dispatch(getCoverageQuotePlans({ accountId, proposalId, coverage, read_token }));

      return response;
    } catch (err) {
      toast(ERROR.SYSTEM_ERROR, {
        type: 'error',
      });
      console.warn(err);
    }
  },
);

export const getCoverageOffers = createAsyncThunk(
  'coverage-builder/get-coverage-offers',
  async (data, thunkApi) => {
    try {
      const {
        accountId,
        proposalId,
        coverage,
        configId,
        brokerConfig,
        setOffers,
        setIsLoading,
        read_token,
        params,
      } = data;

      setIsLoading(true);

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();

        api.apiClient.setToken(token);
      }

      const response = await api.coverageBuilder.getCoverageOffers(
        accountId,
        proposalId,
        coverage,
        configId,
        params,
      );

      const groupMembers = thunkApi.getState().groupMembers.groupMembers;

      const brokerOffer = {
        ...brokerConfig,
        issuer_name: 'broker',
        status: 'selected',
      };

      const updatedResponse = [brokerOffer, ...response];

      const dvCoverages = ['dental', 'vision'];
      const ahcCoverages = ['accident', 'hospital', 'critical_illness'];

      const calculatedResponse = updatedResponse?.map((item) => {
        if (item?.status !== 'pending') {
          let calculatedRates = {};

          if (coverage === 'medical') {
            if (item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateMedicalRates(
                groupMembers,
                item,
                {},
                item?.selected_plan_name,
                coverage,
              );
            }
            if (!item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateMedicalAgeRates(groupMembers, item);
            }
          }

          if (dvCoverages.includes(coverage)) {
            calculatedRates = calculateDentalVisionRates(
              groupMembers,
              item,
              {},
              item?.selected_plan_name,
              coverage,
            );
          }

          if (coverage === 'life') {
            if (item?.rate_tier_type === '4tier') {
              if (item?.is_uni_tobacco) {
                calculatedRates = calculateUniLifeRates(groupMembers, item);
              }

              if (!item?.is_uni_tobacco) {
                calculatedRates = calculateLifeRates(groupMembers, item);
              }
            }

            if (item?.rate_tier_type !== '4tier') {
              calculatedRates = calculateLifeConfigurationAgeRates(groupMembers, item);
            }
          }

          if (coverage === 'disability') {
            if (item?.rate_tier_type === 'employee') {
              calculatedRates = calculateDisabilityRates(groupMembers, item, {});
            }

            if (item?.rate_tier_type !== 'employee') {
              calculatedRates = calculateDisabilityAgeRates(groupMembers, item);
            }
          }

          if (ahcCoverages.includes(coverage)) {
            if (item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateConfigurationRates(groupMembers, item, {});
            }

            if (!item?.rate_tier_type?.includes('tier')) {
              calculatedRates = calculateConfigurationAgeRates(groupMembers, item);
            }
          }

          return { ...item, calculation: calculatedRates };
        }
        return item;
      });

      setOffers(calculatedResponse);

      return response;
    } catch (err) {
      console.error(err);
    } finally {
      const { setIsLoading } = data;

      setIsLoading(false);
    }
  },
);

// TEMPLATES

export const createCoverageTemplate = createAsyncThunk(
  'coverage-builder/create-coverage-templates',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, payload } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await await api.coverageBuilder.createCoverageTemplate(
        accountId,
        proposalId,
        coverage,
        payload,
      );

      toast('Template was created successfully', { type: 'success' });
    } catch (err) {
      console.warn(err);
    }
  },
);

export const getCoverageTemplates = createAsyncThunk(
  'coverage-builder/get-coverage-templates',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, page, per_page } = data;

      const token = await auth.currentUser.getIdToken();
      api.apiClient.setToken(token);

      const payload = { page, per_page };

      const response = await api.coverageBuilder.getCoverageTemplates(
        accountId,
        proposalId,
        coverage,
        payload,
      );

      return { coverage, response };
    } catch (err) {
      console.warn(err);
    }
  },
);

export const deleteCoverageTemplate = createAsyncThunk(
  'coverage-builder/delete-coverage-template',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, templateId } = data;

      const token = await auth.currentUser.getIdToken();

      api.apiClient.setToken(token);

      await api.coverageBuilder.deleteCoverageTemplate(accountId, proposalId, coverage, templateId);

      toast(`${coverage} template was deleted successfully!`, { type: 'success' });

      thunkApi.dispatch(getCoverageTemplates({ accountId, proposalId, coverage }));
    } catch (e) {
      console.warn(e);
    }
  },
);

export const getCoverageQuotePlans = createAsyncThunk(
  'coverage-builder/get-coverage-quote-plans',
  async (data, thunkApi) => {
    try {
      const { accountId, proposalId, coverage, read_token } = data;

      if (!read_token) {
        const token = await auth.currentUser.getIdToken();
        api.apiClient.setToken(token);
      }

      const response = await api.coverageBuilder.getCoverageQuotePlans(
        accountId,
        proposalId,
        coverage,
        read_token,
      );

      return { coverage, response };
    } catch (err) {
      console.warn(err);
    }
  },
);

// CLEAR STATE

export const clearCoverageBuilderState = createAsyncThunk(
  'coverage-builder/clear-state',
  (data, thunkApi) => {
    thunkApi.dispatch(clearCoveragesBuilderState());
    // thunkApi.dispatch(clearVisionTemplateState());
  },
);
