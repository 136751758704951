import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.critical;

export const getCriticalPlansSelector = createSelector(getState, (state) => state.criticalPlans);

// ISSUER

export const getIssuerCriticalPlansSelector = createSelector(
  getState,
  (state) => state.issuerCriticalPlans,
);
export const isLoadingIssuerCriticalPlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerCriticalPlans,
);
