import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';

import { Input } from 'src/components';

import {
  normalizeAllAgeUniRates,
  lifeUniAllAges,
  LIFE_UNI_ALL_AGES_NAMES,
  lifeTobaccoAllAges,
  LIFE_TOBACCO_ALL_AGES_NAMES,
  lifeNonTobaccoAllAges,
  LIFE_NON_TOBACCO_ALL_AGES_NAMES,
  normalizeAllAgeNonTobaccoRates,
  normalizeAllAgeTobaccoRates,
} from './lifeAllAges';
import {
  lifeNonTobaccoFiveYearAges,
  lifeTobaccoFiveYearAges,
  lifeUniFiveYearAges,
  LIFE_NON_TOBACCO_FIVE_YEAR_NAMES,
  LIFE_TOBACCO_FIVE_YEAR_NAMES,
  LIFE_UNI_FIVE_YEAR_NAMES,
  normalizeLifeNonTobaccoFiveYearAgeRates,
  normalizeLifeTobaccoFiveYearAgeRates,
  normalizeLifeUniFiveYearAgeRates,
} from './lifeFiveYearAges';

import {
  lifeNonTobaccoTenYearAges,
  lifeTobaccoTenYearAges,
  lifeUniTenYearAges,
  LIFE_NON_TOBACCO_TEN_YEAR_NAMES,
  LIFE_TOBACCO_TEN_YEAR_NAMES,
  LIFE_UNI_TEN_YEAR_NAMES,
  normalizeLifeNonTobaccoTenYearAgeRates,
  normalizeLifeTobaccoTenYearAgeRates,
  normalizeLifeUniTenYearAgeRates,
} from './lifeTenYearAges';

import { threeDigitsRegex } from 'src/constants/regularExpression';

import classes from './lifeAgeBandedRates.module.scss';

export const LifeAgeBandedRates = (props) => {
  const {
    tierType,
    ageBandedRates,

    onSetAllAgeBandedUniRates,
    onSetFiveYearAgeBandedUniRates,
    onSetTenYearAgeBandedUniRates,
    onSetAllAgeBandedTobaccoRates,
    onSetFiveYearAgeBandedTobaccoRates,
    onSetTenYearAgeBandedTobaccoRates,

    allAgeBandedUni,
    setAllAgeBandeUniPayload,
    allAgeBandedTobacco,
    setAllAgeBandedTobaccoPayload,
    allAgeBandedNonTobacco,
    setAllAgeBandedNonTobaccoPayload,

    fiveYearBandedUni,
    setFiveYearBandeUniPayload,
    fiveYearBandedTobacco,
    setFiveYearBandedTobaccoPayload,
    fiveYearBandedNonTobacco,
    setFiveYearBandedNonTobaccoPayload,

    tenYearBandeUni,
    setTenYearBandeUniPayload,
    tenYearBandedTobacco,
    setTenYearBandedTobaccoPayload,
    tenYearBandedNonTobacco,
    setTenYearBandedNonTobaccoPayload,

    isUniTobacco,
    isEditMode = true,
    isIssuer = false,
  } = props;

  const [allAgeUniBandedRates, setAllAgeUniBandedRates] = useState({});
  const [allAgeTobaccoBandedRates, setAllAgeTobaccoBandedRates] = useState({});
  const [allAgeNonTobaccoBandedRates, setAllAgeNonTobaccoBandedRates] = useState({});

  const [fiveYearAgeUniRates, setFiveYearAgeUniRates] = useState({});
  const [fiveYearAgeTobaccoRates, setFiveYearAgeTobaccoRates] = useState({});
  const [fiveYearAgeNonTobaccoRates, setFiveYearAgeNonTobaccoRates] = useState({});

  const [tenYearAgeUniRates, setTenYearAgeUniRates] = useState({});
  const [tenYearAgeTobaccoRates, setTenYearAgeTobaccoRates] = useState({});
  const [tenYearAgeNonTobaccoRates, setTenYearAgeNonTobaccoRates] = useState({});

  const [allAgeBandedUniRatesPayload, setAllAgeBandedUniRatesPayload] = useState({});
  const [allAgeBandedTobaccoRatesPayload, setAllAgeBandedTobaccoRatesPayload] = useState({});

  const [fiveYearAgeUniRatesPayload, setFiveYearAgeUniRatesPayload] = useState({});
  const [fiveYearAgeTobaccoRatesPayload, setFiveYearAgeTobaccoRatesPayload] = useState({});

  const [tenYearAgeUniRatesPayload, setTenYearAgeUniRatesPayload] = useState({});
  const [tenYearAgeTobaccoRatesPayload, setTenYearAgeTobaccoRatesPayload] = useState({});

  const [selectedTarget, setSelectedTarget] = useState('');

  useEffect(() => {
    setAllAgeUniBandedRates(
      isIssuer ? allAgeBandedUni || lifeUniAllAges(ageBandedRates) : lifeUniAllAges(ageBandedRates),
    );
    setAllAgeTobaccoBandedRates(
      isIssuer
        ? allAgeBandedTobacco || lifeTobaccoAllAges(ageBandedRates)
        : lifeTobaccoAllAges(ageBandedRates),
    );
    setAllAgeNonTobaccoBandedRates(
      isIssuer
        ? allAgeBandedNonTobacco || lifeNonTobaccoAllAges(ageBandedRates)
        : lifeNonTobaccoAllAges(ageBandedRates),
    );

    setFiveYearAgeUniRates(
      isIssuer
        ? fiveYearBandedUni || lifeUniFiveYearAges(ageBandedRates)
        : lifeUniFiveYearAges(ageBandedRates),
    );
    setFiveYearAgeTobaccoRates(
      isIssuer
        ? fiveYearBandedTobacco || lifeTobaccoFiveYearAges(ageBandedRates)
        : lifeTobaccoFiveYearAges(ageBandedRates),
    );
    setFiveYearAgeNonTobaccoRates(
      isIssuer
        ? fiveYearBandedNonTobacco || lifeNonTobaccoFiveYearAges(ageBandedRates)
        : lifeNonTobaccoFiveYearAges(ageBandedRates),
    );

    setTenYearAgeUniRates(
      isIssuer
        ? tenYearBandeUni || lifeUniTenYearAges(ageBandedRates)
        : lifeUniTenYearAges(ageBandedRates),
    );
    setTenYearAgeTobaccoRates(
      isIssuer
        ? tenYearBandedTobacco || lifeTobaccoTenYearAges(ageBandedRates)
        : lifeTobaccoTenYearAges(ageBandedRates),
    );
    setTenYearAgeNonTobaccoRates(
      isIssuer
        ? tenYearBandedNonTobacco || lifeNonTobaccoTenYearAges(ageBandedRates)
        : lifeNonTobaccoTenYearAges(ageBandedRates),
    );
  }, [
    ageBandedRates,
    allAgeBandedNonTobacco,
    allAgeBandedTobacco,
    allAgeBandedUni,
    fiveYearBandedNonTobacco,
    fiveYearBandedTobacco,
    fiveYearBandedUni,
    isIssuer,
    tenYearBandeUni,
    tenYearBandedNonTobacco,
    tenYearBandedTobacco,
  ]);

  useEffect(() => {
    if (tierType === 'all_ages' && isUniTobacco) {
      const payload = normalizeAllAgeUniRates(allAgeUniBandedRates);

      setAllAgeBandedUniRatesPayload(payload);
    }
  }, [tierType, allAgeUniBandedRates, isUniTobacco]);

  useEffect(() => {
    if (tierType === 'all_ages' && !isUniTobacco) {
      const tobaccoPayload = normalizeAllAgeTobaccoRates(allAgeTobaccoBandedRates);
      const nonTobaccoPayload = normalizeAllAgeNonTobaccoRates(allAgeNonTobaccoBandedRates);

      const payload = {
        ...tobaccoPayload,
        ...nonTobaccoPayload,
      };

      setAllAgeBandedTobaccoRatesPayload(payload);
    }
  }, [tierType, allAgeTobaccoBandedRates, isUniTobacco, allAgeNonTobaccoBandedRates]);

  useEffect(() => {
    if (tierType === 'banded_5' && isUniTobacco) {
      const payload = normalizeLifeUniFiveYearAgeRates(fiveYearAgeUniRates);

      setFiveYearAgeUniRatesPayload(payload);
    }
  }, [tierType, fiveYearAgeUniRates, isUniTobacco]);

  useEffect(() => {
    if (tierType === 'banded_5' && !isUniTobacco) {
      const tobaccoPayload = normalizeLifeTobaccoFiveYearAgeRates(fiveYearAgeTobaccoRates);
      const nonTobaccoPayload = normalizeLifeNonTobaccoFiveYearAgeRates(fiveYearAgeNonTobaccoRates);

      const payload = {
        ...tobaccoPayload,
        ...nonTobaccoPayload,
      };

      setFiveYearAgeTobaccoRatesPayload(payload);
    }
  }, [fiveYearAgeNonTobaccoRates, fiveYearAgeTobaccoRates, isUniTobacco, tierType]);

  useEffect(() => {
    if (tierType === 'banded_10' && isUniTobacco) {
      const payload = normalizeLifeUniTenYearAgeRates(tenYearAgeUniRates);

      setTenYearAgeUniRatesPayload(payload);
    }
  }, [tierType, tenYearAgeUniRates, isUniTobacco]);

  useEffect(() => {
    if (tierType === 'banded_10' && !isUniTobacco) {
      const tobaccoPayload = normalizeLifeTobaccoTenYearAgeRates(tenYearAgeTobaccoRates);
      const nonTobaccoPayload = normalizeLifeNonTobaccoTenYearAgeRates(tenYearAgeNonTobaccoRates);

      const payload = {
        ...tobaccoPayload,
        ...nonTobaccoPayload,
      };

      setTenYearAgeTobaccoRatesPayload(payload);
    }
  }, [tenYearAgeTobaccoRates, tenYearAgeNonTobaccoRates, isUniTobacco, tierType]);

  // UNI-RATES
  useEffect(() => {
    if (onSetAllAgeBandedUniRates) {
      onSetAllAgeBandedUniRates(allAgeBandedUniRatesPayload);
    }
  }, [onSetAllAgeBandedUniRates, allAgeBandedUniRatesPayload]);

  useEffect(() => {
    if (onSetFiveYearAgeBandedUniRates) {
      onSetFiveYearAgeBandedUniRates(fiveYearAgeUniRatesPayload);
    }
  }, [onSetFiveYearAgeBandedUniRates, fiveYearAgeUniRatesPayload]);

  useEffect(() => {
    if (onSetTenYearAgeBandedUniRates) {
      onSetTenYearAgeBandedUniRates(tenYearAgeUniRatesPayload);
    }
  }, [onSetTenYearAgeBandedUniRates, tenYearAgeUniRatesPayload]);
  //  TOBACCO-NON-TOBACCO RATES
  useEffect(() => {
    if (onSetAllAgeBandedTobaccoRates) {
      onSetAllAgeBandedTobaccoRates(allAgeBandedTobaccoRatesPayload);
    }
  }, [onSetAllAgeBandedTobaccoRates, allAgeBandedTobaccoRatesPayload]);

  useEffect(() => {
    if (onSetFiveYearAgeBandedTobaccoRates) {
      onSetFiveYearAgeBandedTobaccoRates(fiveYearAgeTobaccoRatesPayload);
    }
  }, [onSetFiveYearAgeBandedTobaccoRates, fiveYearAgeTobaccoRatesPayload]);

  useEffect(() => {
    if (onSetTenYearAgeBandedTobaccoRates) {
      onSetTenYearAgeBandedTobaccoRates(tenYearAgeTobaccoRatesPayload);
    }
  }, [onSetTenYearAgeBandedTobaccoRates, tenYearAgeTobaccoRatesPayload]);

  const onFocusInput = useCallback((event, target, value, setValue) => {
    setSelectedTarget(target);

    if (Number(event.target.value) === 0) {
      const payload = {
        ...value,
        [target]: '',
      };

      return setValue(payload);
    }
  }, []);

  const onBlurInput = useCallback((event, target, value, setValue) => {
    setSelectedTarget('');

    if (!event.target.value) {
      const payload = {
        ...value,
        [target]: 0,
      };

      return setValue(payload);
    }
  }, []);

  const onChangeAllBandedAgeUniInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...allAgeUniBandedRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setAllAgeBandeUniPayload(payload);
        }
        return setAllAgeUniBandedRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...allAgeUniBandedRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setAllAgeBandeUniPayload(payload);
        }
        return setAllAgeUniBandedRates(payload);
      }

      payload = {
        ...allAgeUniBandedRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setAllAgeBandeUniPayload(payload);
      }
      setAllAgeUniBandedRates(payload);
    },
    [allAgeUniBandedRates, isIssuer, selectedTarget, setAllAgeBandeUniPayload],
  );

  const onChangeAllBandedAgeTobaccoInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...allAgeTobaccoBandedRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setAllAgeBandedTobaccoPayload(payload);
        }
        return setAllAgeTobaccoBandedRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...allAgeTobaccoBandedRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setAllAgeBandedTobaccoPayload(payload);
        }
        return setAllAgeTobaccoBandedRates(payload);
      }

      payload = {
        ...allAgeTobaccoBandedRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setAllAgeBandedTobaccoPayload(payload);
      }
      setAllAgeTobaccoBandedRates(payload);
    },
    [allAgeTobaccoBandedRates, isIssuer, selectedTarget, setAllAgeBandedTobaccoPayload],
  );

  const onChangeAllBandedAgeNonTobaccoInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...allAgeNonTobaccoBandedRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setAllAgeBandedNonTobaccoPayload(payload);
        }
        return setAllAgeNonTobaccoBandedRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...allAgeNonTobaccoBandedRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setAllAgeBandedNonTobaccoPayload(payload);
        }
        return setAllAgeNonTobaccoBandedRates(payload);
      }

      payload = {
        ...allAgeNonTobaccoBandedRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setAllAgeBandedNonTobaccoPayload(payload);
      }
      setAllAgeNonTobaccoBandedRates(payload);
    },
    [allAgeNonTobaccoBandedRates, isIssuer, selectedTarget, setAllAgeBandedNonTobaccoPayload],
  );

  const onChangeFiveYearAgeUniInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...fiveYearAgeUniRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setFiveYearBandeUniPayload(payload);
        }
        return setFiveYearAgeUniRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...fiveYearAgeUniRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setFiveYearBandeUniPayload(payload);
        }
        return setFiveYearAgeUniRates(payload);
      }

      payload = {
        ...fiveYearAgeUniRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setFiveYearBandeUniPayload(payload);
      }
      setFiveYearAgeUniRates(payload);
    },
    [fiveYearAgeUniRates, isIssuer, selectedTarget, setFiveYearBandeUniPayload],
  );

  const onChangeFiveYearAgeTobaccoInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...fiveYearAgeTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setFiveYearBandedTobaccoPayload(payload);
        }
        return setFiveYearAgeTobaccoRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...fiveYearAgeTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setFiveYearBandedTobaccoPayload(payload);
        }
        return setFiveYearAgeTobaccoRates(payload);
      }

      payload = {
        ...fiveYearAgeTobaccoRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setFiveYearBandedTobaccoPayload(payload);
      }
      setFiveYearAgeTobaccoRates(payload);
    },
    [fiveYearAgeTobaccoRates, isIssuer, selectedTarget, setFiveYearBandedTobaccoPayload],
  );

  const onChangeFiveYearAgeNonTobaccoInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...fiveYearAgeNonTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setFiveYearBandedNonTobaccoPayload(payload);
        }
        return setFiveYearAgeNonTobaccoRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...fiveYearAgeNonTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setFiveYearBandedNonTobaccoPayload(payload);
        }
        return setFiveYearAgeNonTobaccoRates(payload);
      }

      payload = {
        ...fiveYearAgeNonTobaccoRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setFiveYearBandedNonTobaccoPayload(payload);
      }
      setFiveYearAgeNonTobaccoRates(payload);
    },
    [fiveYearAgeNonTobaccoRates, isIssuer, selectedTarget, setFiveYearBandedNonTobaccoPayload],
  );

  const onChangeTenYearAgeUniInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...tenYearAgeUniRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setTenYearBandeUniPayload(payload);
        }
        return setTenYearAgeUniRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...tenYearAgeUniRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setTenYearBandeUniPayload(payload);
        }
        return setTenYearAgeUniRates(payload);
      }

      payload = {
        ...tenYearAgeUniRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setTenYearBandeUniPayload(payload);
      }
      setTenYearAgeUniRates(payload);
    },
    [tenYearAgeUniRates, selectedTarget, isIssuer, setTenYearBandeUniPayload],
  );

  const onChangeTenYearAgeTobaccoInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...tenYearAgeTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setTenYearBandedTobaccoPayload(payload);
        }
        return setTenYearAgeTobaccoRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...tenYearAgeTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setTenYearBandedTobaccoPayload(payload);
        }
        return setTenYearAgeTobaccoRates(payload);
      }

      payload = {
        ...tenYearAgeTobaccoRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setTenYearBandedTobaccoPayload(payload);
      }
      setTenYearAgeTobaccoRates(payload);
    },
    [tenYearAgeTobaccoRates, selectedTarget, isIssuer, setTenYearBandedTobaccoPayload],
  );

  const onChangeTenYearAgeNonTobaccoInput = useCallback(
    (event) => {
      let payload = {};

      if (!event.target.value.match(threeDigitsRegex)) {
        payload = {
          ...tenYearAgeNonTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, event.target.value.length - 1),
        };
        if (isIssuer) {
          return setTenYearBandedNonTobaccoPayload(payload);
        }
        return setTenYearAgeNonTobaccoRates(payload);
      }

      if (!event.target.value.includes('.')) {
        const payload = {
          ...tenYearAgeNonTobaccoRates,
          [selectedTarget]: event.target.value.slice(0, 5),
        };
        if (isIssuer) {
          return setTenYearBandedNonTobaccoPayload(payload);
        }
        return setTenYearAgeNonTobaccoRates(payload);
      }

      payload = {
        ...tenYearAgeNonTobaccoRates,
        [selectedTarget]: event.target.value,
      };
      if (isIssuer) {
        return setTenYearBandedNonTobaccoPayload(payload);
      }
      setTenYearAgeNonTobaccoRates(payload);
    },
    [tenYearAgeNonTobaccoRates, selectedTarget, isIssuer, setTenYearBandedNonTobaccoPayload],
  );

  const normalizeUniAllAgeLabel = useCallback((item) => {
    return LIFE_UNI_ALL_AGES_NAMES[item] === 'Age 80' ? 'Age 80+' : LIFE_UNI_ALL_AGES_NAMES[item];
  }, []);

  const renderAllAgeUniBandedRates = useCallback(
    () =>
      Object?.keys(allAgeUniBandedRates)?.map((item) => (
        <td key={item} className={isIssuer && classes.IssuerLifeAgeBandedWrapper}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={normalizeUniAllAgeLabel(item)}
                  tierType="number"
                  value={allAgeUniBandedRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, allAgeUniBandedRates, setAllAgeUniBandedRates)
                  }
                  onBlur={(event) =>
                    onBlurInput(event, item, allAgeUniBandedRates, setAllAgeUniBandedRates)
                  }
                  onChange={onChangeAllBandedAgeUniInput}
                  type="number"
                />
                {isIssuer && `(${normalizeUniAllAgeLabel(item)})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${allAgeUniBandedRates[item] || '0'} (${normalizeUniAllAgeLabel(item)})`}
          </>
        </td>
      )),
    [
      allAgeUniBandedRates,
      isEditMode,
      isIssuer,
      normalizeUniAllAgeLabel,
      onBlurInput,
      onChangeAllBandedAgeUniInput,
      onFocusInput,
    ],
  );

  const renderAllAgeTobaccoBandedRates = useCallback(() => {
    return (
      <div className={isIssuer ? classes.IssuerTobaccoWrapper : classes.TobaccoWrapper}>
        <div className={isIssuer ? classes.IssuerTobaccoItem : classes.TobaccoItem}>
          {Object?.keys(allAgeTobaccoBandedRates)?.map((item) => (
            <td key={item} className={isIssuer && classes.IssuerLifeAgeBandedWrapper}>
              <>
                {isEditMode && (
                  <>
                    <Input
                      label={LIFE_TOBACCO_ALL_AGES_NAMES[item]}
                      tierType="number"
                      value={allAgeTobaccoBandedRates[item]}
                      onFocus={(event) =>
                        onFocusInput(
                          event,
                          item,
                          allAgeTobaccoBandedRates,
                          setAllAgeTobaccoBandedRates,
                        )
                      }
                      onBlur={(event) =>
                        onBlurInput(
                          event,
                          item,
                          allAgeTobaccoBandedRates,
                          setAllAgeTobaccoBandedRates,
                        )
                      }
                      onChange={onChangeAllBandedAgeTobaccoInput}
                      type="number"
                    />
                  </>
                )}
                {!isEditMode &&
                  isIssuer &&
                  `$${allAgeTobaccoBandedRates[item] || '0'} (${
                    LIFE_TOBACCO_ALL_AGES_NAMES[item]
                  })`.replace(' Tobacco', '')}
              </>
            </td>
          ))}
        </div>
        <div className={isIssuer ? classes.IssuerTobaccoItem : classes.TobaccoItem}>
          {Object?.keys(allAgeNonTobaccoBandedRates)?.map((item) => (
            <td key={item} className={isIssuer && classes.IssuerLifeAgeBandedWrapper}>
              <>
                {isEditMode && (
                  <>
                    <Input
                      label={LIFE_NON_TOBACCO_ALL_AGES_NAMES[item]}
                      tierType="number"
                      value={allAgeNonTobaccoBandedRates[item]}
                      onFocus={(event) =>
                        onFocusInput(
                          event,
                          item,
                          allAgeNonTobaccoBandedRates,
                          setAllAgeNonTobaccoBandedRates,
                        )
                      }
                      onBlur={(event) =>
                        onBlurInput(
                          event,
                          item,
                          allAgeNonTobaccoBandedRates,
                          setAllAgeNonTobaccoBandedRates,
                        )
                      }
                      onChange={onChangeAllBandedAgeNonTobaccoInput}
                      type="number"
                    />
                  </>
                )}
                {!isEditMode &&
                  isIssuer &&
                  `$${allAgeNonTobaccoBandedRates[item] || '0'} (${
                    LIFE_NON_TOBACCO_ALL_AGES_NAMES[item]
                  })`.replace(' Non-Tobacco', '')}
              </>
            </td>
          ))}
        </div>
        {isEditMode && (
          <div className={isIssuer && isEditMode ? classes.IssuerTobaccoAges : ''}>
            {Object?.keys(allAgeNonTobaccoBandedRates)?.map((item) => (
              <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
                <>
                  {isIssuer &&
                    `(${LIFE_NON_TOBACCO_ALL_AGES_NAMES[item]})`.replace(' Non-Tobacco', '')}
                  {!isEditMode &&
                    isIssuer &&
                    `$${allAgeNonTobaccoBandedRates[item] || '0'} (${
                      LIFE_NON_TOBACCO_ALL_AGES_NAMES[item]
                    })`.replace(' Non-Tobacco', '')}
                </>
              </td>
            ))}
          </div>
        )}
      </div>
    );
  }, [
    allAgeNonTobaccoBandedRates,
    allAgeTobaccoBandedRates,
    isEditMode,
    isIssuer,
    onBlurInput,
    onChangeAllBandedAgeNonTobaccoInput,
    onChangeAllBandedAgeTobaccoInput,
    onFocusInput,
  ]);

  const normalizeUniFiveYearLabel = useCallback((item) => {
    return LIFE_UNI_FIVE_YEAR_NAMES[item] === 'Age 80' ? 'Age 80+' : LIFE_UNI_FIVE_YEAR_NAMES[item];
  }, []);

  const renderFiveAgeUniBandedRates = useCallback(
    () =>
      Object?.keys(fiveYearAgeUniRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={normalizeUniFiveYearLabel(item)}
                  tierType="number"
                  value={fiveYearAgeUniRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, fiveYearAgeUniRates, setFiveYearAgeUniRates)
                  }
                  onBlur={(event) =>
                    onBlurInput(event, item, fiveYearAgeUniRates, setFiveYearAgeUniRates)
                  }
                  onChange={onChangeFiveYearAgeUniInput}
                  type="number"
                />
                {isIssuer && `(${normalizeUniFiveYearLabel(item)})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${fiveYearAgeUniRates[item] || '0'} (${normalizeUniFiveYearLabel(item)})`}
          </>
        </td>
      )),
    [
      fiveYearAgeUniRates,
      isIssuer,
      isEditMode,
      normalizeUniFiveYearLabel,
      onFocusInput,
      onBlurInput,
      onChangeFiveYearAgeUniInput,
    ],
  );

  const renderFiveAgeTobaccoBandedRates = useCallback(() => {
    return (
      <div className={isIssuer ? classes.IssuerTobaccoWrapper : classes.TobaccoWrapper}>
        <div className={isIssuer ? classes.IssuerTobaccoItem : classes.TobaccoItem}>
          {Object?.keys(fiveYearAgeTobaccoRates)?.map((item) => (
            <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
              <>
                {isEditMode && (
                  <>
                    <Input
                      label={LIFE_TOBACCO_FIVE_YEAR_NAMES[item]}
                      tierType="number"
                      value={fiveYearAgeTobaccoRates[item]}
                      onFocus={(event) =>
                        onFocusInput(
                          event,
                          item,
                          fiveYearAgeTobaccoRates,
                          setFiveYearAgeTobaccoRates,
                        )
                      }
                      onBlur={(event) =>
                        onBlurInput(
                          event,
                          item,
                          fiveYearAgeTobaccoRates,
                          setFiveYearAgeTobaccoRates,
                        )
                      }
                      onChange={onChangeFiveYearAgeTobaccoInput}
                      type="number"
                    />
                  </>
                )}
                {!isEditMode &&
                  isIssuer &&
                  `$${fiveYearAgeTobaccoRates[item] || '0'} (${
                    LIFE_TOBACCO_FIVE_YEAR_NAMES[item]
                  })`.replace(' Tobacco', '')}
              </>
            </td>
          ))}
        </div>
        <div className={isIssuer ? classes.IssuerTobaccoItem : classes.TobaccoItem}>
          {Object?.keys(fiveYearAgeNonTobaccoRates)?.map((item) => (
            <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
              <>
                {isEditMode && (
                  <>
                    <Input
                      label={LIFE_NON_TOBACCO_FIVE_YEAR_NAMES[item]}
                      tierType="number"
                      value={fiveYearAgeNonTobaccoRates[item]}
                      onFocus={(event) =>
                        onFocusInput(
                          event,
                          item,
                          fiveYearAgeNonTobaccoRates,
                          setFiveYearAgeNonTobaccoRates,
                        )
                      }
                      onBlur={(event) =>
                        onBlurInput(
                          event,
                          item,
                          fiveYearAgeNonTobaccoRates,
                          setFiveYearAgeNonTobaccoRates,
                        )
                      }
                      onChange={onChangeFiveYearAgeNonTobaccoInput}
                      type="number"
                    />
                  </>
                )}
                {!isEditMode &&
                  isIssuer &&
                  `$${fiveYearAgeNonTobaccoRates[item] || '0'} (${
                    LIFE_NON_TOBACCO_FIVE_YEAR_NAMES[item]
                  })`.replace(' Non-Tobacco', '')}
              </>
            </td>
          ))}
        </div>
        {isEditMode && (
          <div className={isIssuer && isEditMode ? classes.IssuerTobaccoAges : ''}>
            {Object?.keys(fiveYearAgeNonTobaccoRates)?.map((item) => (
              <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
                <>
                  {isIssuer &&
                    `(${LIFE_NON_TOBACCO_FIVE_YEAR_NAMES[item]})`.replace(' Non-Tobacco', '')}
                  {!isEditMode &&
                    isIssuer &&
                    `$${fiveYearAgeNonTobaccoRates[item] || '0'} (${
                      LIFE_NON_TOBACCO_FIVE_YEAR_NAMES[item]
                    })`.replace(' Non-Tobacco', '')}
                </>
              </td>
            ))}
          </div>
        )}
      </div>
    );
  }, [
    fiveYearAgeNonTobaccoRates,
    fiveYearAgeTobaccoRates,
    isEditMode,
    isIssuer,
    onBlurInput,
    onChangeFiveYearAgeNonTobaccoInput,
    onChangeFiveYearAgeTobaccoInput,
    onFocusInput,
  ]);

  const normalizeUniTenYearLabel = useCallback((item) => {
    return LIFE_UNI_TEN_YEAR_NAMES[item] === 'Age 80' ? 'Age 80+' : LIFE_UNI_TEN_YEAR_NAMES[item];
  }, []);

  const renderTenAgeUniBandedRates = useCallback(
    () =>
      Object?.keys(tenYearAgeUniRates)?.map((item) => (
        <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
          <>
            {isEditMode && (
              <>
                <Input
                  label={normalizeUniTenYearLabel(item)}
                  tierType="number"
                  value={tenYearAgeUniRates[item]}
                  onFocus={(event) =>
                    onFocusInput(event, item, tenYearAgeUniRates, setTenYearAgeUniRates)
                  }
                  onBlur={(event) =>
                    onBlurInput(event, item, tenYearAgeUniRates, setTenYearAgeUniRates)
                  }
                  onChange={onChangeTenYearAgeUniInput}
                  type="number"
                />
                {isIssuer && `(${normalizeUniTenYearLabel(item)})`}
              </>
            )}
            {!isEditMode &&
              isIssuer &&
              `$${tenYearAgeUniRates[item] || '0'} (${normalizeUniTenYearLabel(item)})`}
          </>
        </td>
      )),
    [
      tenYearAgeUniRates,
      isIssuer,
      isEditMode,
      normalizeUniTenYearLabel,
      onFocusInput,
      onBlurInput,
      onChangeTenYearAgeUniInput,
    ],
  );

  const renderTenAgeTobaccoBandedRates = useCallback(() => {
    return (
      <div className={isIssuer ? classes.IssuerTobaccoWrapper : classes.TobaccoWrapper}>
        <div className={isIssuer ? classes.IssuerTobaccoItem : classes.TobaccoItem}>
          {Object?.keys(tenYearAgeTobaccoRates)?.map((item) => (
            <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
              <>
                {isEditMode && (
                  <>
                    <Input
                      label={LIFE_TOBACCO_TEN_YEAR_NAMES[item]}
                      tierType="number"
                      value={tenYearAgeTobaccoRates[item]}
                      onFocus={(event) =>
                        onFocusInput(event, item, tenYearAgeTobaccoRates, setTenYearAgeTobaccoRates)
                      }
                      onBlur={(event) =>
                        onBlurInput(event, item, tenYearAgeTobaccoRates, setTenYearAgeTobaccoRates)
                      }
                      onChange={onChangeTenYearAgeTobaccoInput}
                      type="number"
                    />
                  </>
                )}
                {!isEditMode &&
                  isIssuer &&
                  `$${tenYearAgeTobaccoRates[item] || '0'} (${
                    LIFE_TOBACCO_TEN_YEAR_NAMES[item]
                  })`.replace(' Tobacco', '')}
              </>
            </td>
          ))}
        </div>
        <div className={isIssuer ? classes.IssuerTobaccoItem : classes.TobaccoItem}>
          {Object?.keys(tenYearAgeNonTobaccoRates)?.map((item) => (
            <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrapper : ''}>
              <>
                {isEditMode && (
                  <>
                    <Input
                      label={LIFE_NON_TOBACCO_TEN_YEAR_NAMES[item]}
                      tierType="number"
                      value={tenYearAgeNonTobaccoRates[item]}
                      onFocus={(event) =>
                        onFocusInput(
                          event,
                          item,
                          tenYearAgeNonTobaccoRates,
                          setTenYearAgeNonTobaccoRates,
                        )
                      }
                      onBlur={(event) =>
                        onBlurInput(
                          event,
                          item,
                          tenYearAgeNonTobaccoRates,
                          setTenYearAgeNonTobaccoRates,
                        )
                      }
                      onChange={onChangeTenYearAgeNonTobaccoInput}
                      type="number"
                    />
                  </>
                )}
                {!isEditMode &&
                  isIssuer &&
                  `$${tenYearAgeNonTobaccoRates[item] || '0'} (${
                    LIFE_NON_TOBACCO_TEN_YEAR_NAMES[item]
                  })`.replace(' Non-Tobacco', '')}
              </>
            </td>
          ))}
        </div>
        {isEditMode && (
          <div className={isIssuer && isEditMode ? classes.IssuerTobaccoAges : ''}>
            {Object?.keys(tenYearAgeNonTobaccoRates)?.map((item) => (
              <td key={item} className={isIssuer ? classes.IssuerLifeAgeBandedWrappe : ''}>
                <>
                  {isIssuer &&
                    `(${LIFE_NON_TOBACCO_TEN_YEAR_NAMES[item]})`.replace(' Non-Tobacco', '')}
                  {!isEditMode &&
                    isIssuer &&
                    `$${tenYearAgeNonTobaccoRates[item] || '0'} (${
                      LIFE_NON_TOBACCO_TEN_YEAR_NAMES[item]
                    })`.replace(' Non-Tobacco', '')}
                </>
              </td>
            ))}
          </div>
        )}
      </div>
    );
  }, [
    tenYearAgeNonTobaccoRates,
    tenYearAgeTobaccoRates,
    isEditMode,
    isIssuer,
    onBlurInput,
    onChangeTenYearAgeNonTobaccoInput,
    onChangeTenYearAgeTobaccoInput,
    onFocusInput,
  ]);

  const ageBandedRatesStyles = classnames({
    [classes.LifeAgeBandedRates]: tierType !== 'all_ages' && isUniTobacco,
    [classes.AllLifeAgeBandedRates]: tierType === 'all_ages' && isUniTobacco,
  });

  return (
    <div className={!isIssuer ? ageBandedRatesStyles : ''}>
      {tierType === 'all_ages' && isUniTobacco && renderAllAgeUniBandedRates()}
      {tierType === 'banded_5' && isUniTobacco && renderFiveAgeUniBandedRates()}
      {tierType === 'banded_10' && isUniTobacco && renderTenAgeUniBandedRates()}

      {tierType === 'all_ages' && !isUniTobacco && renderAllAgeTobaccoBandedRates()}
      {tierType === 'banded_5' && !isUniTobacco && renderFiveAgeTobaccoBandedRates()}
      {tierType === 'banded_10' && !isUniTobacco && renderTenAgeTobaccoBandedRates()}
    </div>
  );
};
