export const normalizePlanLevel = (level) => {
  if (level === 'bronze') {
    return ['bronze', 'expanded_bronze'];
  } else {
    return level ? [level] : [];
  }
};

export const normalizePlanType = (type) => {
  if (type === 'PPO') {
    return 'PPO, POS';
  } else {
    return type ? [type] : [];
  }
};

export const isVisibleEditButton = (status) => {
  switch (status) {
    case 'declined':
    case 'selected':
    case 'approved':
      return false;

    default:
      return true;
  }
};

export const isVisibleStatus = (status) => {
  switch (status) {
    case 'completed':
    case 'selected':
    case 'approved':
      return true;

    default:
      return false;
  }
};
