import React, { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { BuilderSelect, BuilderCASelect } from 'src/features/coverageBuilder';
import {
  hospitalizationOptions,
  initialConfinementOptions,
  compMethodOptions,
} from 'src/constants/coverage';
import { currencyFormatter } from 'src/constants/currency';
import { AGES_80 } from 'src/features/coverageBuilder/cb_constants';

export const useHospitalBuild = () => {
  const initialState = {
    id: uuid(),
    ...AGES_80,
    is_current_rate: false,
    initial_hospital_confinement: '',
    per_day_hospitalization: '',
    comp_method: '',
    comp_amount_pct: '10',
    comp_pepm_dollar: null,
    comp_pppm_dollar: null,
    custom_fields: [],
    rate_tier_type: '3tier',
    rate_ee: null,
    rate_ee_sp: null,
    rate_ee_ch: null,
    rate_ee_1: null,
    rate_fam: null,
  };

  const [formData, setFormData] = useState(initialState);

  const onChange = useCallback((event, key) => {
    setFormData((prev) => ({ ...prev, [key]: event.target.value }));
  }, []);

  const build = {
    coverage: 'hospital',
    title: 'Hospital',
    card_options: {
      proposal: [
        {
          title: 'Initial Hospital Confinement',
          key: 'initial_hospital_confinement',
          format: (value) => currencyFormatter(value),
        },
        {
          title: 'Per Day Hospitalization',
          key: 'per_day_hospitalization',
          format: (value) => currencyFormatter(value),
        },
      ],
    },
    form_options: {
      initial_state: initialState,
      form_data: formData,
      setFormData,
    },
    form_content: () => [
      <BuilderSelect
        key="1"
        label="Initial Hospital Confinement"
        value={formData.initial_hospital_confinement}
        options={initialConfinementOptions}
        onChange={(event) => onChange(event, 'initial_hospital_confinement')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="2"
        label="Per Day Hospitalization"
        value={formData.per_day_hospitalization}
        options={hospitalizationOptions}
        onChange={(event) => onChange(event, 'per_day_hospitalization')}
        type="other"
        symbol="$"
        inputType="number"
      />,
      <BuilderSelect
        key="3"
        label="Compensation Method"
        value={formData.comp_method}
        options={compMethodOptions}
        onChange={(event) => onChange(event, 'comp_method')}
      />,
      <BuilderCASelect
        key="4"
        size="small"
        label="Compensation Amount"
        formData={formData}
        setFormData={setFormData}
      />,
    ],
    offer_details: [
      { key: 'initial_hospital_confinement', format: (value) => currencyFormatter(value) },
      { key: 'per_day_hospitalization', format: (value) => currencyFormatter(value) },
      { key: 'comp_method' },
    ],
  };

  return { build };
};
