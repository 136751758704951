import { createSelector } from '@reduxjs/toolkit';

const getState = (state) => state.hospital;

export const getHospitalPlansSelector = createSelector(getState, (state) => state.hospitalPlans);

// ISSUER
export const getIssuerHospitalPlansSelector = createSelector(
  getState,
  (state) => state.issuerHospitalPlans,
);
export const isLoadingIssuerHospitalPlansSelector = createSelector(
  getState,
  (state) => state.isLoadingIssuerHospitalPlans,
);
