import Base from './base';

class PlanConfiguration extends Base {
  getDentalIssuers = () => {
    return this.apiClient.get('issuers');
  };

  updateVisionPlanOrder = (accountId, proposalId, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/vision/config`,
      payload,
    );
  };

  getMedicalPlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/medical/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getDentalPlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/dental/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getMedicalQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/medical/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  getDentalQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/dental/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  updateDentalQuotePlanOrder = (accountId, proposalId, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/dental/config?selected_offers=true`,
      payload,
    );
  };

  getVisionPlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/vision/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getVisionQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/vision/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  updateVisionQuotePlanOrder = (accountId, proposalId, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/vision/config?selected_offers=true`,
      payload,
    );
  };

  getLifePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/life/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getLifeQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/life/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  updateLifeQuotePlanOrder = (accountId, proposalId, payload) => {
    return this.apiClient.put(
      `accounts/${accountId}/proposals/${proposalId}/rfp/life/config?selected_offers=true`,
      payload,
    );
  };

  getDisabilityPlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/disability/config${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getDisabilityQuotePlans = (accountId, proposalId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/disability/config?selected_offers=true${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  deleteMedicalPlanConfiguration = (accountId, proposalId, planId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/medical/config/${planId}`,
    );
  };

  deleteDentalPlanConfiguration = (accountId, proposalId, planId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/dental/config/${planId}`,
    );
  };

  deleteLifePlanConfiguration = (accountId, proposalId, planId) => {
    return this.apiClient.delete(
      `accounts/${accountId}/proposals/${proposalId}/rfp/life/config/${planId}`,
    );
  };

  sendProposalRequest = (accountId, proposalId, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/send-to-issuers`,
      payload,
    );
  };

  sendContactsProposalRequest = (accountId, proposalId, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/send-to-contacts`,
      payload,
    );
  };

  resendContactsProposalRequest = (accountId, proposalId, payload) => {
    return this.apiClient.post(
      `accounts/${accountId}/proposals/${proposalId}/rfp/resend-to-contact`,
      payload,
    );
  };

  getSharedCarriers = (accountId, proposalId) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/selected-issuers-lines`,
    );
  };

  getSharedContacts = (accountId, proposalId) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/selected-contacts-lines`,
    );
  };

  getMedicalOffers = (accountId, proposalId, medicalName, configId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${medicalName}/config/${configId}/offers${
        read_token ? `?token=${read_token}` : ''
      }`,
    );
  };

  getMedicalQuotes = (accountId, proposalId, medicalName, configId, read_token) => {
    return this.apiClient.get(
      `accounts/${accountId}/proposals/${proposalId}/rfp/${medicalName}/config/${configId}/offers?f[status]=selected,approved${
        read_token ? `&token=${read_token}` : ''
      }`,
    );
  };

  getRequestedProposalsStatus = (accountId, proposalId) => {
    return this.apiClient.get(`accounts/${accountId}/proposals/${proposalId}/rfp/all`);
  };
}

export default PlanConfiguration;
