export const fundingTypeData = [
  { name: 'Fully Insured', key: 'fully_insured' },
  { name: 'Level Funded', key: 'level_funded' },
];

export const planTypeData = [
  { name: 'HMO', key: 'HMO' },
  { name: 'PPO', key: 'PPO' },
];

export const waitingPeriodData = [
  { name: 'Yes', key: 'yes' },
  { name: 'No', key: 'no' },
];

export const networkCoverageData = [
  { name: 'MAC', key: 'MAC' },
  { name: 'UCR', key: 'UCR' },
];

export const annualMaxData = (onChange, value) => [
  { name: '$1,000', key: 1000 },
  { name: '$1,500', key: 1500 },
  { name: '$2,000', key: 2000 },
  { name: '$2,500', key: 2500 },
  { name: '$5,000', key: 5000 },
  { name: 'No Max', key: 99999 },
  {
    name: 'Other: ',
    key: 'other',
    type: 'numeric',
    onChange: (event) => onChange(event, 'dppo_annual_max_other', 'annual'),
    value: value,
  },
];

export const deductibleData = [
  { name: '$0', key: '0' },
  { name: '$25', key: '25' },
  { name: '$50', key: '50' },
  { name: '$75', key: '75' },
  { name: '$100', key: '100' },
];

export const preventativeData = [
  { name: '100%', key: 100 },
  { name: '90%', key: 90 },
  { name: '80%', key: 80 },
];

export const basicData = [
  { name: '100%', key: 100 },
  { name: '90%', key: 90 },
  { name: '80%', key: 80 },
  { name: '70%', key: 70 },
  { name: '60%', key: 60 },
  { name: '50%', key: 50 },
];

export const majorData = [
  { name: '80%', key: 80 },
  { name: '70%', key: 70 },
  { name: '60%', key: 60 },
  { name: '50%', key: 50 },
];

export const orthodonticData = [
  { name: 'None', key: 'none' },
  { name: 'Child Only', key: 'child' },
  { name: 'Adult + Child', key: 'adult_child' },
];

export const orthodonticCoverageData = [
  { name: '$1,000', key: 1000 },
  { name: '$1,500', key: 1500 },
  { name: '$2,000', key: 2000 },
  { name: '$2,500', key: 2500 },
];

export const implantCoverageData = [
  { name: 'Yes', key: 'yes' },
  { name: 'No', key: 'no' },
];

export const compensationMethodData = [
  { name: 'Levelized', key: 'levelized' },
  { name: 'Heaped', key: 'heaped' },
];

export const compensationAmountData = (
  selectedConfig,
  onChangePercentage,
  onChangePepmPppmValue,
) => [
  {
    name: 'Percent',
    key: 'Percent other',
    type: 'percentage',
    onChange: (event) => onChangePercentage(event, 'comp_amount_pct'),
    value: selectedConfig?.comp_amount_pct,
  },
  {
    name: 'PEPM',
    key: 'PEPM other',
    type: 'numeric',
    onChange: (event) => onChangePepmPppmValue(event, 'comp_pepm_dollar'),
    value: selectedConfig?.comp_pepm_dollar,
  },
  {
    name: 'PPPM',
    key: 'PPPM other',
    type: 'numeric',
    onChange: (event) => onChangePepmPppmValue(event, 'comp_pppm_dollar'),
    value: selectedConfig?.comp_pppm_dollar,
  },
];
