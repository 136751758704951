import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { getLifeOffers } from 'src/store/lifeConfiguration';

import { LifeConfigurationForm } from '../LifeConfigurationForm/LifeConfigurationForm';

import { normalizeDeathBenefit } from 'src/constants/requestProposal';
import { IssuerCoverageCard } from '../IssuerCoverageCard/IssuerCoverageCard';
import { LIFE_FORM_NAMES } from 'src/constants/formNames';

export const LifeConfiguration = (props) => {
  const { id, accountId, issuerId, proposalId, userId, brokerRequest } = props;

  const dispatch = useDispatch();

  const [selectedProposalIds, setSelectedProposalIds] = useState([]);

  const [lifeOffers, setLifeOffers] = useState([]);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);

  const offerStatus = lifeOffers?.find((item) => item.config_id === id)?.status;

  const getOffers = useCallback(() => {
    dispatch(
      getLifeOffers({
        accountId,
        proposalId,
        configId: id,
        setOffers: setLifeOffers,
        setIsLoading: setIsLoadingOffers,
      }),
    );
  }, [dispatch, id, accountId, proposalId]);

  useEffect(() => {
    if (id) {
      getOffers();
    }
    return () => {
      setLifeOffers([]);
    };
  }, [id, getOffers]);

  const handleOpenProposal = useCallback(
    (id) => () => {
      if (selectedProposalIds.includes(id)) {
        const updatedSelectedQuotes = _.reject(selectedProposalIds, (itemId) => itemId === id);

        setSelectedProposalIds(updatedSelectedQuotes);
        return;
      }

      setSelectedProposalIds([...selectedProposalIds, id]);
    },
    [selectedProposalIds],
  );

  const cardData = [
    {
      title: LIFE_FORM_NAMES.contribution_type,
      sub_title: brokerRequest?.contribution_type,
    },
    {
      title: LIFE_FORM_NAMES.death_benefit,
      sub_title: normalizeDeathBenefit(brokerRequest?.death_benefit),
    },
    {
      title: 'Current',
      sub_title: brokerRequest?.is_current_rate ? 'Yes' : 'No',
    },
  ];

  return (
    <IssuerCoverageCard
      id={id}
      selectedProposalIds={selectedProposalIds}
      handleOpenProposal={handleOpenProposal}
      cardData={cardData}
      isLoading={isLoadingOffers}
      status={offerStatus}
    >
      <LifeConfigurationForm
        id={id}
        accountId={accountId}
        proposalId={proposalId}
        userId={userId}
        issuerId={issuerId}
        brokerConfig={brokerRequest}
        lifeOffers={lifeOffers}
        getLifeOffers={getOffers}
      />
    </IssuerCoverageCard>
  );
};
