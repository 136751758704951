import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getIssuerDataSelector } from 'src/store/issuer';

import {
  getIssuerMedicalPlansSelecetor,
  isLoadingIssuerMedicalPlansSelector,
  getIssuerMedicalPlans,
} from 'src/store/medicalConfiguration';

import { EmptyField, Loader, LoaderWrapper } from 'src/components';
import { MedicalConfiguration } from '../../components';

import classes from './medicalPlans.module.scss';

export const MedicalPlans = (props) => {
  const { activeTab } = props;

  const dispatch = useDispatch();

  const params = useParams();
  const accountId = params?.id;
  const proposalId = params?.proposalId;

  const issuerData = useSelector(getIssuerDataSelector);
  const medicalPlans = useSelector(getIssuerMedicalPlansSelecetor);
  const isLoading = useSelector(isLoadingIssuerMedicalPlansSelector);

  const issuerId = issuerData?.issuer_id;

  useEffect(() => {
    dispatch(getIssuerMedicalPlans({ accountId, proposalId }));
  }, [dispatch, accountId, proposalId]);

  return isLoading ? (
    <div className={classes.Loader}>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    </div>
  ) : medicalPlans?.length > 0 ? (
    medicalPlans?.map((item) => (
      <div key={item.id}>
        <MedicalConfiguration
          id={item.id}
          activeTab={activeTab}
          accountId={accountId}
          proposalId={item.proposal_id}
          userId={issuerData?.id}
          issuerId={issuerId}
          brokerRequest={item}
        />
      </div>
    ))
  ) : (
    <EmptyField title="No medical RFP created yet." />
  );
};
