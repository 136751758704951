import { onBlurInput, onFocusInput } from 'src/components';

export const planTypeData = [
  { name: 'Short Term Disability', key: 'std' },
  { name: 'Long Term Disability', key: 'ltd' },
];

export const companyContributionData = [
  { name: 'Employer Paid', key: 'employer' },
  { name: 'Voluntary', key: 'voluntary' },
];

export const pctIncomeRequestData = (onChange, value, setValue) => {
  return [
    { name: '60%', key: 60 },
    { name: '66%', key: 66 },
    { name: '70%', key: 70 },
    { name: '20% non integrated', key: 20 },
    { name: '25% non integrated', key: 25 },
    { name: '30% non integrated', key: 30 },
    {
      name: 'Other: ',
      key: 'other',
      type: 'percentage',
      onChange: onChange,
      onFocus: (event) => onFocusInput(event, setValue),
      onBlur: (event) => onBlurInput(event, setValue),
      value: value,
    },
  ];
};

export const stdEliminationPeriodData = (onChange, value) => {
  return [
    { name: '0/7', key: '0/7' },
    { name: '7/7', key: '7/7' },
    { name: '7/14', key: '7/14' },
    { name: '14/14', key: '14/14' },
    { name: '14/30', key: '14/30' },
    { name: '30/30', key: '30/30' },
    {
      name: 'Other: ',
      key: 'other',
      type: 'text',
      onChange: onChange,
      value: value,
    },
  ];
};

export const stdBenefitPeriodData = (onChange, value, setValue) => {
  return [
    { name: '30 Days', key: '30' },
    { name: '60 Days', key: '60' },
    { name: '90 Days', key: '90' },
    { name: '180 Days', key: '180' },
    { name: '1 Year', key: '365' },
    { name: '2 Years', key: '730' },
    {
      name: 'Other: ',
      key: 'other',
      label: 'Days',
      type: 'text',
      onChange: onChange,
      onFocus: (event) => onFocusInput(event, setValue),
      onBlur: (event) => onBlurInput(event, setValue),
      value: value,
    },
  ];
};

export const ltdEliminationPeriodData = (onChange, value, setValue) => {
  return [
    { name: '30 Days', key: '30' },
    { name: '60 Days', key: '60' },
    { name: '90 Days', key: '90' },
    { name: '180 Days', key: '180' },
    {
      name: 'Other: ',
      key: 'other',
      label: 'Days',
      type: 'text',
      onChange: onChange,
      onFocus: (event) => onFocusInput(event, setValue),
      onBlur: (event) => onBlurInput(event, setValue),
      value: value,
    },
  ];
};

export const ltdBenefitPeriodData = [
  { name: 'Age 65', key: '65' },
  { name: 'Age 67', key: '67' },
  { name: 'SSRA', key: 'SSRA', tooltip: 'Social Security Retirement Age' },
  { name: '2 years', key: '2' },
  { name: '5 years', key: '5' },
];
